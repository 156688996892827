import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

//styles
import '../styles/header.css';
//images
import peoplexLogo from '../images/logo_peoplex.png';
import userAccount from '../images/user-account.png';
import searchIconHeader from '../images/search.png';
import searchIconHeaderGrey from '../images/search-grey.png';
import folderIcon from '../images/folder/folder.png';
import talentIcon from '../images/talentCard/Header/talent.png';
import logOutIcon from '../images/logOut.svg';
import closeCross from '../images/close-regular.svg';
import { useMsal } from '@azure/msal-react';
import { logOut } from '../services/logOut';

const Header = (props) => {
  const [headerSearcherPlaceholder, setHeaderSearcherPlaceholder] = useState('');
  const { instance } = useMsal();
  // Obtiene la ubicación actual de la página utilizando 'useLocation' de react-router-dom
  const location = useLocation();
  // Define condiciones para mostrar u ocultar elementos del encabezado
  const currentPath = location.pathname;
  const showHeader = currentPath !== '/';
  const showSearcherHeader = currentPath !== '/home' && currentPath !== '/canalatencion';
  const showFolderHeader = currentPath !== '/canalatencion';

  // Estado para controlar la visibilidad de un popup
  const [showPopup, setShowPopup] = useState(false);
  const handlePopupToggle = () => {
    setShowPopup((prevState) => !prevState);
  };

  // Estados para controlar la expansión y apertura del componente de búsqueda
  const [isExpanded, setIsExpanded] = useState(true);
  const [isSearcherOpen, setIsSearcherOpen] = useState(true);

  // Manejador para alternar la expansión y apertura del componente de búsqueda
  const handleToggle = (ev) => {
    // Asegúrate de que el clic no proviene de la lupa gris
    if (ev.target.classList.contains('header__greyIcon--item')) {
      return;
    }

    if (ev.target.classList.contains('searcherHeader__container')) {
      setIsExpanded(true);
      setIsSearcherOpen(true);

      //borra el contenido del input
      if (!isExpanded) {
        setHeaderSearcherPlaceholder('');
      }
    }
  };

  const handleGreyIconClick = (ev) => {
    ev.stopPropagation();
  };

  // Inicializa la variable "navigate" para cambiar de ruta en la navegación
  const navigate = useNavigate();

  // Función que maneja el cambio en el campo de búsqueda
  const handleChange = (ev) => {
    ev.preventDefault();
    setHeaderSearcherPlaceholder(ev.target.value);
    // props.handleFilterSearcher(ev.target.value);
  };

  // Función que maneja la tecla Enter para iniciar la búsqueda
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Cambia a la ruta deseada
      props.handleFilterSearcher(event.target.value);
      setHeaderSearcherPlaceholder('');
      props.setHandleUpperCategoryIdParam(
        '133' +
          (props.userInfoSSFF.custom15 === 'Y' || props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)' ? ',138' : '') +
          (props.userInfoSSFF.custom15 === 'Y' ? ',40' : '')
      ); //(BusinessEX-40/ PeoplEX-133/ Roles-138) //(BusinessEX-40/ PeoplEX-133/ Roles-138)
      navigate('/search/articles');
    }
  };

  const handleLogout = async () => {
    //const token = localStorage.getItem('jwtToken');
    const msalToken = localStorage.getItem('idToken');
    const ssffToken = localStorage.getItem('jwtSSFFToken');

    if (msalToken || ssffToken) {
      const success = await logOut(msalToken, ssffToken);
      if (!success) {
        console.error('El token no pudo ser invalidado correctamente.');
      }
    }

    // Eliminar datos locales
    localStorage.removeItem('autocompleteData');
    localStorage.removeItem('responseAuth');
    localStorage.removeItem('jwtSSFFToken');
    localStorage.removeItem('idToken');
    sessionStorage.clear();

    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    instance.logout();
    navigate('/');
  };

  // // Useffect para manejar el cierre del componente de búsqueda
  // useEffect(() => {
  //   const handleGlobalClick = (event) => {
  //     const isSearcherContainer = event.target.classList.contains('searcherHeader__container');
  //     const isSearcherInput = event.target.classList.contains('searcherHeader__input');

  //     // if (isSearcherOpen && !isSearcherContainer && !isSearcherInput) {
  //     //   setIsSearcherOpen(false);
  //     //   setIsExpanded(false);
  //     // }
  //   };

  //   document.addEventListener('click', handleGlobalClick);

  //   return () => {
  //     document.removeEventListener('click', handleGlobalClick);
  //   };
  // }, [isSearcherOpen]);

  const handleFolderClick = () => {
    navigate('/folder');
  };

  return (
    <>
      {showHeader && (
        <div className='header'>
          <Link to='/home' className='peoplex__logo'>
            <img src={peoplexLogo} className='peoplex__logo' alt='logo-telefonica' />
          </Link>
          <div className='header__icons'>
            {showSearcherHeader && (
              <div className={`searcherHeader__container ${isExpanded ? 'expanded' : ''}`} onClick={handleToggle}>
                <img
                  src={searchIconHeaderGrey}
                  alt='search-icon'
                  onClick={handleGreyIconClick}
                  className={`header__greyIcon--item ${isExpanded ? 'expanded' : ''}`}
                />
                <input
                  type='text'
                  placeholder='¿Qué estás buscando?...'
                  autoComplete='off'
                  className={`searcherHeader__input ${isExpanded ? 'expanded' : ''}`}
                  value={headerSearcherPlaceholder}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                <img src={searchIconHeader} alt='search-icon' className={`searcherHeader__container ${isExpanded ? 'expanded lupa' : ''}`} />
              </div>
            )}
            {showFolderHeader && (
              <Link to='/folder' className='folder-icon' onClick={handleFolderClick}>
                <img src={folderIcon} alt='folder-icon' className='header__icon--item' />
              </Link>
            )}
            <div className='userAccount__container' onClick={handlePopupToggle}>
              <img src={userAccount} alt='userAccount-icon' className='header__icon--item user-icon' />
            </div>
            {showPopup && (
              <div className='user__popup'>
                <div className='user__popup--close'>
                  <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={handlePopupToggle} />
                </div>
                <div className='user__popup--info'>
                  <p className='user__popup--mail'>{props.userInfoSSFF.username}</p>
                </div>
                <div className='user__popup--logOut' onClick={handleLogout}>
                  <img className='user__popup--logOutimg' src={logOutIcon} alt='logOuticon' />
                  <button className='user__popup--logOutbutton' onClick={handlePopupToggle}>
                    Log Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
