import React from 'react';
import { Link } from 'react-router-dom';

//styles
import '../styles/bubbles_categories.css';

//images
import nominaBeneficios from '../../images/bubbles/nomina.png';
import formacionDesarrollo from '../../images/bubbles/formacion.png';
import herramientas from '../../images/bubbles/Herramientas.png';
import vacaciones from '../../images/bubbles/Vacaciones.png';
import desarrollo from '../../images/bubbles/Desarrollo.png';
import talento from '../../images/bubbles/Talento.png';
import equipo from '../../images/bubbles/Equipo.png';
import elipse355 from '../../images/ellipse355.png';

// Mapeo de imágenes de categorías por nombre
const categoryImageMap = {
  'Nómina y Beneficios': nominaBeneficios,
  'Mi Desarrollo': formacionDesarrollo,
  'Herramientas y Políticas': herramientas,
  'Vacaciones y Permisos': vacaciones,
  'Desarrollo de mi Equipo': desarrollo,
  'Atracción y Retención de Talento': talento,
  'Compensación y Organización': equipo,
};

const BubblesCategories = ({ categories, setSelectedCategory, userInfoSSFF, extraInfoDescriptionCategories }) => {
  // Filtrar y ordenar las categorías por área
  const employeeCategories = categories.filter((category) => category.depth === 2 && category.parent === '133');
  const managerCategories = categories.filter((category) => category.depth === 2 && category.parent === '40');

  // Función para seleccionar aleatoriamente una imagen
  const getRandomCatImage = () => {
    const randomIndex = Math.floor(Math.random() * 10) + 1; // En random-categories hay 10 imágenes. Sumamos 1 para evitar que salga 0.
    return randomIndex;
  };

  return (
    <div className='categories' id='bubbles-categories'>
      {/* Agrupación: Área de Empleado */}
      <div className='categories__breadcrumb'>
        <img src={elipse355} alt='circle-icon' className='circle-icon' />
        <h4 className='categories__breadcrumb--title'>ÁREA DE EMPLEADO</h4>
      </div>
      <div className='categories__container'>
        {employeeCategories.map((category, index) => (
          <Link
            to='/subcategories'
            className='bubble__container'
            key={index}
            onClick={() =>
              // Cuando se hace clic en una burbuja de categoría, actualiza el estado del componente padre con la información de la categoría seleccionada. Esto permite al componente padre almacenar y utilizar esta información para mostrar subcategorías.
              setSelectedCategory({ name: category.name, id: category.id })
            }
          >
            <div className='bubble'>
              <img
                src={categoryImageMap[category.name] || require(`../../images/categories/random-categories/category${getRandomCatImage()}.jpg`)}
                alt={`categorie-${category.name}`}
              />
              <h2 className='bubble__title'>{category.name}</h2>
            </div>
          </Link>
        ))}
      </div>
      {/* Agrupación: Área de Manager */}
      {userInfoSSFF.custom15 === 'Y' && (
        <>
          <div className='categories__breadcrumb'>
            <img src={elipse355} alt='circle-icon' className='circle-icon' />
            <h4 className='categories__breadcrumb--title'>ÁREA DE MANAGER</h4>
          </div>
          <div className='categories__container'>
            {managerCategories.map((category, index) => (
              <Link
                to='/subcategories'
                className='bubble__container'
                key={index}
                onClick={() => setSelectedCategory({ name: category.name, id: category.id })}
              >
                <div className='bubble'>
                  <img
                    src={categoryImageMap[category.name] || require(`../../images/categories/random-categories/category${getRandomCatImage()}.jpg`)}
                    alt={`categorie-${category.name}`}
                  />
                  <h2 className='bubble__title'>{category.name}</h2>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BubblesCategories;
