const JIRA_API_URL = 'https://jirabi-tgt.atlassian.net/rest/api';

export const createJiraTicket = async (ticketData, filesAttached) => {
  const requestData = {
    ...ticketData,
    filesAttached: filesAttached || [],
  };
  const response = await fetch('/jira/createJiraTicket', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error('Error en la solicitud a JIRA');
  }

  const data = await response.json();
  return data;
};

export const getJiraTickets = async () => {
  const idToken = localStorage.getItem('idToken');

  try {
    const response = await fetch('/jira/getJiraTickets', {
      method: 'GET',
      headers: {
        'x-msal-Authorization': `Bearer ${idToken}`,
        // 'x-jwt-Authorization': `Bearer ${jwtSSFF}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los tickets de JIRA');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener los tickets:', error);
    throw error;
  }
};

export const updateJiraTicket = async (updatedTicketData, ticketId, filesAttached) => {
  const idToken = localStorage.getItem('idToken');

  const requestData = {
    ...updatedTicketData,
    ticketId,
    filesAttached: filesAttached || [],
  };

  const response = await fetch('/jira/updateJiraTicket', {
    method: 'PUT', // Cambia a 'PATCH' si tu API lo requiere
    headers: {
      'x-msal-Authorization': `Bearer ${idToken}`,
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error('Error en la solicitud de actualización a JIRA');
  }

  const data = await response.json();
  return data;
};

export const getJiraTicketAttachments = async (ticketId) => {
  try {
    const response = await fetch(`/jira/getJiraTicketsAttachments/${ticketId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error al obtener los tickets de JIRA');
    }

    /* const attachments = await response.json();

    // Convertir los archivos adjuntos a Base64
    const attachmentsWithBase64 = await Promise.all(
      attachments.map(async (att) => {
        const contentUrl = `${JIRA_API_URL}/2/attachment/content/${att.id}`;
        const base64Content = await urlToBase64(contentUrl);
        return {
          filename: att.filename,
          content: base64Content,
          id: att.id,
        };
      })
    );

    return attachmentsWithBase64; */
    return response.json();
  } catch (error) {
    console.error('Error al obtener los adjuntos del ticket:', error);
    throw error;
  }
};
/* export const downloadAttachmentJira = async (attachmentId) => {
  try {
    const response = await fetch(
      `/jira/downloadAttachmentJira/${attachmentId}`,
      {
        method: 'GET',
        headers: {},
      }
    );

    if (!response.ok) {
      throw new Error('Error al obtener el archivo adjunto de JIRA');
    }

    // Aquí esperamos la respuesta en formato blob
    const blob = await response.blob();

    // Verifica si el blob es un JSON, esto puede indicar que hubo un error
    const text = await blob.text();
    try {
      const jsonResponse = JSON.parse(text);
      // Si la respuesta es un JSON, muestra un error
      if (jsonResponse.error) {
        throw new Error(jsonResponse.error);
      }
    } catch (e) {
      // No era un JSON, continúa con la descarga
      console.log('Archivo válido, continuando con la descarga.');
    }

    // Crear una URL para el blob
    // Crear una URL para el blob
    const url = window.URL.createObjectURL(blob);

    // Crear un enlace para descargar el archivo
    const link = document.createElement('a');
    link.href = url;

    // Extraer el nombre del archivo si viene en los headers
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = `attachment_${attachmentId}.pdf`; // Nombre por defecto

    // Si se encuentra Content-Disposition, extraer el nombre del archivo
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (fileNameMatch && fileNameMatch[1]) {
        fileName = fileNameMatch[1];

        // Asegúrate de que el nombre del archivo termine con .pdf
        if (!fileName.endsWith('.pdf')) {
          fileName += '.pdf';
        }
      }
    }

    link.download = fileName;

    // Simular un clic para iniciar la descarga
    document.body.appendChild(link);
    link.click();

    // Remover el enlace después de que se descargue el archivo
    document.body.removeChild(link);

    // Liberar el blob URL
    window.URL.revokeObjectURL(url);

    return true; // Indica que la descarga fue exitosa
  } catch (error) {
    console.error('Error al descargar el archivo adjunto:', error);
    throw error;
  }
};
 */

export const downloadAttachmentJira = async (attachmentId) => {
  try {
    // Construir la URL de descarga del archivo adjunto
    const downloadUrl = `${JIRA_API_URL}/2/attachment/content/${attachmentId}`;

    // Crear un enlace temporal
    const link = document.createElement('a');
    link.href = downloadUrl;

    // Establecer el nombre del archivo por defecto con la extensión .pdf
    link.download = `attachment_${attachmentId}.pdf`; // Nombre del archivo descargado

    // Simular un clic para iniciar la descarga
    document.body.appendChild(link);
    link.click();

    // Remover el enlace después de que se haya simulado el clic
    document.body.removeChild(link);

    return true; // Indica que la acción fue exitosa
  } catch (error) {
    console.error('Error al descargar el archivo adjunto:', error);
    throw error;
  }
};
