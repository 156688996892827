import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import '../styles/featured_content.css';

//images
import elipse355 from '../../images/ellipse355.png';
import img1 from '../../images/featured_content/1.jpeg';
import img2 from '../../images/featured_content/2.jpeg';
import img3 from '../../images/featured_content/3.png';
import img4 from '../../images/featured_content/4.png';
import img5 from '../../images/featured_content/5.jpg';
import img6 from '../../images/featured_content/6.jpg';
import img7 from '../../images/featured_content/7.jpg';
import img8 from '../../images/featured_content/8.jpg';
import img9 from '../../images/featured_content/9.jpg';
import img10 from '../../images/featured_content/10.jpg';

//icons
import iconArrowRight from '../../images/arrow-right.svg';
import iconArrowRight2 from '../../images/arrow-right.svg';

const Featured_content = ({
  featuredContent,
  categories,
  setDataFeaturedContent,
  extraInfoFeatContent,
  userInfoSSFF,
}) => {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
  const [dataExtraInfoFeatContent, setDataExtraInfoFeatContent] = useState([]);

  const navigate = useNavigate();

  //Evento para capturar el id del artículo sobre el que hacemos click
  const handleArticleClick = (
    articleId,
    articleTitle,
    articleAnswer,
    clickCode,
    rateCode,
    CONTACT_MANAGER
  ) => {
    //Verificamos si el articleId es 242 (Cambiar a 494: ¿Quieres conocer el catálogo de perfiles de Telefónica?) Diciembre23 el ID es 902.
    if (articleId === 902) {
      //Redirigimos a la ruta /groups
      navigate('/roles');
    } else {
      //Abrir artículo
      const articleUrl = `/featured/articles`;
      setDataFeaturedContent({
        id: articleId,
        title: articleTitle,
        answer: articleAnswer,
        clickCode: clickCode,
        rateCode,
        CONTACT_MANAGER,
      });
      navigate(articleUrl);
    }
  };

  useEffect(() => {
    if (extraInfoFeatContent.length !== 0) {
      setDataExtraInfoFeatContent(extraInfoFeatContent);
      sessionStorage.setItem('extrainfo', JSON.stringify(extraInfoFeatContent));
    } else {
      setDataExtraInfoFeatContent(
        JSON.parse(sessionStorage.getItem('extrainfo'))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Mapeo del contenido destacado
  const mapFeaturedContent = featuredContent.map((article, index) => (
    <article
      className="featured__contentbox"
      key={article.id}
      onClick={() =>
        handleArticleClick(
          article.id,
          article.title,
          article.attributes.ANSWER_TEXT,
          article.tracking.clickCode,
          article.tracking.rateCode,
          article.attributes.CONTACT_MANAGER
        )
      }
    >
      <div className="featured__contentbox__imgbox">
        <img
          className="featured__contentbox__imgbox--img"
          src={images[index % images.length]}
          alt="category-background"
        />
        <div className="featured__contentbox__imgbox--text">
          <h5 className="featured__contentbox__textbox--categorie">
            {
              categories.find(
                (categorie) => categorie.id === article.categories[0]
              )?.name
            }
          </h5>
          <h4 className="featured__contentbox__textbox--title">
            {article.title}
          </h4>
        </div>
        <div className="featured__contentbox__iconbox">
          <div className="iconWidth">
            <img
              src={iconArrowRight}
              className="featured__contentbox__iconbox--iconArrowRight"
              alt="arrow-icon"
            />
            <img
              src={iconArrowRight2}
              className="featured__contentbox__iconbox--iconArrowRight"
              alt="arrow-icon"
            />
          </div>
        </div>
      </div>
    </article>
  ));

  return (
    <>
      {/* Contenido Destacado */}

      <section className="featured__articles">
        {/* Breadcrumb */}

        <div className="featured__articles--breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <h4 className="featured__articles--title">CONTENIDO DESTACADO</h4>
        </div>

        {/* Contenedor para mostrar el contenido destacado */}
        <div className="featured__boxes">{mapFeaturedContent}</div>
      </section>
    </>
  );
};

export default Featured_content;
