import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/subcategories.css';
import elipse355 from '../../images/ellipse355.png';

//imagenes subcagegorías
//por defecto
import defaultImage from '../../images/subcategories/defaultImage.jpg';
////nomina y beneficios
import cat1Image1 from '../../images/subcategories/nomina-beneficios/image1.jpg';
import cat1Image2 from '../../images/subcategories/nomina-beneficios/image2.jpg';
import cat1Image3 from '../../images/subcategories/nomina-beneficios/image3.jpg';
import cat1Image4 from '../../images/subcategories/nomina-beneficios/image4.jpg';
import cat1Image5 from '../../images/subcategories/nomina-beneficios/image5.jpg';
import cat1Image6 from '../../images/subcategories/nomina-beneficios/image6.jpg';
import cat1Image7 from '../../images/subcategories/nomina-beneficios/image7.jpg';
import cat1Image8 from '../../images/subcategories/nomina-beneficios/image8.jpg';
import cat1Image9 from '../../images/subcategories/nomina-beneficios/image9.jpg';
import cat1Image10 from '../../images/subcategories/nomina-beneficios/image10.jpg';
import cat1Image11 from '../../images/subcategories/nomina-beneficios/image11.jpg';

////formación y desarrollo
import cat2Image1 from '../../images/subcategories/formacion-desarrollo/image1.jpg';
import cat2Image2 from '../../images/subcategories/formacion-desarrollo/image2.jpg';
import cat2Image3 from '../../images/subcategories/formacion-desarrollo/image3.jpg';
import cat2Image4 from '../../images/subcategories/formacion-desarrollo/image4.jpg';
import cat2Image5 from '../../images/subcategories/formacion-desarrollo/image5.jpg';
import cat2Image6 from '../../images/subcategories/formacion-desarrollo/image6.jpg';
import cat2Image7 from '../../images/subcategories/formacion-desarrollo/image7.jpg';
////herramientas, sistemas y políticas
import cat3Image1 from '../../images/subcategories/herramientas-sistemas-politicas/image1.jpg';
import cat3Image2 from '../../images/subcategories/herramientas-sistemas-politicas/image2.jpg';
import cat3Image3 from '../../images/subcategories/herramientas-sistemas-politicas/image3.jpg';
import cat3Image4 from '../../images/subcategories/herramientas-sistemas-politicas/image4.jpg';
import cat3Image5 from '../../images/subcategories/herramientas-sistemas-politicas/image5.jpg';
import cat3Image6 from '../../images/subcategories/herramientas-sistemas-politicas/image6.jpg';
import cat3Image7 from '../../images/subcategories/herramientas-sistemas-politicas/image7.jpg';
import cat3Image8 from '../../images/subcategories/herramientas-sistemas-politicas/image8.jpg';
import cat3Image9 from '../../images/subcategories/herramientas-sistemas-politicas/image9.jpg';
import cat3Image10 from '../../images/subcategories/herramientas-sistemas-politicas/image10.jpg';
import cat3Image11 from '../../images/subcategories/herramientas-sistemas-politicas/image11.jpg';
////vacaciones
import cat4Image1 from '../../images/subcategories/vacaciones/image1.jpg';
import cat4Image2 from '../../images/subcategories/vacaciones/image2.jpg';
import cat4Image3 from '../../images/subcategories/vacaciones/image3.jpg';
import cat4Image4 from '../../images/subcategories/vacaciones/image4.jpg';
////solicitudes y peticiones
import cat5Image1 from '../../images/subcategories/solicitudes-peticiones/image1.jpg';
import cat5Image2 from '../../images/subcategories/solicitudes-peticiones/image2.jpg';
import cat5Image3 from '../../images/subcategories/solicitudes-peticiones/image3.jpg';
////desarrollo de mi equipo
import cat6Image1 from '../../images/subcategories/desarrollo-equipo/image1.jpg';
import cat6Image2 from '../../images/subcategories/desarrollo-equipo/image2.jpg';
import cat6Image3 from '../../images/subcategories/desarrollo-equipo/image3.jpg';
import cat6Image4 from '../../images/subcategories/desarrollo-equipo/image4.jpg';
////atracción y retención de talento
import cat7Image1 from '../../images/subcategories/atraccion-talento/image1.jpg';
import cat7Image2 from '../../images/subcategories/atraccion-talento/image2.jpg';
import cat7Image4 from '../../images/subcategories/atraccion-talento/image4.jpg';
////compensación y organización de mi equipo
import cat8Image1 from '../../images/subcategories/compensacion-organizacion/image1.jpg';
import cat8Image2 from '../../images/subcategories/compensacion-organizacion/image2.jpg';
import cat8Image3 from '../../images/subcategories/compensacion-organizacion/image3.jpg';
////mis consultas como manager
import cat9Image1 from '../../images/subcategories/consultas-manager/image1.jpg';

//Se define un objeto subcategoryImageMap que mapea nombres de subcategorías a las imágenes correspondientes.
const subcategoryImageMap = {
  //default
  default: defaultImage,
  //nomina y beneficios
  'Nómina, Préstamos y Anticipos': cat1Image1,
  'Beneficios Flex': cat1Image2,
  'Beneficio de Comida': cat1Image3,
  'Oferta Movistar para Empleados': cat1Image4,
  'Seguro Médico': cat1Image5,
  'Seguro de Vida': cat1Image6,
  'Plan de Pensiones': cat1Image7,
  'Wonest (Albenture)': cat1Image8,
  Gympass: cat1Image9,
  'Plan de Acciones - GESP': cat1Image10,
  ATAM: cat1Image11,
  'Otras ayudas y Subvenciones': cat3Image6,
  //formación y desarrollo
  Formación: cat2Image1,
  Skills: cat2Image2,
  Vacantes: cat2Image3,
  Onboarding: cat2Image4,
  'Conversaciones de Impacto y Crecimiento': cat2Image5,
  Bienestar: cat2Image6,
  Conciliación: cat2Image7,
  //herramientas, sistemas y políticas
  'Puesto de Trabajo': cat3Image1,
  Plataformas: cat3Image2,
  Infraestructuras: cat3Image3,
  'Política de Pay Review y Bono': cat3Image4,
  'Política de Viajes': cat3Image5,
  'Política de Gastos Profesionales': cat3Image6,
  'Políticas de Igualdad y Diversidad': cat3Image7,
  'Política de Asignación y Contratación Internacional': cat3Image8,
  'Política de Prevención de Riesgos Laborales': cat3Image9,
  'Normativa laboral': cat3Image10,
  'Política del Modelo de Trabajo': cat3Image11,
  //vacaciones
  Vacaciones: cat4Image1,
  'Permisos Retribuidos': cat4Image2,
  'Permisos No Retribuidos': cat4Image3,
  Excedencias: cat4Image4,
  //solicitudes y peticiones
  'Actualización de mis Datos': cat5Image1,
  'Canales de Contacto con People': cat5Image2,
  'Gestión de solicitudes desde PeoplEX': cat5Image3,
  //desarrollo de mi equipo
  'Gestión del Talento': cat6Image1,
  'Formación de mi Equipo': cat6Image2,
  'Skills de mi Equipo': cat6Image3,
  'Strategy Workforce Planning': cat6Image4,
  //atracción y retención de talento
  'Vacantes y Atracción de Talento': cat7Image1,
  'Becas Talentum': cat7Image2,
  'Motivación y Bienestar de mi Equipo': cat7Image4,
  //compensación y organización de mi equipo
  'Revisión Salarial y Bono': cat8Image1,
  'Organigrama y Cambios Organizativos': cat8Image2,
  'Modelo de Trabajo Flexible': cat8Image3,
  ////mis consultas como manager
  '¿Cómo gestionar otras consultas?': cat9Image1,
};

// La función de componente 'Subcategories' toma tres propiedades como argumentos destructurados:
// - 'subcategories': Contiene todas las subcategorías disponibles.
// - 'selectedCategory': La categoría principal seleccionada por el usuario.
// - 'setSelectedSubcategory': Una función que permite establecer la subcategoría seleccionada.

// Luego, se crea 'filteredSubcategories' que contiene solo las subcategorías que cumplen con dos condiciones:
// 1. El 'id' de la subcategoría no es igual al 'id' de la categoría principal seleccionada.
// 2. El 'parent' de la subcategoría coincide con el nombre de la categoría principal seleccionada.

const Subcategories = ({
  subcategories,
  selectedCategory,
  setSelectedSubcategory,
  setIsPageGroup,
  extraInfoDescriptionCategories,
  setExtraInfoDescriptionCategories,
}) => {
  const [dataTextSubcategory, setDataTextSubcategory] = useState({});
  useEffect(() => {
    if (selectedCategory.name !== undefined) {
      setDataTextSubcategory(selectedCategory);
      sessionStorage.setItem(
        'dataselectedSubcategory',
        JSON.stringify(selectedCategory)
      );
    } else {
      setDataTextSubcategory(
        JSON.parse(sessionStorage.getItem('dataselectedSubcategory'))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataSubcategories, setDataSubcategories] = useState([]);

  useEffect(() => {
    if (
      selectedCategory.name !== undefined ||
      subcategories.length !== 0 ||
      extraInfoDescriptionCategories.length !== 0
    ) {
      setDataSubcategories(subcategories);
      sessionStorage.setItem('subcategories', JSON.stringify(subcategories));
      setExtraInfoDescriptionCategories(extraInfoDescriptionCategories);
      sessionStorage.setItem(
        'extraInfoCategoriesDescription',
        JSON.stringify(extraInfoDescriptionCategories)
      );
    } else {
      setDataSubcategories(JSON.parse(sessionStorage.getItem('subcategories')));
      setExtraInfoDescriptionCategories(
        JSON.parse(sessionStorage.getItem('extraInfoCategoriesDescription'))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Función para seleccionar aleatoriamente una imagen
  const getRandomSubcatImage = () => {
    const randomIndex = Math.floor(Math.random() * 40) + 1; //En random-subcategories hay 40 imágenes. Sumamos 1 para evitar que salga
    return randomIndex;
  };

  return (
    <>
      <section className="subcategoriesPage">
        {/* Breadcrumb --> */}
        <div className="subcategories__breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <Link to="/home" className="subcategories__breadcrumb--title">
            INICIO {''}
          </Link>
          <span className="subcategories__breadcrumb--text">
            &gt; {dataTextSubcategory.name}
          </span>
        </div>
        {/* <-- Breadcrumb*/}
        <h2 className="subcategories__title">{dataTextSubcategory.name}</h2>
        {/* Listado Subcategorías */}
        <div className="articlesContainer">
          {dataSubcategories
            .filter(
              (subcategory) =>
                subcategory.id !== dataTextSubcategory.id &&
                subcategory.parent === dataTextSubcategory.id
            )
            .map((subcategory, index) => (
              <Link
                to="/subcategories/articles"
                key={index}
                className="subcategories__links"
                onClick={() => {
                  setIsPageGroup(false);
                  setSelectedSubcategory({
                    name: subcategory.name,
                    id: subcategory.id,
                    parent: dataTextSubcategory.name,
                  });
                }}
              >
                <article className="subcategories__articlebox">
                  {/* <div className="articlebox__imageContainer"> */}
                  <img
                    className="articlebox__img"
                    src={
                      subcategoryImageMap[subcategory.name] ||
                      require(`../../images/subcategories/random-subcategories/image${getRandomSubcatImage()}.jpg`)

                      // subcategoryImageMap.default
                    }
                    alt={`categorie-${subcategory.name}`}
                  />
                  {/* </div> */}
                  <div className="articlebox__textContainer">
                    <h3 className="articlebox__textContainer__name">
                      {subcategory.name}
                    </h3>
                    <p className="articlebox__textContainer--description">
                      {
                        extraInfoDescriptionCategories.find(
                          (description) =>
                            description.ID_Categoria === subcategory.id
                        )?.Descripcion_Categoria
                      }
                    </p>
                  </div>
                </article>
              </Link>
            ))}
        </div>
      </section>
    </>
  );
};

export default Subcategories;
