import React, { useState } from 'react';
import '../styles/loginComponent.css';
import { useNavigate } from 'react-router-dom';
import { getUserInfoSSFF } from '../services/apiSSFF';
import { sessionUserStartInbenta, USER_TYPE_TO_INBENTA_ID } from '../services/api';

const LoginComponent = ({ setUserInfoSSFF }) => {
  const isProduction = true;
  const [selectedUser, setSelectedUser] = useState('');
  const navigate = useNavigate();

  const callSSFF = async (idToken) => {
    const userSSFF = await getUserInfoSSFF(idToken);
    const { token } = userSSFF;

    if (userSSFF.userInformation.d.results.length !== 0 && USER_TYPE_TO_INBENTA_ID[userSSFF.userInformation.d.results[0].custom03] !== undefined) {
      setUserInfoSSFF(userSSFF.userInformation.d.results[0]);
      localStorage.setItem('jwtSSFFToken', token);
      sessionUserStartInbenta(
        userSSFF.userInformation.d.results[0].custom03,
        userSSFF.userInformation.d.results[0].custom15,
        userSSFF.userInformation.d.results[0].username
      );
      console.log(userSSFF.userInformation.d.results[0]);
      if (userSSFF.userInformation.d.results[0].custom03 === '12NL' || userSSFF.userInformation.d.results[0].custom03 === '2113') {
        navigate('/canalatencion');
      } else {
        navigate('/home');
      }
    }
  };
  const handleLogin = async () => {
    if (!isProduction) {
      try {
        const response = await fetch('/generateFakeToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: selectedUser,
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch fake token');
        }
        let idToken = await response.json();
        localStorage.setItem('idToken', idToken);
        callSSFF(idToken);
      } catch (error) {
        console.error('Error fetching fake token:', error);
        throw new Error('Error fetching fake token');
      }
    }
  };

  return (
    <>
      <div>
        <h2>Selecciona user para entrar</h2>
        <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option value='' disabled>
            Selecciona un usuario
          </option>
          <option value='gabriel.cruzb@telefonica.com'>Gabriel Cruz</option>
          <option value='nayra.romerosalas@telefonica.com'>Nayra Romero</option>
          <option value='pablo.navarrofh@telefonica.com'>Pablo Navarro</option>
          <option value='irene.serranosebastian@telefonica.com'>Irene Serrano</option>
          <option value='marcos.azabachesuarez.practicas@telefonica.com'>Marcos Azabache</option>
          <option value='asuncion.lopez@telefonica.com'>Asunción Lopez</option>
          <option value='sonia.casadobarrero@telefonica.com'>Sonia</option>
          <option value='cristian.roibu@telefonica.com'>Cristian</option>
          <option value='josemanuel.dearce@telefonica.com'>Paises</option>
          <option value='david.alonsogarcia@telefonica.com'>David Alonso</option>
          <option value='mariajose.navarromaillo@telefonica.com'>TELFISA</option>
        </select>
        <button onClick={() => handleLogin()}>Iniciar sesión</button>
      </div>
    </>
  );
};

export default LoginComponent;
