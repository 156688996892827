import React from 'react';

const RecruitmentDates = ({ formData, errors, onChange }) => {
  return (
    <div className="formJiraNewSection">
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.postingStart && '*'} POSTING START:
          <input
            type="date"
            name="postingStart"
            value={formData.postingStart}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.postingStart ? 'errorBorder' : ''} 
              ${formData.postingStart ? 'prefilledBorder' : ''}`}
          />
{/*           {errors.postingStart && <span className="errorMessage">{errors.postingStart}</span>}
 */}        </label>

        <label className="formJiraNewLabel_three">
          {errors.briefingDate && '*'} BRIEFING DATE:
          <input
            type="date"
            name="briefingDate"
            value={formData.briefingDate}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.briefingDate ? 'errorBorder' : ''} 
              ${formData.briefingDate ? 'prefilledBorder' : ''}`}
          />
         {/*  {errors.briefingDate && <span className="errorMessage">{errors.briefingDate}</span>} */}
        </label>

        <label className="formJiraNewLabel_three">
          {errors.presentationOfResults && '*'} PRESENTATION OF RESULTS:
          <input
            type="date"
            name="presentationOfResults"
            value={formData.presentationOfResults}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.presentationOfResults ? 'errorBorder' : ''} 
              ${formData.presentationOfResults ? 'prefilledBorder' : ''}`}
          />
          {/* {errors.presentationOfResults && <span className="errorMessage">{errors.presentationOfResults}</span>} */}
        </label>
      </div>

      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.feedbackCandidatesToInterview && '*'}FEEDBACK CANDIDATES TO INTERVIEW:
          <input
            type="date"
            name="feedbackCandidatesToInterview"
            value={formData.feedbackCandidatesToInterview}
            onChange={onChange}
            className={`formJiraNewInputText_three
              ${errors.feedbackCandidatesToInterview ? 'errorBorder' : ''} 
              ${formData.feedbackCandidatesToInterview ? 'prefilledBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.businessFirstInterview && '*'}BUSINESS FIRST INTERVIEW:
          <input
            type="date"
            name="businessFirstInterview"
            value={formData.businessFirstInterview}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.businessFirstInterview ? 'errorBorder' : ''} 
              ${formData.businessFirstInterview ? 'prefilledBorder' : ''}`}
          />
        </label>
		<label className="formJiraNewLabel_three">
          {errors.finalCandidateSelected && '*'}FINAL CANDIDATE SELECTED:
          <input
            type="date"
            name="finalCandidateSelected"
            value={formData.finalCandidateSelected}
            onChange={onChange}
            className={`formJiraNewInputText_three
              ${errors.finalCandidateSelected ? 'errorBorder' : ''} 
              ${formData.finalCandidateSelected ? 'prefilledBorder' : ''}`}
          />
		</label>
      </div>
	  <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.offerDate && '*'}OFFER DATE:
          <input
            type="date"
            name="offerDate"
            value={formData.offerDate}
            onChange={onChange}
            className={`formJiraNewInputText_short 
              ${errors.offerDate ? 'errorBorder' : ''} 
              ${formData.offerDate ? 'prefilledBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.verbalOfferAcceptance && '*'}VERBAL OFFER ACCEPTANCE:
          <input
            type="date"
            name="verbalOfferAcceptance"
            value={formData.verbalOfferAcceptance}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.verbalOfferAcceptance ? 'errorBorder' : ''} 
              ${formData.verbalOfferAcceptance ? 'prefilledBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.joinDate && '*'}JOIN DATE:
          <input
            type="date"
            name="joinDate"
            value={formData.joinDate}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.joinDate ? 'errorBorder' : ''} 
              ${formData.joinDate ? 'prefilledBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.onHoldDate && '*'}ON HOLD DATE:
          <input
            type="date"
            name="onHoldDate"
            value={formData.onHoldDate}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.onHoldDate ? 'errorBorder' : ''} 
              ${formData.onHoldDate ? 'prefilledBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.reactivationDate && '*'}REACTIVATION DATE:
          <input
            type="date"
            name="reactivationDate"
            value={formData.reactivationDate}
            onChange={onChange}
            className={`formJiraNewInputText_three
              ${errors.reactivationDate ? 'errorBorder' : ''} 
              ${formData.reactivationDate ? 'prefilledBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.cancelledDate && '*'}CANCELLED DATE:
          <input
            type="date"
            name="cancelledDate"
            value={formData.cancelledDate}
            onChange={onChange}
            className={`formJiraNewInputText_three 
              ${errors.cancelledDate ? 'errorBorder' : ''} 
              ${formData.cancelledDate ? 'prefilledBorder' : ''}`}
          />
        </label>
      </div>
    </div>
  );
};

export default RecruitmentDates;
