import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';

import '../../styles/form.css';
import { createTicket, USER_TYPE_TO_INBENTA_ID, isUserExist } from '../../../services/api';

const TelematicCertPeriods = ({ userInfoSSFF }) => {
  const [nombreApellidosRecienNacido, setNombreApellidosRecienNacido] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [permisoPeriodoInicio, setPermisoPeriodoInicio] = useState('');
  const [permisoPeriodoFin, setPermisoPeriodoFin] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showNombreApellidosRecienNacidoRequired, setshowNombreApellidosRecienNacidoRequired] = useState(false);
  const [showFechaNacimientoRequired, setShowFechaNacimientoRequired] = useState(false);
  const [showPermisoPeriodoInicioRequired, setShowPermisoPeriodoInicioRequired] = useState(false);
  const [showPermisoPeriodoFinRequired, setShowPermisoPeriodoFinRequired] = useState(false);
  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const areFieldsValid =
      nombreApellidosRecienNacido.trim() !== '' &&
      fechaNacimiento.trim() !== '' &&
      permisoPeriodoInicio.trim() !== '' &&
      permisoPeriodoFin.trim() !== '';
    setIsButtonEnabled(areFieldsValid);
  }, [nombreApellidosRecienNacido, fechaNacimiento, permisoPeriodoInicio, permisoPeriodoFin]);

  const handleNombreApellidosRecienNacidoChange = (e) => {
    setNombreApellidosRecienNacido(e.target.value);
    setshowNombreApellidosRecienNacidoRequired(false);
  };

  const handleFechaNacimientoChange = (e) => {
    setFechaNacimiento(e.target.value);
    setShowFechaNacimientoRequired(false);
  };

  const handlePermisoPeriodoInicioChange = (e) => {
    setPermisoPeriodoInicio(e.target.value);
    setShowPermisoPeriodoInicioRequired(false);
  };

  const handlePermisoPeriodoFinChange = (e) => {
    setPermisoPeriodoFin(e.target.value);
    setShowPermisoPeriodoFinRequired(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid =
      nombreApellidosRecienNacido.trim() !== '' &&
      fechaNacimiento.trim() !== '' &&
      permisoPeriodoInicio.trim() !== '' &&
      permisoPeriodoFin.trim() !== '';
    if (nombreApellidosRecienNacido === '') {
      setshowNombreApellidosRecienNacidoRequired(true);
    }
    if (fechaNacimiento === '') {
      setShowFechaNacimientoRequired(true);
    }
    if (permisoPeriodoInicio === '') {
      setShowPermisoPeriodoInicioRequired(true);
    }
    if (permisoPeriodoFin === '') {
      setShowPermisoPeriodoFinRequired(true);
    } else if (areFieldsValid) {
      try {
        setIsSubmitting(true);
        const dataUserInbenta = await isUserExist(userInfoSSFF.username);

        // Sanitizar los valores de entrada del formulario
        const sanitizedNombreApellidosRecienNacido = DOMpurify.sanitize(nombreApellidosRecienNacido);
        const sanitizedFechaNacimiento = DOMpurify.sanitize(fechaNacimiento);
        const sanitizedPermisoPeriodoInicio = DOMpurify.sanitize(permisoPeriodoInicio);
        const sanitizedpermisoPeriodoFin = DOMpurify.sanitize(permisoPeriodoFin);

        const message = `
      <div style={{ backgroundColor: 'transparent' }}>
      <div><br /></div>
      1. EMAIL CORPORATIVO
      <br />
      <b>${userInfoSSFF.username}</b>
      </div>
     <div><br /></div>
      <div>
       2. NOMBRE Y APELLIDOS
        <br />
         <b>${userInfoSSFF.displayName}</b>
         </div>
     <div><br /></div>
     <div>
      3. EMPRESA
      <br />
      <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
      <div><br /></div>
      4. NOMBRE Y APELLIDOS DEL RECIÉN NACIDO
      <br />
      <b>${sanitizedNombreApellidosRecienNacido}</b>
      <div><br /></div>
      5. FECHA DE NACIMIENTO
      <br />
      <b>${sanitizedFechaNacimiento}</b>
      <div><br /></div>
      6. PERMISO DE PATERNIDAD REGISTRADO EN SSFF
      <br />
      INICIO
      <br />
      <b>${sanitizedPermisoPeriodoInicio}</b>
      <br />
      FIN
      <br />
      <b>${sanitizedpermisoPeriodoFin}</b>
      <div><br /></div>
    </div>
      
      `;

        await createTicket(
          'Solicitud permiso de maternidad/paternidad en varios periodos',
          message,
          userInfoSSFF.custom03,
          1,
          [],
          dataUserInbenta.data[0].id
        );

        setNombreApellidosRecienNacido('');
        setFechaNacimiento('');
        setPermisoPeriodoInicio('');
        setPermisoPeriodoFin('');
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };
  return (
    <>
      <section className='formArticle'>
        <h1 className='formArticle__title'>Solicitud del Certificado Telemático de Empresa (maternidad/paternidad) en varios periodos</h1>
        <p className='formArticle__text'>
          Si has decidido disfrutar de tu permiso de maternidad o paternidad en diferentes periodos debes solicitar, para cada uno de los periodos, la
          emisión del correspondiente Certificado de Empresa que enviaremos a la Seguridad Social, para que puedas tramitar tu prestación. (Te
          recordamos que es necesario que hayas registrado, previamente, este nuevo periodo de permiso de maternidad/paternidad en SSFF). Una vez
          enviemos el certificado a la Seguridad Social, te lo haremos saber para que puedas iniciar los trámites de solicitud de tu prestación,
          siguiendo los pasos que se detallan en PeoplEX.
        </p>
        <form target='_blank' className='formArticle__form' action='#' onSubmit={handleSubmit}>
          <div className='formArticleSection'>
            <label className='formArticleLabel' htmlFor='nombreApellidosRecienNacido'>
              Nombre y Apellidos del recién nacido
              {showNombreApellidosRecienNacidoRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
            </label>
            <input
              type='text'
              placeholder='Escriba su respuesta'
              className='formArticleInputText'
              value={nombreApellidosRecienNacido}
              onChange={handleNombreApellidosRecienNacidoChange}
            />
          </div>
          <div className='formArticleSection'>
            <label className='formArticleLabel' htmlFor='FechaNacimientoRecienNacido'>
              Fecha de nacimiento
              {showFechaNacimientoRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
            </label>
            <input
              type='date'
              id='fechaNacimiento'
              className='formArticleInputText form-date__input'
              value={fechaNacimiento}
              onChange={handleFechaNacimientoChange}
            />
          </div>
          <div className='formArticleSection'>
            <label className='formArticleLabel' htmlFor='permisoMatPatSsff'>
              Permiso de maternidad/paternidad registrado en SSFF (nuevo periodo)
            </label>
            <div className='datesContainer'>
              <div className='datesContainer--date'>
                <p className='formArticle__contact'>
                  Inicio
                  {showPermisoPeriodoInicioRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
                </p>
                <input
                  type='date'
                  id='inicio'
                  className='formArticleInputText form-date__input'
                  value={permisoPeriodoInicio}
                  onChange={handlePermisoPeriodoInicioChange}
                />
              </div>
              <div className='datesContainer--date'>
                <p className='formArticle__contact'>
                  Fin
                  {showPermisoPeriodoFinRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
                </p>
                <input
                  type='date'
                  id='fin'
                  className='formArticleInputText form-date__input'
                  value={permisoPeriodoFin}
                  onChange={handlePermisoPeriodoFinChange}
                />
              </div>
            </div>
          </div>
          <div className='formArticleButton__section'>
            <button className={`formArticleButton ${isButtonEnabled ? 'enabled' : 'disabled'}`} type='submit'>
              Enviar
            </button>
            {isSubmitting && (
              // SPINNER LOADING
              <div class='loader'></div>
            )}
          </div>
        </form>
        <div className='contactTextContainer'>
          <p className='formArticle__contact'>
            Para cualquier duda, contacta al buzón <a href='mailto:preguntanos@personas.telefonica.com'>preguntanos@personas.telefonica.com</a>
          </p>
        </div>
        {feedbackPopup && (
          <div className='dislikePopup-overlay'>
            <div className='dislikePopup-content'>
              <div className='dislikePopup__sectionTitle'>
                <h1 className='dislikePopup__sectionTitle--title spacing'>Solicitud enviada correctamente</h1>
              </div>
              <div className='dislikePopup__sectionText'>
                <p className='timeOutPopup__sectionText--text'>Nos pondremos en contacto contigo a la mayor brevedad posible</p>
              </div>
              <div className='contactMeSectionButton'>
                <button className='contactMePopup--button' onClick={handleCloseFeedbackPopup}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className='dislikePopup-overlay'>
            <div className='dislikePopup-content failurePopup'>
              <div className='dislikePopup__sectionText'>
                <p className='timeOutPopup__sectionText--text'>
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className='contactMeSectionButton'>
                <button className='contactMePopup--button failureButton' onClick={handleCloseFeedbackPopup}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default TelematicCertPeriods;
