import React, { useEffect } from 'react';

const capitalizeFirstLetter = (str) => {
  if (!str) return '';

  return str
    .toLowerCase()
    .split(' ')
    .map((word) =>
      word
        .split('-')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join('-')
    )
    .join(' ');
};

const PersonalInfoSection = ({ userInfoSSFF, ticketData, updateEmployeeLEntity }) => {
  const getSafeString = (value) => (typeof value === 'string' ? value : '');

  const name = capitalizeFirstLetter(getSafeString(ticketData?.employeeName).split(' ')[0] || getSafeString(userInfoSSFF?.firstName) || '');

  const surname = capitalizeFirstLetter(
    getSafeString(ticketData?.employeeName).split(' ').slice(1).join(' ') || getSafeString(userInfoSSFF?.lastName) || ''
  );

  const email = ticketData?.employeeEmail || userInfoSSFF?.username || '';
  const managerEmail = ticketData?.employeeManagerEmail || userInfoSSFF?.manager.username;
  const registrationId = ticketData?.employeeRegistrationId || userInfoSSFF?.empInfo.personNav.personalInfoNav.results[0].customString5;

  const companyMapping = {
    '0250_ESP': 'TSA',
    '0024': 'TINDI',
    1412: 'TGS',
    1970: 'TSC',
    2205: 'CS2',
    1583: 'TOI',
    1343: 'TCE',
    1893: 'Telxius Cable',
    1702: 'Telxius Telecom',
    1017: 'T. Hispam',
  };

  // Mapear código de la empresa a su nombre
  const companyCode = ticketData?.employeeLEntity || userInfoSSFF?.custom03;
  const company = companyMapping[companyCode] || companyCode; // Si no existe en el mapeo, muestra el código original

  useEffect(() => {
    if (updateEmployeeLEntity) {
      updateEmployeeLEntity(company); // Actualiza el campo en el padre
    }
  }, [company, updateEmployeeLEntity]);

  return (
    <>
      <div className='formJiraNameEmailSection'>
        <div className='formJiraEndSection_inline'>
          <label className='formJiraEndLabel_short' htmlFor='name'>
            Nombre
          </label>
          <input type='text' className='formJiraEndInputText_short' value={name} readOnly />
          <label className='formJiraEndLabel_short2' htmlFor='surname'>
            Apellidos
          </label>
          <input type='text' className='formJiraEndInputText_short2' value={surname} readOnly />
        </div>
        <div className='formJiraEndSection'>
          <label className='formJiraEndLabel_short' htmlFor='email'>
            Correo
          </label>
          <input type='text' className='formJiraEndInputText' value={email} readOnly />
        </div>
        <div className='formJiraEndSection'>
          <label className='formJiraEndLabel_short' htmlFor='company'>
            Empresa
          </label>
          <input type='text' className='formJiraEndInputText' value={company} readOnly />
        </div>
        <div className='formJiraEndSection'>
          <label className='formJiraEndLabel_short' htmlFor='registrationId'>
            Matrícula
          </label>
          <input type='text' className='formJiraEndInputText_short' value={registrationId} readOnly />
        </div>
        <div className='formJiraEndSection'>
          <label className='formJiraEndLabel_short' htmlFor='managerEmail'>
            Email del manager
          </label>
          <input type='text' className='formJiraEndInputText' value={managerEmail} readOnly />
        </div>
      </div>
    </>
  );
};

export default PersonalInfoSection;
