import React, { useState, useEffect } from 'react';
//styles
import '../styles/subcategories_article-list.css';
import elipse355 from '../../images/ellipse355.png';

//components
import ArticleItem from './article-item';

import { fetchSubcategoriesArticles } from '../../services/api';
import { Link, useLocation } from 'react-router-dom';

const Subcategories_articleList = ({ selectedSubcategory, isPageGroup, userInfoSSFF }) => {
  const [dataPage, setDataPage] = useState({});

  // Extraer articleId desde la URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const articleId = queryParams.get('articleId');

  useEffect(() => {
    if (selectedSubcategory.parent !== undefined || selectedSubcategory.name !== undefined) {
      setDataPage({
        parent: selectedSubcategory.parent,
        name: selectedSubcategory.name,
        dataisPageGroup: isPageGroup,
      });
      sessionStorage.setItem(
        'dataselectedArticle',
        JSON.stringify({
          parent: selectedSubcategory.parent,
          name: selectedSubcategory.name,
          dataisPageGroup: isPageGroup,
        })
      );
    } else {
      setDataPage(JSON.parse(sessionStorage.getItem('dataselectedArticle')));
    }
  }, [dataPage.dataisPageGroup, isPageGroup, selectedSubcategory.name, selectedSubcategory.parent]);

  const [subcategoriesArticles, setSubcategoriesArticles] = useState([]);
  useEffect(() => {
    const fetchSubcategoriesArticlesComponent = async () => {
      try {
        let idCategory = selectedSubcategory.id ? selectedSubcategory.id : sessionStorage.getItem('selectedSubcategory');

        const subcategoriesArticlesData = await fetchSubcategoriesArticles(idCategory, userInfoSSFF.custom03);
        if (isPageGroup) {
          if (userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)') {
            setSubcategoriesArticles(subcategoriesArticlesData.filter((article) => article.attributes.Visibilidad === 'People'));
          }
          // else if (
          //   (userInfoSSFF.custom15 === 'Y' &&
          //     userInfoSSFF.custom03 === '1893') ||
          //   userInfoSSFF.custom03 === '1702' ||
          //   userInfoSSFF.custom03 === '1017'
          // ) {
          //   setSubcategoriesArticles(subcategoriesArticlesData);
          // }
          else if (userInfoSSFF.custom15 === 'Y') {
            setSubcategoriesArticles(subcategoriesArticlesData.filter((article) => article.attributes.Visibilidad === 'Perfiles'));
          }
        } else {
          setSubcategoriesArticles(subcategoriesArticlesData);
        }
        sessionStorage.setItem('selectedSubcategory', idCategory);
      } catch (error) {}
    };

    fetchSubcategoriesArticlesComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Usar articleId si está presente para cargar un artículo específico
  useEffect(() => {
    if (articleId) {
      console.log('Cargando artículo con ID:', articleId);
      // Aquí podrías agregar lógica para cargar ese artículo específico
    }
  }, [articleId]);

  return (
    <>
      <section className='articleList'>
        {/* Breadcrumb --> */}
        <div className='articleList__breadcrumb--container'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/home' className='articleList__breadcrumb--title'>
            INICIO {''}
          </Link>
          {dataPage.dataisPageGroup && (
            <span className='articleList__breadcrumb--text parent'>
              <Link to='/roles'>&gt; ROLES {''}</Link>
            </span>
          )}
          {dataPage.dataisPageGroup ? (
            <span className='articleList__breadcrumb--text parent'>
              <Link to='/roles/groups'>
                &gt; {dataPage.parent} {''}
              </Link>
            </span>
          ) : (
            <span className='articleList__breadcrumb--text parent'>
              <Link to='/subcategories'>
                &gt; {dataPage.parent} {''}
              </Link>
            </span>
          )}
          <span className='articleList__breadcrumb--text'>&gt; {dataPage.name}</span>
        </div>
        {/* Breadcrumb --> */}
        {/* Listado artículos */}
        <section className='articleList__content'>
          {subcategoriesArticles.map((article) => (
            <ArticleItem
              title={article.title}
              answer={article.attributes.ANSWER_TEXT}
              expandedState={false}
              clickCode={article.tracking.clickCode}
              rateCode={article.tracking.rateCode}
              userInfoSSFF={userInfoSSFF}
              articleId={article.id}
              contactMe={article.attributes.CONTACT_MANAGER}
            ></ArticleItem>
          ))}
        </section>
      </section>
    </>
  );
};

export default Subcategories_articleList;
