import React, { useEffect, useState } from "react";
import { validateFields } from './validateFields';

export const OrganizationalChangeComponent = ({
	organizationalChange,
	newDepartments,
	responsiblePerson,
	hrbp,
	businessUnit,
	division,
	department,
	costCenter,
	objectivesResult,
	setOrganizationalChange,
	setNewDepartments,
	setResponsiblePerson,
	setHrbp,
	setBusinessUnit,
	setDivision,
	setDepartment,
	setCostCenter,
	setObjectivesResult,
	errors,
	setErrors
}) => {
	console.log('organizationalChange', organizationalChange);
	console.log('newDepartments', newDepartments);
	console.log('responsiblePerson', responsiblePerson);
	console.log('hrbp', hrbp);

	const [showWarning, setShowWarning] = React.useState(false);
	const [initialValues, setInitialValues] = useState({});
	useEffect(() => {
		setInitialValues({
			organizationalChange,
			newDepartments,
			responsiblePerson,
			hrbp,
			businessUnit,
			division,
			department,
			costCenter,
			objectivesResult
		});
	}, []); 
	useEffect(() => {
	 const fields = {
	   organizationalChange,
	   newDepartments,
	   responsiblePerson,
	   hrbp,
	   businessUnit,
	   division,
	   department,
	   costCenter,
	   objectivesResult
	 };
	 const validationErrors = validateFields(fields);
	 setErrors(validationErrors);
	 console.log(validationErrors);
	 setShowWarning(Object.keys(validationErrors).length > 0);
   }, [ organizationalChange, newDepartments, responsiblePerson, hrbp, businessUnit, division, department, costCenter, objectivesResult ]);

	const getInputClass = (fieldValue, fieldName) => {

		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeInputText prefilledBorder'; 
		} 
		return errors[fieldName] ?  'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
	};

	const getRadioClass = (fieldValue, fieldName) => {
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeRadio prefilledBorder'; 
		}
		return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
	};

	const renderLabel = (labelText, fieldName) => {
		return (
		  <label className="formJiraChangeLabel">
			{errors[fieldName] && '*'} {labelText}
		  </label>
		);
	};


	return (
		<div className="formJiraChangeCheckboxGroup_changes">
			<div className='formJiraChangeInfoTittle'>
				Cambios organizativos
			</div>
			<div className="formJiraChangeRadioGroup">
				<div className="formJiraChangeLabelRadio">
					<label className="formJiraChangeLabel">
					{renderLabel('¿Se trata de una reorganización?', 'organizationalChange' )}
					</label>
					<div className="formJiraChangeRadio">
					<label className='formJiraChangeLabelRadio'>
						<input
						type="radio"
						name="organizationalChange"
						className= {getRadioClass('organizationalChange', 'organizationalChange')}
						value="yes"
						checked={organizationalChange === 'yes'}
						onChange={() => setOrganizationalChange('yes')}
						/>
						Sí
					</label>
					<label className='formJiraChangeLabelRadio'>
						<input
						type="radio"
						name="organizationalChange"
						className={getRadioClass('organizationalChange', 'organizationalChange')}
						value="no"
						checked={organizationalChange === 'no'}
						onChange={() => setOrganizationalChange('no')}
						/>
						No
					</label>
					</div>
				</div>
				<div className="formJiraChangeLabelRadio">
					<label className="formJiraChangeLabelRadio">
					{renderLabel('¿Hay que crear nuevos departamentos?', 'newDepartments')}
					</label>
					<div className="formJiraChangeRadio">
					<label className='formJiraChangeLabelRadio'>
						<input
						type="radio"
						name="newDepartments"
						className={getRadioClass('newDepartments', 'newDepartments')}
						value="yes"
						checked={newDepartments === 'yes'}
						onChange={() => setNewDepartments('yes')}
						/>
						Sí
					</label>
					<label className='formJiraChangeLabelRadio'>
						<input
						type="radio"
						name="newDepartments"
						className={getRadioClass('newDepartments',)}
						value="no"
						checked={newDepartments === 'no'}
						onChange={() => setNewDepartments('no')}
						/>
						No
					</label>
					</div>
				</div>
					<label className="formJiraChangeLabel">
						{renderLabel('Responsable directo:', 'responsiblePerson')}
					
					<input
						type="text"
						placeholder="Responsable Directo"
						className={getInputClass(responsiblePerson, 'responsiblePerson')}
						value={responsiblePerson}
						onChange={e => setResponsiblePerson(e.target.value)}
					/>
					</label>
				<div className="formJiraChangeSection_both">
					<label className="formJiraChangeLabel_bothIndividual">
						 {errors.hrbp && '*'}HRBP:
						<input
							type="text"
							placeholder="HRBP"
							className= {getInputClass(hrbp, 'hrbp')}
							value={hrbp}
							onChange={e => setHrbp(e.target.value)}
						/>
					</label>
					<label className="formJiraChangeLabel_bothIndividual">
						 {errors.businessUnit && '*'}Unidad de negocio:
						<input
							type="text"
							placeholder="Unidad de negocio"
							className= {getInputClass(businessUnit, 'businessUnit')}
							value={businessUnit}
							onChange={e => setBusinessUnit(e.target.value)}
						/>
					</label>
				</div>
				<div className="formJiraChangeSection_both">
					<label className="formJiraChangeLabel_bothIndividual">
						{errors.division && '*'}División:
						<input
							type="text"
							placeholder="División"
							className= {getInputClass(division, 'division')}
							value={division}
							onChange={e => setDivision(e.target.value)}
						/>
					</label>
					<label className="formJiraChangeLabel_bothIndividual">
						{errors.department && '*'}Departamento:
						<input
							type="text"
							placeholder="Departamento"
							className= {getInputClass(department, 'department')}
							value={department}
							onChange={e => setDepartment(e.target.value)}
						/>
					</label>
				</div>
				<div className="formJiraChangeSection_both">
					<label className="formJiraChangeLabel_bothIndividual">
						{errors.costCenter && '*'}Centro de coste:
						<input
							type="text"
							placeholder="Centro de coste"
							className= {getInputClass(costCenter, 'costCenter')}
							value={costCenter}
							onChange={e => setCostCenter(e.target.value)}
						/>
					</label>
					<label className="formJiraChangeLabel_bothIndividual">
						{errors.objectivesResult && '*'}Resultado objetivos:
						<input
							type="text"
							placeholder="Resultado objetivos"
							className= {getInputClass(objectivesResult, 'objectivesResult')}
							value={objectivesResult}
							onChange={e => setObjectivesResult(e.target.value)}
						/>
					</label>
				</div>
			</div>
			{showWarning && (
			<div className="formJiraChangeWarningMessage">
				*Es necesario completar todos los campos indicados en rojo
			</div>
			)}
		</div>
	);
};
