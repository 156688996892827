import React, { useState, useEffect } from 'react';

import '../../../knowledgePortal/styles/search_article-list.css';

import ArticleItem from '../../../knowledgePortal/components/article-item';
import { Link } from 'react-router-dom';
import { fetchSearchArticles } from '../../../services/api';

const SearchArticleList = ({
  filterInSearcher,
  handleUpperCategoryIdParam,
  userInfoSSFF,
  results, // Recibe los resultados filtrados
}) => {
  const [dataArticlesSearcher, setDataArticlesSearcher] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSearchArticlesComponent = async () => {
      setIsLoading(true);
      try {
        let searchTerm =
          filterInSearcher.length !== 0
            ? filterInSearcher
            : sessionStorage.getItem('dataTextSearcher');

        const subcategoriesArticlesData = await fetchSearchArticles(
          searchTerm,
          handleUpperCategoryIdParam,
          userInfoSSFF.custom03
        );
        console.log('subcategoriesData', subcategoriesArticlesData);
        console.log('uppercategory', handleUpperCategoryIdParam);
        if (handleUpperCategoryIdParam.includes('138')) {
          let copyArticlesData = subcategoriesArticlesData.filter(
            (article) =>
              article.attributes.Visibilidad !== 'People' &&
              article.attributes.Visibilidad !== 'Perfiles'
          );

          if (userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)') {
            setDataArticlesSearcher(
              copyArticlesData.concat(
                subcategoriesArticlesData.filter(
                  (article) => article.attributes.Visibilidad === 'People'
                )
              )
            );
          } else if (userInfoSSFF.custom15 === 'Y') {
            setDataArticlesSearcher(
              copyArticlesData.concat(
                subcategoriesArticlesData.filter(
                  (article) => article.attributes.Visibilidad === 'Perfiles'
                )
              )
            );
          }
        } else {
          setDataArticlesSearcher(subcategoriesArticlesData);
          console.log(dataArticlesSearcher);
        }

        sessionStorage.setItem('dataTextSearcher', searchTerm);
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setIsLoading(false); // Marcar la carga como completa
      }
    };

    fetchSearchArticlesComponent();
  }, [filterInSearcher, handleUpperCategoryIdParam, userInfoSSFF]);

  // Si hay resultados pasados desde ContactUsInbentaInfo, los utilizamos
  /*   useEffect(() => {
    if (results.length > 0) {
      setDataArticlesSearcher(results);
    }
  }, [results]); */

  return (
    <section className='search__articleList'>
      <h4 className='search__ArticleList--title'>Resultados de tu búsqueda</h4>
      <p className='search__ArticleList--search'>
        {dataArticlesSearcher.length !== 0 ? filterInSearcher : null}
      </p>
      {isLoading ? (
        <p>Cargando resultados...</p>
      ) : (
        <section className='search__articleList__content'>
          {dataArticlesSearcher.length !== 0 ? (
            dataArticlesSearcher.map((article) => {
              console.log(article);
              return (
                <ArticleItem
                  key={article.id}
                  title={article.title}
                  answer={
                    article.attributes?.ANSWER_TEXT ||
                    'Sin respuesta disponible'
                  }
                  expandedState={false}
                  clickCode={article.tracking.clickCode}
                  rateCode={article.tracking.rateCode}
                  userInfoSSFF={userInfoSSFF}
                  articleId={article.id}
                  contactMe={article.attributes.CONTACT_MANAGER}
                />
              );
            })
          ) : handleUpperCategoryIdParam === '138' ? (
            <p>
              No hay resultados. Visita los{' '}
              <Link to='/roles' className='categorias__noResult'>
                roles
              </Link>{' '}
              de nuestra página de roles o{' '}
              <Link to='/contacto' className='contacto__noResult'>
                contacta con nosotros
              </Link>{' '}
              si no encuentras la información que buscas.
            </p>
          ) : (
            <p>
              No hay resultados. Visita las{' '}
              <Link
                to='/home#bubbles-categories'
                className='categorias__noResult'
              >
                categorías
              </Link>{' '}
              de nuestra página de inicio.
            </p>
          )}
        </section>
      )}
    </section>
  );
};

export default SearchArticleList;
