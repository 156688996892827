import React from 'react';

const RecruitmentDetails = ({ formData, errors, onChange }) => {
  return (
    <div className="formJiraNewSection">      
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.ageOfVacancy && '*'}AGE OF VACANCY:
          <input
            type="number"
            name="ageOfVacancy"
            value={formData.ageOfVacancy}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.ageOfVacancy ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.timeFromLLToFirstInterview && '*'}TIME FROM LL TO 1ST INTERVIEW:
          <input
            type="time"
            name="timeFromLLToFirstInterview"
            value={formData.timeFromLLToFirstInterview}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.timeFromLLToFirstInterview ? 'errorBorder' : ''}`}
          />
        </label>
		<label className="formJiraNewLabel_three">
          {errors.timeFromCandidateSelectedToVerbalOffer && '*'}TIME FROM CANDIDATE SELECTED TO VERBAL OFFER:
          <input
            type="time"
            name="timeFromCandidateSelectedToVerbalOffer"
            value={formData.timeFromCandidateSelectedToVerbalOffer}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.timeFromCandidateSelectedToVerbalOffer ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
           {errors.timeToVerbalOffer && '*'}TIME TO VERBAL OFFER:
          <input
            type="time"
            name="timeToVerbalOffer"
            value={formData.timeToVerbalOffer}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.timeToVerbalOffer ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.sourcing && '*'}SOURCING:
          <input
            type="text"
            name="sourcing"
            value={formData.sourcing}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.sourcing ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.offerToHire && '*'}OFFER TO HIRE:
          <input
            type="text"
            name="offerToHire"
            value={formData.offerToHire}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.offerToHire ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection'>
        <label className="formJiraNewLabel">
          {errors.finalCandidateName && '*'}FINAL CANDIDATE (Name):
          <input
            type="text"
            name="finalCandidateName"
            value={formData.finalCandidateName}
            onChange={onChange}
            className={`formJiraNewInputText ${errors.finalCandidateName ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection'>
        <label className="formJiraNewLabel">
          {errors.corporateMail && '*'}CORPORATE MAIL:
          <input
            type="email"
            name="corporateMail"
            value={formData.corporateMail}
            onChange={onChange}
            className={`formJiraNewInputText ${errors.corporateMail ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className="formJiraNewLabel">
          {errors.personalMail && '*'}PERSONAL EMAIL:
          <input
            type="email"
            name="personalEmail"
            value={formData.personalMail}
            onChange={onChange}
            className={`formJiraNewInputText ${errors.personalMail ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel_short">
          {errors.finalLocalCategoryGradeOfHiring && '*'}FINAL LOCAL CATEGORY/GRADE OF HIRING:
          <input
            type="text"
            name="finalLocalCategoryGradeOfHiring"
            value={formData.finalLocalCategoryGradeOfHiring}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.finalLocalCategoryGradeOfHiring ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel">
          {errors.finalGlobalCategoryGradeOfHiring && '*'}FINAL GLOBAL CATEGORY/GRADE OF HIRING:
          <input
            type="text"
            name="finalGlobalCategoryGradeOfHiring"
            value={formData.finalGlobalCategoryGradeOfHiring}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.finalGlobalCategoryGradeOfHiring ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.ageOfferDate && '*'}AGE (OFFER DATE):
          <input
            type="number"
            name="ageAtOfferDate"
            value={formData.ageOfferDate}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.ageOfferDate ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.gender && '*'}GENDER:
          <select
            name="gender"
            value={formData.gender}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.gender ? 'errorBorder' : ''}`}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <label className="formJiraNewLabel_three">
          {errors.nationality && '*'}NATIONALITY:
          <input
            type="text"
            name="nationality"
            value={formData.nationality}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.nationality ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.externalInternal && '*'}EXTERNAL/INTERNAL:
          <select
            name="externalInternal"
            value={formData.externalInternal}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.externalInternal ? 'errorBorder' : ''}`}
          >
            <option value="">Select External/Internal</option>
            <option value="External">External</option>
            <option value="Internal">Internal</option>
          </select>
        </label>

        <label className="formJiraNewLabel">
          {errors.recruitmentSource && '*'}RECRUITMENT SOURCE:
          <input
            type="text"
            name="recruitmentSource"
            value={formData.recruitmentSource}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.recruitmentSource ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.finalLocationHQ2 && '*'}FINAL LOCATION (HQ2):
          <input
            type="text"
            name="finalLocationHQ2"
            value={formData.finalLocationHQ2}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.finalLocationHQ2 ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
          {errors.building && '*'}BUILDING:
          <input
            type="text"
            name="building"
            value={formData.building}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.building ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          FLOOR:{errors.floor && '*'}
          <input
            type="text"
            name="floor"
            value={formData.floor}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.floor ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
          {errors.countryFinalCandidate && '*'}COUNTRY (FINAL CANDIDATE):
          <input
            type="text"
            name="countryFinalCandidate"
            value={formData.countryFinalCandidate}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.countryFinalCandidate ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.needsInternationalMobility && '*'}NEEDS INTERNATIONAL MOBILITY:
          <select
            name="needsInternationalMobility"
            value={formData.needsInternationalMobility}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.needsInternationalMobility ? 'errorBorder' : ''}`}
          >
            <option value="">Select Mobility</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label className="formJiraNewLabel">
           {errors.salary && '*'}SALARY:
          <input
            type="number"
            name="salary"
            value={formData.salary}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.salary ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.targetBonus && '*'}TARGET BONUS:
          <input
            type="text"
            name="targetBonus"
            value={formData.targetBonus}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.targetBonus ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
          {errors.currency && '*'}CURRENCY:
          <input
            type="text"
            name="currency"
            value={formData.currency}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.currency ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
         {errors.targetResults && '*'}TARGET RESULTS:
          <input
            type="text"
            name="targetResults"
            value={formData.targetResults}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.targetResults ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
          {errors.disability && '*'}DISABILITY:
          <input
            type="text"
            name="disability"
            value={formData.disability}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.disability ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.sourceFinalCandidate && '*'}SOURCE FINAL CANDIDATE:
          <select
            name="sourceFinalCandidate"
            value={formData.sourceFinalCandidate}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.sourceFinalCandidate ? 'errorBorder' : ''}`}
          >
            <option value="">Select Source</option>
            <option value="Group">Group</option>
            <option value="Outside Group">Outside Group</option>
          </select>
        </label>

        <label className="formJiraNewLabel">
          {errors.previousCompany && '*'}PREVIOUS COMPANY:
          <input
            type="text"
            name="previousCompany"
            value={formData.previousCompany}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.previousCompany ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.school && '*'}SCHOOL:
          <input
            type="text"
            name="school"
            value={formData.school}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.school ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
           {errors.isContractor && '*'}IS CONTRACTOR:
          <select
            name="isContractor"
            value={formData.isContractor}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.isContractor ? 'errorBorder' : ''}`}
          >
            <option value="">Select Contractor Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
           {errors.revolvingDoors && '*'}REVOLVING DOORS (Political Exposure/Sanctions):
          <input
            type="text"
            name="revolvingDoors"
            value={formData.revolvingDoors}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.revolvingDoors ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
           {errors.contractType && '*'}CONTRACT TYPE:
          <input
            type="text"
            name="contractType"
            value={formData.contractType}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.contractType ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.workPercentage && '*'}WORK PERCENTAGE:
          <input
            type="text"
            name="workPercentage"
            value={formData.workPercentage}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.workPercentage ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
          {errors.employeeType && '*'}EMPLOYEE TYPE:
          <select
            name="employeeType"
            value={formData.employeeType}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.employeeType ? 'errorBorder' : ''}`}
          >
            <option value="">Select Employee Type</option>
            <option value="Employee">Employee</option>
            <option value="Intern">Intern</option>
          </select>
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.dateOfJoiningCompany && '*'}DATE OF JOINING COMPANY:
          <input
            type="date"
            name="dateOfJoiningCompany"
            value={formData.dateOfJoiningCompany}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.dateOfJoiningCompany ? 'errorBorder' : ''}`}
          />
        </label>

        <label className="formJiraNewLabel">
          {errors.seniorityDate && '*'}SENIORITY DATE:
          <input
            type="date"
            name="seniorityDate"
            value={formData.seniorityDate}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.seniorityDate ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.fullRemote && '*'}FULL REMOTE:
          <select
            name="fullRemote"
            value={formData.fullRemote}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.fullRemote ? 'errorBorder' : ''}`}
          >
            <option value="">Select Remote Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label className="formJiraNewLabel">
          {errors.professionalGroup && '*'}PROFESSIONAL GROUP:
          <input
            type="text"
            name="professionalGroup"
            value={formData.professionalGroup}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.professionalGroup ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
      
      {/* Add more fields as needed */}
    </div>
  );
};

export default RecruitmentDetails;
