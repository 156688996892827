import React, { useState, useEffect } from 'react';
import BusinessInfoChannel from './businessInfoChannel';
import IncorporationNeedChannel from './incorporationNeed.js';
import PositionDetailsChannel from './positionDetails.js';
import ManagerChannelSection from './managerChannelSection';
import RecruiterInfo from './recruiterInfoChannel';
import { ValidateForm } from './validateForm.js';
import { Link } from 'react-router-dom';
import { handleSubmit } from './handleSubmit';
import elipse355 from '../../../../images/ellipse355.png';
import '../../../styles/new/newEmployeeForm.css';

const ManagerForm = ({ userInfoSSFF, ticketData }) => {
  const [formData, setFormData] = useState({
    businessUnit: userInfoSSFF?.custom02 || '',
    division: userInfoSSFF?.division || '',
    departamento: userInfoSSFF?.department || '',
    needType: '',
    vacancyType: '',
    replacementName: '',
    replaceByIntern: '',
    internshipWindow: '',
    postInternship: '',
    rationale: '',
    criticality: '',
    responsibilities: '',
    skills: '',
    project: '',
    location: '',
    desiredDate: '',
    hiringManager: '',
    costCenter: '',
    globalRole: '',
    benchmark: '',
    locationHQ: '',
    jobClassification: '',
    vacancySSFFID: '',
    roleTitle: '',
    criticalProfile: '',
    hrbp: '',
    type: '',
    legalEntity: '',
    finalCandidateName: '',
    corporateMail: '',
    personalEmail: '',
    finalLocalCategoryGradeOfHiring: '',
    finalGlobalCategoryGradeOfHiring: '',
    countryFinalCandidate: '',
    needsInternationalMobility: '',
    salary: '',
    targetBonus: '',
    currency: '',
    isContractor: '',
    revolvingDoors: '',
    contractType: '',
  });

  useEffect(() => {
    if (ticketData) {
      setFormData({
        businessUnit: ticketData.businessUnit || '',
        division: ticketData.division || '',
        departamento: ticketData.departamento || '',
        needType: ticketData.needType || '',
        vacancyType: ticketData.vacancyType || '',
        replacementName: ticketData.replacementName || '',
        replaceByIntern: ticketData.replaceByIntern || '',
        internshipWindow: ticketData.internshipWindow || '',
        postInternship: ticketData.postInternship || '',
        rationale: ticketData.rationale || '',
        criticality: ticketData.criticality || '',
        responsibilities: ticketData.responsibilities || '',
        skills: ticketData.skills || '',
        project: ticketData.project || '',
        location: ticketData.location || '',
        desiredDate: ticketData.desiredDate || '',
        hiringManager: ticketData.hiringManager || '',
        costCenter: ticketData.costCenter || '',
        globalRole: ticketData.globalRole || '',
        benchmark: ticketData.benchmark || '',
        locationHQ: ticketData.locationHQ || '',
        jobClassification: ticketData.jobClassification || '',
        vacancySSFFID: ticketData.vacancySSFFID || '',
        roleTitle: ticketData.roleTitle || '',
        criticalProfile: ticketData.criticalProfile || '',
        hrbp: ticketData.hrbp || '',
        type: ticketData.type || '',
        legalEntity: ticketData.legalEntity || '',
        finalCandidateName: ticketData.finalCandidateName || '',
        corporateMail: ticketData.corporateMail || '',
        personalEmail: ticketData.personalEmail || '',
        finalLocalCategoryGradeOfHiring:
          ticketData.finalLocalCategoryGradeOfHiring || '',
        finalGlobalCategoryGradeOfHiring:
          ticketData.finalGlobalCategoryGradeOfHiring || '',
        countryFinalCandidate: ticketData.countryFinalCandidate || '',
        needsInternationalMobility: ticketData.needsInternationalMobility || '',
        salary: ticketData.salary || '',
        targetBonus: ticketData.targetBonus || '',
        currency: ticketData.currency || '',
        isContractor: ticketData.isContractor || '',
        revolvingDoors: ticketData.revolvingDoors || '',
        contractType: ticketData.contractType || '',
      });
    }
  }, [ticketData]);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    const validationErrors = ValidateForm(updatedFormData);
    console.log('erroressss', validationErrors);
    setErrors(validationErrors);
  };

  const handleClear = () => {
    setFormData({
      businessUnit: ticketData.businessUnit || '',
      division: ticketData.division || '',
      departamento: ticketData.departamento || '',
      needType: ticketData.needType || '',
      vacancyType: ticketData.vacancyType || '',
      replacementName: ticketData.replacementName || '',
      replaceByIntern: ticketData.replaceByIntern || '',
      internshipWindow: ticketData.internshipWindow || '',
      postInternship: ticketData.postInternship || '',
      rationale: ticketData.rationale || '',
      criticality: ticketData.criticality || '',
      responsibilities: ticketData.responsibilities || '',
      skills: ticketData.skills || '',
      project: ticketData.project || '',
      location: ticketData.location || '',
      desiredDate: ticketData.desiredDate || '',
      hiringManager: ticketData.hiringManager || '',
      costCenter: ticketData.costCenter || '',
      globalRole: ticketData.globalRole || '',
      benchmark: ticketData.benchmark || '',
      locationHQ: ticketData.locationHQ || '',
      jobClassification: ticketData.jobClassification || '',
      vacancySSFFID: ticketData.vacancySSFFID || '',
      roleTitle: ticketData.roleTitle || '',
      criticalProfile: ticketData.criticalProfile || '',
      hrbp: ticketData.hrbp || '',
      type: ticketData.type || '',
      legalEntity: ticketData.legalEntity || '',
      finalCandidateName: ticketData.finalCandidateName || '',
      corporateMail: ticketData.corporateMail || '',
      personalEmail: ticketData.personalEmail || '',
      finalLocalCategoryGradeOfHiring:
        ticketData.finalLocalCategoryGradeOfHiring || '',
      finalGlobalCategoryGradeOfHiring:
        ticketData.finalGlobalCategoryGradeOfHiring || '',
      countryFinalCandidate: ticketData.countryFinalCandidate || '',
      needsInternationalMobility: ticketData.needsInternationalMobility || '',
      salary: ticketData.salary || '',
      targetBonus: ticketData.targetBonus || '',
      currency: ticketData.currency || '',
      isContractor: ticketData.isContractor || '',
      revolvingDoors: ticketData.revolvingDoors || '',
      contractType: ticketData.contractType || '',
    });
  };

  const isButtonEnabled = !isSubmitting && Object.keys(errors).length === 0;

  return (
    <div className='combined-form'>
      <div className='jiraNewForm__breadcrumb'>
        <img src={elipse355} alt='circle-icon' className='circle-icon' />
        <Link to='/form' className='jiraNewForm__breadcrumb--title'>
          INICIO
        </Link>
        <div className='jiraNewForm__breadcrumb--text'>
          &gt; SOLICITUD DE ALTA
        </div>
      </div>
      <h1 className='formJiraNew__title'>
        Solicitud de Alta (Canal de Manager)
      </h1>
      <form
        onSubmit={(e) =>
          handleSubmit(
            e,
            formData,
            setIsSubmitting,
            handleClear,
            setErrors,
            ValidateForm
          )
        }
      >
        <BusinessInfoChannel
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          onChange={handleChange}
        />
        <IncorporationNeedChannel
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          onChange={handleChange}
        />
        <PositionDetailsChannel
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          onChange={handleChange}
        />
        <ManagerChannelSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          onChange={handleChange}
        />
        <RecruiterInfo
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          onChange={handleChange}
        />
        <div className='formJiraNewButton_section'>
          <button
            type='button'
            className='secondaryButton'
            onClick={handleClear}
          >
            Limpiar
          </button>
          <button
            className={`formJiraNewButton ${
              isButtonEnabled ? 'enabled' : 'disabled'
            }`}
            type='submit'
            //disabled={!isButtonEnabled}
          >
            Enviar
          </button>
          {isSubmitting && <div className='loader'></div>}
        </div>
      </form>
    </div>
  );
};

export default ManagerForm;
