import React from 'react';

const priorityMap = {
  High: 'alta',
  Medium: 'media',
  Low: 'baja',
};

const FilterCheckbox = ({ label, options, selectedValues, onChange }) => {
  return (
    <div className='filter-section'>
      <h4 className='filter-section-title'>{label}</h4>
      <div className='filter-section-fields'>
        {options.map((option) => {
          const translatedPriority = priorityMap[option.value] || option.value;

          return (
            <label key={option.value} className='filter-section-label'>
              <input
                className='filter-section-input'
                type='checkbox'
                value={option.value}
                checked={selectedValues.includes(translatedPriority)}
                onChange={onChange}
              />
              {option.label}
            </label>
          );
        })}
      </div>
    </div>
  );
};

const HistorialFilterSidebar = ({
  selectedTypes,
  handleTypeChange,
  selectedStatuses,
  handleStatusChange,
  selectedPriorities,
  handlePriorityChange,
}) => {
  return (
    <div className='filter-sidebar'>
      <h3>Filtros</h3>

      <FilterCheckbox
        label='Cola'
        options={[
          { value: 'termination', label: 'Baja' },
          { value: 'change', label: 'Cambio' },
        ]}
        selectedValues={selectedTypes}
        onChange={handleTypeChange}
      />

      <FilterCheckbox
        label='Estado'
        options={[
          { value: 'Backlog', label: 'Backlog' },
          // {
          //   value: 'SelectedForDevelopment',
          //   label: 'Seleccionado para desarrollo',
          // },
          { value: 'InProgress', label: 'En curso' },
          { value: 'Finalized', label: 'Finalizada' },
        ]}
        selectedValues={selectedStatuses}
        onChange={handleStatusChange}
      />
      <FilterCheckbox
        label='Prioridad'
        options={[
          { value: 'High', label: 'Alta' },
          { value: 'Medium', label: 'Media' },
          { value: 'Low', label: 'Baja' },
        ]}
        selectedValues={selectedPriorities}
        onChange={handlePriorityChange}
      />
    </div>
  );
};

export default HistorialFilterSidebar;
