export const getFolder = async (jwtSSFF, token) => {
  try {
    const response = await fetch(`/files`, {
      method: 'GET',
      headers: {
        'x-msal-Authorization': `Bearer ${token}`,
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`
      },
    });

    if (!response.ok) {
      throw new Error('Fallo en la solicitud de nominas');
    }
    const responseUserPayroll = await response.json();
    return responseUserPayroll;
  } catch (error) {
    throw new Error('Error en la solicitud de nominas ' + error.message);
  }
};


export const getPayrollHCM = async (jwtSSFF,idToken, selectedMonth, selectedYear) => {
  try {
    
    const response = await fetch(`/getPayrollHCM`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'x-msal-Authorization': `Bearer ${idToken}`,
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`
      },
      body: JSON.stringify({ selectedMonth, selectedYear })
    });

    if (!response.ok) {
      throw new Error('Fallo en la solicitud del certificado');
    }

    const responseData = await response.arrayBuffer();

    // Si no hay datos, devolvemos un array vacío
    if (responseData.byteLength === 0) {
      return [];
    }

    return responseData;
  } catch (error) {
    console.error('Error al obtener el certificado:', error.message);
    // Opcionalmente puedes devolver un array vacío aquí también
    return [];
  }
};


