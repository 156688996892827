import CryptoJS from 'crypto-js';
import DOMpurify from 'dompurify';
import { createJiraTicket } from '../../../../../services/apiJira';
import { validateFields } from './formValidate';

const secretKey = 'your-secret-key';

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

// Al enviar el forulario, hay que mostrar al ususario que si algunos campos han sido cambiados, esto puede afectar que otros campos también cambien.
const showSalaryChangeWarning = () => {
  const confirmMessage = `Al cambiar el salario, también pueden cambiar los siguientes campos:

    • Local Grade / Categoría Local.
    • Role Change / Cambio De Rol.
    • Benchmark Name / Código Benchmark.

    ¿Desea continuar?`;
  return window.confirm(confirmMessage);
};

// Función de advertencia para cambio organizativo
const showOrgChangeWarning = () => {
  const confirmMessage = `Al realizar cambios organizativos, los siguientes campos podrían sufrir cambios:

    • Business Results Objectives / Resultado Objetivos Negocio.
    • Role Change / Cambio De Rol.
    • Line Manager E-Mail / E-Mail Jefe Directo.
    • Benchmark Name / Código Benchmark.
    • Job Classification / Código.
    • Job Classification / Puesto.

    ¿Desea continuar?`;
  return window.confirm(confirmMessage);
};

// Función de advertencia para cambio de localización
const showLocationChangeWarning = () => {
  const confirmMessage = `Al realizar un cambio de localización, los siguientes campos podrían sufrir cambios:

    • Currency / Moneda.
    • Salary / Salario.
    • Bonus / Bono.
    • Job Classification / Código.
    • Job Classification / Puesto.
    • Legal Entity / Entidad Jurídica.

    ¿Desea continuar?`;
  return window.confirm(confirmMessage);
};

// Función de advertencia para cambio de rol (NO)
const showRoleChangeNoWarning = () => {
  const confirmMessage = `Al no consistir en un cambio de rol,  los siguientes campos podrían sufrir cambios:

    • Local Grade / Categoría Local.
    • Job Classification / Código.
    • Job Classification / Puesto.
    • Business Unit / Unidad De Negocio.
    • Division / División.
    • Department / Departamento.
    • Cost Centre / Centro De Coste.
    • Business Results Objectives / Resultado Objetivos Negocio.
    • Salary / Salario.
    • Bonus / Bono.

    ¿Desea continuar?`;
  return window.confirm(confirmMessage);
};

// Función de advertencia para cambio de rol (SÍ)
const showRoleChangeYesWarning = () => {
  const confirmMessage = `Al realizar un cambio de rol, los siguientes campos podrían sufrir cambios:

    • Local Grade / Categoría Local.
    • Job Classification / Código.
    • Job Classification / Puesto.
    • Benchmark Name / Código Benchmark.
    • Business Unit / Unidad De Negocio.
    • Division / División.
    • Department / Departamento.
    • Cost Centre / Centro De Coste.
    • Business Results Objectives / Resultado Objetivos Negocio.
    • Line Manager E-Mail / E-Mail Jefe Directo.
    • Salary / Salario.
    • Bonus / Bono.

    ¿Desea continuar?`;
  return window.confirm(confirmMessage);
};
// Traducción de los campos del formulario para que se muestren en español al usuario
const translations = {
  effectiveDate: 'Fecha Efectiva',
  employeeNameDisplay: 'Nombre del Empleado',
  organizationalChange: 'Cambio Organizacional',
  newDepartments: 'Nuevos Departamentos',
  responsiblePerson: 'Persona Responsable',
  roleChangeDetails: 'Detalles del Cambio de Rol',
  managerChange: 'Cambio de Manager',
  newManager: 'Nuevo Manager',
  changeDetails: 'Detalles del Cambio',
  salaryChange: 'Cambio de Salario',
  currency: 'Moneda',
  bonusOrIncentives: 'Bono o Incentivos',
  bonusPercentage: 'Porcentaje de Bono',
  incentivesAmount: 'Monto de Incentivos',
  salaryReason: 'Razón del Salario',
  city: 'Ciudad',
  building: 'Edificio',
  floor: 'Piso/Planta',
  country: 'País',
};

export const handleSubmit = async (
  e,
  effectiveDate,
  changeType,
  organizationalChange,
  newDepartments,
  responsiblePerson,
  roleChange,
  roleChangeDetails,
  managerChange,
  newManager,
  changeDetails,
  salaryChange,
  currency,
  bonusOrIncentives,
  bonusPercentage,
  incentivesAmount,
  salaryReason,
  city,
  country,
  building,
  floor,
  contractChange,
  isButtonEnabled,
  setIsSubmitting,
  handleClearForm,
  setChangeType,
  setFeedbackPopup,
  setMessageSendingFailed,
  employeeName,
  selectedUser
) => {
  e.preventDefault();

  // Validar campos
  const fields = {
    effectiveDate,
    selectedUser,
    roleChange,
    changeDetails,
    roleChangeDetails,
    managerChange,
    newManager,
    salaryChange,
    currency,
    bonusOrIncentives,
    bonusPercentage,
    incentivesAmount,
    salaryReason,
    organizationalChange,
    newDepartments,
    responsiblePerson,
    city,
    building,
    floor,
    country,
  };

  const errors = validateFields(fields);

  if (Object.keys(errors).length > 0) {
    // Si hay errores, mostrarlos al usuario
    let errorMessage = 'Por favor complete los siguientes campos:\n';
    for (const [field, hasError] of Object.entries(errors)) {
      // Traduce el nombre del campo al español
      const translatedField = translations[field] || field;
      errorMessage += `• ${translatedField}\n`;
    }
    alert(errorMessage);
    return;
  }

  // Si el botón está habilitado, proceder con el envío del ticket
  if (isButtonEnabled) {
    try {
      // Mostrar advertencias dependiendo del tipo de cambio
      if (changeType.includes('Salary')) {
        const userConfirmed = showSalaryChangeWarning();
        if (!userConfirmed) return; // Si el usuario cancela, no se envía el ticket
      }

      if (changeType.includes('Organizational')) {
        const userConfirmed = showOrgChangeWarning();
        if (!userConfirmed) return;
      }

      if (changeType.includes('Location')) {
        const userConfirmed = showLocationChangeWarning();
        if (!userConfirmed) return;
      }

      if (changeType.includes('Role') && roleChange === 'no') {
        const userConfirmed = showRoleChangeNoWarning();
        if (!userConfirmed) return;
      }

      if (changeType.includes('Role') && roleChange === 'yes') {
        const userConfirmed = showRoleChangeYesWarning();
        if (!userConfirmed) return;
      }

      setIsSubmitting(true);

      const sanitize = (value) => DOMpurify.sanitize(value || '');

      const fields = {
        employeeName: encryptValue(sanitize(selectedUser.displayName)),
        manager: encryptValue(sanitize(employeeName.displayName)),
        emailManager: encryptValue(sanitize(employeeName.username)),
        effectiveDate: encryptValue(sanitize(effectiveDate.toString())),
        organizationalChange: encryptValue(
          sanitize(organizationalChange.toString())
        ),
        newDepartments: encryptValue(sanitize(newDepartments.toString())),
        responsiblePerson: encryptValue(sanitize(responsiblePerson)),
        roleChangeDetails: encryptValue(sanitize(roleChangeDetails)),
        managerChange: encryptValue(sanitize(managerChange.toString())),
        newManager: encryptValue(sanitize(newManager)),
        salaryChange: encryptValue(sanitize(salaryChange)),
        currency: encryptValue(sanitize(currency.toString())),
        incentivesAmount: encryptValue(sanitize(incentivesAmount)),
        salaryReason: encryptValue(sanitize(salaryReason)),
        city: encryptValue(sanitize(city)),
        country: encryptValue(sanitize(country)),
        building: encryptValue(sanitize(building)),
        floor: encryptValue(sanitize(floor)),
        contractChange: encryptValue(sanitize(contractChange)),
      };

      let ticketData = {
        summary: `Cambio`,
        issueType: 'Cambio',
        priority: 'Medium',
      };

      const customFields = {
        customfield_10328: fields.employeeName,
        customfield_10329: fields.manager,
        customfield_10330: fields.emailManager,
        customfield_10331: fields.effectiveDate,
        customfield_10332: fields.organizationalChange,
        customfield_10333: fields.newDepartments,
        customfield_10334: fields.responsiblePerson,
        customfield_10335: fields.roleChangeDetails,
        customfield_10336: fields.managerChange,
        customfield_10337: fields.newManager,
        customfield_10338: fields.salaryChange,
        customfield_10339: fields.currency,
        customfield_10340: fields.incentivesAmount,
        customfield_10341: fields.salaryReason,
        customfield_10342: fields.city,
        customfield_10343: fields.country,
        customfield_10344: fields.building,
        customfield_10345: fields.floor,
        customfield_10346: fields.contractChange,
      };

      // Eliminar campos encriptados que tengan valor `undefined` para evitar errores en la API
      Object.keys(customFields).forEach((key) => {
        if (customFields[key] === '' || customFields[key] === 0) {
          delete customFields[key];
        }
      });

      ticketData = { ...ticketData, ...customFields };

      await createJiraTicket(ticketData);

      handleClearForm();
      setChangeType(['info']);
      setFeedbackPopup(true);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setMessageSendingFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  }
};
