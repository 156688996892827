import '../../styles/contactUsButton/contactUsFilterSideBar.css';

const FilterCheckbox = ({ label, options, selectedValues, onChange }) => {
  return (
    <div className='filter-section'>
      <p className='attentionChanel__sideBar--filterTitle'>{label}</p>
      {options.map((option) => (
        <label key={option.value} className='attentionChanel__sideBar--filterLabel'>
          <input type='checkbox' value={option.value} checked={selectedValues.includes(option.value)} onChange={onChange} />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const FilterInput = ({ type, value, onChange, placeholder }) => {
  return (
    <div className='filter-section'>
      <label className='attentionChanel__sideBar--filterLabel'>
        Email: {''}
        <input type='text' placeholder={placeholder} value={value} onChange={onChange} />
      </label>
    </div>
  );
};

// const FilterResponsable = ({ value, onChange }) => {
//   return (
//     <div className='filter-section'>
//       <p className='attentionChanel__sideBar--filterTitle'>Responsable:</p>
//       <label className='attentionChanel__sideBar--filterLabel'>
//         <input type='text' placeholder='Buscar por responsable...' value={value} onChange={onChange} />
//       </label>
//     </div>
//   );
// };

const FilterSidebar = ({ selectedTypes, handleTypeChange, selectedStatuses, handleStatusChange, searchEmail, handleEmailChange }) => {
  return (
    <div className='filter-sidebar'>
      <p className='attentionChanel__sideBar--title'>Filtros</p>
      <FilterCheckbox
        label='Soporte'
        options={[
          { value: 'SOPORTE EMPLEADO', label: 'EMPLEADO' },
          { value: 'SOPORTE MANAGER', label: 'MANAGER' },
        ]}
        selectedValues={selectedTypes}
        onChange={handleTypeChange}
      />
      <FilterCheckbox
        label='Estado'
        options={[
          { value: 'Backlog', label: 'Backlog' },
          { value: 'Selected for Development', label: 'Selected for Development' },
          { value: 'En curso', label: 'In Progress' },
          { value: 'Finalizada', label: 'Done' },
        ]}
        selectedValues={selectedStatuses}
        onChange={handleStatusChange}
      />
      {/* <p className='attentionChanel__sideBar--filterTitle'>Creador por:</p> */}
      <FilterInput label='Email' type='email' value={searchEmail} onChange={handleEmailChange} placeholder='example@mail.com' />
      {/* <FilterInput label='Nombre' type='text' value={searchName} onChange={handleNameChange} placeholder='Buscar por nombre' />
      <FilterResponsable value={searchResponsable} onChange={handleResponsableChange} /> */}
    </div>
  );
};

export default FilterSidebar;
