import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css'; // Asegúrate de importar los estilos

const PhoneNum = ({ label, name, value, onChange }) => {
  const handlePhoneChange = (value, data, event, formattedValue) => {
    // Usamos formattedValue para obtener el número formateado
    onChange({ target: { name, value: formattedValue } });
  };

  return (
    <div className='formJiraEndPhone'>
      <label>{label}</label>
      <PhoneInput country={'es'} value={value} onChange={handlePhoneChange} />
    </div>
  );
};

export default PhoneNum;
