export const logOut = async (msalToken, ssffToken) => {
    try {
        // Crear los headers base con el msalToken siempre presente
        const headers = {
            'x-msal-authorization': `Bearer ${msalToken}`,
            'Content-Type': 'application/json',
        };

        // Si el ssffToken está presente, lo agregamos a los headers
        if (ssffToken) {
            headers['x-jwt-authorization'] = `Bearer ${ssffToken}`;
        }

        // Realizar la solicitud de logout
        const response = await fetch('/logOut', {
            method: 'POST',
            headers: headers,
        });

        if (response.ok) {
            return true;  
        } else {
            console.error('Error al invalidar los tokens en el servidor');
            return false; 
        }
    } catch (error) {
        console.error('Error en la solicitud de logout:', error);
        return false;
    }
};
