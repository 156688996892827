import React from 'react';
import { LabelInput } from './inputs/labelInput';

const CommentsForm = ({ comments, setComments }) => {
  return (
    <div className="formSection">
      <h2 className="formSection__title">Comentarios u Observaciones</h2>
      <LabelInput
        label="Comentarios:"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        type="textarea"
      />
    </div>
  );
};

export default CommentsForm;
