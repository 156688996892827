import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ValidateForm } from './validateForm';
import RecruitmentProfile from './recruitmentProfile';
import RecruitmentDetails from './recruitmentDetails';
import RecruitmentFinancials from './recruitmentFinancials';
import RecruitmentDates from './recruitmentDates';
import RecruitmentMetrics from './recruitmentMetrics';
import ManagerFormSection from './managerFormSection';
import ManagerChannelSection from '../channelForm/managerChannelSection';
import elipse355 from '../../../../images/ellipse355.png';
import '../../../styles/new/newEmployeeForm.css';

const RecruitmentForm = (userInfoSSFF) => {
  const [formData, setFormData] = useState({
		/*vacancySSFFID: ticketData?.vacancySSFFID || '',
		dateUp: ticketData?.dateUp || '',
		status: ticketData?.status || '',
		roleTitle: ticketData?.roleTitle || '',
		criticalProfile: ticketData?.criticalProfile || '',
		profileType: ticketData?.profileType || '',
		recruiter: ticketData?.recruiter || '',
		hrbp: ticketData?.hrbp || '',
		recruitmentManager: ticketData?.recruitmentManager || '',
		recruitmentStatus: ticketData?.recruitmentStatus || '',
		commentsFromRecruiters: ticketData?.commentsFromRecruiters || '',
		type: ticketData?.type || '',
		localGrade: ticketData?.localGrade || '',
		globalGrade: ticketData?.globalGrade || '',
		legalEntity: ticketData?.legalEntity || '',
		country: ticketData?.country || '',
		strategy: ticketData?.strategy || '',
		minExpectedSalary: ticketData?.minExpectedSalary || '',
		maxExpectedSalary: ticketData?.maxExpectedSalary || '',
		expectedBonus: ticketData?.expectedBonus || '',
		currencyExpectedSalary: ticketData?.currencyExpectedSalary || '',
		billingDate: ticketData?.billingDate || '',
		billingLegalEntity: ticketData?.billingLegalEntity || '',
		postingStart: ticketData?.postingStart || '',
		briefingDate: ticketData?.briefingDate || '',
		presentationOfResults: ticketData?.presentationOfResults || '',
		feedbackCandidatesToInterview: ticketData?.feedbackCandidatesToInterview || '',
		businessFirstInterview: ticketData?.businessFirstInterview || '',
		finalCandidateSelected: ticketData?.finalCandidateSelected || '',
		offerDate: ticketData?.offerDate || '',
		verbalOfferAcceptance: ticketData?.verbalOfferAcceptance || '',
		joinDate: ticketData?.joinDate || '',
		onHoldDate: ticketData?.onHoldDate || '',
		reactivationDate: ticketData?.reactivationDate || '',
		cancelledDate: ticketData?.cancelledDate || '',
		ageOfVacancy: ticketData?.ageOfVacancy || '',
		timeFromLLToFirstInterview: ticketData?.timeFromLLToFirstInterview || '',
		timeFromCandidateSelectedToVerbalOffer: ticketData?.timeFromCandidateSelectedToVerbalOffer || '',
		timeToVerbalOffer: ticketData?.timeToVerbalOffer || '',
		sourcing: ticketData?.sourcing || '',
		offerToHire: ticketData?.offerToHire || '',
		finalCandidateName: ticketData?.finalCandidateName || '',
		corporateMail: ticketData?.corporateMail || '',
		personalEmail: ticketData?.personalEmail || '',
		finalLocalCategoryGradeOfHiring: ticketData?.finalLocalCategoryGradeOfHiring || '',
		finalGlobalCategoryGradeOfHiring: ticketData?.finalGlobalCategoryGradeOfHiring || '',
		ageAtOfferDate: ticketData?.ageAtOfferDate || '',
		gender: ticketData?.gender || '',
		nationality: ticketData?.nationality || '',
		externalInternal: ticketData?.externalInternal || '',
		recruitmentSource: ticketData?.recruitmentSource || '',
		finalLocationHQ2: ticketData?.finalLocationHQ2 || '',
		building: ticketData?.building || '',
		floor: ticketData?.floor || '',
		countryFinalCandidate: ticketData?.countryFinalCandidate || '',
		needsInternationalMobility: ticketData?.needsInternationalMobility || '',
		salary: ticketData?.salary || '',
		targetBonus: ticketData?.targetBonus || '',
		currency: ticketData?.currency || '',
		targetResults: ticketData?.targetResults || '',
		disability: ticketData?.disability || '',
		sourceFinalCandidate: ticketData?.sourceFinalCandidate || '',
		previousCompany: ticketData?.previousCompany || '',
		school: ticketData?.school || '',
		isContractor: ticketData?.isContractor || '',
		revolvingDoors: ticketData?.revolvingDoors || '',
		contractType: ticketData?.contractType || '',
		workPercentage: ticketData?.workPercentage || '',
		employeeType: ticketData?.employeeType || '',
		dateOfJoiningCompany: ticketData?.dateOfJoiningCompany || '',
		seniorityDate: ticketData?.seniorityDate || '',
		fullRemote: ticketData?.fullRemote || '',
		professionalGroup: ticketData?.professionalGroup || '',
		totalSSFFApplications: ticketData?.totalSSFFApplications || '',
		totalCandidatesDirectSearch: ticketData?.totalCandidatesDirectSearch || '',
		totalCandidatesPhoneScreening: ticketData?.totalCandidatesPhoneScreening || '',
		totalCandidatesSentToHM: ticketData?.totalCandidatesSentToHM || '',
		totalCandidatesHMValidation: ticketData?.totalCandidatesHMValidation || '',
		totalInterviewedCandidatesHM: ticketData?.totalInterviewedCandidatesHM || '',
		totalWomenSentToHM: ticketData?.totalWomenSentToHM || '',
		totalWomenInShortlist: ticketData?.totalWomenInShortlist || '',
		totalYoungCandidates: ticketData?.totalYoungCandidates || '',
		totalYoungTalentInShortListUnder33: ticketData?.totalYoungTalentInShortListUnder33 || '',
		totalYoungTalentInShortList33To35: ticketData?.totalYoungTalentInShortList33To35 || '',
		positioning: ticketData?.positioning || '',
		totalDisabilityApplications: ticketData?.totalDisabilityApplications || '',
		commentsKPIs: ticketData?.commentsKPIs || '',
		numberSkills: ticketData?.numberSkills || '',
		recommendations: ticketData?.recommendations || '',
		numberRegistrationByRecommendation: ticketData?.numberRegistrationByRecommendation || '',
		placement: ticketData?.placement || '',
		hiringManager: ticketData?.hiringManager || '',
		costCenter: ticketData?.costCenter || '',
		globalRole: ticketData?.globalRole || '',
		benchmark: ticketData?.benchmark || '',
		location: ticketData?.location || '',
		jobClassification: ticketData?.jobClassification || '',*/
		businessUnit: userInfoSSFF?.custom02 || '',
		division: userInfoSSFF?.division || '',
		departamento: userInfoSSFF?.department || '',
		needType: '',
		vacancyType: '',
		replacementName: '',
		replaceByIntern: '',
		internshipWindow: '',
		postInternship: '',
		hiringManager: '',
		costCenter: '',
		globalRole: '', 
		benchmark: '',
		locationHQ: '',
		jobClassification: '',
		vacancySSFFID: '',
		dateUp: '',
		status: '',
		roleTitle: '',
		criticalProfile: '',
		profileType: '',
		recruiter: '',
		hrbp: '',
		recruitmentManager: '',
		recruitmentStatus: '',
		commentsFromRecruiters: '',
		type: '',
		localGrade: '',
		globalGrade: '',
		legalEntity: '',
		country: '',
		strategy: '',
		minExpectedSalary: '',
		maxExpectedSalary: '',
		expectedBonus: '',
		currencyExpectedSalary: '',
		billingDate: '',
		billingLegalEntity: '',
		postingStart: '',
		briefingDate: '',
		presentationOfResults: '',
		feedbackCandidatesToInterview: '',
		businessFirstInterview: '',
		finalCandidateSelected: '',
		offerDate: '',
		verbalOfferAcceptance: '',
		joinDate: '',
		onHoldDate: '',
		reactivationDate: '',
		cancelledDate: '',
		ageOfVacancy: '',
		timeFromLLToFirstInterview: '',
		timeFromCandidateSelectedToVerbalOffer: '',
		timeToVerbalOffer: '',
		sourcing: '',
		offerToHire: '',
		finalCandidateName: '',
		corporateMail: '',
		personalEmail: '',
		finalLocalCategoryGradeOfHiring: '',
		finalGlobalCategoryGradeOfHiring: '',
		ageAtOfferDate: '',
		gender: '',
		nationality: '',
		externalInternal: '',
		recruitmentSource: '',
		finalLocationHQ2: '',
		building: '',
		floor: '',
		countryFinalCandidate: '',
		needsInternationalMobility: '',
		salary: '',
		targetBonus: '',
		currency: '',
		targetResults: '',
		disability: '',
		sourceFinalCandidate: '',
		previousCompany: '',
		school: '',
		isContractor: '',
		revolvingDoors: '',
		contractType: '',
		workPercentage: '',
		employeeType: '',
		dateOfJoiningCompany: '',
		seniorityDate: '',
		fullRemote: '',
		professionalGroup: '',
		totalSSFFApplications: '',
		totalCandidatesDirectSearch: '',
		totalCandidatesPhoneScreening: '',
		totalCandidatesSentToHM: '',
		totalCandidatesHMValidation: '',
		totalInterviewedCandidatesHM: '',
		totalWomenSentToHM: '',
		totalWomenInShortlist: '',
		totalYoungCandidates: '',
		totalYoungTalentInShortListUnder33: '',
		totalYoungTalentInShortList33To35: '',
		positioning: '',
		totalDisabilityApplications: '',
		commentsKPIs: '',
		numberSkills: '',
		recommendations: '',
		numberRegistrationByRecommendation: '',
		placement: '',
		hiringManager: '',
		costCenter: '',
		globalRole: '',
		benchmark: '',
		location: '',
		jobClassification: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
	const { name, value } = e.target;
	const updatedFormData = { ...formData, [name]: value };
	setFormData(updatedFormData);
	const validationErrors = ValidateForm(updatedFormData);
	setErrors(validationErrors);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = ValidateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true); 
      try {
        console.log(formData);
        await new Promise((resolve) => setTimeout(resolve, 2000)); 
      } catch (error) {
        console.error('Error al enviar el formulario:', error);
      } finally {
        setIsSubmitting(false);  
      }
    } else {
      setErrors(validationErrors); 
    }
  };

  const isButtonEnabled = !isSubmitting && Object.keys(errors).length === 0;

  return (
	<div className="combined-form">
		<div className="jiraNewForm__breadcrumb">
			<img src={elipse355} alt="circle-icon" className="circle-icon" />
			<Link to="/form" className="jiraNewForm__breadcrumb--title">
				INICIO
			</Link>
			<div className="jiraNewForm__breadcrumb--text">
				&gt; SOLICITUD DE ALTA
			</div>
		</div>
		<h1 className="formJiraNew__title">Solicitud de Alta (Recruitment)</h1>
		<form onSubmit={handleSubmit}>
			<ManagerFormSection formData={formData} errors={errors} onChange={handleChange} />
			<ManagerChannelSection formData={formData} errors={errors} onChange={handleChange} />
			<RecruitmentProfile formData={formData} errors={errors} onChange={handleChange} />
			<RecruitmentDates formData={formData} errors={errors} onChange={handleChange} />	
			<RecruitmentFinancials formData={formData} errors={errors} onChange={handleChange} />	
			<RecruitmentDetails formData={formData} errors={errors} onChange={handleChange} />
			<RecruitmentMetrics formData={formData} errors={errors} onChange={handleChange} />
			<div className="formJiraNewButton_section">
				<button
					className={`formJiraNewButton ${isButtonEnabled ? 'enabled' : 'disabled'}`}
						type="submit"
							//disabled={!isButtonEnabled}
					>
					Enviar
				</button>
				{isSubmitting && (
				<div className="loader"></div>
				)}
{/* 				<button 
					onClick={handleClearForm}
					className="secondaryButton">
						Limpiar
				</button> */}
			</div>
		</form>
	</div>
  );
};

export default RecruitmentForm;
