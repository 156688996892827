import React from 'react';
import { Link } from 'react-router-dom';
import TerminationRequest from './terminationMain';
import elipse355 from '../../../images/ellipse355.png';
import '../../styles/terminate/terminationMenu.css';

const TerminationMenu = ({ userInfoSSFF, ticketData }) => {
  return (
    <>
      <div className='myFolderSection_content'>
        <div className='myFolderPage__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/form' className='myFolderPage__breadcrumb--title'>
            INICIO
          </Link>
          <div className='myFolderPage__breadcrumb--text'>&gt; SOLICITUD DE BAJA</div>
        </div>
        <div className='myFolderSection_container'>
          <TerminationRequest userInfoSSFF={userInfoSSFF} ticketData={ticketData} />
        </div>
      </div>
    </>
  );
};

export default TerminationMenu;
