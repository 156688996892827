import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';

import { createTicket, USER_TYPE_TO_INBENTA_ID, isUserExist } from '../../../services/api';

const Loan = ({ userInfoSSFF }) => {
  const [matricula, setMatricula] = useState('');
  const [opcion, setOpcion] = useState('1mes');
  const [comentarios, setComentarios] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showMatriculaRequired, setShowMatriculaRequired] = useState(false);
  const [showOpcionRequired, setShowOpcionRequired] = useState(false);
  const [showComentariosRequired, setShowComentariosRequired] = useState(false);
  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const areFieldsValid = matricula.trim() !== '' && opcion !== '' && comentarios.trim() !== '';
    setIsButtonEnabled(areFieldsValid);
  }, [matricula, opcion, comentarios]);

  const handleMatriculaChange = (e) => {
    setMatricula(e.target.value);
    setShowMatriculaRequired(false);
  };

  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
    setShowOpcionRequired(false);
  };

  const handleComentariosChange = (e) => {
    setComentarios(e.target.value);
    setShowComentariosRequired(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid = matricula.trim() !== '' && opcion !== '' && comentarios.trim() !== '';
    if (matricula === '') {
      setShowMatriculaRequired(true);
    }
    if (opcion === '') {
      setShowOpcionRequired(true);
    }
    if (comentarios === '') {
      setShowComentariosRequired(true);
    } else if (areFieldsValid) {
      try {
        setIsSubmitting(true);
        const dataUserInbenta = await isUserExist(userInfoSSFF.username);

        // Sanitizar los valores de entrada del formulario
        const sanitizedMatricula = DOMpurify.sanitize(matricula);
        const sanitizedOpcion = DOMpurify.sanitize(opcion);
        const sanitizedComentarios = DOMpurify.sanitize(comentarios);

        const message = `
<div bgcolor="transparent">
    <div><br /></div>
    1. EMAIL CORPORATIVO
    <br />
    <b>${userInfoSSFF.username}</b>
    <div><br /></div>
    2. NOMBRE Y APELLIDOS
    <br />
      <b>${userInfoSSFF.displayName}</b>
    <div><br /></div>
    3. MATRICULA
    <br />
    <b>${sanitizedMatricula}</b>
    <div><br /></div>
    4. EMPRESA
    <br />
    <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
    <div><br /></div>
    5. ELIGE LA OPCIÓN DE PRÉSTAMO QUE QUIERES SOLICITAR
    <br />
    <b>${sanitizedOpcion}</b>
    <div><br /></div>
    6. COMENTARIOS
    <br />
    <b>${sanitizedComentarios}</b>
    <div><br /></div>
</div>
`;

        await createTicket('NUEVA Solicitud PRESTAMO empleados', message, userInfoSSFF.custom03, 1, [], dataUserInbenta.data[0].id);
        setMatricula('');
        setOpcion('');
        setComentarios('');
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };
  return (
    <>
      <section className='formArticle'>
        <h1 className='formArticle__title'>Solicitud préstamo</h1>
        <p className='formArticle__text'>
          Recuerda que para poder solicitar el alta en la oferta Movistar para empleados, y disfrutar de este beneficio, es necesario disponer de una
          línea fija a tu nombre y haber contratado a través del 1004 alguno de los paquetes actualmente disponibles para esta oferta de empleado.
          <br></br>
          Si ya tienes claro el paquete Movistar que quieres contratar, rellena el siguiente formulario para solicitar el alta en la oferta de
          empleado.
        </p>
        <form target='_blank' className='formArticle__form' action='#' onSubmit={handleSubmit}>
          <div className='formArticleSection'>
            <label className='formArticleLabel' htmlFor='matricula'>
              Matrícula
              {showMatriculaRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
            </label>
            <input
              type='text'
              placeholder='Escriba su respuesta'
              className='formArticleInputText'
              value={matricula}
              onChange={handleMatriculaChange}
            />
          </div>
          <div className='formArticleSection'>
            <label htmlFor='formArticle__option' className='formArticleLabel'>
              Elige la opción de préstamo que quieres solicitar
              {showOpcionRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
            </label>
            <div className='formArticle_radio'>
              <input type='radio' name='formArticle__option' value='1 Mes' defaultChecked onChange={handleOpcionChange} />
              <label htmlFor='1 Mes'> 1 Mes</label>
              <br></br>
              <input type='radio' name='formArticle__option' value='2 Meses' checked={opcion === '2 Meses'} onChange={handleOpcionChange} />
              <label htmlFor='2 Meses'> 2 Meses</label>
              <br></br>
              <input type='radio' name='formArticle__option' value='3 Meses' checked={opcion === '3 Meses'} onChange={handleOpcionChange} />
              <label htmlFor='3 Meses'> 3 Meses</label>
            </div>
          </div>
          <div className='formArticleSection'>
            <label htmlFor='comments' className='formArticleLabel'>
              Comentarios
              {showComentariosRequired && <p className='formArticleLabel__required'>Este es un campo requerido</p>}
            </label>
            <textarea
              className='formArticleTextarea'
              name='comentarios'
              placeholder='Tengo dudas sobre...'
              value={comentarios}
              onChange={handleComentariosChange}
            ></textarea>
          </div>
          <div className='formArticleButton__section'>
            <button className={`formArticleButton ${isButtonEnabled ? 'enabled' : 'disabled'}`} type='submit'>
              Enviar
            </button>
            {isSubmitting && (
              // SPINNER LOADING
              <div class='loader'></div>
            )}
          </div>
        </form>
        <div className='contactTextContainer'>
          <p className='formArticle__contact'>
            Para cualquier duda, contacta al buzón <a href='mailto:preguntanos@personas.telefonica.com'>preguntanos@personas.telefonica.com</a>
          </p>
        </div>
        {feedbackPopup && (
          <div className='dislikePopup-overlay'>
            <div className='dislikePopup-content'>
              <div className='dislikePopup__sectionTitle'>
                <h1 className='dislikePopup__sectionTitle--title spacing'>Solicitud enviada correctamente</h1>
              </div>
              <div className='dislikePopup__sectionText'>
                <p className='timeOutPopup__sectionText--text'>Nos pondremos en contacto contigo a la mayor brevedad posible</p>
              </div>
              <div className='contactMeSectionButton'>
                <button className='contactMePopup--button' onClick={handleCloseFeedbackPopup}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className='dislikePopup-overlay'>
            <div className='dislikePopup-content failurePopup'>
              <div className='dislikePopup__sectionText'>
                <p className='timeOutPopup__sectionText--text'>
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className='contactMeSectionButton'>
                <button className='contactMePopup--button failureButton' onClick={handleCloseFeedbackPopup}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Loan;
