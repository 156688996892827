import React, { useEffect, useState } from 'react';
import { LabelInput, LabelInputBoth } from './inputs/labelInput';
import { validateFields } from './validateFields';

const NewJobClassificationForm = ({
  jcTitle, setJcTitle,
  jcLevel, setJcLevel,
  jcPayGrade, setJcPayGrade,
  jcTelefónicaLevel, setJcTelefónicaLevel,
  jcTelefónicaGrade, setJcTelefónicaGrade,
  legalEntity, setLegalEntity,
  jobFunction, setJobFunction,
  jcCreationDate, setJcCreationDate,
  showForm
}) => {
  const [errors, setErrors] = useState({});
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      jcTitle,
      jcLevel,
      jcPayGrade,
      jcTelefónicaLevel,
      jcTelefónicaGrade,
      legalEntity,
      jobFunction,
      jcCreationDate
    });
  }, []); 

  useEffect(() => {
    const fields = {
      jcTitle,
      jcLevel,
      jcPayGrade,
      jcTelefónicaLevel,
      jcTelefónicaGrade,
      legalEntity,
      jobFunction,
      jcCreationDate
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
  }, [jcTitle, jcLevel, jcPayGrade, jcTelefónicaLevel, jcTelefónicaGrade, legalEntity, jobFunction, jcCreationDate]);

  if (!showForm) return null;

  return (
    <>
      <LabelInput 
        label="Título del puesto:" 
        value={jcTitle} 
        onChange={(e) => setJcTitle(e.target.value)} 
        error={errors.jcTitle} 
      />
      <div className="formJiraChangeSection_both">      
        <LabelInputBoth 
          label="Nivel de puesto:" 
          value={jcLevel} 
          onChange={(e) => setJcLevel(e.target.value)} 
          error={errors.jcLevel} 
        />
        <LabelInputBoth 
          label="Grado de Pago:" 
          value={jcPayGrade} 
          onChange={(e) => setJcPayGrade(e.target.value)} 
          error={errors.jcPayGrade} 
        />  
      </div>
      <div className="formJiraChangeSection_both">
        <LabelInputBoth 
          label="Nivel Telefónica:" 
          value={jcTelefónicaLevel} 
          onChange={(e) => setJcTelefónicaLevel(e.target.value)} 
          error={errors.jcTelefónicaLevel} 
        />
        <LabelInputBoth 
          label="Grado Telefónica:" 
          value={jcTelefónicaGrade} 
          onChange={(e) => setJcTelefónicaGrade(e.target.value)} 
          error={errors.jcTelefónicaGrade} 
        />
      </div>
      <div className="formJiraChangeSection_both">
        <LabelInputBoth 
          label="Entidad legal:" 
          value={legalEntity} 
          onChange={(e) => setLegalEntity(e.target.value)} 
          error={errors.legalEntity} 
        />
        <LabelInputBoth 
          label="Fecha de la creación de la JC:" 
          type="date" 
          value={jcCreationDate} 
          onChange={(e) => setJcCreationDate(e.target.value)} 
          error={errors.jcCreationDate} 
        />
      </div>
      <LabelInput 
        label="Función de puesto:" 
        value={jobFunction} 
        onChange={(e) => setJobFunction(e.target.value)} 
        error={errors.jobFunction} 
      />
    </>
  );
};

export default NewJobClassificationForm;
