import React, { useState, useEffect } from 'react';
import { getFolder, getPayrollHCM } from '../../services/apiPayrolls';
import { downloadFiles } from '../../services/downloadFiles';
import '../styles/payrollComponent.css';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Modal from 'react-modal';
import '../styles/modalPayroll.css';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

// Estilos en línea para el modal
const customStyles = {
  content: {
    top: '40%',
    left: '60%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '10px',
    inset: '40% auto auto 60%',
  },
};

const secretKeyMeta4 = 'your-secret-key';

const encryptValue = (value) => {
  return CryptoJS.AES.encrypt(value, secretKeyMeta4).toString();
};

const PayrollComponent = ({ userInfoSSFF }) => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setselectedMonth] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showlegalEntity, setshowlegalEntity] = useState(true);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('No se encontró ninguna nómina');

  const idToken = localStorage.getItem('idToken');
  const jwtSSFF = localStorage.getItem('jwtSSFFToken');

  const name = userInfoSSFF.displayName;

  const userEmail = encryptValue(userInfoSSFF.username);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setselectedMonth(event.target.value);
  };

  useEffect(() => {
    async function loadFileList() {
      try {
        // Obtener el valor de legalEntity
        let legalEntity = userInfoSSFF.custom03;
        legalEntity = legalEntity.substring(0, 4);
        //let legalEntity = "0215";

        const juridicsHCM = ['0250', '1702', '1017'];

        const juridicMeta4 = ['0024', '1412', '1343', '1583', '1893'];

        // Verificar si la jurídica del usuario pertenece a HCM o Meta4
        const isHCM = juridicsHCM.includes(legalEntity);
        const isMeta4 = juridicMeta4.includes(legalEntity);
        //setSelectedOption(isHCM ? 'hcm' : 'meta4');

        // Asignar la opción correspondiente
        if (isHCM) {
          setSelectedOption('hcm');
          setshowlegalEntity(true);
        } else if (isMeta4) {
          setSelectedOption('meta4');
          setshowlegalEntity(true);
        } else {
          // Si no pertenece ni a HCM ni a Meta4, mostrar modal de error
          setModalMessage(
            <>
              No encontramos ninguna nómina asociada a su entidad legal en este momento. Si necesita asistencia, puede contactar a{' '}
              <a href='mailto:preguntanos@personas.telefonica.com'>Pregúntanos Personas</a>.
            </>
          );
          setShowModal(true);
          setshowlegalEntity(false);
        }
      } catch (error) {
        console.error('Error determinando la opción seleccionada:', error.message);
      }
    }

    // Ejecutar la función `loadFileList` solo si `selectedOption` no está definido.
    if (!selectedOption) {
      loadFileList();
    }
  }, [userInfoSSFF, selectedOption]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (selectedOption === 'meta4') {
          const data = await getFolder(jwtSSFF, idToken);

          if (data.length === 0) {
            // Mostrar popup si no hay datos para Meta4
            setModalMessage(`Actualmente no hay nóminas disponibles para ${name}.`);
            setShowModal(true);
            setFileList([]);
          } else {
            const transformedData = data.map((file) => {
              const [name, date] = file.displayName.split(' ');
              return {
                ...file,
                displayName: name,
                date: date,
              };
            });
            setFileList(transformedData);
          }
        } else if (selectedOption === 'hcm' && selectedYear && selectedMonth) {
          const data = await getPayrollHCM(jwtSSFF, idToken, selectedMonth, selectedYear);

          if (data.length === 0) {
            setModalMessage(`Actualmente no hay nóminas disponibles para ${name}.`);
            setShowModal(true);
            setFileList([]);
          } else {
            const payrollDetails = {
              originalName: 'Nominas',
              displayName: 'nómina',
              date: `${selectedMonth}/${selectedYear.slice(-2)}`,
              rawData: data,
            };

            // Actualizar la lista de archivos
            setFileList(payrollDetails);
          }
        }
      } catch (error) {
        console.error('Error fetching file list:', error.message);
      } finally {
        setLoading(false);
      }
    }

    // Llamar a `fetchData` solo si `selectedOption` está definido
    if (showlegalEntity && selectedOption && (selectedOption === 'meta4' || (selectedOption === 'hcm' && selectedYear && selectedMonth))) {
      fetchData();
    }
  }, [selectedOption, selectedYear, selectedMonth, showlegalEntity]);

  useEffect(() => {
    // Asegúrate de que `fileList` sea siempre un array, incluso si es un objeto (como el caso de `hcm`)
    let filtered = Array.isArray(fileList) ? fileList : fileList ? [fileList] : [];

    if (selectedYear) {
      filtered = filtered.filter((file) => {
        const fileYear = file.date.split('/')[1];
        return fileYear === selectedYear.slice(-2);
      });
    }

    if (selectedMonth) {
      filtered = filtered.filter((file) => {
        const fileMonth = file.date.split('/')[0];
        return fileMonth === selectedMonth;
      });
    }

    setFilteredFiles(filtered);
  }, [fileList, selectedYear, selectedMonth]);

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2019; year--) {
    years.push(year);
  }

  const handleSelectAll = () => {
    const newCheckedItems = { ...checkedItems };
    filteredFiles.forEach((file) => {
      newCheckedItems[file.originalName] = !selectAll;
    });
    setSelectAll(!selectAll);
    setCheckedItems(newCheckedItems);
  };

  const handleCheckboxChange = (originalName) => {
    setCheckedItems({
      ...checkedItems,
      [originalName]: !checkedItems[originalName],
    });
  };

  const downloadPayroll = async () => {
    const selectedFiles = filteredFiles.filter((file) => checkedItems[file.originalName]);

    if (selectedFiles.length === 0) {
      alert('Selecciona al menos un archivo para descargar.');
      return;
    }

    // Lógica para HCM
    if (selectedOption === 'hcm') {
      if (selectedFiles.length === 1) {
        const file = selectedFiles[0];

        try {
          // Verificar si file.rawData ya es un ArrayBuffer (PDF binario)
          if (file.rawData instanceof ArrayBuffer) {
            // Crear un Blob desde los datos binarios recibidos
            const blob = new Blob([file.rawData], { type: 'application/pdf' });

            // Crear una URL de descarga desde el Blob
            const fileUrl = URL.createObjectURL(blob);

            // Crear el enlace de descarga
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', `${file.displayName || 'archivo'}.pdf`);
            document.body.appendChild(link);
            link.click();

            // Limpiar el enlace y liberar la URL del Blob
            setTimeout(() => {
              document.body.removeChild(link);
              URL.revokeObjectURL(fileUrl);
            }, 500);
          } else {
            console.error('No se encontraron datos válidos para el archivo seleccionado');
          }
        } catch (error) {
          console.error('Error al descargar el archivo:', error.message);
        }
      } else {
        // Manejar la descarga de varios archivos como un ZIP
        try {
          const zip = new JSZip();

          selectedFiles.forEach((file) => {
            if (file.rawData) {
              zip.file(`${file.displayName || 'archivo'}.pdf`, new Uint8Array(file.rawData));
            }
          });

          // Generar archivo ZIP
          const zipBlob = await zip.generateAsync({ type: 'blob' });

          // Descargar el archivo ZIP usando file-saver
          saveAs(zipBlob, 'archivos_nominas.zip');
        } catch (error) {
          console.error('Error generando el archivo ZIP:', error);
        }
      }
    } else if (selectedOption === 'meta4') {
      setModalTitle('Recordatorio');
      setModalMessage('Tu nómina se está descargando. Recuerda introducir tu DNI para acceder al archivo.');
      setShowModal(true);

      if (selectedFiles.length === 0) {
        alert('Selecciona al menos un archivo para descargar.');
        return;
      }

      const fileNamesJSON = selectedFiles.map((file) => file.originalName);
      const fileNamesString = JSON.stringify(fileNamesJSON);
      const fileNames = encryptValue(fileNamesString);

      try {
        const blobData = await downloadFiles(jwtSSFF, idToken, fileNames, userEmail);
        const url = window.URL.createObjectURL(new Blob([blobData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'nominas.zip');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading files:', error.message);
      }
    }
  };

  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  const handleNavigate = () => {
    setShowModal(false);
    navigate('/folder');
  };

  // Si no es HCM o Meta4, solo mostramos el modal
  if (!showlegalEntity) {
    return (
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={customStyles} contentLabel='Error de nómina' ariaHideApp={false}>
        <h2 className='title-modal-payroll'>{modalTitle}</h2>
        <p className='description-modal-payroll'>{modalMessage}</p>
        <button onClick={handleNavigate} className='button-modal-payroll'>
          Aceptar
        </button>
      </Modal>
    );
  }

  return (
    <section className='payroll_section'>
      {/* Modal para mostrar los mensajes */}
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={customStyles} contentLabel='Error de nómina' ariaHideApp={false}>
        <h2 className='title-modal-payroll'>{modalTitle}</h2>
        <p className='description-modal-payroll'>{modalMessage}</p>
        <button onClick={() => setShowModal(false)} className='button-modal-payroll'>
          Aceptar
        </button>
      </Modal>

      <div className='payroll_actions'>
        <button className='payroll_actions-download' onClick={downloadPayroll}>
          Descargar
        </button>
        {selectedOption === 'hcm' && (
          <div className='payroll_actions-dateFilter'>
            <select className='select-button month-option' onChange={handleMonthChange} value={selectedMonth}>
              <option className='select' value=''>
                Mes
              </option>
              {months.map((month) => (
                <option key={month} className='select' value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select className='select-button' onChange={handleYearChange} value={selectedYear}>
              <option className='select' value=''>
                Año
              </option>
              {years.map((year) => (
                <option key={year} className='select' value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {/* Mostrar el mensaje fijo cuando se selecciona 'hcm' */}
      {selectedOption === 'hcm' && (!selectedMonth || !selectedYear) && <p>Por favor, seleccione mes y año para consultar su nómina.</p>}
      <div className='payroll_container'>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <table className='invoice-table'>
            <thead>
              <tr className='table-header'>
                <th className='checkbox'>
                  <input
                    type='checkbox'
                    onChange={handleSelectAll}
                    checked={filteredFiles.length > 0 && filteredFiles.every((file) => checkedItems[file.originalName])}
                  />
                </th>
                <th className='table-header_name'>Nombre de documento</th>
                <th className='table-header_date'>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {filteredFiles.map((file) => (
                <tr key={file.originalName}>
                  <td className='checkbox'>
                    <input
                      type='checkbox'
                      checked={checkedItems[file.originalName] || false}
                      onChange={() => handleCheckboxChange(file.originalName)}
                    />
                  </td>
                  <td className='name'>{file.displayName}</td>
                  <td className='date'>{file.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
};

export default PayrollComponent;
