import React from 'react';

const ManagerFormSection = ({ formData, errors, onChange }) => {
  return (
	<div className='formJiraNewSection'>
	<div className="formJiraNewSection_both">
	  <div className="formJiraNewSection_bothIndividual">
		  <label className="formJiraNewLabel_short">
			  {errors.businessUnit && '*'} Unidad de Negocio (BU):
		  </label>
		  <input
		  type="text"
		  name='businessUnit'
		  placeholder="Unidad de Negocio del empleado.."
		  className={`formJiraNewInputText_short ${errors.businessUnit ? 'errorBorder' : ''}`}
		  value={formData.businessUnit}
		  onChange={onChange}
		  />
	  </div>
	  <div className="formJiraNewSection_bothIndividual">
		  <label className="formJiraNewLabel_short">
			  {errors.division && '*'} División:
		  </label>
		  <input
		  type="text"
		  name="division"
		  placeholder="División del empleado..."
		  className={`formJiraNewInputText_short ${errors.division ? 'errorBorder' : ''}`}
		  value={formData.division}
		  onChange={onChange}
		  />
	  </div>
	</div>
	<div className="formJiraNewSection">
		<label className="formJiraNewLabel">
		  {errors.departamento && '*'} Departamento:
	  </label>
	  <input
		type="text"
		name="departamento"
		placeholder="Departamento"
		className={`formJiraNewInputText ${errors.departamento ? 'errorBorder' : ''}`}
		value={formData.departamento}
		onChange={onChange}
	  />
	</div>
	<div className="formJiraNewRadio">
                <label className="formJiraNewLabel">
                    {errors.needType && '*'} ¿Cuál es la necesidad de incorporación?
                </label>
                <div className="formJiraNewRadio_options">
                    <label className="formJiraNewRadioLabel">
                        <input
                            type="radio"
                            name="needType"
                            className={`formJiraNewRadio ${errors.needType ? 'errorBorder' : ''}`}
                            value="vacante"
                            checked={formData.needType === 'vacante'}
                            onChange={onChange}
                        />
                        Vacante
                    </label>
                    <label className="formJiraNewRadioLabel">
                        <input
                            type="radio"
                            name="needType"
                            className={`formJiraNewRadio ${errors.needType ? 'errorBorder' : ''}`}
                            value="beca"
                            checked={formData.needType === 'beca'}
                            onChange={onChange}
                        />
                        Beca
                    </label>
                </div>
            </div>

            {formData.needType === 'vacante' && (
                <div>
                    <div className="formJiraNewSection">
                        <label className="formJiraNewLabel">
                            {errors.vacancyType && '*'} Tipo de Vacante:
                        </label>
                        <div className="formJiraNewRadio_options">
                            <label className="formJiraNewRadioLabel">
                                <input
                                    type="radio"
                                    name="vacancyType"
                                    className={`formJiraNewRadio ${errors.vacancyType ? 'errorBorder' : ''}`}
                                    value="nueva"
                                    checked={formData.vacancyType === 'nueva'}
                                    onChange={onChange}
                                />
                                Nueva Vacante
                            </label>
                            <label className="formJiraNewRadioLabel">
                                <input
                                    type="radio"
                                    name="vacancyType"
                                    className={`formJiraNewRadio ${errors.vacancyType ? 'errorBorder' : ''}`}
                                    value="sustitucion"
                                    checked={formData.vacancyType === 'sustitucion'}
                                    onChange={onChange}
                                />
                                Sustitución
                            </label>
                            <label className="formJiraNewRadioLabel">
                                <input
                                    type="radio"
                                    name="vacancyType"
                                    className={`formJiraNewRadio ${errors.vacancyType ? 'errorBorder' : ''}`}
                                    value="internalizacion"
                                    checked={formData.vacancyType === 'internalizacion'}
                                    onChange={onChange}
                                />
                                Internalización de un Becario
                            </label>
                        </div>
                    </div>

                    {formData.vacancyType === 'sustitucion' && (
                        <div className="formJiraNewSection">
                            <label className="formJiraNewLabel">
                                {errors.replacementName && '*'} Nombre y Apellidos de la Persona a Sustituir:
                            </label>
                            <input
                                type="text"
                                name="replacementName"
                                placeholder="Nombre y Apellidos"
                                className={`formJiraNewInputText_short ${errors.replacementName ? 'errorBorder' : ''}`}
                                value={formData.replacementName || ''}
                                onChange={onChange}
                            />
                        </div>
                    )}
                </div>
            )}

            {formData.needType === 'beca' && (
                <div>
                    <div className="formJiraNewSection">
                        <label className="formJiraNewLabel">
                            {errors.internshipWindow && '*'} Ventana de Incorporación Deseada:
                        </label>
                        <input
                            type="text"
                            name="internshipWindow"
                            placeholder="Ventana de Incorporación"
                            className={`formJiraNewInputText_short ${errors.internshipWindow ? 'errorBorder' : ''}`}
                            value={formData.internshipWindow || ''}
                            onChange={onChange}
                        />
                    </div>
                    <div className="formJiraNewSection">
                        <label className="formJiraNewLabel">
                            {errors.postInternship && '*'} ¿Opciones Reales de Incorporación en Plantilla Después de la Beca?
                        </label>
                        <div className="formJiraNewRadio_options">
                            <label className="formJiraNewRadioLabel">
                                <input
                                    type="radio"
                                    name="postInternship"
                                    className={`formJiraNewRadio ${errors.postInternship ? 'errorBorder' : ''}`}
                                    value="si"
                                    checked={formData.postInternship === 'si'}
                                    onChange={onChange}
                                />
                                Sí
                            </label>
                            <label className="formJiraNewRadioLabel">
                                <input
                                    type="radio"
                                    name="postInternship"
                                    className={`formJiraNewRadio ${errors.postInternship ? 'errorBorder' : ''}`}
                                    value="no"
                                    checked={formData.postInternship === 'no'}
                                    onChange={onChange}
                                />
                                No
                            </label>
                        </div>
                    </div>
                </div>
            )}
  </div>
  );
};

export default ManagerFormSection;
