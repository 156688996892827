import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../../styles/change/formChangeManager.css';
import elipse355 from '../../../../../images/ellipse355.png';
import { getEmployeeSuggestions, getManagerSuggestions } from './suggestions';
import GeneralInfoComponent from './generalInfoComponent';
import ContactInfoWithPopup from './contactInfoPopUp';
import {
  OrganizationalChangeComponent,
  RoleChangeComponent,
  SalaryChangeComponent,
  LocationChangeComponent,
  ContractChangeComponent,
} from './formComponents';
import { CommentsComponent } from './commentsComponent';
import { handleSubmit } from './handleSubmit';
import { validateFields } from './formValidate';

// Aquí se renderiza el formulario de solicitud de cambio rellenado por el manager. Se divide en varias secciones, cada una de las cuales se muestra en función del botón de menú que se haya seleccionado. Cada sección tiene su propio componente que se muestra en función del botón de menú seleccionado.
// Se manejan los estados de los campos del formulario y se validan los campos antes de enviar el formulario.
const ChangeRequest = ({ userInfoSSFF, usersInfoSSFF, ticketData }) => {

	//Estos campos se podrían guardar en un estado global  llamado formData y se podrían manejar con un reducer para evitar pasarlos como props, tenía pendiente hacerlo para mejorar la estructura del código.

	//Campos del formulario, si ya se ha completado el ticket previamente, se rellenan con los datos del ticket anterior(ticketData).
  const [effectiveDate, setEffectiveDate] = useState(
    ticketData?.effectiveDate || ''
  );
  const [organizationalChange, setOrganizationalChange] = useState(
    ticketData?.organizationalChange?.reorganization || ''
  );
  const [newDepartments, setNewDepartments] = useState(
    ticketData?.organizationalChange?.newDepartments || ''
  );
  const [responsiblePerson, setResponsiblePerson] = useState(
    ticketData?.organizationalChange?.responsiblePerson || ''
  );
  const [changeType, setChangeType] = useState(['info']);
  const [roleChange, setRoleChange] = useState(ticketData?.roleChange || '');
  const [roleChangeDetails, setRoleChangeDetails] = useState(
    ticketData?.roleChange?.roleChangeDetails || ''
  );
  const [changeDetails, setChangeDetails] = useState(
    ticketData?.changeDetails || ''
  );
  const [managerChange, setManagerChange] = useState(
    ticketData?.roleChange?.managerChange || ''
  );
  const [newManager, setNewManager] = useState(
    ticketData?.roleChange?.newManager || ''
  );
  const [salaryChange, setSalaryChange] = useState(
    ticketData?.salaryChange?.salary || ''
  );
  const [currency, setCurrency] = useState(
    ticketData?.salaryChange?.currency || ''
  );
  const [bonusOrIncentives, setBonusOrIncentives] = useState('');
  const [bonus, setBonus] = useState('');
  const [bonusPercentage, setBonusPercentage] = useState(
    ticketData?.bonusPercentage || ''
  );
  const [incentives, setIncentives] = useState(ticketData?.incentives || '');
  const [incentivesAmount, setIncentivesAmount] = useState(
    ticketData?.salaryChange?.incentives || ''
  );
  const [salaryReason, setSalaryReason] = useState(
    ticketData?.salaryChange?.salaryReason || ''
  );
  const [locationChange, setLocationChange] = useState(
    ticketData?.locationChange?.locationChange || ''
  );
  const [city, setCity] = useState(ticketData?.locationChange?.city || '');
  const [country, setCountry] = useState(
    ticketData?.locationChange?.country || ''
  );
  const [building, setBuilding] = useState(
    ticketData?.locationChange?.building || ''
  );
  const [floor, setFloor] = useState(ticketData?.locationChange?.floor || '');
  const [contractChange, setContractChange] = useState(
    ticketData?.contractChange || ''
  );
  const [comments, setComments] = useState('');

  //Estados para mostrar mensajes de error en los campos requeridos
 const [showTEffectiveDateRequired, setShowTEffectiveDateRequired] =
    useState(false);
  const [showCommentsRequired, setShowCommentsRequired] = useState(false);
  const [showEffectiveDateInfo, setShowEffectiveDateInfo] = useState(false);

  //Estados para habilitar o deshabilitar el botón de enviar formulario
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);

  //Estados para mostrar sugerencias de empleados y managers: selecciona empleado o selecciona equipo
  const [employeeSuggestions, setEmployeeSuggestions] = useState([]);
  const [managerSuggestions, setManagerSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [searchType, setSearchType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [managedEmployees, setManagedEmployees] = useState([]);
  const [errors, setErrors] = useState({});
  const [focusedButton, setFocusedButton] = useState('');

  //Estados para controlar si se ha empezado a rellenar un campo específico
  const [hasStartedFillingOrgChange, setHasStartedFillingOrgChange] =
    useState(false);
  const [hasStartedFillingRoleChange, setHasStartedFillingRoleChange] =
    useState(false);
  const [hasStartedFillingSalaryChange, setHasStartedFillingSalaryChange] =
    useState(false);
  const [hasStartedFillingLocationChange, setHasStartedFillingLocationChange] =
    useState(false);
  const [hasStartedFillingContractChange, setHasStartedFillingContractChange] =
    useState(false);
  const [hasStartedFillingComments, setHasStartedFillingComments] =
    useState(false);

	//Validar campos del formulario antes de enviar el formulario para que estén todos los campos necesarios rellenados correctamente y activar el botón de enviar. 
  useEffect(() => {
    const areFieldsValid =
      effectiveDate.trim() !== '' &&
      (!changeType.length ||
        (hasStartedFillingOrgChange &&
          organizationalChange &&
          newDepartments &&
          responsiblePerson) ||
        (hasStartedFillingRoleChange &&
          ((roleChange === 'no' && changeDetails) ||
            (roleChange === 'yes' &&
              roleChangeDetails &&
              (managerChange === 'no' ||
                (managerChange === 'yes' && newManager))))) ||
        (hasStartedFillingSalaryChange &&
          salaryChange &&
          currency &&
          ((bonusOrIncentives === 'bonus' && bonusPercentage) ||
            (bonusOrIncentives === 'incentives' && incentivesAmount)) &&
          salaryReason) ||
        (hasStartedFillingLocationChange &&
          city &&
          country &&
          building &&
          floor) ||
        (hasStartedFillingContractChange && contractChange) ||
        comments);

    setIsButtonEnabled(areFieldsValid);
  }, [
    effectiveDate,
    changeType,
    organizationalChange,
    newDepartments,
    responsiblePerson,
    roleChange,
    roleChangeDetails,
    managerChange,
    newManager,
    changeDetails,
    salaryChange,
    currency,
    bonusOrIncentives,
    bonusPercentage,
    incentivesAmount,
    salaryReason,
    city,
    country,
    building,
    floor,
    contractChange,
    comments,
    hasStartedFillingOrgChange,
    hasStartedFillingRoleChange,
    hasStartedFillingSalaryChange,
    hasStartedFillingLocationChange,
    hasStartedFillingContractChange,
  ]);

  //Establecer el componente de información personal por defecto al cargar el formulario
  useEffect(() => {
    setChangeType(['info']);
    setFocusedButton('info');
  }, []);
  //Manejo del menú de formulario
  const handleMenuClick = (type) => {
    setChangeType([type]);
    setFocusedButton(type);
  };
  //Comprobar que se ha empezado a rellenar una sección del menu para mostrar los mensajes de error en los campos requeridos y añadir un valor a cada campo del formulario.
  const handleOrganizationalChange = (value) => {
    setOrganizationalChange(value);
    setHasStartedFillingOrgChange(true);
  };

  const handleRoleChange = (value) => {
    setRoleChange(value);
    setHasStartedFillingRoleChange(true);
  };

  const handleSalaryChange = (value) => {
    setSalaryChange(value);
    setHasStartedFillingSalaryChange(true);
  };

  const handleLocationChange = (value) => {
    setLocationChange(value);
    setHasStartedFillingLocationChange(true);
  };

  const handleContractChange = (value) => {
    setContractChange(value);
    setHasStartedFillingContractChange(true);
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    //setShowCommentsRequired(false);
    setHasStartedFillingComments(true);
  };
  //Sugerencias para el autocomplete de selecciona empleado y selecciona equipo. ESTO ESTÁ PENDIENTE DE REVISAR Y MEJORAR.
  const getSuggestionValue = (suggestion) => suggestion.displayName;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.displayName} - {suggestion.username} - {suggestion.userId}
    </div>
  );

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (searchType === 'employee') {
      setEmployeeSuggestions(getEmployeeSuggestions(value, usersInfoSSFF));
    } else if (searchType === 'team') {
      setManagerSuggestions(getManagerSuggestions(value, usersInfoSSFF));
    }
  };

  const handleSuggestionsClearRequested = () => {
    if (searchType === 'employee') {
      setEmployeeSuggestions([]);
    } else if (searchType === 'team') {
      setManagerSuggestions([]);
    }
  };

  const handleChange = (event, { newValue, suggestion }) => {
    setValue(newValue);
    if (suggestion) {
      setSelectedUser(suggestion);
      setEmployeeName(suggestion.displayName);
      if (searchType === 'team') {
        // Handle manager selection and update managed employees list
        const managerId = suggestion.userId;
        const managed = usersInfoSSFF.filter(
          (user) => user.manager && user.manager.userId === managerId
        );
        setManagedEmployees(managed);
      }
    }
  };

  const inputProps = {
    placeholder: 'Busca por nombre, email o ID',
    value,
    onChange: handleChange,
  };

  //Limpiar formulario
  const handleClearForm = () => {
    setEffectiveDate('');
    setEmployeeName('');
    setChangeType([]);
    setOrganizationalChange(false);
    setNewDepartments('');
    setResponsiblePerson('');
    setRoleChange('');
    setRoleChangeDetails('');
    setChangeDetails('');
    setManagerChange('');
    setNewManager('');
    setSalaryChange('');
    setCurrency('');
    setBonusOrIncentives('');
    setBonusPercentage('');
    setIncentivesAmount('');
    setSalaryReason('');
    setCity('');
    setCountry('');
    setBuilding('');
    setFloor('');
    setContractChange('');
    setComments('');
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  // El nombre nos viene de SSFF en mayúsculas, por lo que hay que formartearlo. El nombre del empleado proviene del empleado escogido por el ususario en selecUser y la información del manager provienen de SSFF. 
  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const getSafeString = (value) => (typeof value === 'string' ? value : '');

  const [employeeName, setEmployeeName] = useState(
    ticketData?.employeeName ||
      capitalizeFirstLetter(getSafeString(selectedUser?.displayName)) ||
      ''
  );

  const [managerName, setManagerName] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  console.log('employeeName:', employeeName);
  useEffect(() => {
    setManagerName(
      capitalizeFirstLetter(
        getSafeString(ticketData?.managerName || userInfoSSFF?.displayName)
      ) || ''
    );
    setManagerEmail(ticketData?.managerEmail || userInfoSSFF?.username || '');
  }, [ticketData, userInfoSSFF]);
  useEffect(() => {
    if (selectedUser) {
      setEmployeeName(
        capitalizeFirstLetter(getSafeString(selectedUser?.displayName))
      );
    }
  }, [selectedUser]);

  return (
    <>
      <section className='formJiraChange'>
        <div className='jiraChangeForm__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/form' className='jiraChangeForm__breadcrumb--title'>
            INICIO
          </Link>
          <div className='jiraChangeForm__breadcrumb--text'>
            &gt; SOLICITUD DE CAMBIO
          </div>
        </div>
        <h1 className='formJiraChange__title'>Solicitud de Cambio</h1>
        <div className='jiraChangeForm_menuFormContainer'>
          <nav className='jiraChangeForm_menu'>
            <button
              onClick={() => handleMenuClick('info')}
              style={{
                backgroundColor:
                  focusedButton === 'info' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'info' ? '#06f' : 'initial',
              }}
            >
              Información General
            </button>
            <button
              onClick={() => handleMenuClick('Organizational')}
              style={{
                backgroundColor:
                  focusedButton === 'Organizational' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Organizational' ? '#06f' : 'initial',
              }}
            >
              Cambios Organizativos
            </button>
            <button
              onClick={() => handleMenuClick('Role')}
              style={{
                backgroundColor:
                  focusedButton === 'Role' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Role' ? '#06f' : 'initial',
              }}
            >
              Cambio de Rol
            </button>
            <button
              onClick={() => handleMenuClick('Salary')}
              style={{
                backgroundColor:
                  focusedButton === 'Salary' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Salary' ? '#06f' : 'initial',
              }}
            >
              Cambios Salariales
            </button>
            <button
              onClick={() => handleMenuClick('Location')}
              style={{
                backgroundColor:
                  focusedButton === 'Location' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Location' ? '#06f' : 'initial',
              }}
            >
              Cambios de Localización
            </button>
            <button
              onClick={() => handleMenuClick('Contract')}
              style={{
                backgroundColor:
                  focusedButton === 'Contract' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Contract' ? '#06f' : 'initial',
              }}
            >
              Cambios de Contrato
            </button>
            <button
              onClick={() => handleMenuClick('comments')}
              style={{
                backgroundColor:
                  focusedButton === 'comments' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'comments' ? '#06f' : 'initial',
              }}
            >
              Observaciones
            </button>
          </nav>

          <form
            className='formJiraChange_form'
            onSubmit={(e) =>
              handleSubmit(
                e,
                effectiveDate,
                changeType,
                hasStartedFillingOrgChange,
                organizationalChange,
                newDepartments,
                responsiblePerson,
                hasStartedFillingRoleChange,
                roleChange,
                roleChangeDetails,
                managerChange,
                newManager,
                changeDetails,
                hasStartedFillingSalaryChange,
                salaryChange,
                currency,
                bonusOrIncentives,
                bonusPercentage,
                incentivesAmount,
                salaryReason,
                hasStartedFillingLocationChange,
                city,
                country,
                building,
                floor,
                hasStartedFillingContractChange,
                contractChange,
                comments,
                isButtonEnabled,
                setIsSubmitting,
                handleClearForm,
                setChangeType,
                setFeedbackPopup,
                setMessageSendingFailed,
                userInfoSSFF,
                errors,
                setErrors,
                selectedUser,
                managedEmployees,
                managerEmail,
                managerName,
                employeeName
              )
            }
          >
			{/* Sección del formulario que muestra los botones para la selección por empleado o equipo, la información personal y la fecha efectiva. */}
            <div className='formJiraChange_section'>
              {changeType.includes('info') && (
                <GeneralInfoComponent
                  searchType={searchType}
                  setSearchType={setSearchType}
                  setValue={setValue}
                  setSelectedUser={setSelectedUser}
                  employeeSuggestions={employeeSuggestions}
                  inputProps={inputProps}
                  selectedUser={selectedUser}
                  setEmployeeName={setEmployeeName}
                  handleChange={handleChange}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  handleSuggestionsFetchRequested={
                    handleSuggestionsFetchRequested
                  }
                  handleSuggestionsClearRequested={
                    handleSuggestionsClearRequested
                  }
                  managerSuggestions={managerSuggestions}
                  managedEmployees={managedEmployees}
                  effectiveDate={effectiveDate}
                  setEffectiveDate={setEffectiveDate}
                  showEffectiveDateInfo={showEffectiveDateInfo}
                  setShowEffectiveDateInfo={setShowEffectiveDateInfo}
                  showTEffectiveDateRequired={showTEffectiveDateRequired}
                  employeeName={employeeName}
                  managerEmail={managerEmail}
                  managerName={managerName}
                />
              )}
			  {/* Sección del formulario que muestra los campos para los cambios organizativos. */}
              {changeType.includes('Organizational') && (
                <OrganizationalChangeComponent
                  organizationalChange={organizationalChange}
                  newDepartments={newDepartments}
                  responsiblePerson={responsiblePerson}
                  setOrganizationalChange={handleOrganizationalChange}
                  setNewDepartments={setNewDepartments}
                  setResponsiblePerson={setResponsiblePerson}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
			  {/* Sección del formulario que muestra los campos para el cambio de rol. */}
              {changeType.includes('Role') && (
                <RoleChangeComponent
                  roleChange={roleChange}
                  roleChangeDetails={roleChangeDetails}
                  managerChange={managerChange}
                  newManager={newManager}
                  changeDetails={changeDetails}
                  setRoleChange={handleRoleChange}
                  setRoleChangeDetails={setRoleChangeDetails}
                  setManagerChange={setManagerChange}
                  setNewManager={setNewManager}
                  setChangeDetails={setChangeDetails}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
			  {/* Sección del formulario que muestra los campos para los cambios salariales. */}
              {changeType.includes('Salary') && (
                <SalaryChangeComponent
                  salaryChange={salaryChange}
                  currency={currency}
                  bonusOrIncentives={bonusOrIncentives}
                  bonus={bonus}
                  bonusPercentage={bonusPercentage}
                  incentives={incentives}
                  incentivesAmount={incentivesAmount}
                  salaryReason={salaryReason}
                  setSalaryChange={handleSalaryChange}
                  setCurrency={setCurrency}
                  setBonusOrIncentives={setBonusOrIncentives}
                  setBonus={setBonus}
                  setBonusPercentage={setBonusPercentage}
                  setIncentives={setIncentives}
                  setIncentivesAmount={setIncentivesAmount}
                  setSalaryReason={setSalaryReason}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
			  {/* Sección del formulario que muestra los campos para los cambios de localización. */}
              {changeType.includes('Location') && (
                <LocationChangeComponent
                  locationChange={locationChange}
                  city={city}
                  country={country}
                  building={building}
                  floor={floor}
                  setLocationChange={handleLocationChange}
                  setCity={setCity}
                  setCountry={setCountry}
                  setBuilding={setBuilding}
                  setFloor={setFloor}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
			  {/* Sección del formulario que muestra los campos para los cambios de contrato. */}
              {changeType.includes('Contract') && (
                <ContractChangeComponent
                  contractChange={contractChange}
                  setContractChange={handleContractChange}
                />
              )}
			  {/* Sección del formulario que muestra los campos para las observaciones. */}
              {changeType.includes('comments') && (
                <CommentsComponent
                  comments={comments}
                  handleCommentsChange={handleCommentsChange}
                  setComments={setComments}
                  /* showCommentsRequired={showCommentsRequired}
						setShowCommentsRequired={setShowCommentsRequired} */
                />
              )}
            </div>
            <div className='formJiraChangeButton_section'>
              <button
                className={`formJiraChangeButton ${
                  isButtonEnabled ? 'enabled' : 'disabled'
                }`}
                type='submit'
                //disabled={!isButtonEnabled}
              >
                Enviar
              </button>
              {isSubmitting && <div className='loader'></div>}
              <button onClick={handleClearForm} className='secondaryButton'>
                Limpiar
              </button>
            </div>
          </form>
        </div>
        <ContactInfoWithPopup
          feedbackPopup={feedbackPopup}
          messageSendingFailed={messageSendingFailed}
          handleCloseFeedbackPopup={handleCloseFeedbackPopup}
        />
      </section>
    </>
  );
};

export default ChangeRequest;
