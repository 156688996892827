// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { HashRouter } from 'react-router-dom';

// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './authConfig'

// const msalInstance = new PublicClientApplication(msalConfig);
// ReactDOM.render(
//   <React.StrictMode>
//     <HashRouter>
//       <MsalProvider instance={msalInstance}>
//       <App />
//       </MsalProvider>
//     </HashRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PeopleEx } from './PeopleEx';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PeopleEx />
    </BrowserRouter>
  </React.StrictMode>
);
