import React, { useEffect, useState } from 'react';
import { getJiraTickets, getJiraTicketAttachments } from '../../../services/apiJira';
import HistorialFilterSidebar from './ticketsFilterSideBar';
import { Link } from 'react-router-dom';
import '../../styles/change/tickets.css';
import elipse355 from '../../../images/ellipse355.png';

//Mostramos el listado de tickets para que el equipo de People pueda trabajar con los formularios de baja, alta y cambios que han creado los empleados o managers.  Actualmente se esta conectando con los archivos de historial: historialTicketsDetails y historialTicketsUtils, pero en un futuro los filtros que se usan para el historial no serán los mismos que los filtros de gestiona tus tickets.
const TicketList = ({ userInfoSSFF }) => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(15);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [selectedPriorities, setSelectedPriorities] = useState([]);

  console.log('USER INFO SSFF', userInfoSSFF);
  const userEmail = userInfoSSFF?.username;

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const ticketList = await getJiraTickets();
        const initialFilteredTickets = ticketList.filter((ticket) => ticket.fields.summary === 'Cambio' || ticket.fields.summary === 'Baja');
        /*const ticketsWithAttachments = await Promise.all(
          initialFilteredTickets.map(async (ticket) => {
            const ticketId = ticket.key;
            console.log('ticket key', ticketId);
            const attachments = await getJiraTicketAttachments(ticketId);
            return { ...ticket, attachments };
          })
        );*/

        setTickets(initialFilteredTickets);
        setFilteredTickets(initialFilteredTickets);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    //console.log('TICKETS RECIBIDOS AL FRONT', tickets);
    fetchTickets();
  }, [userEmail]);

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const lowercasedSearchEmail = searchEmail.toLowerCase();
    const lowercasedSearchName = searchName.toLowerCase();

    const filteredByTypeAndSearchTerm = tickets
      .filter((ticket) => {
        if (selectedTypes.length > 0) {
          const ticketType = ticket.fields.summary.includes('Baja') ? 'termination' : 'change';
          return selectedTypes.includes(ticketType);
        }
        return true;
      })
      .filter((ticket) => (selectedStatuses.length > 0 ? selectedStatuses.includes(ticket.fields.status.name) : true))
      .filter((ticket) => {
        if (selectedPriorities.length > 0) {
          const priorityName = ticket.fields.priority.name.toLowerCase();
          // Mapeo de prioridades a español
          const priorityMap = {
            high: 'alta',
            medium: 'media',
            low: 'baja',
          };
          const translatedPriority = priorityMap[priorityName] || priorityName;
          return selectedPriorities.includes(translatedPriority);
        }
        return true;
      })
      .filter((ticket) => {
        const formattedDate = new Date(ticket.fields.created).toLocaleDateString('es-ES');
        const formattedTime = new Date(ticket.fields.created).toLocaleTimeString('es-ES');

        const concatenatedInfo = `
      ${ticket.id || ''}
      ${ticket.fields.summary || ''} 
      ${ticket.fields.customfield_10319 || ''} 
      ${ticket.fields.customfield_10330 || ''} 
      ${ticket.fields.customfield_10318 || ''} 
      ${ticket.fields.customfield_10329 || ''} 
      ${ticket.fields.status.name || ''} 
      ${ticket.fields.priority.name || ''} 
      ${formattedDate || ''} 
      ${formattedTime || ''}`.toLowerCase();

        return (
          concatenatedInfo.includes(lowercasedSearchTerm) &&
          concatenatedInfo.includes(lowercasedSearchEmail) &&
          concatenatedInfo.includes(lowercasedSearchName)
        );
      });

    setFilteredTickets(filteredByTypeAndSearchTerm);
    setCurrentPage(1);
  }, [searchTerm, searchEmail, searchName, tickets, selectedTypes, selectedStatuses, selectedPriorities]);

  const handleTypeChange = (e) => {
    const value = e.target.value;
    console.log('VALUE', value);
    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.includes(value) ? prevSelectedTypes.filter((type) => type !== value) : [...prevSelectedTypes, value]
    );
  };

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setSelectedStatuses((prevSelectedStatuses) =>
      prevSelectedStatuses.includes(value) ? prevSelectedStatuses.filter((status) => status !== value) : [...prevSelectedStatuses, value]
    );
  };
  const handlePriorityChange = (e) => {
    const value = e.target.value.toLowerCase();
    const translatedPriority = value === 'high' ? 'alta' : value === 'medium' ? 'media' : value === 'low' ? 'baja' : value;
    setSelectedPriorities((prevSelectedPriorities) =>
      prevSelectedPriorities.includes(translatedPriority)
        ? prevSelectedPriorities.filter((priority) => priority !== translatedPriority)
        : [...prevSelectedPriorities, translatedPriority]
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date.toLocaleDateString('es-ES', optionsDate);

    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString('es-ES', optionsTime);

    return { formattedDate, formattedTime };
  };

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredTickets.length / ticketsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (loading) {
    return <p className='loading-message'>Cargando tickets...</p>;
  }

  if (error) {
    return <p className='error-message'>Error al cargar tickets: {error}</p>;
  }

  return (
    <section className='formJiraChange'>
      <div className='jiraChangeForm__breadcrumb'>
        <img src={elipse355} alt='circle-icon' className='circle-icon' />
        <Link to='/form' className='jiraChangeForm__breadcrumb--title'>
          INICIO
        </Link>
        <div className='jiraChangeForm__breadcrumb--text'>&gt; TICKETS</div>
      </div>
      <h1 className='formJiraChange__title'>Gestión de tickets</h1>
      <div className='historialTickets-list-container'>
        <div className='historialTickets-main-content'>
          <input
            type='text'
            className='historialTickets-search-input'
            placeholder='Buscar tickets...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className='historialTickets-table-container'>
            <HistorialFilterSidebar
              selectedTypes={selectedTypes}
              handleTypeChange={handleTypeChange}
              selectedStatuses={selectedStatuses}
              handleStatusChange={handleStatusChange}
              selectedPriorities={selectedPriorities}
              handlePriorityChange={handlePriorityChange}
            />
            <table className='historialTickets-table'>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Creado por</th>
                  <th>Cola</th>
                  <th>Estado</th>
                  <th>Prioridad</th>
                  <th>Últimos cambios</th>
                </tr>
              </thead>
              <tbody>
                {currentTickets.map((ticket) => {
                  const ticketType = ticket.fields.summary.includes('Baja') ? 'termination' : 'change';
                  const { formattedDate, formattedTime } = formatDate(ticket.fields.updated);

                  return (
                    <React.Fragment>
                      <tr>
                        <td>
                          <Link
                            to={`/form/ticket-detail-history`}
                            state={{ ticket, type: ticketType }}
                            className='ticket-link'
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {ticket.id}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/form/ticket-detail-history`}
                            state={{ ticket, type: ticketType }}
                            className='ticket-link'
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <p>{ticket.fields.customfield_10319 || ticket.fields.customfield_10330}</p>
                            <p>{ticket.fields.customfield_10329 || ticket.fields.customfield_10318}</p>
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/form/ticket-detail-history`}
                            state={{ ticket, type: ticketType }}
                            className='ticket-link'
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {ticket.fields.summary}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/form/ticket-detail-history`}
                            state={{ ticket, type: ticketType }}
                            className='ticket-link'
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {ticket.fields.status.name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/form/ticket-detail-history`}
                            state={{ ticket, type: ticketType }}
                            className='ticket-link'
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {ticket.fields.priority.name === 'High' || ticket.fields.priority.name === 'Alta' ? (
                              <span className='priority-high'></span>
                            ) : ticket.fields.priority.name === 'Medium' || ticket.fields.priority.name === 'Media' ? (
                              <span className='priority-medium'></span>
                            ) : (
                              <span className='priority-low'></span>
                            )}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/form/ticket-detail-history`}
                            state={{ ticket, type: ticketType }}
                            className='ticket-link'
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <p>{formattedDate}</p>
                            <p>{formattedTime}</p>
                          </Link>
                        </td>
                      </tr>
                      <tr className='margin-row'>
                        <td colSpan='9'></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className='pagination'>
            {pageNumbers.map((number) => (
              <button key={number} onClick={() => setCurrentPage(number)} disabled={currentPage === number}>
                {number}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TicketList;
