import React from 'react';
import { Link } from 'react-router-dom';
import elipse355 from '../../images/ellipse355.png';
import terminationIcon from '../../images/jira/termination.svg';
import changeIcon from '../../images/jira/change.svg';
import peopleChangeIcon from '../../images/jira/changePeople.svg';
import arrowIcon from '../../images/folder/arrow.svg';
import '../styles/jiraPanel.css';

const MainPanelJira = ({ userInfoSSFF }) => {
  const isManager = userInfoSSFF?.custom15 === 'Y';
  const isViewnext = userInfoSSFF?.division === 'VIEWNEXT';
  const isLifeCycleExperience = userInfoSSFF?.department === 'LIFECYCLE EXPERIENCE (DE107212)';

  const folders = [
    {
      id: 'termination',
      name: 'Baja voluntaria',
      icon: terminationIcon,
      description: 'Solicita tu baja voluntaria',
      path: '/form/termination',
    },
    // {
    //   id: 'change',
    //   name: 'Cambios',
    //   icon: changeIcon,
    //   description: 'Realiza cambios en contratos existentes',
    //   path: '/form/change',
    // },
    //Actualmente se llega a este formulario desde gestiona tus tickets
    /*  {
      id: 'peopleChange',
      name: 'Cambios Area People',
      icon: peopleChangeIcon,
      description: 'Gestiona cambios en el personal',
      path: '/form/tickets',
    }, */
    // {
    //   id: 'newEmployeeManager',
    //   name: 'Alta empleado (manager)',
    //   icon: peopleChangeIcon,
    //   description: 'Alta de nuevo empleado, área del manager',
    //   path: '/form/newEmployeeManager',
    // },
    // {
    //   id: 'newEmployeeChannel',
    //   name: 'Alta empleado (Canal Manager)',
    //   icon: peopleChangeIcon,
    //   description: 'Alta de nuevo empleado, área del Canal del Manager',
    //   path: '/form/newEmployeeChannel',
    // },
    // {
    //   id: 'newEmployeeRecruiter',
    //   name: 'Alta empleado (recruiter)',
    //   icon: peopleChangeIcon,
    //   description: 'Alta de nuevo empleado, área del recruiter',
    //   path: '/form/newEmployeeRecruiter',
    // },
    // {
    //   id: 'contactUsTickets',
    //   name: 'Gestiona tus tickets',
    //   icon: peopleChangeIcon,
    //   description: 'Contacta con nosostros',
    //   path: '/form/ContactUsTickets',
    // },
    {
      id: 'ticketsForm',
      name: 'Gestión de solicitudes',
      icon: peopleChangeIcon,
      description: 'Formularios de baja, cambio y alta ',
      path: '/form/listTicketsForm',
      visible:
        // isManager ||
        // isViewnext ||
        isLifeCycleExperience,
    },
  ];

  return (
    <section className='myFolderPage'>
      <div className='myFolderPage__content'>
        <h1 className='myFolderPage__title'>Formularios</h1>
        <div className='myFolderPage__options'>
          {folders
            .filter((folder) => folder.visible !== false) // Filtra aquellos que no tienen 'visible: false'
            .map((folder) => (
              <div key={folder.id} className='myFolderPage__option'>
                <Link to={folder.path} className='myFolderPage__textContent'>
                  <div className='myFolderPage__imageContent'>
                    <img src={folder.icon} alt={folder.name} className='myFolderPage__image' />
                  </div>
                  <h1 className='myFolderPage__groupTitle'>{folder.name}</h1>
                  <p className='myFolderPage__description'>{folder.description}</p>
                  <div className='myFolderPage__arrowButton'>
                    <img src={arrowIcon} alt='arrow' className='myFolderPage__arrow' />
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <div className='myFolderPage__historyButton'>
        <Link to='/form/history' className='myFolderPage__historyLink'>
          {/*  /<img src={historyIcon} alt="history" className="myFolderPage__historyIcon" /> */}
          <span className='myFolderPage__historyText'>Historial</span>
        </Link>
      </div>
    </section>
  );
};

export default MainPanelJira;
