import '../styles/contactUs-button.css';
import closeCross from '../../images/close-regularBlue.png';
import clipImage from '../../images/clip-light.png';
import deleteFileIcon from '../../images/PeoplExCancel.svg';
import DOMpurify from 'dompurify';
import { useState } from 'react';
import { attachMedia, createTicket, USER_TYPE_TO_INBENTA_ID, isUserExist } from '../../services/api';
import { readFileAsBase64, ALLOWED_TYPE_FILES } from '../../utils/utils';

const QUEUE_EMPLEADO = parseInt(process.env.REACT_APP_QUEUE_EMPLEADO);
const QUEUE_MANAGER = parseInt(process.env.REACT_APP_QUEUE_MANAGER);

const ContactUsButton = ({ userInfoSSFF }) => {
  const [feedbackText, setFeedbackText] = useState('');

  const [files, setFiles] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);

  //pop up de gracias por tu consulta
  const [questionSentPopup, setQuestionSentPopup] = useState(false);
  const [showTeamTextarea, setShowTeamTextarea] = useState(false);
  const [showPersonalTextarea, setShowPersonalTextarea] = useState(false);

  //Mide si el pop-up empleados está abierto o no
  const [employeeAssistancePopupOpen, setEmployeeAssistancePopupOpen] = useState(false);

  //Mide si el pop-up managers está abierto o no
  const [managerAssistancePopupOpen, setManagerAssistancePopupOpen] = useState(false);

  //Estas dos variables estados miden si el input está seleccionado o no. La primera el de necesidad personal y la segunda la de necesidad de equipo
  const [personalAssistanceSelected, setPersonalAssistanceSelected] = useState(false);
  const [teamAssistanceSelected, setTeamAssistanceSelected] = useState(false);
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);

  //funciones

  // const handleFileChange = (e) => {
  //   const selectedFiles = e.target.files;
  //   if (ALLOWED_TYPE_FILES.includes(selectedFiles[0].name.split(".").pop())) {
  //     setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  //     setShowFormatNotSupported(false)
  //   } else {
  //     setShowFormatNotSupported(true);
  //   }
  // };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (ALLOWED_TYPE_FILES.includes(selectedFiles[0].name.split('.').pop())) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setShowFormatNotSupported(false);
    } else {
      setShowFormatNotSupported(true); // Mostrar el mensaje de formato no soportado
    }
  };

  const openPopup = () => {
    if ((userInfoSSFF.custom15 === 'Y' && userInfoSSFF.custom03 === '1893') || userInfoSSFF.custom03 === '1702' || userInfoSSFF.custom03 === '1017') {
      setEmployeeAssistancePopupOpen(true);
    } else if (userInfoSSFF.custom15 === 'Y') {
      setManagerAssistancePopupOpen(true);
    } else {
      setEmployeeAssistancePopupOpen(true);
    }
  };

  const closePopup = () => {
    setManagerAssistancePopupOpen(false);
    setEmployeeAssistancePopupOpen(false);
    // Restablecer los estados de los radio buttons cuando se cierra el popup
    setPersonalAssistanceSelected(false);
    setTeamAssistanceSelected(false);
    setShowTeamTextarea(false);
    setShowPersonalTextarea(false);
    setQuestionSentPopup(false);
    setFeedbackText('');
    setFiles([]);
    setShowFormatNotSupported(false);
  };
  const questionSent = async () => {
    let titleTicket = 'SOPORTE EMPLEADO';
    let message = '';
    let queue = QUEUE_EMPLEADO;

    try {
      setIsSubmitting(true);
      const dataUserInbenta = await isUserExist(userInfoSSFF.username);

      message = `
      <div bgcolor="transparent">
      <div><br /></div>
      1. EMAIL CORPORATIVO
      <br />
      <b>${userInfoSSFF.username}</b>
      <div><br /></div>
      2. NOMBRE Y APELLIDOS
      <br />
      <b>${dataUserInbenta.data[0].name}</b>
      <div><br /></div>
      3. JURIDICA
      <br />
      <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
      <div><br /></div>
      4. DIVISIÓN
      <br />
      <b>${userInfoSSFF.division}</b>
      <div><br /></div>
      5. AREA
      <br />
      <b> ${userInfoSSFF.custom02} </b>
      <div><br /></div>
      6.DEPARTAMENTO
      <br />
      <b>${userInfoSSFF.department}</b>
      <div><br /></div>
      7.CONSULTA
      <br />
      <b>${feedbackText}</b>
      <div><br /></div>
      </div>`;

      // if (managerAssistancePopupOpen) {
      //   queue = 49;

      //   titleTicket = 'SOPORTE MANAGER';
      // }
      if (managerAssistancePopupOpen && personalAssistanceSelected) {
        // Ajusta la cola según la selección del manager
        queue = QUEUE_EMPLEADO; // Necesidad personal
        titleTicket = 'SOPORTE EMPLEADO';
      } else if (managerAssistancePopupOpen && teamAssistanceSelected) {
        queue = QUEUE_MANAGER; // Necesidad de equipo
        titleTicket = 'SOPORTE MANAGER';
      }

      let uuidsMediaArray = [];
      await Promise.all(
        files.map(async (file) => {
          const base64 = await readFileAsBase64(file);
          const uuidMedia = await attachMedia(file.name, base64);
          uuidsMediaArray.push(uuidMedia);
        })
      );

      await createTicket(titleTicket, message, userInfoSSFF.custom03, queue, uuidsMediaArray, dataUserInbenta.data[0].id);

      setFiles([]);
      setManagerAssistancePopupOpen(false);
      setPersonalAssistanceSelected(false);
      setQuestionSentPopup(true);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setMessageSendingFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeQuestionSentPopup = () => {
    setQuestionSentPopup(false);
    closePopup();
    setMessageSendingFailed(false);
  };
  const handlePersonalAssistanceChange = () => {
    setPersonalAssistanceSelected(!personalAssistanceSelected);
    // Asegúrate de que el otro radio esté deseleccionado al cambiar
    setTeamAssistanceSelected(false);
    setShowTeamTextarea(false);
    setShowPersonalTextarea(true);
    setFeedbackText('');
    setFiles([]);
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    document.getElementById('files').value = null;
  };

  const handleTeamAssistanceChange = () => {
    setTeamAssistanceSelected(!teamAssistanceSelected);
    // Asegúrate de que el otro radio esté deseleccionado al cambiar
    setPersonalAssistanceSelected(false);
    setShowPersonalTextarea(false);
    setShowTeamTextarea(true);
    setFeedbackText('');
    setFiles([]);
  };
  const handleFeedbackTextChange = (event) => {
    const text = event.target.value;
    setFeedbackText(text);
  };
  const isSubmitEnabled = feedbackText || teamAssistanceSelected;
  return (
    // <>
    //   <section className="contactUsButtonSection">
    //     <button className="contactUsButton" onClick={openPopup}>
    //       Contacta con nosotros
    //     </button>
    //   </section>
    //   {/* Popup */}
    //   {employeeAssistancePopupOpen && (
    //     <div className="assistancePopup">
    //       <div className="assistancePopup__content employeeContent">
    //         <div className="assistancePopup__titleSection">
    //           <h3 className="assistancePopup__title">
    //             ¿En qué podemos ayudarte?
    //           </h3>
    //           <img
    //             src={closeCross}
    //             alt="cross-to-close-popup"
    //             className="user__popup--closeImg"
    //             onClick={closePopup}
    //           />
    //         </div>
    //         <div className="assistancePopup__textSection">
    //           <p className="assistancePopup__message employeeMessage">
    //             Contactaremos contigo a la mayor brevedad posible.
    //           </p>
    //         </div>
    //         <textarea
    //           className="assistancePopup__feedbackText"
    //           placeholder="Cuéntanos qué necesitas."
    //           value={feedbackText}
    //           onChange={handleFeedbackTextChange}
    //         ></textarea>
    //         {showFormatNotSupported && (
    //           <p className="formArticleLabel__required">
    //             Formato de archivos no soportado. Utilice:{' '}
    //             {ALLOWED_TYPE_FILES.join(', ')}
    //           </p>
    //         )}
    //         {files.length !== 0 && (
    //           <div className="assistancePopup__fileSection">
    //             {files.map((file, index) => (
    //               <>
    //                 <div
    //                   className={'assistancePopup__fileSection__fileSelected'}
    //                 >
    //                   <p
    //                     className={'assistancePopup__fileSection--fileName'}
    //                   >{`${file.name}`}</p>
    //                   <img
    //                     className="assistancePopup__fileSection--deleteFileIcon"
    //                     src={deleteFileIcon}
    //                     alt="deleteFile"
    //                     onClick={() => removeFile(index)}
    //                   />
    //                 </div>
    //               </>
    //             ))}
    //           </div>
    //         )}
    //         <div className="assistancePopup__buttonSection">
    //           <button className="assistancePopup__uploadFiles">
    //             <input
    //               onChange={handleFileChange}
    //               type="file"
    //               className="assistancePopup__uploadFiles--input"
    //               id="files"
    //             />
    //             <label for="files">
    //               <img
    //                 src={clipImage}
    //                 className="assistancePopup__uploadFiles--clip"
    //                 alt="Adjuntar archivos"
    //               />
    //             </label>
    //           </button>
    //           <button
    //             className={`assistancePopup__closeButton ${
    //               isSubmitEnabled ? 'enabled' : ''
    //             }`}
    //             onClick={questionSent}
    //             disabled={!isSubmitEnabled}
    //           >
    //             Enviar
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    //   {managerAssistancePopupOpen && (
    //     <div className="assistancePopup">
    //       <div className="assistancePopup__content">
    //         <div className="assistancePopup__titleSection">
    //           <h3 className="assistancePopup__title">
    //             ¿En qué podemos ayudarte?
    //           </h3>
    //           <img
    //             src={closeCross}
    //             alt="cross-to-close-popup"
    //             className="user__popup--closeImg"
    //             onClick={closePopup}
    //           />
    //         </div>
    //         <div className="assistancePopup__textSection">
    //           <p className="assistancePopup__message">
    //             Contactaremos contigo a la mayor brevedad posible.
    //           </p>
    //           <div className="assistancePopup__textOptions">
    //             <form action="#" method="post" target="_blank">
    //               <input
    //                 type="radio"
    //                 id="personal"
    //                 name="assistance"
    //                 checked={personalAssistanceSelected}
    //                 onChange={handlePersonalAssistanceChange}
    //               />
    //               <label
    //                 for="personal"
    //                 className="assistancePopup__textOptions--label"
    //               >
    //                 Tengo una necesidad personal
    //                 <p className="assistancePopup__textOptions--desc">
    //                   Por ejemplo: Mi nómina y beneficios, mis vacaciones y
    //                   permisos...
    //                 </p>
    //               </label>
    //               <input
    //                 type="radio"
    //                 id="team"
    //                 name="assistance"
    //                 checked={teamAssistanceSelected}
    //                 onChange={handleTeamAssistanceChange}
    //               />
    //               <label
    //                 for="team"
    //                 className="assistancePopup__textOptions--label"
    //               >
    //                 Tengo una necesidad sobre mi equipo
    //                 <p className="assistancePopup__textOptions--desc">
    //                   Por ejemplo: Atracción y retención de talento para mi
    //                   equipo, formación y desarrollo para mi equipo...
    //                 </p>
    //               </label>
    //             </form>
    //           </div>
    //           {showPersonalTextarea === true ? (
    //             <textarea
    //               className="assistancePopup__feedbackText"
    //               placeholder="Cuéntanos qué necesitas."
    //               value={feedbackText}
    //               onChange={handleFeedbackTextChange}
    //             ></textarea>
    //           ) : null}
    //           {showTeamTextarea === true ? (
    //             <textarea
    //               className="assistancePopup__feedbackText"
    //               placeholder="(Opcional) Cuéntanos qué necesitas."
    //               value={feedbackText}
    //               onChange={handleFeedbackTextChange}
    //             ></textarea>
    //           ) : null}
    //         </div>
    //         {files.length !== 0 && (
    //           <div className="assistancePopup__fileSection">
    //             {files.map((file, index) => (
    //               <>
    //                 <div
    //                   className={'assistancePopup__fileSection__fileSelected'}
    //                 >
    //                   <p
    //                     className={'assistancePopup__fileSection--fileName'}
    //                   >{`${file.name}`}</p>
    //                   <img
    //                     className="assistancePopup__fileSection--deleteFileIcon"
    //                     src={deleteFileIcon}
    //                     alt="deleteFile"
    //                     onClick={() => removeFile(index)}
    //                   />
    //                 </div>
    //               </>
    //             ))}
    //           </div>
    //         )}
    //         {showFormatNotSupported && (
    //           <p className="formArticleLabel__required">
    //             Formato de archivos no soportado. Utilice:{' '}
    //             {ALLOWED_TYPE_FILES.join(', ')}
    //           </p>
    //         )}
    //         <div className="assistancePopup__buttonSection">
    //           <button className="assistancePopup__uploadFiles">
    //             <input
    //               onChange={handleFileChange}
    //               type="file"
    //               className="assistancePopup__uploadFiles--input"
    //               id="files"
    //               multiple
    //             />
    //             <label for="files">
    //               <img
    //                 src={clipImage}
    //                 className="assistancePopup__uploadFiles--clip"
    //                 alt="Adjuntar archivos"
    //               />
    //             </label>
    //           </button>
    //           <button
    //             className={`assistancePopup__closeButton ${
    //               isSubmitEnabled ? 'enabled' : ''
    //             }`}
    //             onClick={questionSent}
    //             disabled={!isSubmitEnabled}
    //           >
    //             Enviar
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    //   {questionSentPopup === true ? (
    //     <div className="assistancePopup">
    //       <div className="assistancePopup__content">
    //         <div className="assistancePopup__titleSection">
    //           <h3 className="assistancePopup__title">
    //             Hemos recibido tu consulta
    //           </h3>
    //         </div>
    //         <div className="assistancePopup__textSection">
    //           <p className="assistancePopup__message">
    //             Contactaremos contigo a la mayor brevedad posible.
    //           </p>
    //         </div>
    //         <div className="assistancePopup__buttonSection">
    //           <button
    //             className="assistancePopup__closeButton enabled"
    //             onClick={closeQuestionSentPopup}
    //           >
    //             Aceptar
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   ) : null}
    // </>
    <>
      <section className='contactUsButtonSection'>
        <button className='contactUsButton' onClick={openPopup}>
          Contacta con nosotros
        </button>
      </section>
      {/* Popup */}
      {employeeAssistancePopupOpen && (
        <div className='assistancePopup'>
          <div className='assistancePopup__content employeeContent'>
            <div className='assistancePopup__titleSection'>
              <h3 className='assistancePopup__title'>¿En qué podemos ayudarte?</h3>
              <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={closePopup} />
            </div>
            <div className='assistancePopup__textSection'>
              <p className='assistancePopup__message employeeMessage'>Contactaremos contigo a la mayor brevedad posible.</p>
            </div>
            <textarea
              className='assistancePopup__feedbackText'
              placeholder='Cuéntanos qué necesitas.'
              value={feedbackText}
              onChange={handleFeedbackTextChange}
            ></textarea>
            {showFormatNotSupported && (
              <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
            )}
            {files.length !== 0 && (
              <div className='assistancePopup__fileSection'>
                {files.map((file, index) => (
                  <>
                    <div className={'assistancePopup__fileSection__fileSelected'}>
                      <p className={'assistancePopup__fileSection--fileName'}>{`${file.name}`}</p>
                      <img
                        className='assistancePopup__fileSection--deleteFileIcon'
                        src={deleteFileIcon}
                        alt='deleteFile'
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
            <div className='assistancePopup__buttonSection'>
              <button className='assistancePopup__uploadFiles'>
                <input onChange={handleFileChange} type='file' className='assistancePopup__uploadFiles--input' id='files' />
                <label for='files'>
                  <img src={clipImage} className='assistancePopup__uploadFiles--clip' alt='Adjuntar archivos' />
                </label>
              </button>
              <button
                className={`assistancePopup__closeButton ${isSubmitEnabled ? 'enabled' : ''}`}
                onClick={questionSent}
                disabled={!isSubmitEnabled}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
      {managerAssistancePopupOpen && (
        <div className='assistancePopup'>
          <div className='assistancePopup__content'>
            <div className='assistancePopup__titleSection'>
              <h3 className='assistancePopup__title'>¿En qué podemos ayudarte?</h3>
              <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={closePopup} />
            </div>
            <div className='assistancePopup__textSection'>
              <p className='assistancePopup__message'>Contactaremos contigo a la mayor brevedad posible.</p>
              <div className='assistancePopup__textOptions'>
                <form action='#' method='post' target='_blank'>
                  <input
                    type='radio'
                    id='personal'
                    name='assistance'
                    checked={personalAssistanceSelected}
                    onChange={handlePersonalAssistanceChange}
                  />
                  <label for='personal' className='assistancePopup__textOptions--label'>
                    Tengo una necesidad personal
                    <p className='assistancePopup__textOptions--desc'>Por ejemplo: Mi nómina y beneficios, mis vacaciones y permisos...</p>
                  </label>
                  <input type='radio' id='team' name='assistance' checked={teamAssistanceSelected} onChange={handleTeamAssistanceChange} />
                  <label for='team' className='assistancePopup__textOptions--label'>
                    Tengo una necesidad sobre mi equipo
                    <p className='assistancePopup__textOptions--desc'>
                      Por ejemplo: Atracción y retención de talento para mi equipo, formación y desarrollo para mi equipo...
                    </p>
                  </label>
                </form>
              </div>
              {showPersonalTextarea === true ? (
                <textarea
                  className='assistancePopup__feedbackText'
                  placeholder='Cuéntanos qué necesitas.'
                  value={feedbackText}
                  onChange={handleFeedbackTextChange}
                ></textarea>
              ) : null}
              {showTeamTextarea === true ? (
                <textarea
                  className='assistancePopup__feedbackText'
                  placeholder='(Opcional) Cuéntanos qué necesitas.'
                  value={feedbackText}
                  onChange={handleFeedbackTextChange}
                ></textarea>
              ) : null}
            </div>
            {files.length !== 0 && (
              <div className='assistancePopup__fileSection'>
                {files.map((file, index) => (
                  <>
                    <div className={'assistancePopup__fileSection__fileSelected'}>
                      <p className={'assistancePopup__fileSection--fileName'}>{`${file.name}`}</p>
                      <img
                        className='assistancePopup__fileSection--deleteFileIcon'
                        src={deleteFileIcon}
                        alt='deleteFile'
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
            {showFormatNotSupported && (
              <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
            )}
            <div className='assistancePopup__buttonSection'>
              {isSubmitting && (
                // SPINNER LOADING
                <div class='loader'></div>
              )}
              <button className='assistancePopup__uploadFiles'>
                <input onChange={handleFileChange} type='file' className='assistancePopup__uploadFiles--input' id='files' multiple />
                <label for='files'>
                  <img src={clipImage} className='assistancePopup__uploadFiles--clip' alt='Adjuntar archivos' />
                </label>
              </button>
              <button
                className={`assistancePopup__closeButton ${isSubmitEnabled ? 'enabled' : ''}`}
                onClick={questionSent}
                disabled={!isSubmitEnabled}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
      {questionSentPopup === true ? (
        <div className='assistancePopup'>
          <div className='assistancePopup__content'>
            <div className='assistancePopup__titleSection'>
              <h3 className='assistancePopup__title'>Hemos recibido tu consulta</h3>
            </div>
            <div className='assistancePopup__textSection'>
              <p className='assistancePopup__message'>Contactaremos contigo a la mayor brevedad posible.</p>
            </div>
            <div className='assistancePopup__buttonSection'>
              <button className='assistancePopup__closeButton enabled' onClick={closeQuestionSentPopup}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {messageSendingFailed && (
        <div className='dislikePopup-overlay'>
          <div className='dislikePopup-content failurePopup'>
            <div className='dislikePopup__sectionText'>
              <p className='timeOutPopup__sectionText--text'>
                No se ha podido enviar su mensaje. <br /> Por favor, vuelve a probar transcurrido unos minutos. Si el error persiste, contacta con
                nosotros a través de{' '}
              </p>
              <a href='mailto:preguntanos@personas.telefonica.com' target='_blank' rel='noreferrer'>
                preguntanos@personas.telefonica.com
              </a>
            </div>
            <div className='contactMeSectionButton'>
              <button className='contactMePopup--button failureButton' onClick={closeQuestionSentPopup}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsButton;
