import React from 'react';

const RecruiterInfo = ({ formData, errors, onChange }) => {
  return (
    <div className='formJiraNewSection'>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.vacancySSFFID && '*'}SSFF ID de la vacante:
          <input
            type='text'
            name='vacancySSFFID'
            value={formData.vacancySSFFID}
            onChange={onChange}
            className={`formJiraNewInputText ${
              errors.vacancySSFFID ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className='formJiraNewLabel_short2'>
          {errors.roleTitle && '*'}ROLE TITLE:
          <input
            type='text'
            name='roleTitle'
            value={formData.roleTitle}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.roleTitle ? 'errorBorder' : ''
            }`}
          />
        </label>
        <label className='formJiraNewLabel'>
          {errors.criticalProfile && '*'}CRITICAL PROFILE:
          <input
            type='text'
            name='criticalProfile'
            value={formData.criticalProfile}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.criticalProfile ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className='formJiraNewLabel_short2'>
          {errors.hrbp && '*'}HRBP:
          <input
            type='text'
            name='hrbp'
            value={formData.hrbp}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.hrbp ? 'errorBorder' : ''
            }`}
          />
        </label>
        <label className='formJiraNewLabel'>
          {errors.type && '*'}TYPE:
          <select
            name='type'
            value={formData.type}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.type ? 'errorBorder' : ''
            }`}
          >
            <option value=''>Select Type</option>
            <option value='Permanente'>Permanente</option>
            <option value='Temporal'>Temporal</option>
          </select>
        </label>
        <label className='formJiraNewLabel'>
          {errors.legalEntity && '*'}LEGAL ENTITY:
          <input
            type='text'
            name='legalEntity'
            value={formData.legalEntity}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.legalEntity ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.finalCandidateName && '*'}FINAL CANDIDATE (Name):
          <input
            type='text'
            name='finalCandidateName'
            value={formData.finalCandidateName}
            onChange={onChange}
            className={`formJiraNewInputText ${
              errors.finalCandidateName ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.corporateMail && '*'}CORPORATE MAIL:
          <input
            type='email'
            name='corporateMail'
            value={formData.corporateMail}
            onChange={onChange}
            className={`formJiraNewInputText ${
              errors.corporateMail ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.personalEmail && '*'}PERSONAL EMAIL:
          <input
            type='email'
            name='personalEmail'
            value={formData.personalEmail}
            onChange={onChange}
            className={`formJiraNewInputText ${
              errors.personalEmail ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className='formJiraNewLabel'>
          {errors.finalLocalCategoryGradeOfHiring && '*'}FINAL LOCAL
          CATEGORY/GRADE OF HIRING:
          <input
            type='text'
            name='finalLocalCategoryGradeOfHiring'
            value={formData.finalLocalCategoryGradeOfHiring}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.finalLocalCategoryGradeOfHiring ? 'errorBorder' : ''
            }`}
          />
        </label>
        <label className='formJiraNewLabel'>
          {errors.finalGlobalCategoryGradeOfHiring && '*'}FINAL GLOBAL
          CATEGORY/GRADE OF HIRING:
          <input
            type='text'
            name='finalGlobalCategoryGradeOfHiring'
            value={formData.finalGlobalCategoryGradeOfHiring}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.finalGlobalCategoryGradeOfHiring ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className='formJiraNewLabel'>
          {errors.countryFinalCandidate && '*'}COUNTRY (FINAL CANDIDATE):
          <input
            type='text'
            name='countryFinalCandidate'
            value={formData.countryFinalCandidate}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.countryFinalCandidate ? 'errorBorder' : ''
            }`}
          />
        </label>
        <label className='formJiraNewLabel'>
          {errors.needsInternationalMobility && '*'}NEEDS INTERNATIONAL
          MOBILITY:
          <select
            name='needsInternationalMobility'
            value={formData.needsInternationalMobility}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.needsInternationalMobility ? 'errorBorder' : ''
            }`}
          >
            <option value=''>Select Mobility</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className='formJiraNewLabel_short'>
          {errors.salary && '*'}SALARY:
          <input
            type='number'
            name='salary'
            value={formData.salary}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.salary ? 'errorBorder' : ''
            }`}
          />
        </label>
        <label className='formJiraNewLabel_short'>
          {errors.targetBonus && '*'}TARGET BONUS:
          <input
            type='number'
            name='targetBonus'
            value={formData.targetBonus}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.targetBonus ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.currency && '*'}CURRENCY:
          <select
            name='currency'
            className={`formJiraNewInputText ${
              errors.currency ? 'errorBorder' : ''
            }`}
            value={formData.currency}
            onChange={onChange}
          >
            <option value='' disabled>
              Seleccione una moneda...
            </option>
            <option value='USD'>USD - Dólar Estadounidense</option>
            <option value='EUR'>EUR - Euro</option>
            <option value='GBP'>GBP - Libra Esterlina</option>
            <option value='BRL'>BRL - Real Brasileño</option>
            <option value='COP'>COP - Peso Colombiano</option>
            <option value='PEN'>PEN - Sol Peruano</option>
            <option value='VES'>VES - Bolívar Venezolano</option>
            <option value='ARS'>ARS - Peso Argentino</option>
            <option value='UYU'>UYU - Peso Uruguayo</option>
            <option value='MXN'>MXN - Peso Mexicano</option>
            <option value='PYG'>PYG - Guaraní Paraguayo</option>
          </select>
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className='formJiraNewLabel'>
          {errors.isContractor && '*'}IS CONTRACTOR:
          <select
            name='isContractor'
            value={formData.isContractor}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.isContractor ? 'errorBorder' : ''
            }`}
          >
            <option value=''>Select Contractor Status</option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
        </label>
        <label className='formJiraNewLabel'>
          {errors.revolvingDoors && '*'}REVOLVING DOORS (Political
          Exposure/Sanctions):
          <input
            type='text'
            name='revolvingDoors'
            value={formData.revolvingDoors}
            onChange={onChange}
            className={`formJiraNewInputText_short ${
              errors.revolvingDoors ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.contractType && '*'}CONTRACT TYPE:
          <input
            type='text'
            name='contractType'
            value={formData.contractType}
            onChange={onChange}
            className={`formJiraNewInputText ${
              errors.contractType ? 'errorBorder' : ''
            }`}
          />
        </label>
      </div>
    </div>
  );
};

export default RecruiterInfo;
