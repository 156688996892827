import { validateFields } from './formValidate';
import React, { useEffect, useState } from 'react';

const handleRadioChange = (currentValue, setter, selectedValue) => {
  if (currentValue === selectedValue) {
    setter(''); // Deseleccionar si se vuelve a hacer clic
  } else {
    setter(selectedValue); // Seleccionar si es un valor diferente
  }
};
// A continuación tenemos las funciones que muestran los componentes de cada sección del formulario de cambio.
export const OrganizationalChangeComponent = ({
  organizationalChange,
  newDepartments,
  responsiblePerson,
  setOrganizationalChange,
  setNewDepartments,
  setResponsiblePerson,
  errors,
  setErrors,
}) => {
  const [showWarning, setShowWarning] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});
  //Se utiliza el hook useEffect para actualizar el estado de los campos del formulario y comprobar si ya se han completado previamente
  useEffect(() => {
    setInitialValues({
      organizationalChange,
      newDepartments,
      responsiblePerson,
    });
  }, []);
  // Se validan los campos del formulario y se muestra un mensaje de advertencia si no se han completado
  useEffect(() => {
    const fields = {
      organizationalChange,
      newDepartments,
      responsiblePerson,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [organizationalChange, newDepartments, responsiblePerson]);

  //Si los campos ya han sido completados previamente, se muestra un borde de color azul y si hay algun error se define el borde de color rojo
  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeInputText errorBorder'
      : 'formJiraChangeInputText';
  };
  const getRadioClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeRadio errorBorder'
      : 'formJiraChangeRadio';
  };

  const renderLabel = (labelText, fieldName) => {
    return (
      <label className='formJiraChangeLabel'>
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios organizativos</div>
      <div className='formJiraChangeRadioGroup'>
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabel'>
            {renderLabel(
              '¿Se trata de una reorganización?',
              'organizationalChange'
            )}
          </label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                className={getRadioClass(
                  'organizationalChange',
                  'organizationalChange'
                )}
                value='yes'
                checked={organizationalChange === 'yes'}
                //onChange={() => setOrganizationalChange('yes')}
                onChange={() =>
                  handleRadioChange(
                    organizationalChange,
                    setOrganizationalChange,
                    'yes'
                  )
                }
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='organizationalChange'
                className={getRadioClass(
                  'organizationalChange',
                  'organizationalChange'
                )}
                value='no'
                checked={organizationalChange === 'no'}
                //onChange={() => setOrganizationalChange('no')}
                onChange={() =>
                  handleRadioChange(
                    organizationalChange,
                    setOrganizationalChange,
                    'no'
                  )
                }
              />
              No
            </label>
          </div>
        </div>
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabelRadio'>
            {renderLabel(
              '¿Hay que crear nuevos departamentos?',
              'newDepartments'
            )}
          </label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                className={getRadioClass('newDepartments', 'newDepartments')}
                value='yes'
                checked={newDepartments === 'yes'}
                //onChange={() => setNewDepartments('yes')}
                onChange={() =>
                  handleRadioChange(newDepartments, setNewDepartments, 'yes')
                }
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='newDepartments'
                className={getRadioClass('newDepartments')}
                value='no'
                checked={newDepartments === 'no'}
                //onChange={() => setNewDepartments('no')}
                onChange={() =>
                  handleRadioChange(newDepartments, setNewDepartments, 'no')
                }
              />
              No
            </label>
          </div>
        </div>
        <div className='formJiraChangeSection'>
          <label className='formJiraChangeLabel'>
            {renderLabel('Responsable directo:', 'responsiblePerson')}
            <input
              type='text'
              placeholder='Responsable Directo'
              className={getInputClass(responsiblePerson, 'responsiblePerson')}
              value={responsiblePerson}
              onChange={(e) => setResponsiblePerson(e.target.value)}
            />
          </label>
        </div>
      </div>
      {showWarning && (
        <div className='formJiraChangeWarningMessage'>
          *Es necesario completar todos los campos indicados en rojo
        </div>
      )}
    </div>
  );
};
export const RoleChangeComponent = ({
  roleChange,
  roleChangeDetails,
  managerChange,
  newManager,
  changeDetails,
  setRoleChange,
  setRoleChangeDetails,
  setManagerChange,
  setNewManager,
  setChangeDetails,
  errors,
  setErrors,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [showWarning, setShowWarning] = React.useState(false);

  useEffect(() => {
    setInitialValues({
      roleChange,
      roleChangeDetails,
      managerChange,
      newManager,
      changeDetails,
    });
  }, []);

  useEffect(() => {
    const fields = {
      roleChange,
      roleChangeDetails,
      managerChange,
      newManager,
      changeDetails,
    };
    const validationErrors = validateFields(fields);
    setShowWarning(Object.keys(validationErrors).length > 0);
    setErrors(validationErrors);
  }, [roleChange, roleChangeDetails, managerChange, newManager, changeDetails]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeInputText errorBorder'
      : 'formJiraChangeInputText';
  };

  const getRadioClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeRadio errorBorder'
      : 'formJiraChangeRadio';
  };

  const renderLabel = (labelText, fieldName) => {
    return (
      <label className='formJiraChangeLabel'>
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  useEffect(() => {
    if (roleChangeDetails) {
      setRoleChange('yes');
    }
  }, [roleChangeDetails]);

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambio de Rol</div>
      <div className='formJiraChangeRadioGroup'>
        <div className='formJiraChangeLabelRadio'>
          <label className='formJiraChangeLabel'>
            {renderLabel('¿Es un cambio de rol?', 'roleChange')}
          </label>
          <div className='formJiraChangeRadio'>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                className={getRadioClass('roleChange', 'roleChange')}
                value='yes'
                checked={roleChange === 'yes'}
                //onChange={() => setRoleChange('yes')}
                onChange={() =>
                  handleRadioChange(roleChange, setRoleChange, 'yes')
                }
              />
              Sí
            </label>
            <label className='formJiraChangeLabelRadio'>
              <input
                type='radio'
                name='roleChange'
                className={getRadioClass('roleChange', 'roleChange')}
                value='no'
                checked={roleChange === 'no'}
                //onChange={() => setRoleChange('no')}
                onChange={() =>
                  handleRadioChange(roleChange, setRoleChange, 'no')
                }
              />
              No
            </label>
          </div>
        </div>

        {roleChange === 'yes' && (
          <div className='formJiraChangeRadioGroup'>
            <label className='formJiraChangeLabel'>
              {renderLabel(
                '¿Puedes indicarnos en qué consiste el cambio de rol y si es necesario un cambio de puesto?',
                'roleChangeDetails'
              )}
            </label>
            <input
              type='text'
              className={getInputClass(
                'roleChangeDetails',
                'roleChangeDetails'
              )}
              value={roleChangeDetails}
              onChange={(e) => setRoleChangeDetails(e.target.value)}
              placeholder='Detalles del cambio de rol...'
            />
            <div className='formJiraChangeLabelRadio'>
              <label className='formJiraChangeLabel'>
                {renderLabel(
                  '¿Cambia de manager o de equipo?,',
                  'managerChange'
                )}
              </label>
              <div className='formJiraChangeRadio'>
                <label className='formJiraChangeLabelRadio'>
                  <input
                    type='radio'
                    name='managerChange'
                    className={getRadioClass('managerChange', 'managerChange')}
                    value='yes'
                    checked={managerChange === 'yes'}
                    //onChange={() => setManagerChange('yes')}
                    onChange={() =>
                      handleRadioChange(managerChange, setManagerChange, 'yes')
                    }
                  />
                  Sí
                </label>
                <label className='formJiraChangeLabelRadio'>
                  <input
                    type='radio'
                    name='managerChange'
                    className={getRadioClass('managerChange', 'managerChange')}
                    value='no'
                    checked={managerChange === 'no'}
                    //onChange={() => setManagerChange('no')}
                    onChange={() =>
                      handleRadioChange(managerChange, setManagerChange, 'no')
                    }
                  />
                  No
                </label>
              </div>
            </div>
            {managerChange === 'yes' && (
              <label className='formJiraChangeLabel'>
                {renderLabel('Nuevo Manager:', 'newManager')}
                <input
                  type='text'
                  className={getInputClass(newManager, 'newManager')}
                  value={newManager}
                  onChange={(e) => setNewManager(e.target.value)}
                  placeholder='Nombre del nuevo manager'
                />
              </label>
            )}
          </div>
        )}
        {roleChange === 'no' && (
          <div className='formJiraChangeRadioGroup'>
            <label className='formJiraChangeLabel'>
              ¿Qué cambio quieres realizar?
            </label>
            <input
              type='text'
              className={getInputClass(changeDetails, 'changeDetails')}
              value={changeDetails}
              onChange={(e) => setChangeDetails(e.target.value)}
              placeholder='Detalles del cambio...'
            />
          </div>
        )}
      </div>
      {showWarning && (
        <div className='formJiraChangeWarningMessage'>
          *Es necesario completar todos los campos indicados en rojo
        </div>
      )}
    </div>
  );
};

export const SalaryChangeComponent = ({
  salaryChange,
  currency,
  bonusOrIncentives,
  bonusPercentage,
  incentivesAmount,
  salaryReason,
  setSalaryChange,
  setCurrency,
  setBonusOrIncentives,
  setBonusPercentage,
  setIncentivesAmount,
  setSalaryReason,
  errors,
  setErrors,
}) => {
  const [showWarning, setShowWarning] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      salaryChange,
      currency,
      bonusOrIncentives,
      bonusPercentage,
      incentivesAmount,
      salaryReason,
    });
  }, []);

  useEffect(() => {
    const fields = {
      salaryChange,
      currency,
      bonusOrIncentives,
      bonusPercentage,
      incentivesAmount,
      salaryReason,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [
    salaryChange,
    currency,
    bonusOrIncentives,
    bonusPercentage,
    incentivesAmount,
    salaryReason,
  ]);

  const getInputClass = (fieldValue, fieldName) => {
    if (
      fieldName === 'salaryReason' ||
      fieldName === 'currency' ||
      fieldName === 'salaryChange'
    ) {
      return fieldValue && fieldValue === initialValues[fieldName]
        ? 'formJiraChangeInputText prefilledBorder'
        : errors[fieldName]
        ? 'formJiraChangeInputText errorBorder'
        : 'formJiraChangeInputText';
    }

    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText_short prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeInputText_short errorBorder'
      : 'formJiraChangeInputText_short';
  };

  const radioClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeRadio prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeRadio errorBorder'
      : 'formJiraChangeRadio';
  };

  const renderLabel = (labelText, fieldName) => {
    return (
      <label className='formJiraChangeLabel'>
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  useEffect(() => {
    if (incentivesAmount) {
      setBonusOrIncentives('incentives');
    } else if (bonusPercentage) {
      setBonusOrIncentives('bonus');
    }
  }, [incentivesAmount]);

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios salariales</div>
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          {errors.salaryChange && '*'}Salario Fijo Anual:
          <input
            type='number'
            className={getInputClass(salaryChange, 'salaryChange')}
            value={salaryChange}
            onChange={(e) => setSalaryChange(e.target.value)}
            placeholder='Salario anual...'
            min={0}
          />
        </label>
        <label className='formJiraChangeLabel_bothIndividual'>
          {errors.currency && '*'}Moneda:
          <select
            className={getInputClass(currency, 'currency')}
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value='' disabled>
              Seleccione una moneda...
            </option>
            <option value='USD'>USD - Dólar Estadounidense</option>
            <option value='EUR'>EUR - Euro</option>
            <option value='GBP'>GBP - Libra Esterlina</option>
            <option value='BRL'>BRL - Real Brasileño</option>
            <option value='COP'>COP - Peso Colombiano</option>
            <option value='PEN'>PEN - Sol Peruano</option>
            <option value='VES'>VES - Bolívar Venezolano</option>
            <option value='ARS'>ARS - Peso Argentino</option>
            <option value='UYU'>UYU - Peso Uruguayo</option>
            <option value='MXN'>MXN - Peso Mexicano</option>
            <option value='PYG'>PYG - Guaraní Paraguayo</option>
          </select>
        </label>
      </div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short'>
          {renderLabel('Bonus o Incentivos Comerciales:', 'bonusOrIncentives')}
        </label>
        <div className='formJiraChangeRadioGroup'>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='bonusOrIncentives'
              className={radioClass(bonusOrIncentives, 'bonusOrIncentives')}
              value='bonus'
              checked={bonusOrIncentives === 'bonus'}
              //onChange={() => setBonusOrIncentives('bonus')}
              onChange={() =>
                handleRadioChange(
                  bonusOrIncentives,
                  setBonusOrIncentives,
                  'bonus'
                )
              }
            />
            {renderLabel('Bonus', 'bonusPercentage')}
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type='radio'
              name='bonusOrIncentives'
              className={radioClass(bonusOrIncentives, 'bonusOrIncentives')}
              value='incentives'
              checked={bonusOrIncentives === 'incentives'}
              //onChange={() => setBonusOrIncentives('incentives')}
              onChange={() =>
                handleRadioChange(
                  bonusOrIncentives,
                  setBonusOrIncentives,
                  'incentives'
                )
              }
            />
            {renderLabel('Incentivos Comerciales', 'incentivesAmount')}
          </label>
        </div>

        {bonusOrIncentives === 'bonus' && (
          <div>
            <label className='formJiraChangeLabel'>
              {renderLabel('% de Bonus:', 'bonusPercentage')}
            </label>
            <input
              type='number'
              className={getInputClass(bonusPercentage, 'bonusPercentage')}
              value={bonusPercentage}
              onChange={(e) => setBonusPercentage(e.target.value)}
              placeholder='% de bonus...'
              min={0}
            />
          </div>
        )}

        {bonusOrIncentives === 'incentives' && (
          <div>
            <label className='formJiraChangeLabel'>
              {renderLabel(
                'Cantidad de Incentivos Comerciales:',
                'incentivesAmount'
              )}
            </label>
            <input
              type='number'
              className={getInputClass(incentivesAmount, 'incentivesAmount')}
              value={incentivesAmount}
              onChange={(e) => setIncentivesAmount(e.target.value)}
              placeholder='Cantidad de incentivos...'
              min={0}
            />
          </div>
        )}
      </div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>
          {renderLabel('¿Cuál es el motivo del cambio?', 'salaryReason')}
          <input
            type='text'
            className={getInputClass(salaryReason, 'salaryReason')}
            value={salaryReason}
            onChange={(e) => setSalaryReason(e.target.value)}
            placeholder='Motivo del cambio...'
          />
        </label>
      </div>
      {showWarning && (
        <div className='formJiraChangeWarningMessage'>
          *Es necesario completar todos los campos indicados en rojo <br />
          *En el caso de que se haya seleccionado un cambio de moneda, es
          necesario completar los campos de cambio de localización.
        </div>
      )}
    </div>
  );
};

export const LocationChangeComponent = ({
  city,
  country,
  building,
  floor,
  setCity,
  setCountry,
  setBuilding,
  setFloor,
  errors,
  setErrors,
}) => {
  const [showWarning, setShowWarning] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      city,
      country,
      building,
      floor,
    });
  }, []);

  useEffect(() => {
    const fields = {
      city,
      country,
      building,
      floor,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [city, country, building, floor]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeInputText errorBorder'
      : 'formJiraChangeInputText';
  };

  const renderLabel = (labelText, fieldName, additionalClass = '') => {
    return (
      <label className={`formJiraChangeLabel ${additionalClass}`}>
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  return (
    <div className='formJiraChangeCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios de localización</div>
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          {renderLabel('Ciudad:', 'city', 'formJiraChangeLabel_bothIndividual')}
          <input
            type='text'
            className={getInputClass(city, 'city')}
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder='Ciudad...'
          />
        </label>
        <label className='formJiraChangeLabel_bothIndividual'>
          {renderLabel(
            'País:',
            'country',
            'formJiraChangeLabel_bothIndividual'
          )}
          <input
            type='text'
            className={getInputClass(country, 'country')}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder='País...'
          />
        </label>
      </div>
      <div className='formJiraChangeSection_both'>
        <label className='formJiraChangeLabel_bothIndividual'>
          {renderLabel(
            'Edificio:',
            'building',
            'formJiraChangeLabel_bothIndividual'
          )}
          <input
            type='text'
            className={getInputClass(building, 'building')}
            value={building}
            onChange={(e) => setBuilding(e.target.value)}
            placeholder='Edificio...'
          />
        </label>
        <label className='formJiraChangeLabel_bothIndividual'>
          {renderLabel(
            'Planta:,',
            'floor',
            'formJiraChangeLabel_bothIndividual'
          )}

          <input
            type='text'
            className={getInputClass(floor, 'floor')}
            value={floor}
            onChange={(e) => setFloor(e.target.value)}
            placeholder='Planta...'
          />
        </label>
      </div>
      {showWarning && (
        <div className='formJiraChangeWarningMessage'>
          *Es necesario completar todos los campos indicados en rojo <br /> *En
          el caso de que se haya seleccionado un cambio de país, es necesario
          completar los campos de cambio de salario.
        </div>
      )}
    </div>
  );
};

export const ContractChangeComponent = ({
  contractChange,
  setContractChange,
}) => {
  const [showWarning, setShowWarning] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState('');

  useEffect(() => {
    setInitialValues({
      contractChange,
    });
  }, []);

  useEffect(() => {
    const fields = {
      contractChange,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [contractChange]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeInputText errorBorder'
      : 'formJiraChangeInputText';
  };
  const renderLabel = (labelText, fieldName) => {
    return (
      <label className='formJiraChangeLabel'>
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  return (
    <div className='formJiraCheckboxGroup_changes'>
      <div className='formJiraChangeInfoTittle'>Cambios de contrato</div>
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel'>
          {renderLabel('Tipo de Contrato:', 'contractChange')}
        </label>
        <input
          type='text'
          className={getInputClass(contractChange, 'contractChange')}
          value={contractChange}
          onChange={(e) => setContractChange(e.target.value)}
        />
      </div>
      {showWarning && (
        <div className='formJiraChangeWarningMessage'>
          *Es necesario completar todos los campos indicados en rojo
        </div>
      )}
    </div>
  );
};
