import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { getJiraTickets } from '../../../../../services/apiJira';
import { Link } from 'react-router-dom';
import '../../../../styles/change/tickets.css';
/* 
const secretKey = 'your-secret-key';

const decryptValue = (encryptedValue) => {
    try {

        if (typeof encryptedValue === 'object' && encryptedValue !== null) {
            return encryptedValue;
        }

        const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    } catch (e) {
        console.error('Error al desencriptar el valor:', e);
        return encryptedValue; 
    }
}; */

const TicketList = () => {
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [ticketsPerPage] = useState(15);

   useEffect(() => {
        const fetchTickets = async () => {
            try {
                const ticketList = await getJiraTickets();
                console.log('TICKETS RECIBIDOS AL FRONT', ticketList);
                setTickets(ticketList);
				const initialFilteredTickets = ticketList.filter(ticket =>
                    ticket.fields.summary === 'Cambio'
                );
                setFilteredTickets(initialFilteredTickets);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTickets();
    }, []); 
	/* useEffect(() => {
        const fetchTickets = async () => {
            try {
                const ticketList = await getJiraTickets();
                console.log('TICKETS RECIBIDOS AL FRONT', ticketList);

                const decryptedTickets = ticketList.map(ticket => {
                    const fields = ticket.fields;
                    return {
                        ...ticket,
                        fields: {
                            ...fields,
                            customfield_10329: decryptValue(fields.customfield_10329),
                            customfield_10331: decryptValue(fields.customfield_10331),
                        }
                    };
                });

                setTickets(decryptedTickets);
                setFilteredTickets(decryptedTickets);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTickets();
    }, []);*/
 
    useEffect(() => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const newFilteredTickets = tickets
            .filter(ticket =>
                ticket.fields.summary.toLowerCase() === 'cambio' && // Filtra solo los tickets con resumen "Cambio"
                ticket.fields.summary.toLowerCase().includes(lowercasedSearchTerm)
            );
        setFilteredTickets(newFilteredTickets);
        setCurrentPage(1);
    }, [searchTerm, tickets]);
	
    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
    const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredTickets.length / ticketsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return <p className="loading-message">Cargando tickets...</p>;
    }

    if (error) {
        return <p className="error-message">Error al cargar tickets: {error}</p>;
    }

    return (
        <div className="ticket-list-container">
            <h2 className="ticket-list-title">Historial</h2>
            <input
                type="text"
                className="ticket-search-input"
                placeholder="Buscar tickets..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {filteredTickets.length === 0 ? (
                <p className="no-tickets-message">No se encontraron tickets.</p>
            ) : (
                <ul className="ticket-list">
                    {currentTickets.map((ticket) => (
                        <li key={ticket.key} className="ticket-list-item">
                            <Link to={`/form/ticket-detail`} state={{ ticket }} className="ticket-link">
                                <div className="ticket-details">
                                    <span className="ticket-id">{ticket.id}</span>
                                    <span className="ticket-summary">{ticket.fields.summary}</span>
                                    <span className="ticket-manager">{ticket.fields.customfield_10329}</span>
                                    <span className="ticket-change-date">{ticket.fields.customfield_10331}</span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}

            <div className="pagination-container">
                {pageNumbers.map(number => (
                    <button
                        key={number}
                        className="pagination-button"
                        onClick={() => setCurrentPage(number)}
                        disabled={number === currentPage}
                    >
                        {number}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TicketList;


/*import React, { useEffect, useState } from 'react';
import { getJiraTickets } from '../../../../../services/apiJira';
import { Link } from 'react-router-dom';
import '../../../../styles/change/tickets.css';

const TicketList = () => {
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [ticketsPerPage] = useState(15);

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const ticketList = await getJiraTickets();
                console.log('TICKETS RECIBIDOS AL FRONT', ticketList);
                setTickets(ticketList);
                setFilteredTickets(ticketList);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTickets();
    }, []);

    useEffect(() => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const newFilteredTickets = tickets.filter(ticket =>
            ticket.fields.summary.toLowerCase().includes(lowercasedSearchTerm)
        );
        setFilteredTickets(newFilteredTickets);
        setCurrentPage(1);
    }, [searchTerm, tickets]);

    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
    const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredTickets.length / ticketsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return <p className="loading-message">Cargando tickets...</p>;
    }

    if (error) {
        return <p className="error-message">Error al cargar tickets: {error}</p>;
    }

    // Asegúrate de que solo estás intentando renderizar cadenas o números
    return (
        <div className="ticket-list-container">
            <h2 className="ticket-list-title">Tickets Emitidos</h2>
            <input
                type="text"
                className="ticket-search-input"
                placeholder="Buscar tickets..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {filteredTickets.length === 0 ? (
                <p className="no-tickets-message">No se encontraron tickets.</p>
            ) : (
                <div>
                   <ul className="ticket-list">
                        {currentTickets.map((ticket) => (
                            <li key={ticket.key} className="ticket-list-item">
                                <Link to={`/form/ticket-detail`} state={{ ticket }}>
                                    <div className="ticket-summary">
                                        {/* Asegúrate de renderizar solo texto *
                                        <h3>{typeof ticket.fields.summary === 'string' ? ticket.fields.summary : 'Resumen no disponible'}</h3>
                                        {/* Otros detalles del ticket, si es necesario 
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <div className="pagination-container">
                        {pageNumbers.map(number => (
                            <button
                                key={number}
                                className="pagination-button"
                                onClick={() => setCurrentPage(number)}
                                disabled={number === currentPage}
                            >
                                {number}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TicketList;
*/