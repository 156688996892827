export const validateFields = (fields) => {
	const {
	  businessUnit,
	  division,
	  department,
	  costCenter,
	  roleChange,
	  changeDetails,
	  roleChangeDetails,
	  managerChange,
	  newManager,
	  globalRole,
	  jobClassification,
	  category,
	  benchmarkCode,
	  salaryChange,
	  currency,
	  bonusOrIncentives,
	  bonusPercentage,
	  incentivesAmount,
	  salaryReason,
	  organizationalChange,
	  newDepartments,
	  responsiblePerson,
	  hrbp,
	  objectivesResult,
	  city,
	  building,
	  floor,
	  country,
	  jcTitle,
	  jcLevel,
	  jcPayGrade,
	  jcTelefónicaLevel,
	  jcTelefónicaGrade,
	  jcLegalEntity,
	  jobFunction,
	  jcCreationDate,
	  effectiveDate,
	  manager,
	  businessUnitPosition,
	  divisionPosition,
	  departmentPosition,
	  costCenterPosition,
	  globalRolePosition,
	  benchmark,
	  locationPosition,
	  jobClassificationPosition,
	  contractChange,
	  hours,

	} = fields;

	const errors = {};
  

	if (!effectiveDate) errors.effectiveDate = true;
	// Validaciones Job classification
 	if( jcCreationDate || jcTitle || jcLevel || jcPayGrade || jcTelefónicaLevel || jcTelefónicaGrade || jobFunction || jcLegalEntity){	
		if (!effectiveDate) errors.effectiveDate = true;
		if (!jcCreationDate) errors.jcCreationDate = true;
		if (!jcTitle) errors.jcTitle = true;
		if (!jcLevel) errors.jcLevel = true;
		if (!jcPayGrade) errors.jcPayGrade = true;
		if (!jcTelefónicaLevel) errors.jcTelefónicaLevel = true;
		if (!jcTelefónicaGrade) errors.jcTelefónicaGrade = true;
		if (!jobFunction) errors.jobFunction = true;
		if (!jcLegalEntity) errors.jcLegalEntity = true;
	} 
	
	// Validaciones de Cambio de Puesto
	if (manager || businessUnitPosition || divisionPosition || departmentPosition || costCenterPosition || globalRolePosition || benchmark || locationPosition || jobClassificationPosition) {
	  if (!manager) errors.manager = true;
	  if (!businessUnitPosition) errors.businessUnitPosition = true;
	  if (!divisionPosition) errors.divisionPosition = true;
	  if (!departmentPosition) errors.departmentPosition = true;
	  if (!costCenterPosition) errors.costCenterPosition = true;
	  if (!globalRolePosition) errors.globalRolePosition = true;
	  if (!benchmark) errors.benchmark = true;
	  if (!locationPosition) errors.locationPosition = true;
	  if (!jobClassificationPosition) errors.jobClassificationPosition = true;
	}


	
/* 	if (jobClassificationCode) {
	  if (!jobClassificationPosition) errors.jobClassificationPosition = true;
	  if (roleChange !== 'yes') errors.roleChange = true;
	} */
  
	// Validaciones de Cambio Organizativo
	if (organizationalChange !== 'false' || newDepartments !== 'false' || responsiblePerson || hrbp || businessUnit || division || department || costCenter || objectivesResult) {
		if (!organizationalChange) errors.organizationalChange = true;
		if (!newDepartments) errors.newDepartments = true;
		if (!responsiblePerson) errors.responsiblePerson = true;
		if (!hrbp) errors.hrbp = true;
		if (!businessUnit) errors.businessUnit = true;
		if (!division) errors.division = true;
		if (!department) errors.department = true;
		if (!costCenter) errors.costCenter = true;
		if (!objectivesResult) errors.objectivesResult = true;
	}
  
	// Validaciones de Cambio de Rol
	if (roleChange || roleChangeDetails || managerChange !== 'false' || changeDetails || benchmarkCode || globalRole || jobClassification || category) {
		if (roleChange === 'yes') {
		if (!roleChangeDetails) errors.roleChangeDetails = true;
		if (managerChange === 'yes' && !newManager) errors.newManager = true;
		} else if (roleChange === 'no') {
		if (!changeDetails) errors.changeDetails = true;
		if (!globalRole) errors.globalRole = true;
		if (!jobClassification) errors.jobClassification = true;
		if (!category) errors.category = true;
		if (!benchmarkCode) errors.benchmarkCode = true;
		}
	}
  
	// Validaciones Salariales

	if(salaryChange || currency || bonusOrIncentives || bonusPercentage || incentivesAmount || salaryReason){
		if (salaryChange === undefined || salaryChange <= 0) {
		errors.salaryChange = true;
		}
	
		if (!currency) {
		errors.currency = true;
		}
	
		if (!bonusOrIncentives) {
		errors.bonusOrIncentives = true;
		}
	
		if (bonusOrIncentives === 'bonus') {
		if (!bonusPercentage || bonusPercentage <= 0) {
			errors.bonusPercentage = true;
		}
		} else if (bonusOrIncentives === 'incentives') {
		if (!incentivesAmount || incentivesAmount <= 0) {
			errors.incentivesAmount = true;
		}
		}
	
		if (!salaryReason) {
		errors.salaryReason = true;
		}
	}

	// Validaciones de Cambio de Localización
	if (city || building || floor || country) {
	  if (!city) errors.city = true;
	  if (!building) errors.building = true;
	  if (!floor) errors.floor = true;
	  if (!country) errors.country = true;
	}

	if (contractChange || hours) {
		if (!contractChange) errors.contractChange = true;
		if (!hours) errors.hours = true;
	}
	// Validaciones Adicionales
	/* if (country) {
	  if (!legalEntity) errors.legalEntity = true;
	  if (!costCenter) errors.costCenter = true;
	  if (!location) errors.location = true;
	  if (!benchmarkCode) errors.benchmarkCode = true;
	} */

	return errors;
  };
  