import React from 'react';

const RecruitmentFinancials = ({ formData, errors, onChange }) => {
  return (
    <div className="formJiraNewSection">
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.minExpectedSalary && '*'}MIN EXPECTED SALARY:
          <input
            type="number"
            name="minExpectedSalary"
            value={formData.minExpectedSalary}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.minExpectedSalary ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.maxExpectedSalary && '*'}MAX EXPECTED SALARY:
          <input
            type="number"
            name="maxExpectedSalary"
            value={formData.maxExpectedSalary}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.maxExpectedSalary ? 'errorBorder' : ''}`}
          />
        </label>  
        <label className="formJiraNewLabel_three">
          {errors.expectedBonus && '*'}EXPECTED BONUS:
          <input
            type="number"
            name="expectedBonus"
            value={formData.expectedBonus}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.expectedBonus ? 'errorBorder' : ''}`}
          />
        </label>  
	</div>
	 <div className='formJiraNewSection'>	
        <label className="formJiraNewLabel">
          {errors.currencyExpectedSalary && '*'}CURRENCY EXPECTED SALARY:
          <input
            type="text"
            name="currencyExpectedSalary"
            value={formData.currencyExpectedSalary}
            onChange={onChange}
            className={`formJiraNewInputText ${errors.currencyExpectedSalary ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
	  <div className='formJiraNewSection_both'>
		<label className="formJiraNewLabel_short">
		  {errors.billingDate && '*'}BILLING DATE:
		  <input
			type="date"
			name="billingDate"
			value={formData.billingDate}
			onChange={onChange}
			className={`formJiraNewInputText_short ${errors.billingDate ? 'errorBorder' : ''}`}
		  />
		</label>
		<label className="formJiraNewLabel_short">
		  {errors.billingLegalEntity && '*'}BILLING LEGAL ENTITY:
		  <input
		    type="text"
			name="billingLegalEntity"
			value={formData.billingLegalEntity}
			onChange={onChange}
			className={`formJiraNewInputText_short ${errors.billingLegalEntity ? 'errorBorder' : ''}`}
		  />
		</label>
	  </div>
      {/* Add more fields as needed */}
    </div>
  );
};

export default RecruitmentFinancials;
