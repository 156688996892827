import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//styles
import '../styles/featured_content-item.css';

//images
import elipse355 from '../../images/ellipse355.png';

//components
import ArticleItem from './article-item';

const FeaturedContentItem = ({ dataFeaturedContent, userInfoSSFF }) => {

    const [dataFeaturedContentRefresh, setDataFeaturedContentRefresh] = useState(dataFeaturedContent);

    useEffect(() => {
        window.scrollTo(0, 0); //Coloca el scroll en la parte superior cuando carga el componente

        //Guardado de variables de sesion.
        if (dataFeaturedContent.title !== undefined && dataFeaturedContent.answer !== undefined) {
            setDataFeaturedContentRefresh(dataFeaturedContent);
            sessionStorage.setItem('dataFeaturedContentRefresh', JSON.stringify(dataFeaturedContent));
        } else {
            setDataFeaturedContentRefresh(JSON.parse(sessionStorage.getItem('dataFeaturedContentRefresh')));
        }
    }, [dataFeaturedContent]);

    return (
        <>
            <section className="featuredContent__article">
                <div className="featuredContent__article__breadcrumb--container">
                    <img src={elipse355} alt="circle-icon" className="circle-icon" />
                    <Link to="/home" className="featuredContent__article__breadcrumb--title">
                        INICIO {''}
                    </Link>
                    <span className="featuredContent__article__breadcrumb--text">
                        &gt; CONTENIDO DESTACADO
                    </span>
                </div>
                <p className="featuredContent__article--articleId"></p>
                <section className="featuredContent__article__content">
                    <ArticleItem
                        title={dataFeaturedContentRefresh.title}
                        answer={dataFeaturedContentRefresh.answer}
                        expandedState={true}
                        clickCode={dataFeaturedContentRefresh.clickCode}
                        rateCode={dataFeaturedContentRefresh.rateCode}
                        userInfoSSFF={userInfoSSFF}
                        articleId={dataFeaturedContent.id}
                        contactMe={dataFeaturedContentRefresh.CONTACT_MANAGER}
                    >
                            
                    </ArticleItem>
                </section>
            </section>
        </>
    );
};

export default FeaturedContentItem;
