import React from 'react';

const YesNoSection = ({ label, name, value, onChange, showRequired, details }) => (
  <div className='formJiraEndSectionRadio'>
    <label className='formJiraEndLabel'>
      {label}
      {showRequired && <p className='formJiraEndLabel__required'>Este es un campo requerido</p>}
      <div>
        <label className='formJiraEndRadio'>
          <input className='terminationRadioInput' type='radio' name={name} value='yes' checked={value === 'yes'} onChange={onChange} />
          Sí
        </label>
        <label className='formJiraEndRadio'>
          <input className='terminationRadioInput' type='radio' name={name} value='no' checked={value === 'no'} onChange={onChange} />
          No
        </label>
      </div>
    </label>
    {details}
  </div>
);

export default YesNoSection;
