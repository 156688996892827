import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useIsAuthenticated } from '@azure/msal-react';

// Importa los componentes necesarios
import ProtectedRoutes from './knowledgePortal/components/protectedRoutes';
import Header from './components/header';
import HeaderPaises from './components/headerPaises';
import FeaturedContent from './knowledgePortal/components/featured_content';
import FrequentQuestions from './knowledgePortal/components/frequent_questions';
// import Searcher from './knowledgePortal/components/Searcher';
import Carrusel from './knowledgePortal/components/carrusel';
import BubblesCategories from './knowledgePortal/components/bubbles_categories';
import Subcategories from './knowledgePortal/components/subcategories';
import SubcategoriesArticleList from './knowledgePortal/components/subcategories_article-list';
import About from './knowledgePortal/components/about';
import HelpUs from './knowledgePortal/components/help_us';
import TimeOutPopUp from './components/timeOut_popup';
import SearchArticleList from './knowledgePortal/components/search_article-list';
import Footer from './components/footer';
import ContactUs from './knowledgePortal/components/contactUs-button';
import ContactUsPaises from './knowledgePortal/components/contactUs-buttonSinglePage';
import NotFound404 from './knowledgePortal/components/notFound404';
// import ContactPage from './components/contactUs-page';
import AboutUsPage from './knowledgePortal/components/aboutUsPage';
import Roles from './knowledgePortal/components/roles';
import RolesGroups from './knowledgePortal/components/rolesGroups';
import FeaturedContentItem from './knowledgePortal/components/featured_content-item';
// import LoginComponent from './components/loginComponent';
// Importa los componentes de formularios
import Loan from './knowledgePortal/components/forms/loan';
import Fusion from './knowledgePortal/components/forms/fusion';
import HealthInsurance from './knowledgePortal/components/forms/healthInsurance';
import LifeInsurance from './knowledgePortal/components/forms/lifeInsurance';
import Advance from './knowledgePortal/components/forms/advance';
import SharedPayment from './knowledgePortal/components/forms/sharedPayment';
import HealthInsuranceBeneficiaries from './knowledgePortal/components/forms/healthInsuranceBeneficiaries';
import LifeInsuranceBeneficiaries from './knowledgePortal/components/forms/lifeInsuranceBeneficiaries';
import TelematicCert from './knowledgePortal/components/forms/telematicCert';
import TelematicCertPeriods from './knowledgePortal/components/forms/telematicCertPeriods';
import Incidence from './knowledgePortal/components/forms/Incidence';
// import NewFeaturePopup from './components/newFeature';
import NewFeaturePopup from './components/newFeature';
import DynamicArticlePage from './knowledgePortal/components/carrusel/dynamicArticlePage.js';
//MainPanel
import MainPanel from './components/mainPanel';

import MyFolderPage from './folder/components/myFolderPage';
import MyFolderSection from './folder/components/myFolderSection';
// import SWPTool from './swp/components/SWPTool';

//TALENT FILES
// import MainTalent from './talentCards/PageMain/Components/MainTalentComponent';
// import TalentViewComponent from './talentCards/TalentFiles/firstTalentView/TalentViewComponent';
// import SecondTalentComponent from './talentCards/TalentFiles/SecondTalentView/SecondTalentComponent';

//JIRA
import HeaderDG from './jira/components/header/headerDG';
import MainPanelJira from './jira/components/jiraPanel';
//Formularios
import Termination from './jira/components/terminationForm/menuTermination';
import UpdateTickets from './jira/components/terminationForm/updateTicket';
import Change from './jira/components/changeForm/formManager/components/formManagerMenu';
import ListTickets from './jira/components/changeForm/formPeople/ticketsList/listTickets';
import HistorialTickets from './jira/components/historialTickets';
import TicketDetails from './jira/components/listTicketsForm/ticketsDetails';
import TicketDetailsHistory from './jira/components/historialTicketsDetails';
import NewEmployeeManager from './jira/components/newEmployee/managerForm/managerForm';
import NewEmployeeRecruiter from './jira/components/newEmployee/recruiter/recruitmentForm';
import NewEmployeeChannel from './jira/components/newEmployee/channelForm/managerChannelForm';
import ListTicketsForm from './jira/components/listTicketsForm/tickets';

//Canal de atención al empleado
import ContactUsTickets from './jira/components/contactUsTickets/contactUsTicketsList';
import ContactUsTicketsDetails from './jira/components/contactUsTickets/contactUsTicketDetails';

// Importa las funciones de API necesarias
import {
  fetchCategories,
  fetchFeaturedContent,
  fetchfrequentQuestions,
  handleTokenRefresh,
  fetchAutocompleter,
  fetchExtraInfoRoles,
  fetchExtraInfoFeatContent,
  fetchCategoriesDescription,
} from './services/api';
// Importa los estilos
import './styles/app.css';
import './styles/vars.css';
import AuthTeamsOrMsal from './authTeamsOrMsal';
import { TeamsFxContext } from './Context';
// import { fetchEmployeeList } from './services/TalentCards/FetchEmployeeList';
//import { addEmployeeId } from './services/TalentCards/FetchEmployeeAdd';
// import ThreeMobileViewComponent from './talentCards/TalentFiles/threeTalentViewMobile/ThreeMobileViewComponent';
// import CardEmployee from './talentCards/PageMain/Components/CardEmployee';
// import { fetchEmployeeDeleteList } from './services/TalentCards/FetchEmployeeDeleteList';
// import { fetchEmployeeSSFF } from './services/TalentCards/FetchEmployeeSSFF';

function App(props) {
  const [filterInSearcher, setFilterInSearcher] = useState([]);
  const handleFilterSearcher = (value) => {
    setFilterInSearcher(value);
  };
  const [userInfoSSFF, setUserInfoSSFF] = useState({});

  // Variables estado
  const [loggedIn, setLoggedIn] = useState(false);
  const [categories, setCategories] = useState([]); // Variable estado para almacenar las 'Categorias' de la API
  const [selectedCategory, setSelectedCategory] = useState([]); // Variable estado que almacena el ID de la Categoría sobre la que clickamos
  const [selectedSubcategory, setSelectedSubcategory] = useState([]); // Variable estado que almacena el ID de la Subcategoría sobre la que clickamos
  const [frequentQuestions, setFrequentQuestions] = useState([]); // Variable estado para almacenar las 'Preguntas Frecuentes' de la API
  const [featuredContent, setFeaturedContent] = useState([]); // Variable estado para almacenar el 'Contenido Destacado' de la API
  const [dataFeaturedContent, setDataFeaturedContent] = useState([]); // Variable estado para almacenar los datos del artículo de 'Contenido Destacado' de la API
  const [autocompleteArticles, setAutocompleteArticles] = useState([]); // Variable estado para almacenar en Local Storage los artículos del autocomplete
  const [isInactive, setIsInactive] = useState(false); // Variable estado para almacenar el estado de apertura y cierre del artículo
  const [handleUpperCategoryIdParam, setHandleUpperCategoryIdParam] = useState(''); // Variable estado con la que pasaremos como parámetro el upperCategoryIdParam de Inbenta (BusinessEX-40/ PeoplEX-133/ Contenidos Destacados-134/ Roles-138)
  const [extraInfoRoles, setExtraInfoRoles] = useState([]); // Variable estado para guardar el campo 'extra info Roles' de la API
  const [extraInfoFeatContent, setExtraInfoFeatContent] = useState([]); // Variable estado para guardar el campo 'extra info Contenido Destacado' de la API
  const [selectedRole, setSelectedRole] = useState({}); // Variable estado para guardar el 'group' sobre el que se ha clickado paraa mostrar las familias y roles.
  const [extraInfoDescriptionCategories, setExtraInfoDescriptionCategories] = useState([]); // Variable estado para guardar el campo 'descripcion categorias' de la API
  const [isPageGroup, setIsPageGroup] = useState(false); // Variable estado que usamos para reutilizar el componente de article-list y modificar el 'breadcrumb' en abse a si el artículo es de 'roles' o no.
  // const [featurePopup, setFeaturePopup] = useState(true);
  // const [cookies, setCookie] = useCookies(['newFeaturePopup']);
  const [loggedInDisplayName, setLoggedInDisplayName] = useState(null);
  const { themeString } = useContext(TeamsFxContext); // themeString nos sirve para obtener el theme de Teams Fx
  const [selected, setSelected] = useState(null); // Variable estado para guardar el 'section' sobre el que se ha clickado paraa mostrar la opcion de carpeta seleccionada.
  const [featurePopup, setFeaturePopup] = useState(true);
  //const [showNewFeaturePopup, setShowNewFeaturePopup] = useState(false);
  const [cookies, setCookie] = useCookies(['newFeaturePopup']);
  const isAuthenticated = useIsAuthenticated();
  //TalentFIles
  const [empleadosList, setEmpleadosList] = useState([]);
  const [empleadosDeleteList, setempleadosDeleteList] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingDeleteList, setloadingDeleteList] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);

  const [currentUserEmail, setcurrentUserEmail] = useState('');
  const [userPermissions, setuserPermissions] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [usersObject, setusersObject] = useState([]);
  //NewTalent
  const idToken = localStorage.getItem('idToken');
  const jwtSSFF = localStorage.getItem('jwtSSFFToken');

  const handleEmailChange = useCallback((email) => {
    setcurrentUserEmail(email);
  }, []);

  const handlePermissionsChange = useCallback((permissions) => {
    setuserPermissions(permissions);
  }, []);

  /*const handleUsersListChange = (users) => {
    setUsersList(users);
  };
  
  const handleUserObjectChange = (usersObject) => {
    setusersObject(usersObject);
  }; */

  //TALENT FILES FETCH
  // FIX  - ESTO NO SE PUEDE HACER AL INICIAR
  // useEffect(() => {
  //   async function fetchCompleteEmployeeData() {
  //     try {
  //       const data = await fetchEmployeeSSFF(idToken, jwtSSFF);

  //       setEmpleadosList(data);
  //     } catch (error) {
  //       console.error('Error al cargar los datos de empleados:', error);
  //     } finally {
  //       setLoadingList(false);
  //     }
  //   }
  //   async function loadEmployeeDeleteList() {
  //     try {
  //       const data = await fetchEmployeeDeleteList();
  //       setempleadosDeleteList(data);
  //     } catch (error) {
  //       console.error('Error al cargar los datos de empleados:', error);
  //     } finally {
  //       setloadingDeleteList(false);
  //     }
  //   }
  //   fetchCompleteEmployeeData();
  //   loadEmployeeDeleteList();
  // }, []);

  // Para eliminar un empleado del objeto empleadosList
  // const updateEmployeeListAfterDelete = (deletedEmployeeId) => {
  //   setEmpleadosList((prevList) => {
  //     const updatedList = { ...prevList };
  //     delete updatedList[deletedEmployeeId];
  //     return updatedList;
  //   });
  // };

  // Función para manejar la inactividad
  const handleInactivity = () => {
    setIsInactive(true);
  };

  useEffect(() => {
    let inactivityTimer;
    const resetInactivity = () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
      inactivityTimer = setTimeout(handleInactivity, 300000); // 5 minutos
    };

    window.addEventListener('mousemove', resetInactivity);
    window.addEventListener('keydown', resetInactivity);

    return () => {
      window.removeEventListener('mousemove', resetInactivity);
      window.removeEventListener('keydown', resetInactivity);
    };
  }, []);

  useEffect(() => {
    const fetchSearcherData = async () => {
      try {
        if (!localStorage.getItem('autocompleteData')) {
          const autocompleteArticlesData = await fetchAutocompleter();
          localStorage.setItem('autocompleteData', JSON.stringify(autocompleteArticlesData));
          setAutocompleteArticles(autocompleteArticlesData);
        } else {
          setAutocompleteArticles(JSON.parse(localStorage.getItem('autocompleteData')));
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchSearcherData();
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      if (userInfoSSFF.custom03 !== undefined) {
        try {
          const [
            categoriesData,
            extraInfoRolesData,
            extraInfoDescriptionCategoriesData,
            extraInfoFeatContentData,
            featuredContentData,
            frequentQuestionsData,
          ] = await Promise.all([
            fetchCategories(),
            fetchExtraInfoRoles(),
            fetchCategoriesDescription(),
            fetchExtraInfoFeatContent(userInfoSSFF.custom03),
            fetchFeaturedContent(userInfoSSFF.custom03),
            fetchfrequentQuestions(userInfoSSFF.custom03),
          ]);

          setCategories(categoriesData);
          setExtraInfoRoles(extraInfoRolesData);
          setExtraInfoDescriptionCategories(extraInfoDescriptionCategoriesData);
          setExtraInfoFeatContent(extraInfoFeatContentData);
          setFeaturedContent(featuredContentData);
          setFrequentQuestions(frequentQuestionsData);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchApiData();
  }, [userInfoSSFF]);

  // useEffect de la redirección de 'contacto' a bubbles-categories'
  const location = useLocation();

  // useEffect(() => {
  //   if (cookies.newFeaturePopup) {
  //     setFeaturePopup(false);
  //   }
  // }, [cookies.newFeaturePopup]);

  // const handleClosePopup = () => {
  //   setCookie('newFeaturePopup', true, { path: '/', maxAge: 31536000 }); // La cookie dura un año
  //   setFeaturePopup(false);
  // };

  useEffect(() => {
    // Verificar si la URL incluye un hash
    if (location.hash === '#bubbles-categories') {
      // Desplazar la página al componente BubblesCategories
      const element = document.getElementById('bubbles-categories');
      if (element) {
        element.scrollIntoView({ behavior: 'instant' });
      }
    }
  }, [location]);

  const navigate = useNavigate();
  const OnBack = () => {
    navigate('/home');
  };

  return (
    <>
      <Routes>
        <Route path='*' element={<NotFound404 />} />
        <Route
          path='/'
          element={
            <>
              {/* <LoginComponent
                setUserInfoSSFF={setUserInfoSSFF}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              /> */}
              <AuthTeamsOrMsal isInTeams={props.isInTeams} setUserInfoSSFF={setUserInfoSSFF} userInfoSSFF={userInfoSSFF} />
            </>
          }
        />
        <Route
          path='/'
          element={
            <ProtectedRoutes
              setUserInfoSSFF={setUserInfoSSFF}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              setLoggedInDisplayName={setLoggedInDisplayName}
            />
          }
        >
          <Route
            path='home'
            element={
              loggedIn && (
                <>
                  {/* {showNewFeaturePopup && (
                    <NewFeaturePopup onClose={handleClosePopup} />
                  )} */}
                  {!props.isInTeams && <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />}
                  <Header
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
                  />
                  <div className='marginContainer'>
                    <MainPanel
                      userInfoSSFF={userInfoSSFF}
                      onEmailChange={handleEmailChange}
                      onPermissionsChange={handlePermissionsChange}
                      idToken={idToken}
                      jwtSSFF={jwtSSFF}
                      /* onUsersListChange={handleUsersListChange}
                      onUserObjectChange={handleUserObjectChange} 
                      empleadosList={empleadosList}*/
                    />
                  </div>
                </>
              )
            }
          />
          <Route
            path='knowledgepage'
            element={
              loggedIn ? (
                <>
                  {/* {showNewFeaturePopup && (
                    <NewFeaturePopup onClose={handleClosePopup} />
                  )} */}
                  <Header
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
                  />
                  {/* <Searcher
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    autocompleteArticles={autocompleteArticles}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
                    loggedInDisplayName={loggedInDisplayName}
                    isInTeams={props.isInTeams}
                  /> */}
                  <Carrusel />
                  <div className='marginContainer'>
                    <BubblesCategories
                      userInfoSSFF={userInfoSSFF}
                      categories={categories}
                      setSelectedCategory={setSelectedCategory}
                      extraInfoDescriptionCategories={extraInfoDescriptionCategories}
                    />
                    <FeaturedContent
                      categories={categories}
                      featuredContent={featuredContent}
                      setDataFeaturedContent={setDataFeaturedContent}
                      extraInfoFeatContent={extraInfoFeatContent}
                      userInfoSSFF={userInfoSSFF}
                    />
                    <FrequentQuestions frequentQuestions={frequentQuestions} userInfoSSFF={userInfoSSFF} />
                    <About />
                    <HelpUs />
                    <Footer />
                  </div>
                  <ContactUs userInfoSSFF={userInfoSSFF} />
                </>
              ) : null
            }
          />
          <Route
            path='subcategories'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <Subcategories
                    subcategories={categories}
                    selectedCategory={selectedCategory}
                    setIsPageGroup={setIsPageGroup}
                    setSelectedSubcategory={setSelectedSubcategory}
                    extraInfoDescriptionCategories={extraInfoDescriptionCategories}
                    setExtraInfoDescriptionCategories={setExtraInfoDescriptionCategories}
                  />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <SubcategoriesArticleList selectedSubcategory={selectedSubcategory} isPageGroup={isPageGroup} userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/advance'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <Advance userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/fusion'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <Fusion userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/healthinsurance'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <HealthInsurance userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/healthinsurancebeneficiaries'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <HealthInsuranceBeneficiaries userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/incidence'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <Incidence userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/lifeinsurance'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <LifeInsurance userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/lifeinsurancebeneficiaries'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <LifeInsuranceBeneficiaries userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/loan'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <Loan userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/sharedpayment'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <SharedPayment userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/telematic'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <TelematicCert userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='subcategories/articles/form/telematicperiods'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <TelematicCertPeriods userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='search/articles'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <SearchArticleList
                    filterInSearcher={filterInSearcher}
                    handleUpperCategoryIdParam={handleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          {/* <Route
          path="/contacto"
          element={
            <div className="marginContainer">
              <ContactPage />
            </div>
          }
        /> */}
          <Route
            path='aboutUs'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <AboutUsPage />
                </div>
              </>
            }
          />
          <Route
            path='roles'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <Roles
                    categories={categories}
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    setSelectedRole={setSelectedRole}
                    setSelectedSubcategory={setSelectedSubcategory}
                    setIsPageGroup={setIsPageGroup}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          <Route
            path='roles/groups'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <RolesGroups
                    categories={categories}
                    extraInfo={extraInfoRoles}
                    selectedRole={selectedRole}
                    setSelectedSubcategory={setSelectedSubcategory}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    setIsPageGroup={setIsPageGroup}
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                  />
                </div>
              </>
            }
          />
          <Route
            path='featured/articles'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <FeaturedContentItem dataFeaturedContent={dataFeaturedContent} userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='folder'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
                <div className='marginContainer'>
                  <MyFolderPage userInfoSSFF={userInfoSSFF} />
                  <Footer />
                </div>
              </>
            }
          />
          <Route
            path='/folder/:section'
            element={
              <>
                <Header
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <MyFolderSection
                    //selectedSection={selected}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          {/* <Route
            path='talentCards'
            element={
              <>
                <MainTalent
                  userInfoSSFF={userInfoSSFF}
                  empleadosList={empleadosList}
                  setEmpleadosList={setEmpleadosList}
                  loadingList={loadingList}
                  //addEmployee={addEmployee}
                  isAdding={isAdding}
                  updateEmployeeListAfterDelete={updateEmployeeListAfterDelete}
                  //updateEmployeeListAfterAdd={updateEmployeeListAfterAdd}
                  currentUserEmail={currentUserEmail}
                  userPermissions={userPermissions}
                  usersObject={usersObject}
                  empleadosDeleteList={empleadosDeleteList}
                  idToken = {idToken}
                  jwtSSFF = {jwtSSFF}
                />
              </>
            }
          />
          <Route
            path='talentCards/:user_id'
            element={
              <>
                <div className=''>
                  <TalentViewComponent
                    userInfoSSFF={userInfoSSFF}
                    currentUserEmail={currentUserEmail}
                    userPermissions={userPermissions}
                    empleadosList={empleadosList}
                  />
                </div>
              </>
            }
          />
          <Route
            path='talentCardsSecond/:user_id'
            element={
              <>
                <div className=''>
                  <SecondTalentComponent userInfoSSFF={userInfoSSFF} currentUserEmail={currentUserEmail} userPermissions={userPermissions} />
                </div>
              </>
            }
          />
          <Route
            path='talentCardsThreeMobile/:id'
            element={
              <>
                <div className=''>
                  <ThreeMobileViewComponent userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='Cards'
            element={
              <>
                <div className=''>
                  <CardEmployee userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          /> */}
          <Route
            path='form'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <MainPanelJira
                    userInfoSSFF={userInfoSSFF}
                    /*                     usersInfoSSFF={usersInfoSSFF}
                     */
                  />
                </div>
              </>
            }
          />
          <Route
            path='form/termination'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <Termination userInfoSSFF={userInfoSSFF} />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path='form/change'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <Change
                    userInfoSSFF={userInfoSSFF}
                    /* usersInfoSSFF={usersInfoSSFF} */
                  />
                </div>
              </>
            }
          />
          <Route
            path='form/tickets'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <ListTickets userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/history'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <HistorialTickets userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/ticket-detail'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <TicketDetails />
                </div>
              </>
            }
          />
          <Route
            path='form/ticket-detail-history'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <TicketDetailsHistory userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/newEmployeeManager'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <NewEmployeeManager userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/newEmployeeRecruiter'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <NewEmployeeRecruiter userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/newEmployeeChannel'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <NewEmployeeChannel userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/ContactUsTickets'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                {/* <div className='marginContainer'> */}
                <ContactUsTickets userInfoSSFF={userInfoSSFF} />
                {/* </div> */}
              </>
            }
          />
          <Route
            path='form/ContactUsTickets/details'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <ContactUsTicketsDetails
                    handleFilterSearcher={handleFilterSearcher}
                    filterInSearcher={filterInSearcher}
                    autocompleteArticles={autocompleteArticles}
                    setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                    userInfoSSFF={userInfoSSFF}
                  />
                </div>
              </>
            }
          />
          <Route
            path='form/listTicketsForm'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                <div className='marginContainer'>
                  <ListTicketsForm userInfoSSFF={userInfoSSFF} />
                </div>
              </>
            }
          />
          <Route
            path='form/ticket-detail'
            element={
              <>
                <HeaderDG
                  onBack={OnBack}
                  handleFilterSearcher={handleFilterSearcher}
                  filterInSearcher={filterInSearcher}
                  setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                  userInfoSSFF={userInfoSSFF}
                />
                {/* <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} /> */}
                <div className='marginContainer'>
                  <TicketDetails />
                  {/*                   usersInfoSSFF={usersInfoSSFF}
                   */}{' '}
                  userInfoSSFF={userInfoSSFF}
                </div>
              </>
            }
          />
          <Route
            path='article/:id'
            element={
              <DynamicArticlePage
                handleFilterSearcher={handleFilterSearcher}
                filterInSearcher={filterInSearcher}
                setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                userInfoSSFF={userInfoSSFF}
                isInactive={isInactive}
                setIsInactive={setIsInactive}
                handleTokenRefresh={handleTokenRefresh}
                handleUpperCategoryIdParam={handleUpperCategoryIdParam}
              />
            }
          />
        </Route>
        <Route
          path='canalatencion'
          element={
            <>
              <HeaderPaises
                handleFilterSearcher={handleFilterSearcher}
                filterInSearcher={filterInSearcher}
                setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                userInfoSSFF={userInfoSSFF}
              />
              <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
              <div className='marginContainer'>
                <ContactUsPaises userInfoSSFF={userInfoSSFF} />
              </div>
            </>
          }
        />
        {/* <Route
          path='swpTool'
          element={
            <>
              <Header
                handleFilterSearcher={handleFilterSearcher}
                filterInSearcher={filterInSearcher}
                setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                userInfoSSFF={userInfoSSFF}
              />
              <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
              <SWPTool userInfoSSFF={userInfoSSFF} />
              <Footer />
            </>
          }
        /> */}
        {/* <Route
          path='adminPanel'
          element={
            <>
              <Header
                handleFilterSearcher={handleFilterSearcher}
                filterInSearcher={filterInSearcher}
                setHandleUpperCategoryIdParam={setHandleUpperCategoryIdParam}
                userInfoSSFF={userInfoSSFF}
              />
              <TimeOutPopUp isInactive={isInactive} setIsInactive={setIsInactive} handleTokenRefresh={handleTokenRefresh} />
              <div className='marginContainer'>
                <AdminPanel userInfoSSFF={userInfoSSFF} folderAccessToken={folderAccessToken} />
                <Footer />
              </div>
            </>
          }
        /> */}
      </Routes>
    </>
  );
}

export default App;
