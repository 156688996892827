export const validateFields = (fields) => {
  const {
    effectiveDate,
    selectedUser,
    roleChange,
    changeDetails,
    roleChangeDetails,
    managerChange,
    newManager,
    salaryChange,
    currency,
    bonusOrIncentives,
    bonusPercentage,
    incentivesAmount,
    salaryReason,
    organizationalChange,
    newDepartments,
    responsiblePerson,
    city,
    building,
    floor,
    country,
  } = fields;

  const errors = {};

  // Validaciones Generales
  /* 	if( effectiveDate || jcCreationDate || jcTitle || jcLevel || jcPayGrade || jcTelefónicaLevel || jcTelefónicaGrade || jobFunction){	
		if (!effectiveDate) errors.effectiveDate = true;
		if (!jcCreationDate) errors.jcCreationDate = true;
		if (!jcTitle) errors.jcTitle = true;
		if (!jcLevel) errors.jcLevel = true;
		if (!jcPayGrade) errors.jcPayGrade = true;
		if (!jcTelefónicaLevel) errors.jcTelefónicaLevel = true;
		if (!jcTelefónicaGrade) errors.jcTelefónicaGrade = true;
		if (!jobFunction) errors.jobFunction = true;
	} */

  // Validaciones de Cambio de Puesto

  /* 	if (jobClassificationCode) {
	  if (!jobClassificationPosition) errors.jobClassificationPosition = true;
	  if (roleChange !== 'yes') errors.roleChange = true;
	} */
  // Date
  if (!effectiveDate) {
    errors.effectiveDate = true;
  }
  /* 	if( !selectedUser?.displayName ) {
		errors.employeeNameDisplay = true;
	} */

  // Validaciones de Cambio Organizativo
  if (organizationalChange || newDepartments || responsiblePerson) {
    if (!organizationalChange) errors.organizationalChange = true;
    if (!newDepartments) errors.newDepartments = true;
    if (!responsiblePerson) errors.responsiblePerson = true;
  }

  // Validaciones de Cambio de Rol
  if (
    roleChange ||
    roleChangeDetails ||
    managerChange ||
    newManager ||
    changeDetails
  ) {
    if (roleChange === 'yes') {
      if (!roleChangeDetails) errors.roleChangeDetails = true;
      if (!managerChange) errors.managerChange = true;
      if (managerChange === 'yes' && !newManager) errors.newManager = true;
    } else if (roleChange === 'no') {
      if (!changeDetails) errors.changeDetails = true;
    }
  }

  // Validaciones Salariales

  if (
    salaryChange ||
    currency ||
    bonusOrIncentives ||
    bonusPercentage ||
    incentivesAmount ||
    salaryReason
  ) {
    if (salaryChange === undefined || salaryChange <= 0) {
      errors.salaryChange = true;
    }

    if (!currency) {
      errors.currency = true;
    }

    if (!bonusOrIncentives) {
      errors.bonusOrIncentives = true;
    }

    if (bonusOrIncentives === 'bonus') {
      if (!bonusPercentage || bonusPercentage <= 0) {
        errors.bonusPercentage = true;
      }
    } else if (bonusOrIncentives === 'incentives') {
      if (!incentivesAmount || incentivesAmount <= 0) {
        errors.incentivesAmount = true;
      }
    }

    if (!salaryReason) {
      errors.salaryReason = true;
    }
  }

  // Validaciones de Cambio de Localización
  if (city || building || floor || country) {
    if (!city) errors.city = true;
    if (!building) errors.building = true;
    if (!floor) errors.floor = true;
    if (!country) errors.country = true;
  }
  // Validaciones Adicionales
  /* 	if (country) {
	  if (!legalEntity) errors.legalEntity = true;
	  if (!costCenter) errors.costCenter = true;
	  if (!location) errors.location = true;
	  if (!benchmarkCode) errors.benchmarkCode = true;
	} */
  if (country) {
    if (!salaryChange) errors.salaryChange = true;
    if (!currency) errors.currency = true;
    if (!bonusOrIncentives) errors.bonusOrIncentives = true;
    if (!salaryReason) errors.salaryReason = true;
  }
  if (currency) {
    if (!country) errors.country = true;
    if (!city) errors.city = true;
    if (!building) errors.building = true;
    if (!floor) errors.floor = true;
  }

  return errors;
};
