// ManagerChannelForm.js
import React from 'react';

const ManagerChannelSection = ({ formData, errors, onChange }) => {
  return (
    <div className='formJiraNewSection'>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.hiringManager && '*'} Hiring Manager:
        </label>
        <input
          type='text'
          name='hiringManager'
          placeholder='Hiring Manager'
          className={`formJiraNewInputText ${
            errors.hiringManager ? 'errorBorder' : ''
          }`}
          value={formData.hiringManager || ''}
          onChange={onChange}
        />
      </div>

      <div className='formJiraNewSection_both'>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel'>
            {errors.costCenter && '*'} Centro de Coste:
          </label>
          <input
            type='text'
            name='costCenter'
            placeholder='Centro de Coste'
            className={`formJiraNewInputText ${
              errors.costCenter ? 'errorBorder' : ''
            }`}
            value={formData.costCenter || ''}
            onChange={onChange}
          />
        </div>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel'>
            {errors.globalRole && '*'} Rol Global:
          </label>
          <input
            type='text'
            name='globalRole'
            placeholder='Rol Global'
            className={`formJiraNewInputText ${
              errors.globalRole ? 'errorBorder' : ''
            }`}
            value={formData.globalRole || ''}
            onChange={onChange}
          />
        </div>
      </div>

      <div className='formJiraNewSection_both'>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel'>
            {errors.benchmark && '*'} Benchmark:
          </label>
          <input
            type='text'
            name='benchmark'
            placeholder='Benchmark'
            className={`formJiraNewInputText ${
              errors.benchmark ? 'errorBorder' : ''
            }`}
            value={formData.benchmark || ''}
            onChange={onChange}
          />
        </div>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel'>
            {errors.locationHQ && '*'} Ubicación (HQ):
          </label>
          <input
            type='text'
            name='locationHQ'
            placeholder='Ubicación (HQ)'
            className={`formJiraNewInputText ${
              errors.locationHQ ? 'errorBorder' : ''
            }`}
            value={formData.locationHQ || ''}
            onChange={onChange}
          />
        </div>
      </div>

      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.jobClassification && '*'} Job Classification:
        </label>
        <input
          type='text'
          name='jobClassification'
          placeholder='Job Classification'
          className={`formJiraNewInputText ${
            errors.jobClassification ? 'errorBorder' : ''
          }`}
          value={formData.jobClassification || ''}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default ManagerChannelSection;
