import '../styles/help_us.css';
import elipse355 from '../../images/ellipse355.png';
import image7 from '../../images/image7.png';

const HelpUs = () => {
  return (
    <>
      <section className="helpUs">
        {/* Breadcrumb */}
        <div className="helpUs__breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <h4>COMPARTE TU OPINIÓN</h4>
        </div>

        <div className="helpUs__content">
          <div className="helpUs__content--textbox">
            <h2 className="helpUs__content--title">
              ¿Te gustaría ayudar a mejorar PeoplEX?
            </h2>
            <p className="helpUs__content--description">
              Al equipo de Personas le gustaría conocer tu experiencia y tus
              recomendaciones para mejorar PeoplEX. Completa esta encuesta y
              participa. ¡Gracias!
            </p>
            <button className="helpUs__content--button">
              <a
                href="https://forms.office.com/pages/responsepage.aspx?id=DmBElwQ-Lkm6oSXsJFxvEJOMfffZbDdJpkOfdIaErhhUOTg5MkJYN1RFMjE4M0wwRE8yM0xSWk9YUy4u"
                target="_blank"
                rel="noreferrer"
                className="helpUs__content--link"
              >
                Participar
              </a>
            </button>
          </div>
          <div className="helpUs__imgbox">
            <img src={image7} alt="person-writing" className="helpUs__image" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpUs;
