import React from 'react';

const RecruitmentMetrics = ({ formData, errors, onChange }) => {
  return (
    <div className="formJiraNewSection">
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.totalSSFFApplications && '*'}TOTAL SSFF APPLICATIONS:
          <input
            type="number"
            name="totalSSFFApplications"
            value={formData.totalSSFFApplications}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalSSFFApplications ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalCandidatesDirectSearch && '*'}TOTAL CANDIDATES DIRECT SEARCH:
          <input
            type="number"
            name="totalCandidatesDirectSearch"
            value={formData.totalCandidatesDirectSearch}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalCandidatesDirectSearch ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalCandidatesPhoneScreening && '*'}TOTAL CANDIDATES PHONE SCREENING:
          <input
            type="number"
            name="totalCandidatesPhoneScreening"
            value={formData.totalCandidatesPhoneScreening}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalCandidatesPhoneScreening ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.totalCandidatesSentToHM && '*'}TOTAL CANDIDATES SENT TO HM:
          <input
            type="number"
            name="totalCandidatesSentToHM"
            value={formData.totalCandidatesSentToHM}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalCandidatesSentToHM ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalCandidatesHMValidation && '*'}TOTAL CANDIDATES HM VALIDATION:
          <input
            type="number"
            name="totalCandidatesHMValidation"
            value={formData.totalCandidatesHMValidation}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalCandidatesHMValidation ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalInterviewedCandidatesHM && '*'}TOTAL INTERVIEWED CANDIDATES (HM):
          <input
            type="number"
            name="totalInterviewedCandidatesHM"
            value={formData.totalInterviewedCandidatesHM}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalInterviewedCandidatesHM ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.totalWomenSentToHM && '*'}TOTAL WOMEN SENT TO HM:
          <input
            type="number"
            name="totalWomenSentToHM"
            value={formData.totalWomenSentToHM}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalWomenSentToHM ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalWomenInShortlist && '*'}TOTAL WOMEN IN SHORTLIST:
          <input
            type="number"
            name="totalWomenInShortlist"
            value={formData.totalWomenInShortlist}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalWomenInShortlist ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalYoungCandidates && '*'}TOTAL YOUNG CANDIDATES:
          <input
            type="number"
            name="totalYoungCandidates"
            value={formData.totalYoungCandidates}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalYoungCandidates ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.totalYoungTalentInShortList33 && '*'}TOTAL YOUNG TALENT IN SHORT LIST (33):
          <input
            type="number"
            name="totalYoungTalentInShortListBelow33"
            value={formData.totalYoungTalentInShortListBelow33}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalYoungTalentInShortListBelow33 ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.totalYoungTalentInShortList33to35 && '*'}TOTAL YOUNG TALENT IN SHORT LIST (33-35):
          <input
            type="number"
            name="totalYoungTalentInShortList33to35"
            value={formData.totalYoungTalentInShortList33to35}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.totalYoungTalentInShortList33to35 ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          POSITIONING (OBLIGATORIO: LICENCIADO=29, EXPERTO=32, DESEABLE: JEFE=33, GERENTE=35):
          <input
            type="text"
            name="positioning"
            value={formData.positioning}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.positioning ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel_short">
          {errors.totalDisabilityApplications && '*'}TOTAL DISABILITY APPLICATIONS:
          <input
            type="number"
            name="totalDisabilityApplications"
            value={formData.totalDisabilityApplications}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.totalDisabilityApplications ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_short2">
          {errors.commentsKPIs && '*'}COMMENTS KPIS:
          <textarea
            name="commentsKPIs"
            value={formData.commentsKPIs}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.commentsKPIs ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel_short">
          {errors.numSkills && '*'}Nº SKILLS:
          <input
            type="number"
            name="numSkills"
            value={formData.numSkills}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.numSkills ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_short2">
          {errors.recommendations && '*'}RECOMMENDATIONS:
          <textarea
            name="recommendations"
            value={formData.recommendations}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.recommendations ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel_short">
          {errors.numRegistrationByRecommendation && '*'}Nº REGISTRATION BY RECOMMENDATION:
          <input
            type="number"
            name="numRegistrationByRecommendation"
            value={formData.numRegistrationByRecommendation}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.numRegistrationByRecommendation ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_short2">
         {errors.placement && '*'}PLACEMENT:
          <input
            type="text"
            name="placement"
            value={formData.placement}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.placement ? 'errorBorder' : ''}`}
          />
        </label>
      </div>

    </div>
  );
};

export default RecruitmentMetrics;
