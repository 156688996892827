// import { app } from '@microsoft/teams-js';
// import { useState } from 'react';
// import { TeamsFxContext } from './Context';
// import App from './App';
// import { MsalProvider } from '@azure/msal-react';
// import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { msalConfig } from './authConfig';
// const CLIENT_APP_ID = process.env.REACT_APP_CLIENT_APP_ID;
// const LOGIN_ENDPOINT = process.env.REACT_APP_LOGIN_ENDPOINT;

// export function PeopleEx(props) {
//   const [inTeams, setInTeams] = useState(undefined);

//   const { loading, theme, themeString, teamsUserCredential } =
//     // To-do: change to use ENV
//     useTeamsUserCredential({
//       initiateLoginEndpoint: LOGIN_ENDPOINT,
//       clientId: CLIENT_APP_ID,
//     });

//   const msalInstance = new PublicClientApplication(msalConfig);

//   app
//     .getContext()
//     .then(() => {
//       setInTeams(true);
//     })
//     .catch(() => {
//       setInTeams(false);
//     });

//   return (
//     <>
//       {inTeams === undefined ? (
//         <div>Loading...</div>
//       ) : (
//         <>
//           {inTeams ? (
//             <>
//               <TeamsFxContext.Provider
//                 value={{ theme, themeString, teamsUserCredential }}
//               >
//                 <App isInTeams={true} />
//               </TeamsFxContext.Provider>
//             </>
//           ) : (
//             <MsalProvider instance={msalInstance}>
//               <App isInTeams={false} />
//             </MsalProvider>
//           )}
//         </>
//       )}
//     </>
//   );
// }

import { app } from '@microsoft/teams-js';
import { useState } from 'react';
import { TeamsFxContext } from './Context';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { ThemeContextProvider, getMovistarSkin } from '@telefonica/mistica';

const CLIENT_APP_ID = process.env.REACT_APP_CLIENT_APP_ID;
const LOGIN_ENDPOINT = process.env.REACT_APP_LOGIN_ENDPOINT;

export function PeopleEx(props) {
  const misticaTheme = {
    skin: getMovistarSkin(),
    i18n: { locale: 'es-ES', phoneNumberFormattingRegionCode: 'ES' },
  };

  const [inTeams, setInTeams] = useState(undefined);

  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: LOGIN_ENDPOINT,
    clientId: CLIENT_APP_ID,
  });

  const msalInstance = new PublicClientApplication(msalConfig);

  app
    .getContext()
    .then(() => {
      setInTeams(true);
    })
    .catch(() => {
      setInTeams(false);
    });

  return (
    <>
      {inTeams === undefined ? (
        <div>Loading...</div>
      ) : (
        <ThemeContextProvider theme={misticaTheme}>
          {inTeams ? (
            <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
              <App isInTeams={true} />
            </TeamsFxContext.Provider>
          ) : (
            <MsalProvider instance={msalInstance}>
              <App isInTeams={false} />
            </MsalProvider>
          )}
        </ThemeContextProvider>
      )}
    </>
  );
}
