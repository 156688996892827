import React, { useEffect, useState } from 'react';
import { validateFields } from './validateFields';  

export const SalaryChangeComponent = ({
  salaryChange, setSalaryChange,
  currency, setCurrency,
  bonusOrIncentives, setBonusOrIncentives,
  bonusPercentage, setBonusPercentage,
  incentivesAmount, setIncentivesAmount,
  salaryReason, setSalaryReason,
  errors, setErrors,
}) => {
	const [showWarning, setShowWarning] = React.useState(false);
	const [initialValues, setInitialValues] = useState({});
	
	useEffect(() => {
		setInitialValues({
			salaryChange,
			currency,
			bonusOrIncentives,
			bonusPercentage,
			incentivesAmount,
			salaryReason
		});
	}, []); 

	useEffect(() => {
    const fields = {
      salaryChange,
      currency,
      bonusOrIncentives,
      bonusPercentage,
      incentivesAmount,
      salaryReason
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
	setShowWarning(Object.keys(validationErrors).length > 0);
  }, [
    salaryChange, currency, bonusOrIncentives,
    bonusPercentage, incentivesAmount, salaryReason,
  	]);


  	const getInputClass = (fieldValue, fieldName) => {
		
		if (fieldName === 'salaryReason' || fieldName === 'currency' || fieldName === 'salaryChange') {
			return fieldValue && fieldValue === initialValues[fieldName]
			  ? 'formJiraChangeInputText prefilledBorder'
			  : errors[fieldName]
			  ? 'formJiraChangeInputText errorBorder'
			  : 'formJiraChangeInputText';
		}
		
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeInputText_short prefilledBorder'; 
		} 
		return errors[fieldName] ? 'formJiraChangeInputText_short errorBorder' : 'formJiraChangeInputText_short';
	};

	const radioClass = (fieldValue, fieldName) => {
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeRadio prefilledBorder';
		}
		return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
	};

	const renderLabel = (labelText, fieldName) => {
		return (
		  <label className="formJiraChangeLabel">
			{errors[fieldName] && '*'} {labelText}
		  </label>
		);
	};


  	useEffect(() => {
	if ( incentivesAmount ) {
		setBonusOrIncentives('incentives')
	} else if ( bonusPercentage ) {
		setBonusOrIncentives('bonus')
	}
	}, [incentivesAmount]);


  	return (
    <div className="formJiraChangeCheckboxGroup_changes">
      <div className='formJiraChangeInfoTittle'>Cambios salariales</div>
      <div className="formJiraChangeSection_both">
	  	<label className="formJiraChangeLabel_bothIndividual">
				{errors.salaryChange && '*'}Salario Fijo Anual:
			<input
			type="number"
			className={getInputClass(salaryChange, 'salaryChange')}
			value={salaryChange}
			onChange={e => setSalaryChange(e.target.value)}
			placeholder="Salario anual..."
			min={0}
			/>
		</label>
		<label className="formJiraChangeLabel_bothIndividual">
				{errors.currency && '*'}Moneda:
			<select
			className={getInputClass(currency, 'currency')}
			value={currency}
			onChange={e => setCurrency(e.target.value)}
			>
			<option value="" disabled>Seleccione una moneda...</option>
			<option value="USD">USD - Dólar Estadounidense</option>
			<option value="EUR">EUR - Euro</option>
			<option value="GBP">GBP - Libra Esterlina</option>
			<option value="BRL">BRL - Real Brasileño</option>
			<option value="COP">COP - Peso Colombiano</option>
			<option value="PEN">PEN - Sol Peruano</option>
			<option value="VES">VES - Bolívar Venezolano</option>
			<option value="ARS">ARS - Peso Argentino</option>
			<option value="UYU">UYU - Peso Uruguayo</option>
			<option value="MXN">MXN - Peso Mexicano</option>
			<option value="PYG">PYG - Guaraní Paraguayo</option>
			</select>
		</label>
      </div>
      <div className="formJiraChangeSection">
        <label className="formJiraChangeLabel_short">
			{renderLabel('Bonus o Incentivos Comerciales:', 'bonusOrIncentives')}
		</label>
        <div className="formJiraChangeRadioGroup">
          <label className='formJiraChangeLabelRadio'>
            <input
              type="radio"
              name="bonusOrIncentives"
              className={radioClass(bonusOrIncentives, 'bonusOrIncentives')}
              value="bonus"
              checked={bonusOrIncentives === 'bonus'}
              onChange={() => setBonusOrIncentives('bonus')}
            />
            {renderLabel('Bonus', 'bonusPercentage')}
          </label>
          <label className='formJiraChangeLabelRadio'>
            <input
              type="radio"
              name="bonusOrIncentives"
              className={radioClass(bonusOrIncentives, 'bonusOrIncentives')}
              value="incentives"
              checked={bonusOrIncentives === 'incentives'}
              onChange={() => setBonusOrIncentives('incentives')}
            />
            {renderLabel('Incentivos Comerciales', 'incentivesAmount')}
          </label>
        </div>

        {bonusOrIncentives === 'bonus' && (
          <div>
            <label className="formJiraChangeLabel">
				{renderLabel('% de Bonus', 'bonusPercentage')}
			</label>
            <input
              type="number"
              className={getInputClass(bonusPercentage, 'bonusPercentage')}
              value={bonusPercentage}
              onChange={e => setBonusPercentage(e.target.value)}
              placeholder="% de bonus..."
              min={0}
            />
          </div>
        )}

        {bonusOrIncentives === 'incentives' && (
          <div>
            <label className="formJiraChangeLabel">
				{renderLabel('Cantidad de Incentivos Comerciales:', 'incentivesAmount')}
			</label>
            <input
              type="number"
              className={getInputClass(incentivesAmount, 'incentivesAmount')}
              value={incentivesAmount}
              onChange={e => setIncentivesAmount(e.target.value)}
              placeholder="Cantidad de incentivos..."
              min={0}
            />
          </div>
        )}
      </div>
      <div className="formJiraChangeSection">
        <label className="formJiraChangeLabel">
			{renderLabel('¿Cuál es el motivo del cambio?,', 'salaryReason')}
		</label>
        <input
          type="text"
          className={getInputClass(salaryReason, 'salaryReason')}
          value={salaryReason}
          onChange={e => setSalaryReason(e.target.value)}
          placeholder="Motivo del cambio..."
        />
      </div>
	  {showWarning && (
			<div className="formJiraChangeWarningMessage">
				*Es necesario completar todos los campos indicados en rojo
			</div>
		)}
    </div>
  	);
};
