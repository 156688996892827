import CryptoJS from 'crypto-js';
import DOMPurify from 'dompurify';
import { createJiraTicket } from '../../../../services/apiJira'; // Asegúrate de importar la función correcta

const secretKey = 'your-secret-key'; // Cambia esto por una clave segura

const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, secretKey).toString();
};

export const handleSubmit = async (
    e,
    formData,
    setIsSubmitting,
    handleClear,
    setErrors,
    ValidateForm
) => {
    e.preventDefault();

    // Validar los datos del formulario
    const validationErrors = ValidateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
        setIsSubmitting(true);

        try {
            // Sanitizar y encriptar los campos del formulario
            const sanitize = (value) => DOMPurify.sanitize(value || '');

            const encryptedFields = {
                businessUnit: encryptValue(sanitize(formData.businessUnit)),
                division: encryptValue(sanitize(formData.division)),
                departamento: encryptValue(sanitize(formData.departamento)),
                needType: encryptValue(sanitize(formData.needType)),
                vacancyType: encryptValue(sanitize(formData.vacancyType)),
                replacementName: encryptValue(sanitize(formData.replacementName)),
                replaceByIntern: encryptValue(sanitize(formData.replaceByIntern)),
                internshipWindow: encryptValue(sanitize(formData.internshipWindow)),
                postInternship: encryptValue(sanitize(formData.postInternship)),
                rationale: encryptValue(sanitize(formData.rationale)),
                criticality: encryptValue(sanitize(formData.criticality)),
                responsibilities: encryptValue(sanitize(formData.responsibilities)),
                skills: encryptValue(sanitize(formData.skills)),
                project: encryptValue(sanitize(formData.project)),
                location: encryptValue(sanitize(formData.location)),
                desiredDate: encryptValue(sanitize(formData.desiredDate)),
                hiringManager: encryptValue(sanitize(formData.hiringManager)),
                costCenter: encryptValue(sanitize(formData.costCenter)),
                globalRole: encryptValue(sanitize(formData.globalRole)),
                benchmark: encryptValue(sanitize(formData.benchmark)),
                locationHQ: encryptValue(sanitize(formData.locationHQ)),
                jobClassification: encryptValue(sanitize(formData.jobClassification)),
                vacancySSFFID: encryptValue(sanitize(formData.vacancySSFFID)),
                roleTitle: encryptValue(sanitize(formData.roleTitle)),
                criticalProfile: encryptValue(sanitize(formData.criticalProfile)),
                hrbp: encryptValue(sanitize(formData.hrbp)),
                type: encryptValue(sanitize(formData.type)),
                legalEntity: encryptValue(sanitize(formData.legalEntity)),
                finalCandidateName: encryptValue(sanitize(formData.finalCandidateName)),
                corporateMail: encryptValue(sanitize(formData.corporateMail)),
                personalEmail: encryptValue(sanitize(formData.personalEmail)),
                finalLocalCategoryGradeOfHiring: encryptValue(sanitize(formData.finalLocalCategoryGradeOfHiring)),
                finalGlobalCategoryGradeOfHiring: encryptValue(sanitize(formData.finalGlobalCategoryGradeOfHiring)),
                countryFinalCandidate: encryptValue(sanitize(formData.countryFinalCandidate)),
                needsInternationalMobility: encryptValue(sanitize(formData.needsInternationalMobility)),
                salary: encryptValue(sanitize(formData.salary)),
                targetBonus: encryptValue(sanitize(formData.targetBonus)),
                currency: encryptValue(sanitize(formData.currency)),
                isContractor: encryptValue(sanitize(formData.isContractor)),
                revolvingDoors: encryptValue(sanitize(formData.revolvingDoors)),
                contractType: encryptValue(sanitize(formData.contractType)),
            };

            // Preparar los datos del ticket
            const ticketData = {
                summary: `Solicitud de Alta - Manager`,
                issueType: 'Alta - Manager',
                priority: 'Medium',
                ...encryptedFields
            };

            // Eliminar campos encriptados que tengan valor `undefined` para evitar errores en la API
            Object.keys(ticketData).forEach(key => {
                if (ticketData[key] === '' || ticketData[key] === 0) {
                    delete ticketData[key];
                }
            });

            // Enviar el ticket a Jira
            await createJiraTicket(ticketData);

            // Limpiar el formulario y mostrar mensaje de éxito
            handleClear();
            setErrors({});
            alert('Formulario enviado correctamente');
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        } finally {
            setIsSubmitting(false);
        }
    } else {
        setErrors(validationErrors);
    }
};
