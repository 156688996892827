export const getEmployeeSuggestions = (value, usersInfoSSFF) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : usersInfoSSFF.filter(
        (user) =>
          user.displayName.toLowerCase().includes(inputValue) ||
          user.username.toLowerCase().includes(inputValue) ||
          user.userId.toLowerCase().includes(inputValue)
      );
};

export const getManagerSuggestions = (value, usersInfoSSFF) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : usersInfoSSFF.filter(
        (user) =>
          user.custom15 === 'Y' &&
          (user.displayName.toLowerCase().includes(inputValue) ||
            user.username.toLowerCase().includes(inputValue) ||
            user.userId.toLowerCase().includes(inputValue))
      );
};
