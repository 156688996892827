import React from 'react';
import Autosuggest from 'react-autosuggest';

// Componente para buscar empleados provenientes de SSFF y mostrar sus detalles en el formulario de cambio.
const EmployeeSearch = ({
  employeeSuggestions,
  inputProps,
  selectedUser,
  setSelectedUser,
  setEmployeeName,
  handleChange,
  getSuggestionValue,
  renderSuggestion,
  handleSuggestionsFetchRequested,
  handleSuggestionsClearRequested,
}) => (
  <div className='formArticleCheckboxGroup_change'>
    <label htmlFor='employeeName'>Nombre del Empleado:</label>
    <div className='autosuggest-container'>
      <Autosuggest
        className='ticket-list-item'
        suggestions={employeeSuggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={(event, { suggestion }) => {
          setSelectedUser(suggestion);
          setEmployeeName(suggestion.displayName);
        }}
      />
      {selectedUser && (
        <div className='employeeDetails'>
          {/* Nombre del empleado */}
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel_short' htmlFor='employeeName'>
              Nombre:
            </label>
            <input
              type='text'
              className='formJiraChangeInputText_short'
              value={selectedUser.displayName}
              readOnly
            />
          </div>

          {/* ID del empleado */}
          <div className='formJiraChangeSection'>
            <label className='formJiraChangeLabel_short' htmlFor='employeeId'>
              ID:
            </label>
            <input
              type='text'
              className='formJiraChangeInputText_short'
              value={selectedUser.userId}
              readOnly
            />
          </div>

          {/* Email del empleado */}
          <div className='formJiraChangeSection'>
            <label
              className='formJiraChangeLabel_short'
              htmlFor='employeeEmail'
            >
              Email:
            </label>
            <input
              type='text'
              className='formJiraChangeInputText_short'
              value={selectedUser.username}
              readOnly
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

export default EmployeeSearch;
