import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

//styles
import '../../styles/headerDG.css';
//images
import userAccount from '../../../images/user-account.png';
import searchIconHeader from '../../../images/search.png';
import searchIconHeaderGrey from '../../../images/search-grey.png';
import folderIcon from '../../../images/folder/folder.png';
import logOutIcon from '../../../images/logOut.svg';
import closeCross from '../../../images/close-regular.svg';
import ExitIcon from '../../../images/jira/arrowLeft.png';
import peoplexLogo from '../../../images/logo_peoplex.png';
import { useMsal } from '@azure/msal-react';

const Header = (props) => {
  const [headerSearcherPlaceholder, setHeaderSearcherPlaceholder] = useState('');
  const { instance } = useMsal();
  const location = useLocation();
  const currentPath = location.pathname;
  const showHeader = currentPath !== '/';
  const showSearcherHeader = currentPath !== '/home';
  const [showPopup, setShowPopup] = useState(false);
  const handlePopupToggle = () => {
    setShowPopup((prevState) => !prevState);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSearcherOpen, setIsSearcherOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggle = (ev) => {
    if (ev.target.classList.contains('header__greyIcon--item')) {
      return;
    }

    if (ev.target.classList.contains('searcherHeader__container')) {
      setIsExpanded(true);
      setIsSearcherOpen(true);
      if (!isExpanded) {
        setHeaderSearcherPlaceholder('');
      }
    }
  };

  const handleChange = (ev) => {
    ev.preventDefault();
    setHeaderSearcherPlaceholder(ev.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.handleFilterSearcher(event.target.value);
      setHeaderSearcherPlaceholder('');
      props.setHandleUpperCategoryIdParam(
        '133' +
          (props.userInfoSSFF.custom15 === 'Y' || props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)' ? ',138' : '') +
          (props.userInfoSSFF.custom15 === 'Y' ? ',40' : '')
      );
      navigate('/search/articles');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('autocompleteData');
    localStorage.removeItem('responseAuth');
    sessionStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    instance.logout();
    navigate('/');
  };

  useEffect(() => {
    const handleGlobalClick = (event) => {
      const isSearcherContainer = event.target.classList.contains('searcherHeader__container');
      const isSearcherInput = event.target.classList.contains('searcherHeader__input');

      if (isSearcherOpen && !isSearcherContainer && !isSearcherInput) {
        setIsSearcherOpen(false);
        setIsExpanded(false);
      }
    };

    document.addEventListener('click', handleGlobalClick);

    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [isSearcherOpen]);

  const handleFolderClick = () => {
    navigate('/folder');
  };

  return (
    <>
      {showHeader && (
        <div className='header'>
          <div className='header-logo'>
            <button onClick={props.onBack} className='header-button' style={{ padding: 0 }}>
              <img src={ExitIcon} alt='Log Out' style={{ width: '24px', height: '24px' }} />
            </button>
            <Link to='/home' className='peoplex_logo_dg'>
              <img src={peoplexLogo} className='peoplex_logo_dg_item' alt='logo-telefonica' />
            </Link>
          </div>

          <div className='header__icons'>
            {showSearcherHeader && (
              <div className={`searcherHeader__container ${isExpanded ? 'expanded' : ''}`} onClick={handleToggle}>
                <img
                  src={searchIconHeaderGrey}
                  alt='search-icon'
                  onClick={(ev) => ev.stopPropagation()}
                  className={`header__greyIcon--item ${isExpanded ? 'expanded' : ''}`}
                />
                <input
                  type='text'
                  placeholder='¿Qué estás buscando?...'
                  autoComplete='off'
                  className={`searcherHeader__input ${isExpanded ? 'expanded' : ''}`}
                  value={headerSearcherPlaceholder}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                <img src={searchIconHeader} alt='search-icon' className={`searcherHeader__container ${isExpanded ? 'expanded lupa' : ''}`} />
              </div>
            )}

            <Link to='/folder' className='folder-icon' onClick={handleFolderClick}>
              <img src={folderIcon} alt='folder-icon' className='header__icon--item' />
            </Link>

            <div className='userAccount__container' onClick={handlePopupToggle}>
              <img src={userAccount} alt='userAccount-icon' className='header__icon--item user-icon' />
            </div>
            {showPopup && (
              <div className='user__popup'>
                <div className='user__popup--close'>
                  <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={handlePopupToggle} />
                </div>
                <div className='user__popup--info'>
                  <p className='user__popup--mail'>{props.userInfoSSFF.username}</p>
                </div>
                <div className='user__popup--logOut' onClick={handleLogout}>
                  <img className='user__popup--logOutimg' src={logOutIcon} alt='logOuticon' />
                  <button className='user__popup--logOutbutton' onClick={handlePopupToggle}>
                    Log Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
