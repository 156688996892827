import React from 'react';

const RecruitmentProfile = ({ formData, errors, onChange }) => {
  return (
    <div className="formJiraNewSection">
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
           {errors.vacancySSFFID && '*'}SSFF ID de la vacante:
          <input
            type="text"
            name="vacancySSFFID"
            value={formData.vacancySSFFID}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.vacancySSFFID ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
           {errors.dateUp && '*'}DATE UP:
          <input
            type="date"
            name="dateUp"
            value={formData.dateUp}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.dateUp ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.status && '*'}Estatus:
          <select
            name="status"
            value={formData.status}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.status ? 'errorBorder' : ''}`}
          >
            <option value="">Select Status</option>
            <option value="Active">Active</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Filled">Filled</option>
          </select>
        </label>
	  </div>
	  <div className='formJiraNewSection'>
        <label className="formJiraNewLabel">
          {errors.roleTitle && '*'}ROLE TITLE:
          <input
            type="text"
            name="roleTitle"
            value={formData.roleTitle}
            onChange={onChange}
            className={`formJiraNewInputText ${errors.roleTitle ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_both'>
			<label className="formJiraNewLabel">
			{errors.criticalProfile && '*'}CRITICAL PROFILE:
			<input
				type="text"
				name="criticalProfile"
				value={formData.criticalProfile}
				onChange={onChange}
				className={`formJiraNewInputText_short ${errors.criticalProfile ? 'errorBorder' : ''}`}
			/>
			</label>
			<label className="formJiraNewLabel_short">
			{errors.profileType && '*'}PROFILE:
				<select
					name="profileType"
					value={formData.profileType}
					onChange={onChange}
					className={`formJiraNewInputText_short ${errors.profileType ? 'errorBorder' : ''}`}
					>
					<option value="">Select Profile Type</option>
					<option value="Technical">Technical</option>
					<option value="Non-Technical">Non-Technical</option>
				</select>
			</label>
	  </div>
      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.recruiter && '*'}RECRUITER:
          <input
            type="text"
            name="recruiter"
            value={formData.recruiter}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.recruiter ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_short2">
          {errors.hrbp && '*'}HRBP:
          <input
            type="text"
            name="hrbp"
            value={formData.hrbp}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.hrbp ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_both'>
        <label className="formJiraNewLabel">
          {errors.recruitmentManager && '*'}RECRUITMENT MANAGER:
          <input
            type="text"
            name="recruitmentManager"
            value={formData.recruitmentManager}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.recruitmentManager ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel">
          {errors.recruitmentStatus && '*'}RECRUITMENT STATUS:
          <input
            type="text"
            name="recruitmentStatus"
            value={formData.recruitmentStatus}
            onChange={onChange}
            className={`formJiraNewInputText_short ${errors.recruitmentStatus ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
      <div className='formJiraNewSection'>
        <label className="formJiraNewLabel">
          {errors.commentsFromRecruiters && '*'}COMMENTS FROM RECRUITERS:
          <textarea
            name="commentsFromRecruiters"
            value={formData.commentsFromRecruiters}
            onChange={onChange}
            className={`formJiraNewInputText ${errors.commentsFromRecruiters ? 'errorBorder' : ''}`}
          />
        </label>
	  </div>
	  <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.type && '*'}TYPE:
          <select
            name="type"
            value={formData.type}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.type ? 'errorBorder' : ''}`}
          >
            <option value="">Select Type</option>
            <option value="Permanente">Permanente</option>
            <option value="Temporal">Temporal</option>
          </select>
        </label>
        <label className="formJiraNewLabel_three">
           {errors.localGrade && '*'}LOCAL GRADE:
          <input
            type="text"
            name="localGrade"
            value={formData.localGrade}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.localGrade ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.globalGrade && '*'}GLOBAL GRADE:
          <input
            type="text"
            name="globalGrade"
            value={formData.globalGrade}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.globalGrade ? 'errorBorder' : ''}`}
          />
        </label>
      </div>
      <div className='formJiraNewSection_three'>
        <label className="formJiraNewLabel_three">
          {errors.legalEntity && '*'}LEGAL ENTITY:
          <input
            type="text"
            name="legalEntity"
            value={formData.legalEntity}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.legalEntity ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.country && '*'}COUNTRY:
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.country ? 'errorBorder' : ''}`}
          />
        </label>
        <label className="formJiraNewLabel_three">
          {errors.strategy && '*'}STRATEGY:
          <select
            name="strategy"
            value={formData.strategy}
            onChange={onChange}
            className={`formJiraNewInputText_three ${errors.strategy ? 'errorBorder' : ''}`}
          >
            <option value="">Select Strategy</option>
            <option value="Interno">Interno</option>
            <option value="Interno y Externo">Interno y Externo</option>
          </select>
        </label>
      </div>

    </div>
  );
};

export default RecruitmentProfile;
