import React from 'react';

const IncorporationNeedChannel = ({ formData, errors, onChange }) => {
  return (
    <div className='formJiraNewSection'>
      <div className='formJiraNewRadio'>
        <label className='formJiraNewLabel'>
          {errors.needType && '*'} ¿Cuál es la necesidad de incorporación?
        </label>
        <div className='formJiraNewRadio_options'>
          <label className='formJiraNewRadioLabel'>
            <input
              type='radio'
              name='needType'
              className={`formJiraNewRadio ${
                errors.needType ? 'errorBorder' : ''
              }`}
              value='vacante'
              checked={formData.needType === 'vacante'}
              onChange={onChange}
            />
            Vacante
          </label>
          <label className='formJiraNewRadioLabel'>
            <input
              type='radio'
              name='needType'
              className={`formJiraNewRadio ${
                errors.needType ? 'errorBorder' : ''
              }`}
              value='beca'
              checked={formData.needType === 'beca'}
              onChange={onChange}
            />
            Beca
          </label>
        </div>
      </div>

      {formData.needType === 'vacante' && (
        <div>
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'>
              {errors.vacancyType && '*'} Tipo de Vacante:
            </label>
            <div className='formJiraNewRadio_options'>
              <label className='formJiraNewRadioLabel'>
                <input
                  type='radio'
                  name='vacancyType'
                  className={`formJiraNewRadio ${
                    errors.vacancyType ? 'errorBorder' : ''
                  }`}
                  value='nueva'
                  checked={formData.vacancyType === 'nueva'}
                  onChange={onChange}
                />
                Nueva Vacante
              </label>
              <label className='formJiraNewRadioLabel'>
                <input
                  type='radio'
                  name='vacancyType'
                  className={`formJiraNewRadio ${
                    errors.vacancyType ? 'errorBorder' : ''
                  }`}
                  value='sustitucion'
                  checked={formData.vacancyType === 'sustitucion'}
                  onChange={onChange}
                />
                Sustitución
              </label>
              <label className='formJiraNewRadioLabel'>
                <input
                  type='radio'
                  name='vacancyType'
                  className={`formJiraNewRadio ${
                    errors.vacancyType ? 'errorBorder' : ''
                  }`}
                  value='internalizacion'
                  checked={formData.vacancyType === 'internalizacion'}
                  onChange={onChange}
                />
                Internalización de un Becario
              </label>
            </div>
          </div>

          {formData.vacancyType === 'sustitucion' && (
            <div className='formJiraNewSection'>
              <label className='formJiraNewLabel'>
                {errors.replacementName && '*'} Nombre y Apellidos de la Persona
                a Sustituir:
              </label>
              <input
                type='text'
                name='replacementName'
                placeholder='Nombre y Apellidos'
                className={`formJiraNewInputText_short ${
                  errors.replacementName ? 'errorBorder' : ''
                }`}
                value={formData.replacementName || ''}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      )}

      {formData.needType === 'beca' && (
        <div>
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'>
              {errors.internshipWindow && '*'} Ventana de Incorporación Deseada:
            </label>
            <input
              type='text'
              name='internshipWindow'
              placeholder='Ventana de Incorporación'
              className={`formJiraNewInputText_short ${
                errors.internshipWindow ? 'errorBorder' : ''
              }`}
              value={formData.internshipWindow || ''}
              onChange={onChange}
            />
          </div>
          <div className='formJiraNewSection'>
            <label className='formJiraNewLabel'>
              {errors.postInternship && '*'} ¿Opciones Reales de Incorporación
              en Plantilla Después de la Beca?
            </label>
            <div className='formJiraNewRadio_options'>
              <label className='formJiraNewRadioLabel'>
                <input
                  type='radio'
                  name='postInternship'
                  className={`formJiraNewRadio ${
                    errors.postInternship ? 'errorBorder' : ''
                  }`}
                  value='si'
                  checked={formData.postInternship === 'si'}
                  onChange={onChange}
                />
                Sí
              </label>
              <label className='formJiraNewRadioLabel'>
                <input
                  type='radio'
                  name='postInternship'
                  className={`formJiraNewRadio ${
                    errors.postInternship ? 'errorBorder' : ''
                  }`}
                  value='no'
                  checked={formData.postInternship === 'no'}
                  onChange={onChange}
                />
                No
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncorporationNeedChannel;

/* import React, { useEffect, useState } from 'react';

const IncorporationNeed = ({ formData, setFormData, errors }) => {
   const [initialValues, setInitialValues] = useState({});
   

   useEffect(() => {
       setInitialValues({ ...formData });
   }, []);

   const getInputClass = (fieldValue, fieldName) => {
       if (fieldValue && fieldValue === initialValues[fieldName]) {
           return 'formJiraInputText prefilledBorder';
       }
       return errors[fieldName] ? 'formJiraInputText errorBorder' : 'formJiraInputText';
   };

   const getRadioClass = (fieldValue, fieldName) => {
       if (fieldValue && fieldValue === initialValues[fieldName]) {
           return 'formJiraRadioInput prefilledBorder';
       }
       return errors[fieldName] ? 'formJiraRadioInput errorBorder' : 'formJiraRadio';
   };

   const renderLabel = (labelText, fieldName) => {
       return (
           <label className="formJiraLabel">
               {errors[fieldName] && '*'} {labelText}
           </label>
       );
   };

   return (
       <div className="formJiraSection">
           <div className="formJiraRadio">
               {renderLabel('¿Cuál es la necesidad de incorporación?', 'needType')}
               <div className='formJiraRadio_options'>
                   <label className='formJiraRadioLabel'>
                       <input
                           type="radio"
                           name="needType"
                           className={getRadioClass(formData.needType, 'needType')}
                           value="vacante"
                           checked={formData.needType === 'vacante'}
                           onChange={(e) => setFormData({ ...formData, needType: e.target.value })}
                       />
                       Vacante
                   </label>
                   <label className='formJiraRadioLabel'>
                       <input
                           type="radio"
                           name="needType"
                           className={getRadioClass(formData.needType, 'needType')}
                           value="beca"
                           checked={formData.needType === 'beca'}
                           onChange={(e) => setFormData({ ...formData, needType: e.target.value })}
                       />
                       Beca
                   </label>
               </div>
           </div>

           {formData.needType === 'vacante' && (
               <div>
                   <div className="formJiraSection">
                       {renderLabel('Tipo de Vacante:', 'vacancyType')}
                       <div className='formJiraRadio_options'>
                           <label className='formJiraRadioLabel'>
                               <input
                                   type="radio"
                                   name="vacancyType"
                                   className={getRadioClass(formData.vacancyType, 'vacancyType')}
                                   value="nueva"
                                   checked={formData.vacancyType === 'nueva'}
                                   onChange={(e) => setFormData({ ...formData, vacancyType: e.target.value })}
                               />
                               Nueva Vacante
                           </label>
                           <label className='formJiraRadioLabel'>
                               <input
                                   type="radio"
                                   name="vacancyType"
                                   className={getRadioClass(formData.vacancyType, 'vacancyType')}
                                   value="sustitucion"
                                   checked={formData.vacancyType === 'sustitucion'}
                                   onChange={(e) => setFormData({ ...formData, vacancyType: e.target.value })}
                               />
                               Sustitución
                           </label>
                           <label className='formJiraRadioLabel'>
                               <input
                                   type="radio"
                                   name="vacancyType"
                                   className={getRadioClass(formData.vacancyType, 'vacancyType')}
                                   value="internalizacion"
                                   checked={formData.vacancyType === 'internalizacion'}
                                   onChange={(e) => setFormData({ ...formData, vacancyType: e.target.value })}
                               />
                               Internalización de un Becario
                           </label>
                       </div>
                   </div>

                   {formData.vacancyType === 'sustitucion' && (
                       <div className="formJiraSection">
                           {renderLabel('Nombre y Apellidos de la Persona a Sustituir:', 'replacementName')}
                           <input
                               type="text"
                               id="replacementName"
                               placeholder="Nombre y Apellidos"
                               className={getInputClass(formData.replacementName, 'replacementName')}
                               value={formData.replacementName}
                               onChange={(e) => setFormData({ ...formData, replacementName: e.target.value })}
                           />
                       </div>
                   )}
               </div>
           )}

           {formData.needType === 'beca' && (
               <div>
                   <div className="formJiraSection">
                       {renderLabel('Ventana de Incorporación Deseada:', 'internshipWindow')}
                       <input
                           type="text"
                           id="internshipWindow"
                           placeholder="Ventana de Incorporación"
                           className={getInputClass(formData.internshipWindow, 'internshipWindow')}
                           value={formData.internshipWindow}
                           onChange={(e) => setFormData({ ...formData, internshipWindow: e.target.value })}
                       />
                   </div>
                   <div className="formJiraSection">
                       {renderLabel('¿Opciones Reales de Incorporación en Plantilla Después de la Beca?', 'postInternship')}
                       <div className='formJiraRadio_options'>
                           <label className='formJiraRadioLabel'>
                               <input
                                   type="radio"
                                   name="postInternship"
                                   className={getRadioClass(formData.postInternship, 'postInternship')}
                                   value="si"
                                   checked={formData.postInternship === 'si'}
                                   onChange={(e) => setFormData({ ...formData, postInternship: e.target.value })}
                               />
                               Sí
                           </label>
                           <label className='formJiraRadioLabel'>
                               <input
                                   type="radio"
                                   name="postInternship"
                                   className={getRadioClass(formData.postInternship, 'postInternship')}
                                   value="no"
                                   checked={formData.postInternship === 'no'}
                                   onChange={(e) => setFormData({ ...formData, postInternship: e.target.value })}
                               />
                               No
                           </label>
                       </div>
                   </div>
               </div>
           )}
       </div>
   );
};

export default IncorporationNeed;
 */
