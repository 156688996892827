import React from 'react';

const BusinessInfoChannel = ({ formData, errors, onChange }) => {
  return (
    <div className='formJiraNewSection'>
      <div className='formJiraNewSection_both'>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel_short'>
            {errors.businessUnit && '*'} Unidad de Negocio (BU):
          </label>
          <input
            type='text'
            name='businessUnit'
            placeholder='Unidad de Negocio del empleado..'
            className={`formJiraNewInputText_short ${
              errors.businessUnit ? 'errorBorder' : ''
            }`}
            value={formData.businessUnit}
            onChange={onChange}
          />
        </div>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel_short'>
            {errors.division && '*'} División:
          </label>
          <input
            type='text'
            name='division'
            placeholder='División del empleado...'
            className={`formJiraNewInputText_short ${
              errors.division ? 'errorBorder' : ''
            }`}
            value={formData.division}
            onChange={onChange}
          />
        </div>
      </div>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.departamento && '*'} Departamento:
        </label>
        <input
          type='text'
          name='departamento'
          placeholder='Departamento'
          className={`formJiraNewInputText ${
            errors.departamento ? 'errorBorder' : ''
          }`}
          value={formData.departamento}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default BusinessInfoChannel;

/* import React, { useEffect, useState } from 'react';

const BusinessInfo = ({ formData, setFormData, errors, setErrors }) => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({ ...formData });
  }, [formData]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraInputText_three prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraInputText_three errorBorder' : 'formJiraInputText_three';
  };

  const renderLabel = (labelText, fieldName) => {
    return (
      <label className="formJiraLabel_three">
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  return (
    <div className='formJiraSection'>
      <div className="formJiraSection_one">
        {renderLabel('Unidad de Negocio:', 'businessUnit')}
        <input
          type="text"
          id="businessUnit"
          placeholder="Unidad de Negocio"
          className={getInputClass(formData.businessUnit, 'businessUnit')}
          value={formData.businessUnit}
          onChange={(e) => setFormData({ ...formData, businessUnit: e.target.value })}
        />
	  </div>
	  <div className="formJiraSection_one">
        {renderLabel('División:', 'division')}
        <input
          type="text"
          id="division"
          placeholder="División"
          className={getInputClass(formData.division, 'division')}
          value={formData.division}
          onChange={(e) => setFormData({ ...formData, division: e.target.value })}
        />
	  </div>
	  <div className="formJiraSection_one">
        {renderLabel('Departamento:', 'departamento')}
        <input
          type="text"
          id="departamento"
          placeholder="Departamento"
          className={getInputClass(formData.departamento, 'departamento')}
          value={formData.departamento}
          onChange={(e) => setFormData({ ...formData, departamento: e.target.value })}
        />
      </div>
    </div>
  );
};

export default BusinessInfo;
 */
