import React, { useState, useEffect } from 'react';
import EmployeeSearch from './employeeSearch';
import TeamSearch from './teamSearch';
import { validateFields } from './formValidate';

const GeneralInfoComponent = ({
  searchType,
  setSearchType,
  setValue,
  setSelectedUser,
  employeeSuggestions,
  inputProps,
  selectedUser,
  setEmployeeName,
  handleChange,
  getSuggestionValue,
  renderSuggestion,
  handleSuggestionsFetchRequested,
  handleSuggestionsClearRequested,
  managerSuggestions,
  managedEmployees,
  effectiveDate,
  setEffectiveDate,
  showEffectiveDateInfo,
  setShowEffectiveDateInfo,
  showTEffectiveDateRequired,
  managerName,
  managerEmail,
  employeeName,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setInitialValues({
      effectiveDate,
    });
  }, []);

  useEffect(() => {
    const fields = { effectiveDate };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [effectiveDate]);

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    setValue('');
    setSelectedUser(null);
    setShowPopup(true);
  };

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText_short prefilledBorder';
    }
    return errors[fieldName]
      ? 'formJiraChangeInputText_short errorBorder'
      : 'formJiraChangeInputText_short';
  };

  const handleEffectiveDateChange = (e) => {
    const selectedDate = e.target.value;
    setEffectiveDate(selectedDate);
    setShowEffectiveDateInfo(false);

    const today = new Date();
    const selectedDateObject = new Date(selectedDate);

    if (selectedDateObject < today) {
      setShowEffectiveDateInfo(true);
    }
  };

  //   console.log('managerName:', managerName);
  //   const name = managerName.split(' ')[0];
  //   const surname = managerName.split(' ').slice(1).join(' ');
  //   console.log('managerName:', managerName);
  //   console.log('name:', name);
  //   console.log('surname:', surname);
  /*const getSafeString = (value) => (typeof value === 'string' ? value : '');
  
  const employeeName = capitalizeFirstLetter(
    getSafeString(selectedUser?.displayName) || ''
  );

  const managerName = capitalizeFirstLetter(
    getSafeString(userInfoSSFF?.displayName).split(' ')[0] || ''
  );
  
  const surname = capitalizeFirstLetter(
    getSafeString(userInfoSSFF?.displayName).split(' ').slice(1).join(' ') || ''
  );

  const email = userInfoSSFF?.username || '';*/

  return (
    <div>
      <div className='formJiraChangeSection_searchButton'>
        <button
          type='button'
          className='formJiraChangeButton'
          onClick={() => handleSearchTypeChange('employee')}
        >
          Buscar por Empleado
        </button>
        <button
          type='button'
          className='formJiraChangeButton'
          onClick={() => handleSearchTypeChange('team')}
        >
          Buscar por Equipo
        </button>
      </div>

      {showPopup && (
        <div className='dislikePopup-overlay'>
          <div className='dislikePopup-content'>
            {searchType === 'employee' && (
              <EmployeeSearch
                employeeSuggestions={employeeSuggestions}
                inputProps={inputProps}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setEmployeeName={setEmployeeName}
                handleChange={handleChange}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                handleSuggestionsFetchRequested={
                  handleSuggestionsFetchRequested
                }
                handleSuggestionsClearRequested={
                  handleSuggestionsClearRequested
                }
              />
            )}

            {searchType === 'team' && (
              <TeamSearch
                managerSuggestions={managerSuggestions}
                inputProps={inputProps}
                managedEmployees={managedEmployees}
                handleChange={handleChange}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                handleSuggestionsFetchRequested={
                  handleSuggestionsFetchRequested
                }
                handleSuggestionsClearRequested={
                  handleSuggestionsClearRequested
                }
              />
            )}
            <button
              className='contactMePopup--button'
              onClick={() => setShowPopup(false)}
            >
              {' '}
              Aceptar
            </button>
          </div>
        </div>
      )}

      <div className='formJiraChangeInfoTittle'>Información General</div>

      {/* Mostrar el nombre del empleado seleccionado */}
      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='employeeName'>
          {' '}
          Empleado Seleccionado
        </label>
        <input
          type='text'
          className='formJiraChangeInputText'
          value={employeeName}
          readOnly
        />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='name'>
          Manager
        </label>
        <input
          type='text'
          className='formJiraChangeInputText'
          value={managerName}
          readOnly
        />
      </div>
      {/* <label className='formJiraChangeLabel_short2' htmlFor='surname'>
          {' '}
          Apellidos{' '}
        </label>
        <input
          type='text'
          className='formJiraChangeInputText'
          value={surname}
          readOnly
        />
      </div>*/}

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='username'>
          Correo
        </label>
        <input
          type='email'
          className='formJiraChangeInputText'
          value={managerEmail}
          readOnly
        />
      </div>

      <div className='formJiraChangeSection'>
        <label className='formJiraChangeLabel_short' htmlFor='effectiveDate'>
          Fecha de efectividad
          {showTEffectiveDateRequired && (
            <p className='formJiraChangeLabel__required'>
              Este es un campo requerido
            </p>
          )}
        </label>
        <input
          type='date'
          className={getInputClass(effectiveDate, 'effectiveDate')}
          value={effectiveDate}
          onChange={(e) => handleEffectiveDateChange(e)}
        />
        {showEffectiveDateInfo && (
          <p className='error'>
            Estas seleccionando una fecha anterior a la actual
          </p>
        )}
      </div>
      {showWarning && (
        <div className='formJiraChangeWarningMessage'>
          *Es necesario completar todos los campos indicados en rojo
        </div>
      )}
    </div>
  );
};

export default GeneralInfoComponent;
