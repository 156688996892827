// utils/ticketUtils.js

export const extractTicketData = (fields, type, attachments = []) => {
  const extractField = (fieldKey) => {
    return fields[fieldKey] || '';
  };

  const baseData = {
    attachments,
  };

  if (type === 'change') {
    return {
      employeeName: extractField('customfield_10328'),
      managerName: extractField('customfield_10329'),
      managerEmail: extractField('customfield_10330'),
      effectiveDate: extractField('customfield_10331'),
      organizationalChange: {
        reorganization: extractField('customfield_10332'),
        newDepartments: extractField('customfield_10333'),
        responsiblePerson: extractField('customfield_10334'),
      },
      roleChange: {
        roleChangeDetails: extractTextField(fields['customfield_10335']),
        managerChange: extractField('customfield_10336'),
        newManager: extractField('customfield_10337'),
      },
      salaryChange: {
        salary: extractField('customfield_10338'),
        currency: extractField('customfield_10339'),
        incentives: extractField('customfield_10340'),
        salaryReason: extractTextField(fields['customfield_10341']),
      },
      locationChange: {
        city: extractField('customfield_10342'),
        country: extractField('customfield_10343'),
        building: extractField('customfield_10344'),
        floor: extractField('customfield_10345'),
      },
      contractChange: extractTextField(fields['customfield_10346']),
      comments: extractTextField(fields['comments']),
    };
  } else if (type === 'termination') {
    return {
      ...baseData,
      employeeJuridic: extractField('customfield_10322'),
      employeeEmail: extractField('customfield_10319'),
      employeeName: extractField('customfield_10318'),
      terminationDate: extractField('customfield_10310'),
      terminationType: extractTerminationType(fields['customfield_10312']),
      duration: extractField('customfield_10320'),
      transferToGroup: extractField('customfield_10317'),
      takenVacation: extractField('customfield_10316'),
      actionPlan: extractField('customfield_10323'),
      phoneLine: extractField('customfield_10321'),
    };
  } else {
    return {}; // Para tipos no reconocidos, retorna un objeto vacío.
  }
};

const extractTerminationType = (field) => {
  return field && field.value ? field.value : ''; // Aquí extraemos el valor "Baja voluntaria sin excedencia sin excedencia"
};
// Extraer el texto del campo
const extractTextField = (field) => {
  if (!field || !field.content || !Array.isArray(field.content)) {
    return '';
  }
  const content = field.content[0];
  return content && content.type === 'paragraph' && content.content[0] && content.content[0].text ? content.content[0].text : '';
};
