import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';

import {
  createTicket,
  USER_TYPE_TO_INBENTA_ID,
  isUserExist,
} from '../../../services/api';

const Advance = ({ userInfoSSFF }) => {
  const [matricula, setMatricula] = useState('');
  const [importe, setImporte] = useState('');
  const [opcion, setOpcion] = useState('nomina');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showMatriculaRequired, setShowMatriculaRequired] = useState(false);
  const [showImporteRequired, setShowImporteRequired] = useState(false);
  const [showOpcionRequired, setShowOpcionRequired] = useState(false);
  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Verificamos que todos los campos tienen valores
    const areFieldsValid =
      matricula.trim() !== '' && importe.trim() !== '' && opcion !== '';
    // Actualizamos el estado del botón de enviar
    setIsButtonEnabled(areFieldsValid);
  }, [matricula, importe, opcion]);

  const handleMatriculaChange = (e) => {
    setMatricula(e.target.value);
    setShowMatriculaRequired(false);
  };

  const handleImporteChange = (e) => {
    setImporte(e.target.value);
    setShowImporteRequired(false);
  };

  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
    setShowOpcionRequired(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid =
      matricula.trim() !== '' && importe.trim() !== '' && opcion !== '';
    if (matricula === '') {
      setShowMatriculaRequired(true);
    }
    if (importe === '') {
      setShowImporteRequired(true);
    }
    if (opcion === '') {
      setShowOpcionRequired(true);
    } else if (areFieldsValid) {
      try {
        setIsSubmitting(true);
        const dataUserInbenta = await isUserExist(userInfoSSFF.username);

        const sanitizedMatricula = DOMpurify.sanitize(matricula);
        const sanitizedImporte = DOMpurify.sanitize(importe);
        const sanitizedOpcion = DOMpurify.sanitize(opcion);

        const message = `
          <div style="background-color: transparent;">
            <div><br /></div>
            <div>1. EMAIL CORPORATIVO<br /><b>${userInfoSSFF.username}</b></div>
            <div><br /></div>
            <div>2. NOMBRE Y APELLIDOS<br /><b>${
              dataUserInbenta.data[0].name
            }</b></div>
            <div><br /></div>
            <div>3. MATRICULA<br /><b>${sanitizedMatricula}</b></div>
            <div><br /></div>
            <div>4. EMPRESA<br /><b>${
              USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName
            }</b></div>
            <div><br /></div>
            <div>5. IMPORTE INTERESADO EN SOLICITAR<br /><b>${sanitizedImporte}</b></div>
            <div><br /></div>
            <div>6. ELIGE LA OPCIÓN DE ANTICIPO QUE QUIERES SOLICITAR<br /><b>${sanitizedOpcion}</b></div>
            <div><br /></div>
            <div><br /></div>
            </div>
            `;

        await createTicket(
          'NUEVA Solicitud ANTICIPO empleados',
          message,
          userInfoSSFF.custom03,
          1,
          [],
          dataUserInbenta.data[0].id
        );

        setMatricula('');
        setImporte('');
        setOpcion('');
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  return (
    <>
      <section className="formArticle">
        <h1 className="formArticle__title">Solicitud anticipo</h1>
        <p className="formArticle__text">
          Recuerda que el importe al que puedes acceder es de hasta el máximo
          devengado en el momento de la solicitud. Si en tu solicitud nos
          solicitas una cantidad superior a la devengada, el importe quedará
          limitado al máximo devengado y no al solicitado.
        </p>
        <form
          target="_blank"
          className="formArticle__form"
          action="#"
          onSubmit={handleSubmit}
        >
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="matricula">
              Matrícula
              {showMatriculaRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={matricula}
              onChange={handleMatriculaChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="importe">
              Importe interesado en solicitar
              {showImporteRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
              <p className="formArticleLabel__comment">
                Recuerda que el importe a anticipar puede ser hasta el máximo
                devengado en el momento de la solicitud
              </p>
            </label>
            <input
              type="text"
              className="formArticleInputText"
              value={importe}
              onChange={handleImporteChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="formArticle__option">
              Elige la opción de anticipo que quieres solicitar
              {showOpcionRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <div className="formArticle_radio">
              <input
                type="radio"
                name="formArticle__option"
                value="Anticipo Nómina"
                defaultChecked
                onChange={handleOpcionChange}
              />
              <label htmlFor="Anticipo Nómina"> Anticipo Nómina</label>
              <br></br>
              <input
                type="radio"
                name="formArticle__option"
                value="Anticipo Paga Extra"
                checked={opcion === 'Anticipo Paga Extra'}
                onChange={handleOpcionChange}
              />
              <label htmlFor="Anticipo Paga Extra"> Anticipo Paga Extra</label>
            </div>
          </div>
          <div className="formArticleButton__section">
            <button
              className={`formArticleButton ${
                isButtonEnabled ? 'enabled' : 'disabled'
              }`}
              type="submit"
            >
              Enviar
            </button>
            {isSubmitting && (
              // SPINNER LOADING
              <div class="loader"></div>
            )}
          </div>
        </form>
        <div className="contactTextContainer">
          <p className="formArticle__contact">
            Para cualquier duda, contacta al buzón{' '}
            <a href="mailto:preguntanos@personas.telefonica.com">
              preguntanos@personas.telefonica.com
            </a>
          </p>
        </div>
        {feedbackPopup && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content">
              <div className="dislikePopup__sectionTitle">
                <h1 className="dislikePopup__sectionTitle--title spacing">
                  Solicitud enviada correctamente
                </h1>
              </div>
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  Nos pondremos en contacto contigo a la mayor brevedad posible
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content failurePopup">
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button failureButton"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Advance;
