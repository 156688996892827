import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const downloadPDFTermination = () => {
  const docDefinition = {
    pageMargins: [40, 60, 40, 60], // Márgenes (izquierda, arriba, derecha, abajo)
    content: [
      { text: 'Aviso', style: 'header' },
      {
        text: '• ¿Tienes gastos profesionales o gastos de tarjeta de empresa pendientes?',
        style: 'question',
      },
      {
        text: 'Recuerda que si tienes gastos pendientes debes liquidarlos cuanto antes.',
        style: 'text',
      },
      {
        text: '• ¿Tienes disponibilidades o guardias pendientes?',
        style: 'question',
      },
      {
        text: 'En el caso de tener disponibilidades o guardias pendientes de percibir, consulta con tu responsable para que las informe cuanto antes para que puedan ser incluidas en la liquidación.',
        style: 'text',
      },
      {
        text: '• ¿Qué ocurre con tu taquilla y con los enseres personales que guardas en ella?',
        style: 'question',
      },
      {
        text: 'La llave de la taquilla deberás devolverla a Servicios Generales. Si pasados 15 días desde tu salida no has retirado tus enseres personales, se dará instrucciones de vaciar la taquilla y destruir de forma confidencial todo lo que se encuentra dentro.',
        style: 'text',
      },
      { text: '• ¿Qué debes hacer con la tarjeta de acceso?', style: 'question' },
      {
        text: 'Deberás devolver la tarjeta de acceso en seguridad el último día que acudas al edificio, una vez que no necesites volver a entrar al edificio',
        style: 'text',
      },
      {
        text: '• ¿Qué debes hacer con tu equipo (portátil, etc)?',
        style: 'question',
      },
      {
        text: 'El equipo It se pondrán en contacto contigo para darte las instrucciones oportunas para la devolución del equipo.',
        style: 'text',
      },
      {
        text: '• ¿Tengo que solicitar la baja si me voy a otra empresa del grupo?',
        style: 'question',
      },
      {
        text: 'Sí, es necesario que solicites la baja a través de PeoplEX. Si tienes dudas en cuanto a la fecha de baja, puedes comentarlo con ambos managers (el de la empresa destino y tu manager actual para ver la fecha más adecuada para las tres partes, siempre teniendo en cuenta los días de preaviso.',
        style: 'text',
      },
      {
        text: '• ¿Necesitas descargar los certificados de formación?',
        style: 'question',
      },
      {
        text: 'Si quieres descargar los certificados de las formaciones que has realizado, deberás hacerlo antes de que tu usuario de SuccessFactors esté desactivado. Para poder acceder al contenido, debes entrar en SuccessFactors -Mis asignaciones de aprendizaje. Una vez dentro, haciendo clic en “Mis Asignaciones” y posteriormente “Historial de Aprendizaje”, “Ver todo”. Aquí podrás ver uno a uno todos los contenidos y descargar el diploma correspondiente. En caso de dudas puedes contactar con training_gbus@telefonica.com.',
        style: 'text',
      },
      {
        text: '• ¿Tienes plan de pensiones con Telefónica?',
        style: 'question',
      },
      {
        text: 'Para movilizar el plan de pensiones a otra entidad, deberás acceder a la web de Fonditel y descargarte la orden de movilización que se encuentra dentro del plan en el apartado: “Más información” -> “Impresos”. A continuación, deberás ponerte en contacto con Fonditel y seguir las instrucciones que te proporcionen.Ten en cuenta que no podrás movilizar el plan de pensiones si solicitas una excedencia hasta que esta finalice o deje de estar vigente.',
        style: 'text',
      },
      {
        text: '• ¿Tienes alguna duda o necesitas modificar datos de tu baja?',
        style: 'question',
      },
      {
        text: 'Si tienes cualquier duda adicional o necesitas modificar algún dato relativo a tu baja, por favor ponte en contacto con preguntanos.personas@telefonica.com indicando el ID de tu solicitud.',
        style: 'text',
      },
      {
        text: '• Consulta la información sobre beneficios en Peoplex.',
        style: 'question',
      },
      {
        text: 'Recuerda que tienes accesible en Peoplex toda la información sobre cómo gestionar tus beneficios de cara a la baja',
        style: 'text',
      },
    ],
    styles: {
      header: {
        fontSize: 16, // Tamaño de letra más pequeño
        bold: true,
        margin: [0, 0, 0, 10],
      },
      question: {
        fontSize: 14, // Tamaño de letra más pequeño
        bold: true,
        margin: [0, 10, 0, 5],
      },
      text: {
        fontSize: 12, // Tamaño de letra más pequeño
        margin: [0, 0, 0, 10],
        // Agregar viñetas
        // Para un estilo más completo, puedes definir una lista
        // text: '• {text}',
      },
    },
  };

  // Genera y descarga el PDF
  pdfMake.createPdf(docDefinition).download('informacion.pdf');
};
