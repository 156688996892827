import React from 'react';

export const CommentsComponent = ({
  comments,
  setComments,
  handleCommentsChange,
  showCommentsRequired,
  setShowCommentsRequired,
}) => (
  <div className='formArticleSection'>
    <label className='formArticleLabel'>
      Comentarios
      {showCommentsRequired && (
        <p className='formArticleLabel__required'>Este es un campo requerido</p>
      )}
    </label>
    <textarea
      className='formArticleTextarea'
      value={comments}
      onChange={(e) =>
        handleCommentsChange(e, setComments, setShowCommentsRequired)
      }
    />
  </div>
);
