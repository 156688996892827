export const fetchData = async (endpoint, setData) => {
  try {
    let idToken = localStorage.getItem('idToken');

    if (!idToken) {
      throw new Error('No se encontró el idToken en localStorage');
    }
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: { 'x-msal-Authorization': idToken },
    });
    const data = await response.json();
    if (response.ok) {
      setData(data);
    } else {
      console.error(`Error fetching ${endpoint}:`, response.statusText);
    }
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
  }
};

export const readFileAsBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64Result = reader.result.split(',')[1];
      resolve(base64Result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const ALLOWED_TYPE_FILES = ['doc', 'pdf', 'docx', 'jpg', 'jpeg', 'png'];

export function base64ToBlob(base64, mimeType) {
  console.log('mime type', mimeType);
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

// Función para convertir URL a Base64
export const urlToBase64 = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Error al obtener el archivo: ${response.statusText}`);
  }
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(',')[1]); // Obtener solo la parte base64
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
