import React, { useState } from 'react';
import {
  getUserInfoSSFF,
  getUserInfoSSFFId,
} from '../../../../../services/apiSSFF';

// Componente para buscar equipos provenientes de SSFF y mostrar sus detalles en el formulario de cambio. La idea es que el ususario escibre el id, nombre o email del manager y se muestren los empleados a su cargo.
const TeamSearch = () => {
  const [managerInput, setManagerInput] = useState('');
  const [managedEmployees, setManagedEmployees] = useState([]);
  const [error, setError] = useState('');

  // Función para verificar si el input parece un email
  const isEmail = (input) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      let userInfo;

      if (isEmail(managerInput)) {
        userInfo = await getUserInfoSSFF(managerInput);
      } else {
        userInfo = await getUserInfoSSFFId(managerInput);
      }
      const userResult = userInfo.userInformation.d.results;

      if (userResult) {
        const { displayName, username, userId } = userResult;
        setManagedEmployees([{ displayName, username, userId }]);
        console.log('Resultados obtenidos manager:', userResult);
        setError('');
      } else {
        setError('No se encontraron empleados a cargo de este manager.');
      }
    } catch (err) {
      setError('Error al realizar la solicitud: ' + err.message);
      setManagedEmployees([]);
    }
  };

  return (
    <div className='formJiraChangeCheckboxGroup_change'>
      <form onSubmit={handleSearch}>
        <label className='formJiraLabel'>ID o Email del Manager:</label>
        <input
          type='text'
          className='formJiraChangeInputText_Manager'
          id='managerInput'
          value={managerInput}
          onChange={(e) => setManagerInput(e.target.value)}
          required
        />
        <button type='submit' className='secondaryButton'>
          Buscar Equipo
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {managedEmployees.length > 0 && (
        <div className='managedEmployeesList'>
          <h3>Empleados a su cargo:</h3>
          <ul>
            {managedEmployees.map((employee) => (
              <li key={employee.userId}>
                <input
                  type='checkbox'
                  id={`employee-${employee.userId}`}
                  value={employee.userId}
                />
                <label htmlFor={`employee-${employee.userId}`}>
                  {employee.displayName} - {employee.username}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TeamSearch;

/*import React from 'react';
import Autosuggest from 'react-autosuggest';

const TeamSearch = ({
  managerSuggestions,
  handleSuggestionsFetchRequested,
  handleSuggestionsClearRequested,
  getSuggestionValue,
  renderSuggestion,
  inputProps,
  managedEmployees,
  handleChange,
}) => (
  <div className="formArticleCheckboxGroup_change">
    <label htmlFor="managerName">Nombre del Manager:</label>
    <Autosuggest
      suggestions={managerSuggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={(event, { suggestion }) => handleChange(event, { newValue: suggestion.displayName, suggestion })}
    />
    {managedEmployees.length > 0 && (
      <div className="managedEmployeesList">
        <h3>Empleados a su cargo:</h3>
        <ul>
          {managedEmployees.map((employee) => (
            <li key={employee.userId}>
              <input
                type="checkbox"
                id={`employee-${employee.userId}`}
                value={employee.userId}
              />
              <label htmlFor={`employee-${employee.userId}`}>
                {employee.displayName} - {employee.username}
              </label>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

export default TeamSearch;*/
