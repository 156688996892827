import DOMpurify from 'dompurify';
import CryptoJS from 'crypto-js';
import { createJiraTicket } from '../../../../../../../services/apiJira';
import { validateFields } from './validateFields';

const secretKey = 'your-secret-key'; 

const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value, secretKey).toString();
};

const showSalaryChangeWarning = () => {
    const confirmMessage = 
	
	`Al cambiar el salario, también pueden cambiar los siguientes campos:

    • Local Grade / Categoría Local.
    • Role Change / Cambio De Rol.
    • Benchmark Name / Código Benchmark.

    ¿Desea continuar?`;
    return window.confirm(confirmMessage);
};

// Función de advertencia para cambio organizativo
const showOrgChangeWarning = () => {
    const confirmMessage = 
	`Al realizar cambios organizativos, los siguientes campos podrían sufrir cambios:

    • Business Results Objectives / Resultado Objetivos Negocio.
    • Role Change / Cambio De Rol.
    • Line Manager E-Mail / E-Mail Jefe Directo.
    • Benchmark Name / Código Benchmark.
    • Job Classification / Código.
    • Job Classification / Puesto.

    ¿Desea continuar?`;
    return window.confirm(confirmMessage);
};

// Función de advertencia para cambio de localización
const showLocationChangeWarning = () => {
    const confirmMessage = 
	`Al realizar un cambio de localización, los siguientes campos podrían sufrir cambios:

    • Currency / Moneda.
    • Salary / Salario.
    • Bonus / Bono.
    • Job Classification / Código.
    • Job Classification / Puesto.
    • Legal Entity / Entidad Jurídica.

    ¿Desea continuar?`;
    return window.confirm(confirmMessage);
};

// Función de advertencia para cambio de rol (NO)
const showRoleChangeNoWarning = () => {
    const confirmMessage =
	 `Al no consistir en un cambio de rol,  los siguientes campos podrían sufrir cambios:

    • Local Grade / Categoría Local.
    • Job Classification / Código.
    • Job Classification / Puesto.
    • Business Unit / Unidad De Negocio.
    • Division / División.
    • Department / Departamento.
    • Cost Centre / Centro De Coste.
    • Business Results Objectives / Resultado Objetivos Negocio.
    • Salary / Salario.
    • Bonus / Bono.

    ¿Desea continuar?`;
    return window.confirm(confirmMessage);
};

// Función de advertencia para cambio de rol (SÍ)
const showRoleChangeYesWarning = () => {
    const confirmMessage = 
	`Al realizar un cambio de rol, los siguientes campos podrían sufrir cambios:

    • Local Grade / Categoría Local.
    • Job Classification / Código.
    • Job Classification / Puesto.
    • Benchmark Name / Código Benchmark.
    • Business Unit / Unidad De Negocio.
    • Division / División.
    • Department / Departamento.
    • Cost Centre / Centro De Coste.
    • Business Results Objectives / Resultado Objetivos Negocio.
    • Line Manager E-Mail / E-Mail Jefe Directo.
    • Salary / Salario.
    • Bonus / Bono.

    ¿Desea continuar?`;
    return window.confirm(confirmMessage);
};

const translations = {
    effectiveDate: 'Fecha Efectiva',
    employeeNameDisplay: 'Nombre del Empleado',
    organizationalChange: 'Cambio Organizacional',
    newDepartments: 'Nuevos Departamentos',
    responsiblePerson: 'Persona Responsable',
	hrbp: 'HRBP',
	businessUnit: 'Unidad de Negocio',
	division: 'División',
	department: 'Departamento',
	costCenter: 'Centro de Costos',
	objectivesResult: 'Resultado de Objetivos',
    roleChangeDetails: 'Detalles del Cambio de Rol',
	managerChange: 'Cambio de Manager',
    newManager: 'Nuevo Manager',
    changeDetails: 'Detalles del Cambio',
    salaryChange: 'Cambio de Salario',
    currency: 'Moneda',
    bonusOrIncentives: 'Bono o Incentivos',
    bonusPercentage: 'Porcentaje de Bono',
    incentivesAmount: 'Monto de Incentivos',
    salaryReason: 'Razón del Salario',
    city: 'Ciudad',
    building: 'Edificio',
    floor: 'Piso/Planta',
    country: 'País',
	comments: 'Comentarios',
	contractChange: 'Cambio de Contrato',
	hours: 'Horas/Semana',
	jcTitle: 'Título del Puesto (Job Classification)',
	jcLevel: 'Nivel del Puesto (Job Classification)',
	jcPayGrade: 'Grado de Pago (Job Classification)',
	jcTelefónicaLevel: 'Nivel Telefónica (Job Classification)',
	jcTelefónicaGrade: 'Grado Telefónica (Job Classification)',
	jcLegalEntity: 'Entidad Legal (Job Classification)',
	jobFunction: 'Función del Puesto (Nueva posición)',
	jcCreationDate: 'Fecha de Creación (Nueva posición)',
	manager: 'Manager (Nueva posición)',
	businessUnitPosition: 'Unidad de Negocio (Nueva posición)',
	divisionPosition: 'División (Nueva posición)',
	departmentPosition: 'Departamento (Nueva posición)',
	costCenterPosition: 'Centro de Costos (Nueva posición)',
	globalRolePosition: 'Rol Global (Nueva posición)',
	benchmark: 'Benchmark (Nueva posición)',
	locationPosition: 'Ubicación (Nueva posición)',
	jobClassificationPosition: 'Código (Nueva posición)',
};


const handleSubmit = async (
   e,
   effectiveDate,
   changeType,
   organizationalChange,
   newDepartments,
   responsiblePerson,
   hrbp,
   businessUnit,
   division,
   department,
   costCenter,
   objectivesResult,
   roleChange,
   roleChangeDetails,
   managerChange,
   newManager,
   globalRole,
   jobClassification,
   category,
   benchmarkCode,
   changeDetails,
   salaryChange,
   currency,
   bonusOrIncentives,
   bonusPercentage,
   incentivesAmount,
   salaryReason,
   city,
   country,
   building,
   floor,
   contractChange,
   hours,
   comments,
   jcTitle,
   jcLevel,
   jcPayGrade,
   jcTelefónicaLevel,
   jcTelefónicaGrade,
   jcLegalEntity,
   jobFunction,
   jcCreationDate,
   manager,
   businessUnitPosition,
   divisionPosition,
   departmentPosition,
   costCenterPosition,
   globalRolePosition,
   benchmark,
   locationPosition,
   jobClassificationPosition,
   isButtonEnabled,
   setIsSubmitting,
   handleClearForm,
   setChangeType,
   setFeedbackPopup,
   setMessageSendingFailed,
   employeeName,
   managerEmail,
   managerName,
) => {
   e.preventDefault();

   const fields = {
	businessUnit,
	division,
	department,
	costCenter,
	roleChange,
	changeDetails,
	roleChangeDetails,
	managerChange,
	newManager,
	globalRole,
	jobClassification,
	category,
	benchmarkCode,
	salaryChange,
	currency,
	bonusOrIncentives,
	bonusPercentage,
	incentivesAmount,
	salaryReason,
	organizationalChange,
	newDepartments,
	responsiblePerson,
	hrbp,
	objectivesResult,
	city,
	building,
	floor,
	country,
	jcTitle,
	jcLevel,
	jcPayGrade,
	jcTelefónicaLevel,
	jcTelefónicaGrade,
	jcLegalEntity,
	jobFunction,
	jcCreationDate,
	effectiveDate,
	manager,
	businessUnitPosition,
	divisionPosition,
	departmentPosition,
	costCenterPosition,
	globalRolePosition,
	benchmark,
	locationPosition,
	jobClassificationPosition,
	contractChange,
	hours,
   };

   
   const errors = validateFields(fields);

   if (Object.keys(errors).length > 0) {
	   // Si hay errores, mostrarlos al usuario
	   let errorMessage = 'Por favor complete los siguientes campos:\n';
	   for (const [field, hasError] of Object.entries(errors)) {
		   // Traduce el nombre del campo al español
		   const translatedField = translations[field] || field;
		   errorMessage += `• ${translatedField}\n`;
	   }
	   alert(errorMessage);
	   return;
   }
    

   if (isButtonEnabled) {
		try {
		
		// Mostrar advertencias dependiendo del tipo de cambio
		if (changeType.includes('Salary')) {
			const userConfirmed = showSalaryChangeWarning();
			if (!userConfirmed) return; // Si el usuario cancela, no se envía el ticket
		}

		if (changeType.includes('Organizational')) {
			const userConfirmed = showOrgChangeWarning();
			if (!userConfirmed) return;
		}

		if (changeType.includes('Location')) {
			const userConfirmed = showLocationChangeWarning();
			if (!userConfirmed) return;
		}

		if (changeType.includes('Role') && roleChange === 'no') {
			const userConfirmed = showRoleChangeNoWarning();
			if (!userConfirmed) return;
		}

		if (changeType.includes('Role') && roleChange === 'yes') {
			const userConfirmed = showRoleChangeYesWarning();
			if (!userConfirmed) return;
		}

         setIsSubmitting(true);

         const sanitize = (value) => DOMpurify.sanitize(value || '');

            const fields = {
                employeeName: encryptValue(sanitize(employeeName)),
                manager: encryptValue(sanitize(managerName)),
                emailManager: encryptValue(managerEmail),
                effectiveDate: encryptValue(sanitize(effectiveDate.toString())),
                organizationalChange: encryptValue(sanitize(organizationalChange.toString())),
                newDepartments: encryptValue(sanitize(newDepartments.toString())),
                responsiblePerson: encryptValue(sanitize(responsiblePerson)),
                roleChangeDetails: encryptValue(sanitize(roleChangeDetails)),
                managerChange: encryptValue(sanitize(managerChange.toString())),
                newManager: encryptValue(sanitize(newManager)),
                salaryChange: encryptValue(sanitize(salaryChange)),
                currency: encryptValue(sanitize(currency.toString())),
                incentivesAmount: encryptValue(sanitize(incentivesAmount)),
                salaryReason: encryptValue(sanitize(salaryReason)),
                city: encryptValue(sanitize(city)),
                country: encryptValue(sanitize(country)),
                building: encryptValue(sanitize(building)),
                floor: encryptValue(sanitize(floor)),
                contractChange: encryptValue(sanitize(contractChange)),
            };

            let ticketData = {
                summary: `Cambio`,
                issueType: 'Cambio',
                priority: 'Medium',
            };

            const customFields = {
                'customfield_10328': fields.employeeName,
                'customfield_10329': fields.manager,
                'customfield_10330': fields.emailManager,
                'customfield_10331': fields.effectiveDate,
                'customfield_10332': fields.organizationalChange,
                'customfield_10333': fields.newDepartments,
                'customfield_10334': fields.responsiblePerson,
                'customfield_10335': fields.roleChangeDetails,
                'customfield_10336': fields.managerChange,
                'customfield_10337': fields.newManager,
                'customfield_10338': fields.salaryChange,
                'customfield_10339': fields.currency,
                'customfield_10340': fields.incentivesAmount,
                'customfield_10341': fields.salaryReason,
                'customfield_10342': fields.city,
                'customfield_10343': fields.country,
                'customfield_10344': fields.building,
                'customfield_10345': fields.floor,
                'customfield_10346': fields.contractChange,
            };

            // Eliminar campos encriptados que tengan valor `undefined` para evitar errores en la API
        Object.keys(customFields).forEach(key => {
            if (customFields[key] === '' || customFields[key] === 0) {
                delete customFields[key];
            }
        });

        ticketData = { ...ticketData, ...customFields };

        await createJiraTicket(ticketData);

        handleClearForm();
        setChangeType(['info']);
        setFeedbackPopup(true);
      } catch (error) {
         console.error('Error al enviar el mensaje:', error);
         setMessageSendingFailed(true);
      } finally {
         setIsSubmitting(false);
      }
   }
};

export default handleSubmit;
