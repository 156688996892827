import React from 'react';

export const LabelInputBoth = ({ label, type = "text", value, onChange, error }) => (
	<div className="formJiraChangeSection">  
	  <label className={`formJiraChangeLabel_Manager ${error ? 'errorLabel' : ''}`}>
		 {error && "*"}{label}
	  </label>
	  <input 
		type={type} 
		className={`formJiraChangeInputText_Manager ${error ? 'errorBorder' : ''}`} 
		value={value} 
		onChange={onChange} 
	  />
	</div>
  );


  export const LabelInput = ({ label, type = "text", value, onChange, error }) => (
	<div className="formJiraChangeSection">  
	  <label className={`formJiraChangeLabel ${error ? 'errorLabel' : ''}`}>
	  {error && "*"}{label}
	  </label>
	  <input 
		type={type} 
		className={`formJiraChangeInputText ${error ? 'errorBorder' : ''}`} 
		value={value} 
		onChange={onChange} 
	  />
	</div>
  );
  