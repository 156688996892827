import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import DOMpurify from 'dompurify';
import { updateJiraTicket } from '../../../services/apiJira';

export const EditPopup = ({ ticket, selectedSummary, asignee, selectedStatus, selectedPriority, onClose }) => {
  const secretKey = 'your-secret-key';

  // Función para sanitizar y encriptar valores
  const sanitize = (value) => DOMpurify.sanitize(value);
  const encryptValue = (value) => CryptoJS.AES.encrypt(value, secretKey).toString();

  // Estado del formulario inicializado dinámicamente desde los props
  const [ticketData, setTicketData] = useState({
    issueType: selectedSummary || ticket?.fields?.issuetype?.name || '',
    summary: selectedSummary || ticket?.fields?.summary || '',
    status: selectedStatus || ticket?.fields?.status?.name || '',
    priority: selectedPriority || ticket?.fields?.priority?.name || '',
    asignee: asignee || ticket?.fields?.customfield_10404 || '', // Ajusta según tu mapeo de campos
  });

  // Sincronizar los datos del ticket cada vez que el popup reciba un nuevo ticket
  useEffect(() => {
    if (ticket) {
      setTicketData({
        issueType: ticket?.fields?.issuetype?.name || '',
        summary: ticket?.fields?.summary || '',
        status: ticket?.fields?.status?.name || '',
        priority: ticket?.fields?.priority?.name || '',
        asignee: ticket?.fields?.customfield_10404 || '',
      });
    }
  }, [ticket]);

  // Manejar los cambios en los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Preparamos los campos personalizados para enviar a Jira
  const prepareCustomFields = () => {
    const sanitizedAsignee = sanitize(ticketData.asignee);
    const encryptedAsignee = encryptValue(sanitizedAsignee);

    const customFields = {
      customfield_10404: encryptedAsignee,
    };

    // Eliminar campos vacíos
    Object.keys(customFields).forEach((key) => {
      if (!customFields[key]) {
        delete customFields[key];
      }
    });

    return customFields;
  };

  const handleSave = () => {
    const customFields = prepareCustomFields();
    const updatedTicketData = {
      ...ticketData,
      ...customFields,
    };

    // Llamada para actualizar el ticket en Jira
    console.log('Datos guardados:', updatedTicketData);
    updateJiraTicket(updatedTicketData, ticket.id);
    onClose();
  };

  return (
    <div className='popup'>
      <div className='popup-content'>
        <h3>Editar Ticket - {ticket?.id}</h3>
        <div className='edit-field'>
          <label>Soporte</label>
          <select name='summary' value={ticketData.summary} onChange={handleInputChange}>
            <option value='SOPORTE MANAGER'>MANAGER</option>
            <option value='SOPORTE EMPLEADO'>EMPLEADO</option>
          </select>
        </div>
        <div className='edit-field'>
          <label>Responsable</label>
          <input type='text' name='asignee' value={ticketData.asignee} onChange={handleInputChange} placeholder='Nuevo responsable' />
        </div>
        <div className='edit-field'>
          <label>Estado</label>
          <select name='status' value={ticketData.status} onChange={handleInputChange}>
            <option value='Backlog'>Backlog</option>
            <option value='Selected for Development'>Selected for Development</option>
            <option value='En curso'>In Progress</option>
            <option value='Finalizada'>Done</option>
          </select>
        </div>
        <div className='edit-field'>
          <label>Prioridad</label>
          <select name='priority' value={ticketData.priority} onChange={handleInputChange}>
            <option value='Critical'>Crítica</option>
            <option value='High'>Alta</option>
            <option value='Medium'>Media</option>
            <option value='Low'>Baja</option>
          </select>
        </div>
        <div className='popup-buttons'>
          <button onClick={handleSave}>Guardar</button>
          <button onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};
