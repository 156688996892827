import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

 export const RadioGroup = ({ label, name, options, selectedValue, onChange }) => (
  <div className="formJiraChangeRadio">
    <label className="formJiraChangeRadioLabel">{label}</label>
    <div className="formJiraChangeRadio">
      {options.map((option, index) => (
        <label className='formJiraChangeRadioLabel' key={index}>
          <input
            type="radio"
            name={name}
            className="formJiraChangeRadio"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  </div>
);


export const SwitchGroup = ({ label, checked, onChange }) => (
  <div className="formJiraChangeSection">
    <FormControlLabel
      label={label}
      labelPlacement="start" 
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          sx={{
            '&.Mui-checked': {
              color: 'white', 
              backgroundColor: '#06f', 
            },
            '& .MuiSvgIcon-root': {
              borderRadius: '50%', 
              backgroundColor: checked ? '#06f' : 'transparent', 
              border: '5px solid #06f',
              width: 24,
              height: 24,
            },
          }}
        />
      }
    />
  </div>
);



 /*

import React from 'react';
import Switch from './switchInput'

const RadioGroup = ({ label, name, selectedValue, onChange }) => {
  const handleChange = () => {
    const newValue = !selectedValue;
    onChange(newValue ? 'yes' : 'no'); 
  };

  return (
    <div className="formJiraChangeSection">
      <label className="formJiraChangeLabel">{label}</label>
      <div className="formJiraChangeRadioGroup">
        <Switch
          checked={selectedValue === 'yes'}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default RadioGroup;

*/