// // Este componente 'TimeOutPopup' representa un cuadro emergente que aparece cuando la sesión del usuario está a punto de caducar debido a la inactividad.
// // Se recibe una prop 'isInactive' que indica si la sesión está inactiva y una función 'setIsInactive' para modificar este estado.
// // Si 'isInactive' es verdadero (indicando que la sesión está a punto de caducar), se muestra el cuadro emergente de tiempo de espera.
// // El cuadro emergente contiene un título que informa al usuario sobre la caducidad de la sesión y dos botones:
// // 1. "Permanecer en la sesión": El usuario puede hacer clic en este botón para mantener la sesión activa.
// // 2. "Cancelar": El usuario puede hacer clic en este botón para cerrar el cuadro emergente y finalizar la sesión.

import React, { useState, useEffect } from 'react';
import '../styles/timeOutPopup.css';
import { useNavigate } from 'react-router-dom';

const TimeOutPopup = (
  { isInactive, setIsInactive, handleTokenRefresh },
  props
) => {
  const [countdown, setCountdown] = useState(30);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isInactive && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      handleCloseSession();
    }

    return () => clearTimeout(timer);
  }, [isInactive, countdown, setIsInactive]);

  const handleCloseSession = () => {
    // Restablecer el contador a 30 al cerrar la sesión
    setCountdown(30);
    localStorage.removeItem('autocompleteData');
    localStorage.removeItem('responseAuth');
    sessionStorage.clear();
    navigate('/');
  };

  if (props.isInTeams) {
    return null; // No renderizar nada si estamos en Teams
  }

  return (
    <>
      {isInactive && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="timeOutPopup__sectionTitle">
              <h1 className="timeOutPopup__sectionTitle--title">
                La sesión va a caducar
              </h1>
            </div>
            <div className="timeOutPopup__sectionText">
              <p className="timeOutPopup__sectionText--text">
                Le quedan {countdown} segundos en la sesión. Si desea continuar
                con la navegación y mantener su sesión activa, haga clic en
                "Permanecer en la sesión".
              </p>
            </div>
            <div className="timeOutPopup__sectionButtons">
              <button
                className="timeOutPopup__sectionButtons--button stay"
                onClick={() => {
                  handleTokenRefresh();
                  setIsInactive(false);
                  setCountdown(30);
                }}
              >
                Permanecer en la sesión
              </button>
              <button
                className="timeOutPopup__sectionButtons--button cancel"
                onClick={() => {
                  handleCloseSession();
                  setIsInactive(true);
                }}
              >
                Salir
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TimeOutPopup;
