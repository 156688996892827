import React, { useEffect, useState } from 'react';
import ArticleItem from '../article-item';
import { fetchSearchArticles } from '../../../services/api';

const Article3 = ({ userInfoSSFF, handleUpperCategoryIdParam }) => {
  const [dataArticlesSearcher, setDataArticlesSearcher] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSearchArticlesComponent = async () => {
      setIsLoading(true); // Inicia el estado de carga
      try {
        const searchTerm = '¿Qué es el Plan de Compensación Flexible?';
        const subcategoriesArticlesData = await fetchSearchArticles(searchTerm, handleUpperCategoryIdParam, userInfoSSFF.custom03);

        setDataArticlesSearcher(subcategoriesArticlesData || []);
        console.log('articulo', subcategoriesArticlesData);
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setIsLoading(false); // Finaliza el estado de carga
      }
    };

    fetchSearchArticlesComponent();
  }, [handleUpperCategoryIdParam, userInfoSSFF]);

  return (
    <section className='search__articleList__content' style={{ padding: '5em 0' }}>
      {isLoading ? (
        <p>Cargando resultados...</p>
      ) : (
        <section className='article-section__content'>
          {dataArticlesSearcher.length !== 0 ? (
            dataArticlesSearcher.map((article) => (
              <ArticleItem
                key={article.id}
                title={article.title}
                answer={article.attributes?.ANSWER_TEXT || 'Sin respuesta disponible'}
                expandedState={true}
                clickCode={article.tracking?.clickCode || ''}
                rateCode={article.tracking?.rateCode || ''}
                userInfoSSFF={userInfoSSFF}
                articleId={article.id}
                contactMe={article.attributes?.CONTACT_MANAGER || ''}
              />
            ))
          ) : (
            <p>No se encontraron artículos relacionados.</p>
          )}
        </section>
      )}
    </section>
  );
};

export default Article3;
