import React, { useState, useEffect } from 'react';
//styles
import '../styles/search_article-list.css';

//images
import elipse355 from '../../images/ellipse355.png';

//components
import ArticleItem from './article-item';
import { Link } from 'react-router-dom';

import { fetchSearchArticles } from '../../services/api';

const SearchArticleList = ({
  filterInSearcher,
  handleUpperCategoryIdParam,
  userInfoSSFF,
}) => {
  const [dataArticlesSearcher, setDataArticlesSearcher] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //Articles & Roles
  useEffect(() => {
    const fetchSearchArticlesComponent = async () => {
      try {
        let searchTerm =
          filterInSearcher.length !== 0
            ? filterInSearcher
            : sessionStorage.getItem('dataTextSearcher');
        const subcategoriesArticlesData = await fetchSearchArticles(
          searchTerm,
          handleUpperCategoryIdParam,
          userInfoSSFF.custom03
        );
        if (handleUpperCategoryIdParam.includes('138')) {
          let copyArticlesData = subcategoriesArticlesData.filter(
            (article) =>
              article.attributes.Visibilidad !== 'People' &&
              article.attributes.Visibilidad !== 'Perfiles'
          );
          if (userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)') {
            setDataArticlesSearcher(
              copyArticlesData.concat(
                subcategoriesArticlesData.filter(
                  (article) => article.attributes.Visibilidad === 'People'
                )
              )
            );
          }
          // else if (
          //   (userInfoSSFF.custom15 === 'Y' &&
          //     userInfoSSFF.custom03 === '1893') ||
          //   userInfoSSFF.custom03 === '1702' ||
          //   userInfoSSFF.custom03 === '1017'
          // ) {
          //   setDataArticlesSearcher(subcategoriesArticlesData);
          // }
          else if (userInfoSSFF.custom15 === 'Y') {
            setDataArticlesSearcher(
              copyArticlesData.concat(
                subcategoriesArticlesData.filter(
                  (article) => article.attributes.Visibilidad === 'Perfiles'
                )
              )
            );
          }
        } else {
          setDataArticlesSearcher(subcategoriesArticlesData);
        }
        // Marcar la carga como completa
        setIsLoading(false);
        sessionStorage.setItem('dataTextSearcher', searchTerm);
      } catch (error) {
        console.error('An error occurred:', error);
        setIsLoading(false); // Marcar la carga como completa incluso si hay un error
      }
    };

    fetchSearchArticlesComponent();
  }, [filterInSearcher, handleUpperCategoryIdParam]);

  return (
    <>
      <section className="search__articleList">
        <div className="articleList__breadcrumb--container">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <Link to="/home" className="articleList__breadcrumb--title">
            INICIO {''}
          </Link>
          <span className="articleList__breadcrumb--text">
            &gt; RESULTADOS DE TU BÚSQUEDA
          </span>
        </div>
        <h4 className="search__ArticleList--title">
          Resultados de tu búsqueda
        </h4>
        <p className="search__ArticleList--search">
          {dataArticlesSearcher.length !== 0 ? filterInSearcher : null}
        </p>
        {/* Mostrar un mensaje de carga mientras se espera la respuesta */}
        {isLoading ? (
          <p>Cargando resultados...</p>
        ) : (
          // Listado artículos
          <section className="search__articleList__content">
            {dataArticlesSearcher.length !== 0 ? (
              dataArticlesSearcher.map((article) => (
                <ArticleItem
                  key={article.id}
                  title={article.title}
                  answer={article.attributes.ANSWER_TEXT}
                  expandedState={false}
                  clickCode={article.tracking.clickCode}
                  rateCode={article.tracking.rateCode}
                  userInfoSSFF={userInfoSSFF}
                  articleId={article.id}
                  contactMe={article.attributes.CONTACT_MANAGER}
                ></ArticleItem>
              ))
            ) : handleUpperCategoryIdParam === '138' ? (
              <p>
                No hay resultados. Visita los{' '}
                <Link to="/roles" className="categorias__noResult">
                  roles
                </Link>{' '}
                de nuestra página de roles o{' '}
                <Link to="/contacto" className="contacto__noResult">
                  contacta con nosotros
                </Link>{' '}
                si no encuentras la información que buscas.
              </p>
            ) : (
              <p>
                No hay resultados. Visita las{' '}
                <Link
                  to="/home#bubbles-categories"
                  className="categorias__noResult"
                >
                  categorías
                </Link>{' '}
                de nuestra página de inicio.
              </p>
            )}
          </section>
        )}
      </section>
    </>
  );
};

export default SearchArticleList;
