import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { extractTicketData } from './historialTicketUtils'; // Importa la función utilitaria
//import '../styles/change/tickets.css';
import ChangeRequest from './changeForm/formManager/components/formManagerMenu';
import TerminationMenu from './terminationForm/menuTermination';
import UpdateTickets from './terminationForm/updateTicket';

import { getJiraTicketAttachments } from '../../services/apiJira';
//import PruebaEjemplo from './changeForm/formPeople/form/ticketDetails/pruebaEjemplo.js';

const TicketDetails = ({ userInfoSSFF, usersInfoSSFF }) => {
  // Cambiado a destructuración de props
  const location = useLocation(); // Uso de useLocation hook aquí
  const { ticket, type } = location.state || {};

  const [ticketData, setTicketData] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAttachmentsAndData = async () => {
      if (ticket && type) {
        const { fields } = ticket;

        try {
          const ticketId = ticket.id;
          const ticketKey = ticket.key;

          const attachmentsData = await getJiraTicketAttachments(ticketKey);

          if (attachmentsData && attachmentsData.length > 0) {
            setAttachments(attachmentsData);
            const data = extractTicketData(fields, type, attachmentsData, ticketId, ticketKey);
            setTicketData(data);
          } else {
            setAttachments([]);
            setTicketData(extractTicketData(fields, type, []));
          }
        } catch (err) {
          console.error('Error al obtener adjuntos:', err);
          setError('Error al obtener los adjuntos');
        }
      }
    };

    fetchAttachmentsAndData();
  }, [ticket, type]); // El hook se ejecuta siempre con estos valores

  if (!ticket || !type) {
    return <p>No se encontraron detalles del ticket.</p>;
  }

  if (!ticketData) {
    return <p>Cargando detalles del ticket...</p>;
  }

  let content;
  if (type === 'change') {
    content = <ChangeRequest ticketData={ticketData} userInfoSSFF={userInfoSSFF} />;
  } else if (type === 'termination') {
    content = <UpdateTickets attachments={attachments} ticket={ticket} userInfoSSFF={userInfoSSFF} />;
  } else {
    content = <div>No hay detalles disponibles.</div>;
  }

  return <div>{content}</div>;
};

export default TicketDetails;
