// src/validateForm.js
export const ValidateForm = (formData) => {
	const errors = {};
  
	// Define todos los campos que deben ser validados
	const requiredFields = [
	  'businessUnit',
	  'division',
	  'departamento',
	  'needType',
	  'vacancyType',
	  'replacementName',
	  'replaceByIntern',
	  'internshipWindow',
	  'postInternship',
	  'hiringManager',
	  'costCenter',
	  'globalRole',
	  'benchmark',
	  'locationHQ',
	  'jobClassification',
	  'vacancySSFFID',
	  'dateUp',
	  'status',
	  'roleTitle',
	  'criticalProfile',
	  'profileType',
	  'recruiter',
	  'hrbp',
	  'recruitmentManager',
	  'recruitmentStatus',
	  'commentsFromRecruiters',
	  'type',
	  'localGrade',
	  'globalGrade',
	  'legalEntity',
	  'country',
	  'strategy',
	  'minExpectedSalary',
	  'maxExpectedSalary',
	  'expectedBonus',
	  'currencyExpectedSalary',
	  'billingDate',
	  'billingLegalEntity',
	  'postingStart',
	  'briefingDate',
	  'presentationOfResults',
	  'feedbackCandidatesToInterview',
	  'businessFirstInterview',
	  'finalCandidateSelected',
	  'offerDate',
	  'verbalOfferAcceptance',
	  'joinDate',
	  'onHoldDate',
	  'reactivationDate',
	  'cancelledDate',
	  'ageOfVacancy',
	  'timeFromLLToFirstInterview',
	  'timeFromCandidateSelectedToVerbalOffer',
	  'timeToVerbalOffer',
	  'sourcing',
	  'offerToHire',
	  'finalCandidateName',
	  'corporateMail',
	  'personalMail',
	  'finalLocalCategoryGradeOfHiring',
	  'finalGlobalCategoryGradeOfHiring',
	  'ageOfferDate',
	  'gender',
	  'nationality',
	  'externalInternal',
	  'recruitmentSource',
	  'finalLocationHQ2',
	  'building',
	  'floor',
	  'countryFinalCandidate',
	  'needsInternationalMobility',
	  'salary',
	  'targetBonus',
	  'currency',
	  'targetResults',
	  'objectiveResults',
	  'disability',
	  'sourceFinalCandidate',
	  'previousCompany',
	  'school',
	  'isContractor',
	  'revolvingDoors',
	  'contractType',
	  'workPercentage',
	  'employeeType',
	  'dateOfJoiningCompany',
	  'antiquityDate',
	  'fullRemote',
	  'professionalGroup',
	  'totalSSFFApplications',
	  'totalCandidatesDirectSearch',
	  'totalCandidatesPhoneScreening',
	  'totalCandidatesSentToHM',
	  'totalCandidatesHMValidation',
	  'totalInterviewedCandidatesHM',
	  'totalWomenSentToHM',
	  'totalWomenInShortlist',
	  'totalYoungCandidates',
	  'totalYoungTalentInShortListBelow33',
	  'totalYoungTalentInShortList33to35',
	  'positioning',
	  'totalDisabilityApplications',
	  'commentsKPIs',
	  'numSkills',
	  'recommendations',
	  'numRegistrationByRecommendation',
	  'placement'
	];
  
	// Validar que todos los campos obligatorios no estén vacíos
	requiredFields.forEach(field => {
	  if (!formData[field] || formData[field].trim() === '') {
		errors[field] = '*';
	  }
	});

	/* if (!needType || needType.trim() === '') {
        errors.needType = '*';
    } else if (needType === 'vacante') {
        // Validaciones específicas para "vacante"
        if (!vacancyType || vacancyType.trim() === '') errors.vacancyType = '*';
        if (vacancyType === 'sustitucion' && (!replacementName || replacementName.trim() === '')) {
            errors.replacementName = '*';
        }
        if (vacancyType === 'nueva' && (!replaceByIntern || replaceByIntern.trim() === '')) {
            errors.replaceByIntern = '*';
        }
    } else if (needType === 'beca') {
        // Validaciones específicas para "beca"
        if (!internshipWindow || internshipWindow.trim() === '') errors.internshipWindow = '*';
        if (!postInternship || postInternship.trim() === '') errors.postInternship = '*';
    } */
  
	return errors;
  };
  