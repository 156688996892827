import '../styles/frequent_questions.css';
import elipse355 from '../../images/ellipse355.png';

import FrequentQuestionItem from './frequent_question-item';
import ArticleItem from './article-item';

const Frequent_questions = ({ frequentQuestions,userInfoSSFF }) => {
  // Filtra y estructura los artículos de la subcategoría seleccionada
  // const filteredFrequentQuestionArticles = frequentQuestions.map((article) => ({
  //   id: article.id,
  //   title: article.title,
  //   answer: article.attributes.ANSWER_TEXT,
  // }));
  return (
    <>
      <section className="frequentQuestions">
        {/* Breadcrumb */}
        <div className="frequentQuestions__breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <h4>PREGUNTAS FRECUENTES</h4>
        </div>
        <section className="frequentQuestions__content">
          {/* {mapFrequentQuestions} */}
          {frequentQuestions.map((article) => (
             <FrequentQuestionItem
             key={article.id}
            title={article.title}
            answer={article.attributes.ANSWER_TEXT}
            clickCode={article.tracking.clickCode}
            rateCode={article.tracking.rateCode}
            userInfoSSFF={userInfoSSFF}
            articleId={article.id}
            contactMe={article.attributes.CONTACT_MANAGER}
             />
          //   <ArticleItem
          //   title={article.title}
          //   answer={article.attributes.ANSWER_TEXT}
          //   expandedState={false}
          //   clickCode={article.tracking.clickCode}
          //   rateCode={article.tracking.rateCode}
          //   userInfoSSFF={userInfoSSFF}
          //   articleId={article.id}
          //   contactMe={article.attributes.CONTACT_MANAGER}
          // ></ArticleItem>
          ))}
        </section>
      </section>
    </>
  );
};

export default Frequent_questions;
