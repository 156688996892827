import { useNavigate } from 'react-router-dom';
import "../styles/notFound404.css";

const NotFound404 = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    // Intenta retroceder a la última ruta visitada
    navigate(-1);

    // Si no hay una ruta anterior en el historial, redirige a /
    if (navigate.length <= 1) {
      navigate('/');
    }
  };

  return (
    <>
      <section className="notFound__section">
        <div className="notFound__error">
          <h2 className="notFound__errorNumber">404</h2>
          <p className="notFound__errorDescription">Página no encontrada</p>
        </div>
        <button className="notFound__backButton" onClick={handleGoBack}>
          Volver
        </button>
      </section>
    </>
  );
};

export default NotFound404;
