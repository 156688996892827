import React from 'react';

const Popup = ({ type, message, onClose }) => {
    return (
        <div className="dislikePopup-overlay">
            <div className={`dislikePopup-content ${type === 'failure' ? 'failurePopup' : ''}`}>
                <div className="dislikePopup__sectionTitle">
                    <h1 className="dislikePopup__sectionTitle--title spacing">
                        {type === 'success' ? 'Formulario enviado correctamente' : ''}
                    </h1>
                </div>
                <div className="dislikePopup__sectionText">
                    <p>{message}</p>
                </div>
                <div className="contactMeSectionButton">
                    <button
                        className={`contactMePopup--button ${type === 'failure' ? 'failureButton' : ''}`}
                        onClick={onClose}
                    >
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
