export const ValidateForm = (fields) => {
    const {
        businessUnit,
        division,
        departamento,
        needType,
        vacancyType,
        replacementName,
        replaceByIntern,
        internshipWindow,
        postInternship,
        rationale,
        criticality,
        responsibilities,
        skills,
        project,
        location,
        desiredDate,
    } = fields;

    const errors = {};

    // Validaciones Generales
    if (!businessUnit || businessUnit.trim() === '') errors.businessUnit = '*';
    if (!division || division.trim() === '') errors.division = '*';
    if (!departamento || departamento.trim() === '') errors.departamento = '*';
    if (!location || location.trim() === '') errors.location = '*';
    if (!desiredDate || desiredDate.trim() === '') errors.desiredDate = '*';

    // Validaciones Necesidad de Incorporación
    if (!needType || needType.trim() === '') {
        errors.needType = '*';
    } else if (needType === 'vacante') {
        // Validaciones específicas para "vacante"
        if (!vacancyType || vacancyType.trim() === '') errors.vacancyType = '*';
        if (vacancyType === 'sustitucion' && (!replacementName || replacementName.trim() === '')) {
            errors.replacementName = '*';
        }
        if (vacancyType === 'nueva' && (!replaceByIntern || replaceByIntern.trim() === '')) {
            errors.replaceByIntern = '*';
        }
    } else if (needType === 'beca') {
        // Validaciones específicas para "beca"
        if (!internshipWindow || internshipWindow.trim() === '') errors.internshipWindow = '*';
        if (!postInternship || postInternship.trim() === '') errors.postInternship = '*';
    }

    // Validaciones Adicionales
    if (!rationale || rationale.trim() === '') errors.rationale = '*';
    if (!criticality || criticality.trim() === '') errors.criticality = '*';
    if (!responsibilities || responsibilities.trim() === '') errors.responsibilities = '*';
    if (!skills || skills.trim() === '') errors.skills = '*';
    if (!project || project.trim() === '') errors.project = '*';

	console.log('errores', errors);
    return errors;
};
