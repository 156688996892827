import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';

import {
  attachMedia,
  createTicket,
  USER_TYPE_TO_INBENTA_ID,
  isUserExist,
} from '../../../services/api';
import { readFileAsBase64, ALLOWED_TYPE_FILES } from '../../../utils/utils';

const LifeInsurance = ({ userInfoSSFF }) => {
  const [nombreApellidos, setNombreApellidos] = useState('');
  const [dni, setDni] = useState('');
  const [files, setFiles] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showNombreApellidosRequired, setShowNombreApellidosRequired] =
    useState(false);
  const [showDniRequired, setShowDniRequired] = useState(false);
  const [showFilesRequired, setShowFilesRequired] = useState(false);
  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [selectedFilesInfo, setSelectedFilesInfo] = useState([]);
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const areFieldsValid =
      dni.trim() !== '' && nombreApellidos.trim() !== '' && files !== null;
    setIsButtonEnabled(areFieldsValid);

    const filesInfo = files.map((file) => ({
      name: file.name,
      extension: file.name.split('.').pop(),
    }));
    setSelectedFilesInfo(filesInfo);
  }, [dni, nombreApellidos, files]);

  const handleDniChange = (e) => {
    setDni(e.target.value);
    setShowDniRequired(false);
  };

  const handleNombreApellidosChange = (e) => {
    setNombreApellidos(e.target.value);
    setShowNombreApellidosRequired(false);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    if (ALLOWED_TYPE_FILES.includes(selectedFiles[0].name.split('.').pop())) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setShowFormatNotSupported(false);
    } else {
      setShowFormatNotSupported(true);
    }

    if (files.length >= 0) {
      setShowFilesRequired(false);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    document.getElementById('files').value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid =
      dni.trim() !== '' && nombreApellidos.trim() !== '' && files !== null;
    if (nombreApellidos === '') {
      setShowNombreApellidosRequired(true);
    }
    if (dni === '') {
      setShowDniRequired(true);
    }
    if (files.length === 0) {
      setShowFilesRequired(true);
    } else if (areFieldsValid) {
      try {
        setIsSubmitting(true);
        const dataUserInbenta = await isUserExist(userInfoSSFF.username);

        // Sanitizar los valores de entrada del formulario
        const sanitizedNombreApellidos = DOMpurify.sanitize(nombreApellidos);
        const sanitizedDni = DOMpurify.sanitize(dni);

        const message = `
      <div style={{ backgroundColor: "transparent" }}>
        <div><br /></div>
        <p>1. EMAIL CORPORATIVO</p>
        <br />
        <b>${userInfoSSFF.username}</b>
        <div><br /></div>
        p>2. NOMBRE Y APELLIDOS</p>
         <br />
         <b>${sanitizedNombreApellidos}</b>
         <div><br /></div>
        <p>3. EMPRESA</p>
        <br />
        <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
        <div><br /></div>
        <p>4. DNI</p>
        <br />
        <b>${sanitizedDni}</b>
        <div><br /></div>
      </div>
      `;

        let uuidsMediaArray = [];
        await Promise.all(
          files.map(async (file) => {
            const base64 = await readFileAsBase64(file);
            const uuidMedia = await attachMedia(file.name, base64);
            uuidsMediaArray.push(uuidMedia);
          })
        );

        await createTicket(
          'Solicitud Alta Seguro de vida',
          message,
          userInfoSSFF.custom03,
          1,
          uuidsMediaArray,
          dataUserInbenta.data[0].id
        );

        setNombreApellidos('');
        setDni('');
        setFiles([]);
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  return (
    <>
      <section className="formArticle">
        <h1 className="formArticle__title">
          Solicitud de alta en el Seguro de vida
        </h1>
        <p className="formArticle__text">
          Para solicitar el alta en el Seguro de vida, por favor rellena el
          siguiente formulario y adjunta el correspondiente boletín firmado.
        </p>
        <form
          target="_blank"
          className="formArticle__form"
          action="#"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="nombreApellidos">
              Nombre y Apellidos
              {showNombreApellidosRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={nombreApellidos}
              onChange={handleNombreApellidosChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="dni">
              DNI
              {showDniRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={dni}
              onChange={handleDniChange}
            />
          </div>
          <div className="formArticleSection">
            <label htmlFor="formArticle__option" className="formArticleLabel">
              Justificante del registro
              {showFilesRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
              {showFormatNotSupported && (
                <p className="formArticleLabel__required">
                  Formato de archivos no soportado. Utilice:{' '}
                  {ALLOWED_TYPE_FILES.join(', ')}
                </p>
              )}
            </label>
            <p className="formArticle__text">Adjunta el boletín de Antares</p>
            <div className="formButtonsContainer">
              <label for="files" className="formArticleButton secondaryButton">
                Seleccionar archivos
              </label>
              <input
                type="file"
                id="files"
                onChange={handleFileChange}
                multiple
              />
              <button
                className={`formArticleButton ${
                  isButtonEnabled ? 'enabled' : 'disabled'
                }`}
                type="submit"
              >
                Enviar
              </button>
              {isSubmitting && (
                // SPINNER LOADING
                <div class="loader"></div>
              )}
            </div>
            {selectedFilesInfo.map((fileInfo, index) => (
              <div key={index} className="selectedFileContainer">
                <p className="formArticle__fileInfo">{`${fileInfo.name}`}</p>
                <div className="removeFileIconContainer">
                  <svg
                    onClick={() => removeFile(index)}
                    xmlns="http://www.w3.org/2000/svg"
                    // width="16"
                    // height="16"
                    fill="currentColor"
                    className="removeFileIcon"
                    viewBox="0 0 40 40"
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.503 0.496584C34.1657 1.15869 34.1657 2.23898 33.503 2.90109L19.3891 17.0029L33.503 31.1163C34.154 31.7901 34.1424 32.8703 33.4565 33.5208C32.7938 34.1597 31.7475 34.1597 31.0964 33.5208L16.9826 19.4074L2.90357 33.4744C2.2409 34.1365 1.15969 34.1365 0.497008 33.4744C-0.165669 32.8123 -0.165669 31.7436 0.497008 31.0699L14.576 17.0029L0.497008 2.94756C-0.165669 2.28544 -0.165669 1.21677 0.497008 0.543047C1.15969 -0.119064 2.22927 -0.119064 2.90357 0.543047L16.9826 14.61L31.1081 0.496584C31.7707 -0.165528 32.8403 -0.165528 33.503 0.496584Z"
                        fill="#E66C64"
                      />
                    </svg>
                  </svg>
                </div>
              </div>
            ))}
          </div>
        </form>
        <div className="contactTextContainer">
          <p className="formArticle__contact">
            Para cualquier duda, contacta al buzón{' '}
            <a href="mailto:preguntanos@personas.telefonica.com">
              preguntanos@personas.telefonica.com
            </a>
          </p>
        </div>
        {feedbackPopup && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content">
              <div className="dislikePopup__sectionTitle">
                <h1 className="dislikePopup__sectionTitle--title spacing">
                  Solicitud enviada correctamente
                </h1>
              </div>
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  Nos pondremos en contacto contigo a la mayor brevedad posible
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content failurePopup">
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button failureButton"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default LifeInsurance;
