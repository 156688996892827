import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PayrollComponent from './payrollComponent';
import RetentionsComponent from './retentionsComponent';
import CertificateComponent from './certificateComponent';
import payrollIcon from '../../images/folder/nominas.svg';
import certificateIcon from '../../images/folder/otros_certificados.svg';
import compesationIcon from '../../images/folder/compensation.svg';
import retentionsIcon from '../../images/folder/retenciones.svg';
import elipse355 from '../../images/ellipse355.png';
import '../styles/myFolderSection.css';
import '../styles/payrollComponent.css';
import '../styles/certificateComponent.css';

const MyFolderSection = ({ userInfoSSFF, juridicalSSFF }) => {
  const location = useLocation();
  const { selected } = location.state || {};
  const [selection, setSelection] = useState(selected || '');

  useEffect(() => {
    if (selected) {
      setSelection(selected);
    }
  }, []);

  const handleSelection = (newSelection) => {
    setSelection(newSelection);
  };

  return (
    <>
      <div className='myFolderSection_content'>
        <div className='myFolderPage__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/home' className='myFolderPage__breadcrumb--title'>
            INICIO
          </Link>
          <Link to='/folder' className='myFolderPage__breadcrumb--text'>
            &gt; MI CARPETA
          </Link>
        </div>

        <h1 className='myFolderPage__title'>Mi carpeta</h1>
        <div className='myFolderSection_container'>
          <nav className='myFolderSection_menu'>
            <ul>
              <li className='myFolderSection_certificate'>
                <Link
                  to='/folder/certificates'
                  className={selection === 'certificates' ? 'active' : ''}
                  onClick={() => handleSelection('certificates')}
                >
                  <img src={certificateIcon} alt='Certificate Icon' className='menu-icon' />
                  Certificados
                </Link>
              </li>
              <li className='myFolderSection_payroll'>
                <Link to='/folder/payrolls' className={selection === 'payrolls' ? 'active' : ''} onClick={() => handleSelection('payrolls')}>
                  <img src={payrollIcon} alt='Payroll Icon' className='menu-icon' />
                  Nóminas
                </Link>
              </li>
              {/* <li className='myFolderSection_compensation'>
                <a
                  href='https://performancemanager5.successfactors.eu/sf/personalinfo?blockType=COMPENSATION_STATEMENT_BLOCKS#/Telefonica'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='myFolderSection_dissabledLink' {selection === 'compensation' ? 'active' : 'myFolderSection_dissabledLink'}
                  onClick={() => handleSelection('compensation')} 
                >
                  <img src={compesationIcon} alt='Compensation Icon' className='menu-icon' />
                  Compensación
                </a>
              </li> */}

              {/* <li className='myFolderSection_retentions'>
                <div
                   to="/folder/certificates" 
                  className='myFolderSection_dissabledLink' {selection === "retentions" ? "active" : ""}
                   onClick={() => setSelection("retenciones")} 
                >
                  <img src={retentionsIcon} alt='Retentions Icon' className='menu-icon' />
                  Retenciones
                </div>
              </li> */}
              {/*               <li className="myFolderSection_beneficts">
                <div
                  /* to="/folder/certificates"
                  className="myFolderSection_dissabledLink" {selection === "beneficts" ? "active" : ""}
                   onClick={() => handleSelection("beneficios")}
                >
                  <img
                    src={certificateIcon}
                    alt="Beneficts Icon"
                    className="menu-icon"
                  />
                  Beneficios
                </div>
              </li> */}
            </ul>
          </nav>

          <div className='myFolderSection_components'>
            <div className='myFolderSection_selectedComponent'>
              {selection === 'payrolls' ? (
                <PayrollComponent userInfoSSFF={userInfoSSFF} juridicalSSFF={juridicalSSFF} />
              ) : selection === 'retentions' ? (
                <RetentionsComponent userInfoSSFF={userInfoSSFF} />
              ) : selection === 'certificates' ? (
                <CertificateComponent userInfoSSFF={userInfoSSFF} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFolderSection;
