import Header from '../../../components/header';
import Footer from '../../../components/footer';
import '../../../styles/app.css';

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className='marginContainer'>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
