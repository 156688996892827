export const downloadFiles = async (jwtSSFF, idToken, selectedFiles, userEmail) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'x-msal-Authorization': `Bearer ${idToken}`,
        'x-jwt-Authorization': `Bearer ${jwtSSFF}`
      },
      body: JSON.stringify({ fileNames: selectedFiles,userEmail }),
    };

    const response = await fetch('/metaDownload', requestOptions);
    if (!response.ok) {
      throw new Error('Error downloading files: ' + response.statusText);
    }

    const blobData = await response.blob();
    return blobData;

  } catch (error) {
    console.error('Error downloading files:', error);
    throw new Error('Failed to download files');
  }
};

  
  
  