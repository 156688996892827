import React, { useEffect, useState } from 'react';
import { LabelInput } from './inputs/labelInput';
import { validateFields } from './validateFields'; // Asume que tienes esta función para validar los campos

const NewPositionForm = ({
  manager, setManager,
  businessUnitPosition, setBusinessUnitPosition,
  divisionPosition, setDivisionPosition,
  departmentPosition, setDepartmentPosition,
  costCenterPosition, setCostCenterPosition,
  globalRolePosition, setGlobalRolePosition,
  benchmark, setBenchmark,
  locationPosition, setLocationPosition,
  jobClassificationPosition, setJobClassificationPosition,
  showForm
}) => {
  const [errors, setErrors] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setInitialValues({
      manager,
      businessUnitPosition,
      divisionPosition,
      departmentPosition,
      costCenterPosition,
      globalRolePosition,
      benchmark,
      locationPosition,
      jobClassificationPosition,
    });
  }, []);

  useEffect(() => {
    const fields = {
      manager,
      businessUnitPosition,
      divisionPosition,
      departmentPosition,
      costCenterPosition,
      globalRolePosition,
      benchmark,
      locationPosition,
      jobClassificationPosition,
    };
    const validationErrors = validateFields(fields);
    setErrors(validationErrors);
    setShowWarning(Object.keys(validationErrors).length > 0);
  }, [
    manager,
    businessUnitPosition,
    divisionPosition,
    departmentPosition,
    costCenterPosition,
    globalRolePosition,
    benchmark,
    locationPosition,
    jobClassificationPosition,
  ]);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraChangeInputText_short prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraChangeInputText_short errorBorder' : 'formJiraChangeInputText_short';
  };

  const renderLabel = (labelText, fieldName) => {
    return (
      <label className="formJiraChangeLabel">
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  if (!showForm) return null;
  return (
    <>
      <LabelInput
        label={renderLabel("Manager:", "manager")}
        value={manager}
        onChange={(e) => setManager(e.target.value)}
        className={getInputClass(manager, "manager")}
      />
      <LabelInput
        label={renderLabel("Unidad de negocio:", "businessUnitPosition")}
        value={businessUnitPosition}
        onChange={(e) => setBusinessUnitPosition(e.target.value)}
        className={getInputClass(businessUnitPosition, "businessUnitPosition")}
      />
      <LabelInput
        label={renderLabel("División:", "divisionPosition")}
        value={divisionPosition}
        onChange={(e) => setDivisionPosition(e.target.value)}
        className={getInputClass(divisionPosition, "divisionPosition")}
      />
      <LabelInput
        label={renderLabel("Departamento:", "departmentPosition")}
        value={departmentPosition}
        onChange={(e) => setDepartmentPosition(e.target.value)}
        className={getInputClass(departmentPosition, "departmentPosition")}
      />
      <LabelInput
        label={renderLabel("Centro de coste:", "costCenterPosition")}
        value={costCenterPosition}
        onChange={(e) => setCostCenterPosition(e.target.value)}
        className={getInputClass(costCenterPosition, "costCenterPosition")}
      />
      <LabelInput
        label={renderLabel("Rol/Perfil Global:", "globalRolePosition")}
        value={globalRolePosition}
        onChange={(e) => setGlobalRolePosition(e.target.value)}
        className={getInputClass(globalRolePosition, "globalRolePosition")}
      />
      <LabelInput
        label={renderLabel("Benchmark:", "benchmark")}
        value={benchmark}
        onChange={(e) => setBenchmark(e.target.value)}
        className={getInputClass(benchmark, "benchmark")}
      />
      <LabelInput
        label={renderLabel("Ubicación:", "locationPosition")}
        value={locationPosition}
        onChange={(e) => setLocationPosition(e.target.value)}
        className={getInputClass(locationPosition, "locationPosition")}
      />
      <LabelInput
        label={renderLabel("Puesto de trabajo (Job Classification):", "jobClassificationPosition")}
        value={jobClassificationPosition}
        onChange={(e) => setJobClassificationPosition(e.target.value)}
        className={getInputClass(jobClassificationPosition, "jobClassificationPosition")}
      />
      {showWarning && (
        <div className="formJiraChangeWarningMessage">
          *Es necesario completar todos los campos indicados en rojo*
        </div>
      )}
    </>
  );
};

export default NewPositionForm;
