import React, { useState, useEffect } from 'react';
import BusinessInfoManager from './businessInfoManager';
import IncorporationNeedManager from './incorporationNeedManager';
import PositionDetailsManager from './positionDetailsManager';
import { ValidateForm } from './validateFormManager';
import { handleSubmit} from './handleSubmitManager';
import { Link } from 'react-router-dom';
import elipse355 from '../../../../images/ellipse355.png';
import '../../../styles/new/newEmployeeForm.css';

const ManagerForm = ({ ticketData, userInfoSSFF }) => {
  const [formData, setFormData] = useState({
    /* businessUnit: ticketData.businessUnit || '',
    division: ticketData.division || '',
    departamento: ticketData.departamento || '',
    needType: ticketData.needType || '',
    vacancyType: ticketData.vacancyType || '',
    replacementName: ticketData.replacementName || '',
    replaceByIntern: ticketData.replaceByIntern || '',
    internshipWindow: ticketData.internshipWindow || '',
    postInternship: ticketData.postInternship || '',
    rationale:  ticketData.rationale || '',
    criticality: ticketData.criticality || '',
    responsibilities: ticketData.responsibilities || '',
    skills: ticketData.skills || '',
    project: ticketData.project || '',
    location:  ticketData.location || '',
    desiredDate: ticketData.desiredDate || '' */
	businessUnit: userInfoSSFF?.custom02 || '',
	division: userInfoSSFF?.division || '',
	departamento: userInfoSSFF?.department || '',
	needType: '',
	vacancyType: '',
	replacementName: '',
	replaceByIntern: '',
	internshipWindow: '',
	postInternship: '',
	rationale: '',
	criticality: '',
	responsibilities: '',
	skills: '',
	project: '',
	location: '',
	desiredDate: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
	const { name, value } = e.target;
	const updatedFormData = { ...formData, [name]: value };
	setFormData(updatedFormData);
	const validationErrors = ValidateForm(updatedFormData);
	console.log('erroressss', validationErrors);
	setErrors(validationErrors);
  };

  const handleClear = () => {
    setFormData({
/*       businessUnit: ticketData.businessUnit || '',
      division: ticketData.division || '',
      departamento: ticketData.departamento || '',
      needType: ticketData.needType || '',
      vacancyType: ticketData.vacancyType || '',
      replacementName: ticketData.replacementName || '',
      replaceByIntern: ticketData.replaceByIntern || '',
      internshipWindow: ticketData.internshipWindow || '',
      postInternship: ticketData.postInternship || '',
      rationale: ticketData.rationale || '',
      criticality: ticketData.criticality || '',
      responsibilities: ticketData.responsibilities || '',
      skills: ticketData.skills || '',
      project: ticketData.project || '',
      location: ticketData.location || '',
      desiredDate: ticketData.desiredDate || '' */
	  businessUnit: userInfoSSFF?.custom02 || '',
	  division: userInfoSSFF?.division || '',
	  departamento: userInfoSSFF?.department || '',
	  needType: '',
	  vacancyType: '',
	  replacementName: '',
	  replaceByIntern: '',
	  internshipWindow: '',
	  postInternship: '',
	  rationale: '',
	  criticality: '',
	  responsibilities: '',
	  skills: '',
	  project: '',
	  location: '',
	  desiredDate: ''
    });
  }; 

  const isButtonEnabled = !isSubmitting && Object.keys(errors).length === 0;

  return (
	<div className="combined-form">
		<div className="jiraNewForm__breadcrumb">
			<img src={elipse355} alt="circle-icon" className="circle-icon" />
			<Link to="/form" className="jiraNewForm__breadcrumb--title">
				INICIO
			</Link>
			<div className="jiraNewForm__breadcrumb--text">
				&gt; SOLICITUD DE ALTA
			</div>
		</div>
		<h1 className="formJiraNew__title">Solicitud de Alta (manager)</h1>
		<form 
			onSubmit={(e) => 
				handleSubmit(e, formData, setIsSubmitting, handleClear, setErrors, ValidateForm)}>
			<BusinessInfoManager formData={formData} setFormData={setFormData} errors={errors} onChange={handleChange} />
			<IncorporationNeedManager formData={formData} setFormData={setFormData} errors={errors} onChange={handleChange} />
			<PositionDetailsManager formData={formData} setFormData={setFormData} errors={errors} onChange={handleChange} />
			<div className="formJiraNewButton_section">
				<button
					type="button"
					className="secondaryButton"
					onClick={handleClear}
				>
					Limpiar
				</button>
				<button
					className={`formJiraNewButton ${isButtonEnabled ? 'enabled' : 'disabled'}`}
						type="submit"
							//disabled={!isButtonEnabled}
					>
					Enviar
				</button>
				{isSubmitting && (
				<div className="loader"></div>
				)}
			</div>
    	</form>
	</div>
  );
};

export default ManagerForm;
