import React from 'react';

const PositionDetailsChannel = ({ formData, errors, onChange }) => {
  return (
    <div className='formJiraNewSection'>
      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.rationale && '*'} Rationale del porqué de la Necesidad de
          Incorporación:
        </label>
        <input
          type='text'
          name='rationale'
          placeholder='Rationale'
          className={`formJiraNewInputText ${
            errors.rationale ? 'errorBorder' : ''
          }`}
          value={formData.rationale}
          onChange={onChange}
        />
      </div>

      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.criticality && '*'} Criticalidad del Puesto:
        </label>
        <input
          type='text'
          name='criticality'
          placeholder='Criticality'
          className={`formJiraNewInputText ${
            errors.criticality ? 'errorBorder' : ''
          }`}
          value={formData.criticality}
          onChange={onChange}
        />
      </div>

      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.responsibilities && '*'} Responsabilidades/Funciones
        </label>
        <textarea
          type='text'
          name='responsibilities'
          placeholder='Responsibilities'
          className={`formJiraNewInputText ${
            errors.responsibilities ? 'errorBorder' : ''
          }`}
          value={formData.responsibilities}
          onChange={onChange}
        />
      </div>

      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.skills && '*'}
          Habilidades:
        </label>
        <textarea
          type='text'
          name='skills'
          placeholder='Skills'
          className={`formJiraNewInputText ${
            errors.skills ? 'errorBorder' : ''
          }`}
          value={formData.skills}
          onChange={onChange}
        />
      </div>

      <div className='formJiraNewSection'>
        <label className='formJiraNewLabel'>
          {errors.project && '*'}
          Proyecto:
        </label>
        <input
          type='text'
          id='project'
          placeholder='Project'
          className={`formJiraNewInputText ${
            errors.project ? 'errorBorder' : ''
          }`}
          value={formData.project}
          onChange={onChange}
        />
      </div>

      <div className='formJiraNewSection_both'>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel'>
            {errors.location && '*'}
            Ubicación:
          </label>
          <input
            type='text'
            name='location'
            placeholder='Location'
            className={`formJiraNewInputText ${
              errors.location ? 'errorBorder' : ''
            }`}
            value={formData.location}
            onChange={onChange}
          />
        </div>
        <div className='formJiraNewSection_bothIndividual'>
          <label className='formJiraNewLabel'>
            {errors.desiredDate && '*'}
            Fecha Deseada de Incorporación:
          </label>
          <input
            type='date'
            name='desiredDate'
            className={`formJiraNewInputText ${
              errors.desiredDate ? 'errorBorder' : ''
            }`}
            value={formData.desiredDate}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PositionDetailsChannel;

/* import React, { useEffect, useState } from 'react';

const PositionDetails = ({ formData, setFormData, errors, setErrors }) => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({ ...formData });
  }, []);

  const getInputClass = (fieldValue, fieldName) => {
    if (fieldValue && fieldValue === initialValues[fieldName]) {
      return 'formJiraInputText prefilledBorder';
    }
    return errors[fieldName] ? 'formJiraInputText errorBorder' : 'formJiraInputText';
  };

  const renderLabel = (labelText, fieldName) => {
    return (
      <label className="formJiraLabel">
        {errors[fieldName] && '*'} {labelText}
      </label>
    );
  };

  return (
    <div className="formJiraCheckboxGroup">
      <h3>Detalles del Puesto</h3>

      <div className="formJiraFieldGroup">
        {renderLabel('Rationale del porqué de la Necesidad de Incorporación:', 'rationale')}
        <input
		  type='text'
          id="rationale"
          placeholder="Rationale"
          className={getInputClass(formData.rationale, 'rationale')}
          value={formData.rationale}
          onChange={(e) => setFormData({ ...formData, rationale: e.target.value })}
        />
      </div>

      <div className="formJiraFieldGroup">
        {renderLabel('Criticalidad del Puesto:', 'criticality')}
        <input
		  type='text'
          id="criticality"
          placeholder="Criticality"
          className={getInputClass(formData.criticality, 'criticality')}
          value={formData.criticality}
          onChange={(e) => setFormData({ ...formData, criticality: e.target.value })}
        />
      </div>

      <div className="formJiraFieldGroup">
        {renderLabel('Responsabilidades:', 'responsibilities')}
        <input
		  type='text'
          id="responsibilities"
          placeholder="Responsibilities"
          className={getInputClass(formData.responsibilities, 'responsibilities')}
          value={formData.responsibilities}
          onChange={(e) => setFormData({ ...formData, responsibilities: e.target.value })}
        />
      </div>

      <div className="formJiraFieldGroup">
        {renderLabel('Habilidades:', 'skills')}
        <input
		  type='text'
          id="skills"
          placeholder="Skills"
          className={getInputClass(formData.skills, 'skills')}
          value={formData.skills}
          onChange={(e) => setFormData({ ...formData, skills: e.target.value })}
        />
      </div>

      <div className="formJiraFieldGroup">
        {renderLabel('Proyecto:', 'project')}
        <input
		  type='text'
          id="project"
          placeholder="Project"
          className={getInputClass(formData.project, 'project')}
          value={formData.project}
          onChange={(e) => setFormData({ ...formData, project: e.target.value })}
        />
      </div>

      <div className="formJiraSection_both">
        {renderLabel('Ubicación:', 'location')}
        <input
		  type='text'
          id="location"
          placeholder="Location"
          className={getInputClass(formData.location, 'location')}
          value={formData.location}
          onChange={(e) => setFormData({ ...formData, location: e.target.value })}
        />
        {renderLabel('Fecha Deseada de Incorporación:', 'desiredDate')}
        <input
          type="date"
          id="desiredDate"
          className={getInputClass(formData.desiredDate, 'desiredDate')}
          value={formData.desiredDate}
          onChange={(e) => setFormData({ ...formData, desiredDate: e.target.value })}
        />
      </div>
    </div>
  );
};

export default PositionDetails;
 */
