import React, { useState } from 'react';
import { EditPopup } from './editPopUp';
import EditPencil from '../../../images/jira/pencil.svg';
import infoIcon from '../../../images/swp/infoIcon.png';
import closeCross from '../../../images/close-regularBlue.png';
import '../../styles/contactUsTicketsList.css';

export const TicketsContactUsInfo = ({ ticket }) => {
  const [selectedSummary, setSelectedSummary] = useState(ticket.fields.summary);
  const [asignee, setAsignee] = useState(ticket.fields.assignee ? ticket.fields.assignee.displayName : '');
  const [selectedStatus, setSelectedStatus] = useState(ticket.fields.status.name);
  const [selectedPriority, setSelectedPriority] = useState(ticket.fields.priority.name);
  const [showPopup, setShowPopup] = useState(false);
  const [userInfoPopup, setUserInfoPopup] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('es-ES', optionsDate);

    // CORRECCIÓN: eliminamos el espacio extra después de '2-digit'
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedTime = date.toLocaleTimeString('es-ES', optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatDate(ticket.fields.updated);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  // Función para cerrar el popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const openUserInfoPopup = () => {
    setUserInfoPopup(true);
  };

  const closeUserInfoPopup = () => {
    setUserInfoPopup(false);
  };

  return (
    <div className='ticket-details-container'>
      <h2 className='ticket-list-title'>
        {ticket.id} - {ticket.fields.summary}
      </h2>
      <div class='text-center'>
        <table className='tickets-table'>
          <thead>
            <tr>
              <th classname='ticketsTable-th'>Id</th>
              <th classname='ticketsTable-th'>Creado por</th>
              <th classname='ticketsTable-th'>Soporte</th>
              <th classname='ticketsTable-th'>Responsable</th>
              <th classname='ticketsTable-th'>Estado</th>
              <th classname='ticketsTable-th'>Prioridad</th>
              <th classname='ticketsTable-th'>Contenido</th>
              <th classname='ticketsTable-th'>Creado el</th>
              <th classname='ticketsTable-th'>Editar</th>
            </tr>
          </thead>
          <tbody>
            <tr classname='ticketsTable-tr'>
              <td classname='ticketsTable-td'>{ticket.id}</td>
              <td classname='ticketsTable-td'>
                <p>{ticket.fields.customfield_10319}</p>
                <p>
                  {ticket.fields.customfield_10318}
                  <img src={infoIcon} alt='infoIcon' className='infoIcon' onClick={openUserInfoPopup} />
                </p>
              </td>
              <td classname='ticketsTable-td'>
                {ticket.fields.summary.includes('SOPORTE MANAGER')
                  ? 'Manager'
                  : ticket.fields.summary.includes('SOPORTE EMPLEADO')
                  ? 'Empleado'
                  : ticket.fields.summary}
              </td>
              <td classname='ticketsTable-td'>{ticket.fields.customfield_10404 || '--'}</td>
              <td classname='ticketsTable-td'>{selectedStatus}</td>
              <td className='ticketsTable-td'>
                {selectedPriority === 'High' ? (
                  <span>Alta</span>
                ) : selectedPriority === 'Medium' ? (
                  <span>Media</span>
                ) : selectedPriority === 'Low' ? (
                  <span>Baja</span>
                ) : selectedPriority === 'Crítica' ? (
                  <span>Crítica</span>
                ) : (
                  <span>--</span>
                )}
              </td>
              <td>{ticket.fields.customfield_10350}</td>
              <td classname='ticketsTable-td'>
                <p>{formattedDate}</p>
                <p>{formattedTime}</p>
              </td>
              <td classname='ticketsTable-td'>
                <button className='edit-pencil-button' onClick={handleOpenPopup}>
                  <img className='img-edit-pencil' src={EditPencil} alt='Edit' />
                </button>
                {showPopup && <EditPopup ticket={ticket} handleClosePopup={handleClosePopup} />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='ticket-detail-popUp'>
        {showPopup && (
          <EditPopup
            ticket={ticket}
            selectedSummary={selectedSummary}
            setSelectedSummary={setSelectedSummary}
            asignee={asignee}
            setAsignee={setAsignee}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedPriority={selectedPriority}
            setSelectedPriority={setSelectedPriority}
            onClose={handleClosePopup}
          />
        )}
      </div>
      {userInfoPopup && (
        <div className='popup-modal-overlay'>
          <div className='popup-modal-content'>
            <div className='dataView__firstSection'>
              <p>
                Datos del usuario <span className='text-primary'>{ticket.fields.customfield_10319}</span>
              </p>
              <div className='dataView__closeButton'>
                <img src={closeCross} alt='cross-to-close-popup' className='user__popup--closeImg' onClick={closeUserInfoPopup} />
              </div>
            </div>
            <div className='dataView__infoContainer'>
              <div class='text-center'>
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th classname='ticketsTable-th'>Jurídica</th>
                      <th classname='ticketsTable-th'>Área</th>
                      <th classname='ticketsTable-th'>BU</th>
                      <th classname='ticketsTable-th'>Departamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ticket.fields.customfield_10322}</td>
                      <td>{ticket.fields.customfield_10347}</td>
                      <td>{ticket.fields.customfield_10348}</td>
                      <td>{ticket.fields.customfield_10349}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
