import React, {useEffect, useState} from 'react';
import { validateFields } from './validateFields';

export const ContractChangeComponent = ({ contractChange, setContractChange, hours, setHours }) => {

	const [showWarning, setShowWarning] = React.useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [errors, setErrors] = useState('');

	useEffect(() => {
		setInitialValues({
			contractChange,
			hours,
		});
	}, []);

	useEffect(() => {
		const fields = {
			contractChange,
			hours,
		};
		const validationErrors = validateFields(fields);
		setErrors(validationErrors);
		setShowWarning(Object.keys(validationErrors).length > 0);
	}, [contractChange, hours]);

	const getInputClass = (fieldValue, fieldName) => {
		
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeInputText prefilledBorder'; 
		} 
		return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
	};
	const renderLabel = (labelText, fieldName) => {	
		return (
			<label className="formJiraChangeLabel">
				{errors[fieldName] && '*'} {labelText}
			</label>
		);
	};

	return (
		<div className="formJiraChangeCheckboxGroup_changes">
			<div className='formJiraChangeInfoTittle'>
				Cambios de contrato
			</div>
			<div className="formJiraChangeSection">
				<label className="formJiraChangeLabel">
					{renderLabel('Tipo de Contrato:', 'contractChange')}
				</label>
				<input 
					type="text" 
					className= {getInputClass(contractChange, 'contractChange')}
					value={contractChange} 
					onChange={e => setContractChange(e.target.value)} />
			</div>
			<div className="formJiraChangeSection">
				<label className="formJiraChangeLabel">
					{renderLabel('Horas/Semana:', 'hours')}
				</label>
				<input 
					type="text" 
					className={getInputClass(hours, 'hours')}
					value={hours} 
					onChange={e => setHours(e.target.value)} />
			</div>
			{showWarning && (
			<div className="formJiraChangeWarningMessage">
				*Es necesario completar todos los campos indicados en rojo
			</div>
			)}
		</div>
	);
};

