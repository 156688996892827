import React, { useState, useCallback, useEffect, useRef } from 'react';
import '../../../knowledgePortal/styles/searcher.css';
import SearchArticleList from './contactUsSearchArticleList';
import iconSearch from '../../../images/search.svg';

// Componente que permite buscar artículos en Inbenta, la idea es imitar la sección del buscadornde artículos pero que al seleccionar una pregunta se muestre el artículo correspondiente directamente y no se redirija a la página de la lista de preguntas de ese artículo.
const ContactUsInbentaInfo = (props) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAutocompletedArticles, setFilteredAutocompletedArticles] =
    useState([]);
  const [results, setResults] = useState([]);
  const autocompleteRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      autocompleteRef.current &&
      !autocompleteRef.current.contains(event.target)
    ) {
      setFilteredAutocompletedArticles([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (ev) => {
    ev.preventDefault();
    const { value } = ev.target;
    setSearchTerm(value);
    debouncedAutocomplete(value);
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        setSelectedItemIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        break;
      case 'ArrowDown':
        setSelectedItemIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredAutocompletedArticles.length - 1)
        );
        break;
      case 'Enter':
        if (selectedItemIndex !== -1) {
          const selectedArticle =
            filteredAutocompletedArticles[selectedItemIndex];
          handleSelectArticle(selectedArticle);
        } else {
          handleSearch(searchTerm);
        }
        break;
      default:
        break;
    }
  };

  const handleSelectArticle = (article) => {
    props.handleFilterSearcher(article.repr);
    setSearchTerm(article.repr); // Actualiza el término de búsqueda
    setResults([article]); // Actualiza los resultados para mostrar en SearchArticleList

    // Configura los parámetros adicionales según sea necesario
    props.setHandleUpperCategoryIdParam(
      '133' +
        (props.userInfoSSFF.custom15 === 'Y' ||
        props.userInfoSSFF.division === 'CHIEF PEOPLE OFFICER (DI90009)'
          ? ',138'
          : '') +
        (props.userInfoSSFF.custom15 === 'Y' ? ',40' : '')
    );
  };

  const handleSearch = (term) => {
    const filteredResults = props.autocompleteArticles.filter((article) =>
      article.repr.toLowerCase().includes(term.toLowerCase())
    );
    setResults(filteredResults); // Actualiza los resultados
    console.log(filteredResults);
  };

  const debouncedAutocomplete = useCallback(
    debounce((value) => {
      const filteredResults = props.autocompleteArticles.filter((article) =>
        article.repr.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredAutocompletedArticles(filteredResults);
    }, 300),
    [props.autocompleteArticles]
  );

  return (
    <div className='searcher__container'>
      <img src={iconSearch} alt='search_icon' className='icon' />
      <p className='¿Qué necesitas?'></p>
      <input
        type='text'
        placeholder='Nóminas, vacaciones...'
        autoComplete='off'
        className='search__input'
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={searchTerm}
      />
      {searchTerm !== '' && filteredAutocompletedArticles.length > 0 && (
        <ul className='autocomplete__container' ref={autocompleteRef}>
          {filteredAutocompletedArticles.map((article, index) => (
            <li
              key={article.id}
              className={`autocomplete__item ${
                index === selectedItemIndex ? 'selected' : ''
              }`}
              onClick={() => handleSelectArticle(article)}
            >
              {article.repr}
            </li>
          ))}
        </ul>
      )}
      {results.length > 0 && (
        <SearchArticleList
          filterInSearcher={searchTerm}
          handleUpperCategoryIdParam={props.handleUpperCategoryIdParam}
          userInfoSSFF={props.userInfoSSFF}
          /* results={results} // Pasa los resultados filtrados */
          handleFilterSearcher={props.handleFilterSearcher} // Pasa la función de búsqueda
        />
      )}
    </div>
  );
};

function debounce(func, delay) {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

export default ContactUsInbentaInfo;
