import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';
import {
  createTicket,
  USER_TYPE_TO_INBENTA_ID,
  isUserExist,
} from '../../../services/api';
const SharedPayment = ({ userInfoSSFF }) => {
  const [nombreApellidos, setNombreApellidos] = useState('');
  const [opcion, setOpcion] = useState('pagoCompartido');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showOpcionRequired, setShowOpcionRequired] = useState(false);
  const [showNombreApellidosRequired, setShowNombreApellidosRequired] =
    useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);

  useEffect(() => {
    const areFieldsValid = nombreApellidos.trim() !== '' && opcion !== '';
    setIsButtonEnabled(areFieldsValid);
  }, [nombreApellidos, opcion]);

  const handleNombreApellidosChange = (e) => {
    setNombreApellidos(e.target.value);
    setShowNombreApellidosRequired(false);
  };

  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
    setShowOpcionRequired(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid = nombreApellidos.trim() !== '' && opcion !== '';
    if (nombreApellidos === '') {
      setShowNombreApellidosRequired(true);
    }
    if (opcion === '') {
      setShowOpcionRequired(true);
    } else if (areFieldsValid) {
      try {
        setIsSubmitting(true);
        const dataUserInbenta = await isUserExist(userInfoSSFF.username);

        const sanitizedNombreApellidos = DOMpurify.sanitize(nombreApellidos);
        const sanitizedOpcion = DOMpurify.sanitize(opcion);

        const message = `
      <div style="background-color: transparent;">
        <div><br /></div>
        1. EMAIL CORPORATIVO
        <br />
        <b>${userInfoSSFF.username}</b>
        <div><br /></div>
        2. NOMBRE Y APELLIDOS
        <br />
        <b>${sanitizedNombreApellidos}</b>
        <div><br /></div>
        3. EMPRESA
        <br />
        <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
        <div><br /></div>
        4. TE INTERESA SOLICITAR (A elegir)
        <br />
        <b>${sanitizedOpcion}</b>
        <div><br /></div>
      </div>
    `;

        await createTicket(
          'Temporalmente no disponible - Solicitud alta en Pago Compartido',
          message,
          userInfoSSFF.custom03,
          1,
          [],
          dataUserInbenta.data[0].id
        );

        setNombreApellidos('');
        setOpcion('');
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  return (
    <>
      <section className="formArticle">
        <h1 className="formArticle__title">
          Solicitud alta en Pago Compartido - Temporalmente no disponible
        </h1>
        <p className="formArticle__text">
          Te informamos que la solicitud de nuevas peticiones de adhesión al
          beneficio de Pago Compartido, estará temporalmente deshabilitada. Esto
          se debe a que Telefonica España, que es quien gestiona el servicio,
          está llevando a cabo una serie de cambios en sus procesos internos, e
          incorporando la nueva oferta de servicios de “miMovistar”. No
          obstante, si estás interesado en solicitar este beneficio o hacer
          algún cambio en el servicio que actualmente tienes contratado, déjanos
          tus datos y Nos pondremos en contacto contigo en cuanto tengamos más
          información.
        </p>
        <form
          target="_blank"
          className="formArticle__form"
          action="#"
          onSubmit={handleSubmit}
        >
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="nombreApellidos">
              Nombre y Apellidos
              {showNombreApellidosRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={nombreApellidos}
              onChange={handleNombreApellidosChange}
            />
          </div>
          <div className="formArticleSection">
            <label htmlFor="formArticle__option" className="formArticleLabel">
              Elige la opción quieres solicitar
              {showOpcionRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <div className="formArticle_radio">
              <input
                type="radio"
                name="formArticle__option"
                value="Nuevo alta en pago compartido"
                defaultChecked
                onChange={handleOpcionChange}
              />
              <label htmlFor="Nuevo alta en pago compartido">
                {' '}
                Nuevo alta en pago compartido
              </label>
              <br></br>
              <input
                type="radio"
                name="formArticle__option"
                value="Modificación por cambio de domicilio"
                checked={opcion === 'Modificación por cambio de domicilio'}
                onChange={handleOpcionChange}
              />
              <label htmlFor="Modificación por cambio de domicilio">
                {' '}
                Modificación por cambio de domicilio
              </label>
              <br></br>
              <input
                type="radio"
                name="formArticle__option"
                value="Cambio en el paquete de servicios contratado"
                checked={
                  opcion === 'Cambio en el paquete de servicios contratado'
                }
                onChange={handleOpcionChange}
              />
              <label htmlFor="cambioPaquete">
                {' '}
                Cambio en el paquete de servicios contratado
              </label>
            </div>
          </div>
          <div className="formArticleButton__section">
            <button
              className={`formArticleButton ${
                isButtonEnabled ? 'enabled' : 'disabled'
              }`}
              type="submit"
            >
              Enviar
            </button>
            {isSubmitting && (
              // SPINNER LOADING
              <div class="loader"></div>
            )}
          </div>
        </form>
        <div className="contactTextContainer">
          <p className="formArticle__contact">
            Para cualquier duda, contacta al buzón{' '}
            <a href="mailto:preguntanos@personas.telefonica.com">
              preguntanos@personas.telefonica.com
            </a>
          </p>
        </div>
        {feedbackPopup && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content">
              <div className="dislikePopup__sectionTitle">
                <h1 className="dislikePopup__sectionTitle--title spacing">
                  Solicitud enviada correctamente
                </h1>
              </div>
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  Nos pondremos en contacto contigo a la mayor brevedad posible
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content failurePopup">
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button failureButton"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default SharedPayment;
