export const ValidateForm = (fields) => {
  const {
    businessUnit,
    division,
    departamento,
    needType,
    vacancyType,
    replacementName,
    replaceByIntern,
    internshipWindow,
    postInternship,
    rationale,
    criticality,
    responsibilities,
    skills,
    project,
    location,
    desiredDate,
    hiringManager,
    costCenter,
    globalRole,
    benchmark,
    locationHQ,
    jobClassification,
    vacancySSFFID,
    roleTitle,
    criticalProfile,
    hrbp,
    type,
    legalEntity,
    finalCandidateName,
    corporateMail,
    personalEmail,
    finalLocalCategoryGradeOfHiring,
    finalGlobalCategoryGradeOfHiring,
    countryFinalCandidate,
    needsInternationalMobility,
    salary,
    targetBonus,
    currency,
    isContractor,
    revolvingDoors,
    contractType,
  } = fields;

  const errors = {};

  // Validaciones Generales
  if (!businessUnit || businessUnit.trim() === '') errors.businessUnit = '*';
  if (!division || division.trim() === '') errors.division = '*';
  if (!departamento || departamento.trim() === '') errors.departamento = '*';
  if (!location || location.trim() === '') errors.location = '*';
  if (!desiredDate || desiredDate.trim() === '') errors.desiredDate = '*';

  // Validaciones Necesidad de Incorporación
  if (!needType || needType.trim() === '') {
    errors.needType = '*';
  } else if (needType === 'vacante') {
    // Validaciones específicas para "vacante"
    if (!vacancyType || vacancyType.trim() === '') errors.vacancyType = '*';
    if (
      vacancyType === 'sustitucion' &&
      (!replacementName || replacementName.trim() === '')
    ) {
      errors.replacementName = '*';
    }
    if (
      vacancyType === 'nueva' &&
      (!replaceByIntern || replaceByIntern.trim() === '')
    ) {
      errors.replaceByIntern = '*';
    }
  } else if (needType === 'beca') {
    // Validaciones específicas para "beca"
    if (!internshipWindow || internshipWindow.trim() === '')
      errors.internshipWindow = '*';
    if (!postInternship || postInternship.trim() === '')
      errors.postInternship = '*';
  }

  // Validaciones Adicionales
  if (!rationale || rationale.trim() === '') errors.rationale = '*';
  if (!criticality || criticality.trim() === '') errors.criticality = '*';
  if (!responsibilities || responsibilities.trim() === '')
    errors.responsibilities = '*';
  if (!skills || skills.trim() === '') errors.skills = '*';
  if (!project || project.trim() === '') errors.project = '*';

  //Validaciones formulario de canal de manager
  if (!hiringManager || hiringManager.trim() === '') errors.hiringManager = '*';
  if (!costCenter || costCenter.trim() === '') errors.costCenter = '*';
  if (!globalRole || globalRole.trim() === '') errors.globalRole = '*';
  if (!benchmark || benchmark.trim() === '') errors.benchmark = '*';
  if (!locationHQ || locationHQ.trim() === '') errors.locationHQ = '*';
  if (!jobClassification || jobClassification.trim() === '')
    errors.jobClassification = '*';

  //Validaciones formulario de recruitment
  if (!vacancySSFFID || vacancySSFFID.trim() === '') errors.vacancySSFFID = '*';
  if (!roleTitle || roleTitle.trim() === '') errors.roleTitle = '*';
  if (!criticalProfile || criticalProfile.trim() === '')
    errors.criticalProfile = '*';
  if (!hrbp || hrbp.trim() === '') errors.hrbp = '*';
  if (!type || type.trim() === '') errors.type = '*';
  if (!legalEntity || legalEntity.trim() === '') errors.legalEntity = '*';
  if (!finalCandidateName || finalCandidateName.trim() === '')
    errors.finalCandidateName = '*';
  if (!corporateMail || corporateMail.trim() === '') errors.corporateMail = '*';
  if (!personalEmail || personalEmail.trim() === '') errors.personalEmail = '*';
  if (
    !finalLocalCategoryGradeOfHiring ||
    finalLocalCategoryGradeOfHiring.trim() === ''
  )
    errors.finalLocalCategoryGradeOfHiring = '*';
  if (
    !finalGlobalCategoryGradeOfHiring ||
    finalGlobalCategoryGradeOfHiring.trim() === ''
  )
    errors.finalGlobalCategoryGradeOfHiring = '*';
  if (!countryFinalCandidate || countryFinalCandidate.trim() === '')
    errors.countryFinalCandidate = '*';
  if (!needsInternationalMobility || needsInternationalMobility.trim() === '')
    errors.needsInternationalMobility = '*';
  if (!salary || salary.trim() === '') errors.salary = '*';
  if (!targetBonus || targetBonus.trim() === '') errors.targetBonus = '*';
  if (!currency || currency.trim() === '') errors.currency = '*';
  if (!isContractor || isContractor.trim() === '') errors.isContractor = '*';
  if (!revolvingDoors || revolvingDoors.trim() === '')
    errors.revolvingDoors = '*';
  if (!contractType || contractType.trim() === '') errors.contractType = '*';

  console.log('errores', errors);
  return errors;
};
