import '../styles/about.css';
import elipse355 from '../../images/ellipse355.png';
import employees from '../../images/employees.png';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <section className="about">
        {/* Breadcrumb */}
        <div className="about__breadcrumb">
          <img src={elipse355} alt="circle-icon" className="circle-icon" />
          <h4>SOBRE NOSOTROS</h4>
        </div>

        <section className="about__content">
          <img src={employees} alt="employees-faces" className="employees" />
          <p className="about__description">
            En el equipo de Personas de Telefónica apostamos fuertemente por empoderar a todas las personas para liderar el cambio que nos permita construir la Telefónica de los próximos 100 años.
          </p>
          <Link to="/aboutUs" className="about__link">
            Leer más
          </Link>
        </section>
      </section>
    </>
  );
};

export default About;
