import React from 'react';
import Popup from './popUp';

const ContactInfoWithPopup = ({
  feedbackPopup,
  messageSendingFailed,
  handleCloseFeedbackPopup,
}) => {
  return (
    <div>
      <div className='contactTextContainer'>
        <p className='formArticle__contact'>
          Para cualquier duda, contacta al buzón{' '}
          <a href='mailto:preguntanos@personas.telefonica.com'>
            preguntanos@personas.telefonica.com
          </a>
        </p>
      </div>

      {/* Mostrar el popup */}
      {feedbackPopup && (
        <Popup
          type='success'
          message='Nos pondremos en contacto contigo a la mayor brevedad posible'
          onClose={handleCloseFeedbackPopup}
        />
      )}
      {messageSendingFailed && (
        <Popup
          type='failure'
          message='No se ha podido enviar su mensaje. Inténtelo de nuevo.'
          onClose={handleCloseFeedbackPopup}
        />
      )}
    </div>
  );
};

export default ContactInfoWithPopup;
