import React, { useState } from 'react';
import { downloadPDFTermination } from './dowload';
import { readFileAsBase64, ALLOWED_TYPE_FILES } from '../../../utils/utils';
import clipImage from '../../../images/clip-light.png';
import deleteFileIcon from '../../../images/PeoplExCancel.svg';
import { downloadAttachmentJira } from '../../../services/apiJira';
const FormActions = ({ isButtonEnabled, isSubmitting, feedbackPopup, messageSendingFailed, handleCloseFeedbackPopup, setFiles, files }) => {
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);
  const [showWarning, setShowWarning] = useState(false); // Estado para la advertencia de falta de adjuntos

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    const newFiles = [];
    for (let file of selectedFiles) {
      if (ALLOWED_TYPE_FILES.includes(file.name.split('.').pop())) {
        // const hasExcedenciaFirmada = files.some((f) => f.filename === 'excedencia_firmada.pdf');
        // const newFileName = hasExcedenciaFirmada ? 'finiquito.pdf' : 'excedencia_firmada.pdf';
        // const renamedFile = new File([file], newFileName, { type: file.type });
        // newFiles.push(renamedFile);
        newFiles.push(file);
        setShowFormatNotSupported(false);
      } else {
        setShowFormatNotSupported(true);
      }
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setShowWarning(false);
    }
  };

  const handleSubmitClick = (e) => {
    if (files.length === 0) {
      e.preventDefault(); // Evita el envío si no hay archivos
      setShowWarning(true); // Muestra la advertencia
    }
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    document.getElementById('files').value = null;
  };

  const handleDownload = async (attachmentId) => {
    try {
      // Llamamos a la función que se encarga de descargar el archivo
      const downloadResult = await downloadAttachmentJira(attachmentId);

      if (downloadResult) {
        console.log('Archivo descargado con éxito.');
      }
    } catch (error) {
      console.error('Error al descargar el archivo:', error);

      if (error.response) {
        console.error('Error message:', error.message);
      }
    }
  };

  return (
    <div className='formJiraEndActions'>
      {showFormatNotSupported && (
        <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
      )}
      {files.length !== 0 && (
        <div className='assistancePopup__fileSection'>
          {files.map((file, index) => (
            <>
              <div className={'assistancePopup__fileSection__fileSelected'}>
                <span
                  onClick={() => handleDownload(file.id)} // Asegúrate de que file.id sea el ID correcto del adjunto
                  className={'assistancePopup__fileSection--fileName'}
                  style={{ cursor: 'pointer' }}
                >
                  {file.name} {file.filename}
                </span>
                <img
                  className='assistancePopup__fileSection--deleteFileIcon	'
                  src={deleteFileIcon}
                  alt='deleteFile'
                  onClick={() => removeFile(index)}
                />
              </div>
            </>
          ))}
        </div>
      )}
      <div className='formJiraEndButton__section'>
        {showWarning && <p className='formArticleLabel__required'>Debes adjuntar la documentación para poder enviar la solicitud.</p>}
        <label className='assistancePopup__uploadFiles'>
          <input onChange={handleFileChange} type='file' className='assistancePopup__uploadFiles--input' id='files' />
          <label for='files'>
            <img src={clipImage} className='assistancePopup__uploadFiles--clip' alt='Adjuntar archivos' />
          </label>
        </label>
        <button
          className={`formJiraEndButton ${isButtonEnabled ? 'enabled' : 'disabled'}`}
          type='submit'
          disabled={!isButtonEnabled}
          onClick={handleSubmitClick}
        >
          Enviar
        </button>
        {isSubmitting && <div className='loader'></div>}
      </div>
      <div className='contactTextContainer-formJiraEnd'>
        <p className='formJiraEnd__contact'>
          Para cualquier duda, puedes consultar más información en PeoplEX o ponerte en contacto con nosotros a través del canal que aparece en la
          página principal.
        </p>
      </div>
      {feedbackPopup && (
        <div className='dislikePopup-overlay'>
          <div className='dislikePopup-content'>
            <div className='dislikePopup__sectionTitle'>
              <h1 className='dislikePopup__sectionTitle--title spacing'>Aviso</h1>
            </div>
            <div className='dislikePopup__sectionText'>
              <p className='timeOutPopup__sectionText--question'>
                <p className='timeOutPopup__sectionText--text'>
                  Estamos trabajando en tu solicitud. En breve, recibirás por mail una confirmación con todos los datos que has enviado. A
                  continuación, te dejamos información relevante sobre tu baja:
                </p>

                <strong>¿Tienes gastos profesionales o gastos de tarjeta de empresa pendientes?</strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Recuerda que si tienes gastos pendientes debes liquidarlos cuanto antes para que puedan ser incluidos en la liquidación.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Tienes disponibilidades o guardias pendientes? </strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                En el caso de tener disponibilidades o guardias pendientes de percibir, consulta con tu responsable para que las informe cuanto antes
                para que puedan ser incluidas en la liquidación.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Qué ocurre con tu taquilla y con los enseres personales que guardas en ella? </strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Deberás devolver la llave de la taquilla a Servicios Generales como tarde el día de tu baja. Si pasados 15 días desde tu salida no has
                retirado tus enseres personales, se dará instrucciones de vaciar la taquilla y destruir de forma confidencial todo lo que se encuentre
                dentro.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Qué debes hacer con la tarjeta de acceso? </strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Deberás devolver la tarjeta de acceso en seguridad el último día que acudas al edificio (una vez que no necesites volver a entrar al
                edificio)
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Qué debes hacer con tu equipo (portátil, etc)? </strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                El equipo It se pondrán en contacto contigo para darte las instrucciones oportunas para la devolución del equipo.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Tengo que solicitar la baja si me voy a otra empresa del grupo? </strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Sí, es necesario que solicites la baja a través de PeoplEX. Si tienes dudas en cuanto a la fecha de baja, puedes comentarlo con ambos
                managers (el de la empresa destino y tu manager actual) para ver la fecha más adecuada para las tres partes, siempre teniendo en
                cuenta los días de preaviso.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Necesitas descargar los certificados de formación?</strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Si quieres descargar los certificados de las formaciones que has realizado, deberás hacerlo antes de que tu usuario de SuccessFactors
                esté desactivado. Para poder acceder al contenido, debes entrar en SuccessFactors- Mis asignaciones de aprendizaje.
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Una vez dentro, haciendo clic en “Mis Asignaciones” y posteriormente “Historial de Aprendizaje”, “Ver todo”. Aquí podrás ver uno a uno
                todos los contenidos y descargar el diploma correspondiente. En caso de dudas puedes contactar con{' '}
                <a href='mailto:training_gbus@telefonica.com'>training_gbus@telefonica.com</a>.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Tienes plan de pensiones con Telefónica? </strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Para movilizar el plan de pensiones a otra entidad, deberás acceder a la web de Fonditel y descargarte la orden de movilización que se
                encuentra dentro del plan en el apartado: “Más información” - “Impresos”. A continuación, deberás ponerte en contacto con Fonditel y
                seguir las instrucciones que te proporcionen. Ten en cuenta que no podrás movilizar el plan de pensiones si solicitas una excedencia
                hasta que esta finalice o deje de estar vigente.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>¿Tienes alguna duda o necesitas modificar datos de tu baja?</strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Si tienes cualquier duda adicional o necesitas modificar algún dato relativo a tu baja, por favor ponte en contacto con{' '}
                <a href='mailto:preguntanos.personas@telefonica.com'>preguntanos.personas@telefonica.com</a> indicando el ID de tu solicitud.
              </p>

              <p className='timeOutPopup__sectionText--question'>
                <strong>Consulta la información sobre beneficios en Peoplex.</strong>
              </p>
              <p className='timeOutPopup__sectionText--text'>
                Recuerda que tienes accesible en Peoplex toda la información sobre cómo gestionar tus beneficios de cara a la baja.
              </p>
            </div>
            <div className='contactMeJiraSectionButton'>
              <button className='contactMePopup--button' onClick={handleCloseFeedbackPopup}>
                Aceptar
              </button>
              <button onClick={downloadPDFTermination} className='contactMePopup--button'>
                Descargar Información
              </button>
            </div>
          </div>
        </div>
      )}
      {messageSendingFailed && (
        <div className='dislikePopup-overlay'>
          <div className='dislikePopup-content failurePopup'>
            <div className='dislikePopup__sectionText'>
              <p className='timeOutPopup__sectionText--text'>
                No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
              </p>
            </div>
            <div className='contactMeSectionButton'>
              <button className='contactMePopup--button failureButton' onClick={handleCloseFeedbackPopup}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormActions;
