import { useParams } from 'react-router-dom';
import Layout from './layout';
import Article1 from './article1';
import Article2 from './article2';
import Article3 from './article3';

const DynamicArticlePage = ({ userInfoSSFF, handleUpperCategoryIdParam }) => {
  const { id } = useParams(); // Obtén el parámetro dinámico de la URL

  // Renderiza el componente correspondiente basado en el ID
  const renderArticleComponent = () => {
    switch (id) {
      case '1':
        return <Article1 userInfoSSFF={userInfoSSFF} handleUpperCategoryIdParam={handleUpperCategoryIdParam} />;
      case '2':
        return <Article2 userInfoSSFF={userInfoSSFF} handleUpperCategoryIdParam={handleUpperCategoryIdParam} />;

      case '3':
        return <Article3 userInfoSSFF={userInfoSSFF} handleUpperCategoryIdParam={handleUpperCategoryIdParam} />;

      default:
        return <div>Artículo no encontrado</div>;
    }
  };
  return <Layout>{renderArticleComponent()}</Layout>;
};

export default DynamicArticlePage;
