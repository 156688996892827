import React, { useEffect, useState } from 'react';
import { validateFields } from './validateFields';

export const RoleChangeComponent = ({
	roleChange, setRoleChange,
	roleChangeDetails, setRoleChangeDetails,
	managerChange, setManagerChange,
	newManager, setNewManager,
	changeDetails, setChangeDetails,
	globalRole, setGlobalRole,
	jobClassification, setJobClassification,
	category, setCategory,
	benchmarkCode, setBenchmarkCode,
	errors, setErrors,
  }) => {
	const [showWarning, setShowWarning] = React.useState(false);
	const [initialValues, setInitialValues] = useState({});
	
	useEffect(() => {
		setInitialValues({
		  roleChange,
		  roleChangeDetails,
		  managerChange,
		  newManager,
		  changeDetails,
		  globalRole,
		  jobClassification,
		  category,
		  benchmarkCode,
		});
	}, []); 
	console.log('initialValues', initialValues);
	useEffect(() => {
		const fields = {
		  roleChange,
		  roleChangeDetails,
		  managerChange,
		  newManager,
		  changeDetails,
		  benchmarkCode,
		  globalRole,
		  jobClassification,
		  category,  
		};
		const validationErrors = validateFields(fields);
		setErrors(validationErrors);
		setShowWarning(Object.keys(validationErrors).length > 0);
	}, [ roleChange, roleChangeDetails, managerChange, newManager, changeDetails, benchmarkCode, globalRole, jobClassification, category, changeDetails ]);
	
	const getInputClass = (fieldValue, fieldName) => {
		
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeInputText prefilledBorder'; 
		} 
		return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
	};
	const getRadioClass = (fieldValue, fieldName) => {
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeRadio prefilledBorder';
		}
		return errors[fieldName] ? 'formJiraChangeRadio errorBorder' : 'formJiraChangeRadio';
	};	

	const renderLabel = (labelText, fieldName) => {
		return (
		  <label className="formJiraChangeLabel">
			{errors[fieldName] && '*'} {labelText}
		  </label>
		);
	};

	useEffect(() => {
		if (roleChangeDetails) {
		  setRoleChange('yes');
		}
	}, [roleChangeDetails]);
	
	return (
	<div className="formJiraChangeCheckboxGroup">
	  <div className='formJiraChangeInfoTittle'>
		Cambio de Rol
	  </div>
	  <div className="formJiraChangeRadioGroup">
		<div className="formJiraChangeRadioLabel">
		  <label className="formJiraChangeLabel">
		  {renderLabel('¿Es un cambio de rol?', 'roleChange')}
		  </label>
		  <div  className="formJiraChangeRadio">
			<label className='formJiraChangeLabelRadio'>
			  <input
				type="radio"
				name="roleChange"
				className= {getRadioClass(roleChange, 'roleChange')}
				value="yes"
				checked={roleChange === 'yes'}
				onChange={() => setRoleChange('yes')}
			  />
			  Sí
			</label>
			<label className='formJiraChangeLabelRadio'>
			  <input
				type="radio"
				name="roleChange"
				className= {getRadioClass(roleChange,'roleChange')}
				value="no"
				checked={roleChange === 'no'}
				onChange={() => setRoleChange('no')}
			  />
			  No
			</label>
		  </div>
		</div>
  
		{roleChange === 'yes' && (
		  <div className='formJiraChangeRadioGroup'>
			<label className="formJiraChangeLabel">
				{renderLabel('¿Puedes indicarnos en qué consiste el cambio de rol y si es necesario un cambio de puesto?', 'roleChangeDetails')}
			</label>
			<input
			  type="text"
			  className={getInputClass(roleChangeDetails, 'roleChangeDetails')}
			  value={roleChangeDetails}
			  onChange={e => setRoleChangeDetails(e.target.value)}
			  placeholder="Detalles del cambio de rol..."
			/>
			<div className="formJiraChangeRadioLabel">
			  <label className="formJiraChangeLabel">
			  	{renderLabel('¿Cambia de manager o de equipo?', 'managerChange')}
			  </label>
			  <div className="formJiraChangeRadio">
				<label className='formJiraChangeLabelRadio'>
				  <input
					type="radio"
					name="managerChange"
					className={getRadioClass(managerChange, 'managerChange')}
					value="yes"
					checked={managerChange === 'yes'}
					onChange={() => setManagerChange('yes')}
				  />
				  Sí
				</label>
				<label className='formJiraChangeLabelRadio'>
				  <input
					type="radio"
					name="managerChange"
					className={getRadioClass(managerChange, 'managerChange')}
					value="no"
					checked={managerChange === 'no'}
					onChange={() => setManagerChange('no')}
				  />
				  No
				</label>
			  </div>
			</div>
			{managerChange === 'yes' && (
			  <label className="formJiraChangeLabel">
				{renderLabel('Nuevo Manager:', 'newManager')}
				<input
				  type="text"
				  className={getInputClass(newManager, 'newManager')}
				  value={newManager}
				  onChange={e => setNewManager(e.target.value)}
				  placeholder="Nombre del nuevo manager"
				/>
			  </label>
			)}
		  </div>
		)}
  
		{roleChange === 'no' && (
		  <div className='formJiraChangeRadioGroup'>
			<label className="formJiraChangeLabel">
				{renderLabel('¿Qué cambio quieres realizar?', 'changeDetails')}
			</label>
			<input
			  type="text"
			  className=  {getInputClass(changeDetails, 'changeDetails')}
			  value={changeDetails}
			  onChange={e => setChangeDetails(e.target.value)}
			  placeholder="Detalles del cambio..."
			/>
			<label className="formJiraChangeLabel">
			  {renderLabel('Rol global:', 'globalRole')}
			  <input
				type="text"
				className={getInputClass(globalRole, 'globalRole')}
				value={globalRole}
				onChange={e => setGlobalRole(e.target.value)}
				placeholder="Rol global"
			  />
			</label>
			<label className="formJiraChangeLabel">
			  {renderLabel('Puesto (Job Classification):', 'jobClassification')}
			  <input
				type="text"
				className={getInputClass(jobClassification, 'jobClassification')}
				value={jobClassification}
				onChange={e => setJobClassification(e.target.value)}
				placeholder="Puesto (Job Classification)"
			  />
			</label>
			<label className="formJiraChangeLabel">
			  {renderLabel('Categoría:,', 'category')}
			  <input
				type="text"
				className= {getInputClass(category, 'category')}
				value={category}
				onChange={e => setCategory(e.target.value)}
				placeholder="Categoría"
			  />
			</label>
			<label className="formJiraChangeLabel">
			  {renderLabel('Código Benchmark:,', 'benchmarkCode')}
			  <input
				type="text"
				className={getInputClass(benchmarkCode, 'benchmarkCode')}
				value={benchmarkCode}
				onChange={e => setBenchmarkCode(e.target.value)}
				placeholder="Código Benchmark"
			  />
			</label>
		  </div>
		)}
	  </div>
	  {showWarning && (
			<div className="formJiraChangeWarningMessage">
				*Es necesario completar todos los campos indicados en rojo
			</div>
		)}
	</div>
  );
};