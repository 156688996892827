import '../styles/contactUs-buttonPaises.css';
import clipImage from '../../images/clip-light.png';
import deleteFileIcon from '../../images/PeoplExCancel.svg';
import { useState } from 'react';
import { attachMedia, createTicket, USER_TYPE_TO_INBENTA_ID, isUserExist } from '../../services/api';
import { readFileAsBase64, ALLOWED_TYPE_FILES } from '../../utils/utils';

const QUEUE_EMPLEADO = parseInt(process.env.REACT_APP_QUEUE_EMPLEADO);
const QUEUE_MANAGER = parseInt(process.env.REACT_APP_QUEUE_MANAGER);

const ContactUsButtonPaises = ({ userInfoSSFF }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [personalAssistanceSelected, setPersonalAssistanceSelected] = useState(false);
  const [teamAssistanceSelected, setTeamAssistanceSelected] = useState(false);
  const [showFormatNotSupported, setShowFormatNotSupported] = useState(false);
  const [questionSentPopup, setQuestionSentPopup] = useState(false);

  // Funciones

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (ALLOWED_TYPE_FILES.includes(selectedFiles[0].name.split('.').pop())) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setShowFormatNotSupported(false);
    } else {
      setShowFormatNotSupported(true);
    }
  };

  const questionSent = async () => {
    let titleTicket = 'SOPORTE EMPLEADO';
    let queue = QUEUE_EMPLEADO;

    try {
      setIsSubmitting(true);
      const dataUserInbenta = await isUserExist(userInfoSSFF.username);

      const message = `
      <div bgcolor="transparent">
      <div><br /></div>
      1. EMAIL CORPORATIVO
      <br />
      <b>${userInfoSSFF.username}</b>
      <div><br /></div>
      2. NOMBRE Y APELLIDOS
      <br />
      <b>${dataUserInbenta.data[0].name}</b>
      <div><br /></div>
      3. JURIDICA
      <br />
      <b>${USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName}</b>
      <div><br /></div>
      4. DIVISIÓN
      <br />
      <b>${userInfoSSFF.division}</b>
      <div><br /></div>
      5. AREA
      <br />
      <b>${userInfoSSFF.custom02}</b>
      <div><br /></div>
      6. DEPARTAMENTO
      <br />
      <b>${userInfoSSFF.department}</b>
      <div><br /></div>
      7. CONSULTA
      <br />
      <b>${feedbackText}</b>
      <div><br /></div>
      </div>`;

      if (teamAssistanceSelected) {
        queue = QUEUE_MANAGER;
        titleTicket = 'SOPORTE MANAGER';
      }

      let uuidsMediaArray = [];
      await Promise.all(
        files.map(async (file) => {
          const base64 = await readFileAsBase64(file);
          const uuidMedia = await attachMedia(file.name, base64);
          uuidsMediaArray.push(uuidMedia);
        })
      );

      await createTicket(titleTicket, message, userInfoSSFF.custom03, queue, uuidsMediaArray, dataUserInbenta.data[0].id);

      setFiles([]);
      setPersonalAssistanceSelected(false);
      setTeamAssistanceSelected(false);
      setQuestionSentPopup(true);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setMessageSendingFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePersonalAssistanceChange = () => {
    setPersonalAssistanceSelected(!personalAssistanceSelected);
    setTeamAssistanceSelected(false);
    setFeedbackText('');
    setFiles([]);
  };

  const handleTeamAssistanceChange = () => {
    setTeamAssistanceSelected(!teamAssistanceSelected);
    setPersonalAssistanceSelected(false);
    setFeedbackText('');
    setFiles([]);
  };

  const handleFeedbackTextChange = (event) => {
    const text = event.target.value;
    setFeedbackText(text);
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const isSubmitEnabled = feedbackText || teamAssistanceSelected;

  return (
    <div className='assistancePopupPaises'>
      <div className='assistancePopup__content'>
        <div className='assistancePopup__titleSection'>
          <h3 className='assistancePopup__title'>¿En qué podemos ayudarte?</h3>
        </div>
        <div className='assistancePopup__textSection'>
          <p className='assistancePopup__message'>Contactaremos contigo a la mayor brevedad posible.</p>
          <div className='assistancePopup__textOptions'>
            <form>
              <div className='assistancePopup__inputsContainer'>
                <div className='assistancePopup__option'>
                  <input
                    type='radio'
                    id='personal'
                    name='assistance'
                    checked={personalAssistanceSelected}
                    onChange={handlePersonalAssistanceChange}
                  />
                  <label htmlFor='personal' className='assistancePopup__textOptions--label'>
                    Tengo una necesidad personal
                    <p className='assistancePopup__textOptions--desc'>Por ejemplo: Mi nómina y beneficios, mis vacaciones y permisos...</p>
                  </label>
                </div>
                <div className='assistancePopup__option'>
                  <input type='radio' id='team' name='assistance' checked={teamAssistanceSelected} onChange={handleTeamAssistanceChange} />
                  <label htmlFor='team' className='assistancePopup__textOptions--label'>
                    Tengo una necesidad sobre mi equipo
                    <p className='assistancePopup__textOptions--desc'>
                      Por ejemplo: Atracción y retención de talento para mi equipo, formación y desarrollo para mi equipo...
                    </p>
                  </label>
                </div>
              </div>
            </form>
          </div>
          <textarea
            className='assistancePopup__feedbackText'
            placeholder='Cuéntanos qué necesitas.'
            value={feedbackText}
            onChange={handleFeedbackTextChange}
          ></textarea>
          {showFormatNotSupported && (
            <p className='formArticleLabel__required'>Formato de archivos no soportado. Utilice: {ALLOWED_TYPE_FILES.join(', ')}</p>
          )}
          <div className='assistancePopup__fileSection'>
            {files.map((file, index) => (
              <div key={index} className='assistancePopup__fileSection__fileSelected'>
                <p className='assistancePopup__fileSection--fileName'>{file.name}</p>
                <img
                  className='assistancePopup__fileSection--deleteFileIcon'
                  src={deleteFileIcon}
                  alt='Eliminar archivo'
                  onClick={() => removeFile(index)}
                />
              </div>
            ))}
          </div>
          <div className='assistancePopup__buttonSection'>
            <button className='assistancePopup__uploadFiles'>
              <input type='file' className='assistancePopup__uploadFiles--input' id='files' multiple onChange={handleFileChange} />
              <label htmlFor='files'>
                <img src={clipImage} className='assistancePopup__uploadFiles--clip' alt='Adjuntar archivos' />
              </label>
            </button>
            <button className={`assistancePopup__closeButton ${isSubmitEnabled ? 'enabled' : ''}`} onClick={questionSent} disabled={!isSubmitEnabled}>
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsButtonPaises;
