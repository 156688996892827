import React from 'react';
import NewJobClassificationForm from './newJobClassificationForm';
import NewPositionForm from './newPositionForm';
import { RadioGroup, SwitchGroup } from './inputs/radioGroup';


const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const GeneralInfoComponent = ({
  jcTitle, setJcTitle,
  jcLevel, setJcLevel,
  jcPayGrade, setJcPayGrade,
  jcTelefónicaLevel, setJcTelefónicaLevel,
  jcTelefónicaGrade, setJcTelefónicaGrade,
  legalEntity, setLegalEntity,
  jobFunction, setJobFunction,
  jcCreationDate, setJcCreationDate,
  effectiveDate,
  businessUnitPosition, setBusinessUnitPosition,
  divisionPosition, setDivisionPosition,
  departmentPosition, setDepartmentPosition,
  costCenterPosition, setCostCenterPosition,
  globalRolePosition, setGlobalRolePosition,
  benchmark, setBenchmark,
  locationPosition, setLocationPosition,
  jobClassificationPosition, setJobClassificationPosition,
  manager, setManager,
  isNewJobClasification, setIsNewJobClasification,
  isNewJobPosition, setIsNewJobPosition,
  handleEffectiveDateChange,
  showEffectiveDateInfo,
  showTEffectiveDateRequired,
  employeeName, managerEmail, managerName,
}) => {

  //const handleNewJobClassification = (e) => setIsNewJobClasification(e.target.checked);
  //const handleNewJobPosition = (e) => setIsNewJobPosition(e.target.checked);
  const handleNewJobClassification = (e) => setIsNewJobClasification(e.target.value === 'yes');
  const handleNewJobPosition = (e) => setIsNewJobPosition(e.target.value === 'yes');
  //const name = capitalizeFirstLetter(employeeName.split(' ')[0] || '');
  //const surname = capitalizeFirstLetter(employeeName.split(' ').slice(1).join(' ') || '');

  return (
    <div>
		<div className="formArticleSection">
	    <RadioGroup
          label="¿El cambio implica la creación de una nueva Job Classification?"
          name="newJobClasification"
		  options={[{ label: 'Sí', value: 'yes' }, { label: 'No', value: 'no' }]}
          selectedValue={isNewJobClasification ? 'yes' : 'no'}
          onChange={handleNewJobClassification}
        /> 
{/* 		<SwitchGroup
          label="¿El cambio implica la creación de una nueva Job Classification?"
          checked={isNewJobClasification}
          onChange={handleNewJobClassification}
        /> */}
		<NewJobClassificationForm 
			jcTitle={jcTitle}
			setJcTitle={setJcTitle}
			jcLevel={jcLevel}
			setJcLevel={setJcLevel}
			jcPayGrade={jcPayGrade}
			setJcPayGrade={setJcPayGrade}
			jcTelefónicaLevel={jcTelefónicaLevel}
			setJcTelefónicaLevel={setJcTelefónicaLevel}
			jcTelefónicaGrade={jcTelefónicaGrade}
			setJcTelefónicaGrade={setJcTelefónicaGrade}
			legalEntity={legalEntity}
			setLegalEntity={setLegalEntity}
			jobFunction={jobFunction}
			setJobFunction={setJobFunction}
			jcCreationDate={jcCreationDate}
			setJcCreationDate={setJcCreationDate}
			showForm={isNewJobClasification}
		/>
		</div>
		<div className="formArticleSection">
			<RadioGroup
				label="¿El cambio implica la creación de una nueva posición?"
				name="newJobPosition"
				options={[{ label: 'Sí', value: 'yes' }, { label: 'No', value: 'no' }]}
				selectedValue={isNewJobPosition ? 'yes' : 'no'}
				onChange={handleNewJobPosition}
			/>
			<NewPositionForm
				manager={manager} setManager={setManager}
				businessUnit={businessUnitPosition} setBusinessUnit={setBusinessUnitPosition}
				division={divisionPosition} setDivision={setDivisionPosition}
				department={departmentPosition} setDepartment={setDepartmentPosition}
				costCenter={costCenterPosition} setCostCenter={setCostCenterPosition}
				globalRolePosition={globalRolePosition} setGlobalRolePosition={setGlobalRolePosition}
				benchmark={benchmark} setBenchmark={setBenchmark}
				location={locationPosition} setLocation={setLocationPosition}
				jobClassification={jobClassificationPosition} setJobClassification={setJobClassificationPosition}
				showForm={isNewJobPosition} 
			/>
		</div>	
		<div className="formJiraChangeSection">
			<label className="formJiraChangeLabel_short" htmlFor="name"> Nombre del Empleado </label>
			<input
			type="text"
			className="formJiraChangeInputText"
			value={employeeName}
			readOnly
			/>
			{/* <label className="formJiraChangeLabel_short2" htmlFor="surname"> Apellidos del Empleado </label>
			<input
			type="text"
			className="formJiraChangeInputText"
			value={surname}
			readOnly
			/> */}
		</div>
		<div className="formJiraChangeSection">
			<label className="formJiraChangeLabel_short" htmlFor="ManagerEmail">
			Nombre del manager</label>
			<input
			type="email"
			className="formJiraChangeInputText"
			value={managerName}
			readOnly
			/>
		</div>
		<div className="formJiraChangeSection">
			<label className="formJiraChangeLabel_short" htmlFor="ManagerEmail">
			Email del manager</label>
			<input
			type="email"
			className="formJiraChangeInputText"
			value={managerEmail}
			readOnly
			/>
		</div>

		<div className="formJiraChangeSection">
			<label className="formJiraChangeLabel_short" htmlFor="effectiveDate">
			Fecha
			{showTEffectiveDateRequired && (
				<p className="formJiraChangeLabel__required">
				Este es un campo requerido
				</p>
			)}
			</label>
			<input
			type="date"
			placeholder="fecha"
			className="formJiraChangeInputText_short"
			value={effectiveDate}
			onChange={(e) => handleEffectiveDateChange(e)}
			/>
        {showEffectiveDateInfo && <p className="error">Estas seleccionando una fecha anterior a la actual</p>}
    	</div>
    </div>
  );
};

export default GeneralInfoComponent;
