
import React, { useEffect, useState } from 'react';
import { validateFields } from './validateFields';


export const LocationChangeComponent = ({  city, country, building, floor,  setCity, setCountry, setBuilding, setFloor, errors, setErrors }) => {
	const [showWarning, setShowWarning] = React.useState(false);
	const [initialValues, setInitialValues] = useState({});

	useEffect(() => {
		setInitialValues({
			city,
			country,
			building,
			floor,
		});
	}, []); 

	useEffect(() => {
		const fields = {
		  city,
		  country,
		  building,
		  floor,
		};
		const validationErrors = validateFields(fields);
		setErrors(validationErrors);
		setShowWarning(Object.keys(validationErrors).length > 0);
	  }, [ city, country, building, floor ]);
	
	const getInputClass = (fieldValue, fieldName) => {
		
		if (fieldValue && fieldValue === initialValues[fieldName]) {
			return 'formJiraChangeInputText prefilledBorder'; 
		} 
		return errors[fieldName] ? 'formJiraChangeInputText errorBorder' : 'formJiraChangeInputText';
	};
	const renderLabel = (labelText, fieldName, additionalClass = '') => {
		return (
		  <label className={`formJiraChangeLabel ${additionalClass}`}>
			{errors[fieldName] && '*'} {labelText}
		  </label>
		);
	  };
	  
	
	return (
		<div className="formJiraChangeCheckboxGroup_changes">
			<div className='formJiraChangeInfoTittle'>
				Cambios de localización
			</div>
			<div className="formJiraChangeSection_both">
				<label className="formJiraChangeLabel_bothIndividual">
					{renderLabel('Ciudad:', 'city', 'formJiraChangeLabel_bothIndividual')}	
				<input
					type="text"
					className={getInputClass(city, 'city')}
					value={city}
					onChange={e => setCity(e.target.value)}
					placeholder="Ciudad..."
				/>
				</label>
				<label className="formJiraChangeLabel_bothIndividual">
					{renderLabel('País:', 'country', 'formJiraChangeLabel_bothIndividual')}
				<input
					type="text"
					className={getInputClass(country, 'country')}
					value={country}
					onChange={e => setCountry(e.target.value)}
					placeholder="País..."
				/>
				</label> 
			</div>
			<div className='formJiraChangeSection_both'>
				<label className="formJiraChangeLabel_bothIndividual">
					{renderLabel('Edificio:', 'building', 'formJiraChangeLabel_bothIndividual')}
				<input
					type="text"
					className={getInputClass(building, 'building')}
					value={building}
					onChange={e => setBuilding(e.target.value)}
					placeholder="Edificio..."
				/>
				</label>
				<label className="formJiraChangeLabel_bothIndividual">
					{renderLabel('Planta:,', 'floor', 'formJiraChangeLabel_bothIndividual')}
				
				<input
					type="text"
					className={getInputClass(floor, 'floor')}
					value={floor}
					onChange={e => setFloor(e.target.value)}
					placeholder="Planta..."
				/>
				</label>
			</div>
			{showWarning && (
			<div className="formJiraChangeWarningMessage">
				*Es necesario completar todos los campos indicados en rojo
			</div>
		)}
		</div>
	);
};
