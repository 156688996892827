import { useNavigate } from 'react-router-dom';
import * as bootstrap from 'bootstrap';
import carrusel1 from '../../images/carrusel/carrusel1.png';
import carrusel2 from '../../images/carrusel/carrusel2.jpg';
import carrusel3 from '../../images/carrusel/carrusel3.jpg';
import carrousel4 from '../../images/carrusel/carrousel4.png';
import '../styles/carrusel.css';
import { useEffect } from 'react';

const Carrusel = () => {
  const navigate = useNavigate(); // Hook para redirección

  useEffect(() => {
    const myCarousel = document.querySelector('#carouselExampleIndicators');
    if (myCarousel) {
      new bootstrap.Carousel(myCarousel, {
        interval: 5000, // Cambia de slide cada 5 segundos
        ride: 'carousel', // Inicia automáticamente
        // interval: false, // Esto asegura que sea manual
        // ride: false, // Desactiva el inicio automático
      });
    }
  }, []);

  // Función para manejar los clics en los slides
  const handleSlideClick = (id) => {
    navigate(`/article/${id}`); // Redirige al artículo correspondiente
  };

  return (
    <section>
      <div id='carouselExampleIndicators' className='carousel slide'>
        <ol className='carousel-indicators'>
          <li data-bs-target='#carouselExampleIndicators' data-bs-slide-to='0' className='active'></li>
          <li data-bs-target='#carouselExampleIndicators' data-bs-slide-to='1'></li>
          <li data-bs-target='#carouselExampleIndicators' data-bs-slide-to='2'></li>
          <li data-bs-target='#carouselExampleIndicators' data-bs-slide-to='3'></li>
        </ol>
        <div className='carousel-inner' style={{ maxHeight: '300px' }}>
          {/* Carrusel y Texto + Descripción base para el carrusel:  */}
          {/* <CarruselCard
               icono='https://via.placeholder.com/40' // Reemplaza con la URL de tu icono               
               titulo='Título de la Etiqueta 1'
               descripcion='Descripción para la primera imagen'
               botonAccion='Acción'
               botonLink='#'
             /> */}
          {/* <div className='carousel-caption d-none d-md-block'>
               <h5>Slide 1</h5>
               <p>Descripción de la imagen 1</p>
             </div> */}

          {/* PERFORMANCE */}
          <div
            className='carousel-item active'
            onClick={() => handleSlideClick(1)} // Redirige al artículo 1
            style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clickeable
          >
            <img src={carrusel1} className='d-block w-100' alt='Slide 1' />
          </div>

          {/* DISCAPACIDAD */}
          <div
            className='carousel-item'
            // onClick={() => handleSlideClick(2)} // Redirige al artículo 2
            onClick={() => navigate('/canalatencion')}
            style={{ cursor: 'pointer' }}
          >
            <img src={carrusel2} className='d-block w-100' alt='Slide 2' />
          </div>
          {/* SORTEO AURICULARES */}
          <div
            className='carousel-item'
            //  onClick={() => handleSlideClick(3)}
            style={{ cursor: 'pointer' }}
          >
            <a
              href='https://forms.office.com/pages/responsepage.aspx?id=DmBElwQ-Lkm6oSXsJFxvEComMK0p3ndAtZpDnDjF5sNUQ1lUQVlJMlVHQTNUTUdTSjZQN0xZMVo3UiQlQCN0PWcu&utm_campaign=sorteo-skills&utm_medium=email&utm_source=acumbamail&route=shorturl'
              target='_blank'
              rel='noreferrer'
            >
              <img src={carrusel3} className='d-block w-100' alt='Slide 3' />
            </a>
          </div>

          {/* SKILLSBANK */}
          <div className='carousel-item' onClick={() => handleSlideClick(2)} style={{ cursor: 'pointer' }}>
            <img src={carrousel4} className='d-block w-100' alt='Slide 4' />
          </div>
        </div>

        <a className='carousel-control-prev' href='#carouselExampleIndicators' role='button' data-bs-slide='prev'>
          <span className='carousel-control-prev-icon' aria-hidden='true'></span>
          <span className='visually-hidden'>Previous</span>
        </a>
        <a className='carousel-control-next' href='#carouselExampleIndicators' role='button' data-bs-slide='next'>
          <span className='carousel-control-next-icon' aria-hidden='true'></span>
          <span className='visually-hidden'>Next</span>
        </a>
      </div>
    </section>
  );
};

export default Carrusel;
