import React, { useState, useEffect } from 'react';
//import { fetchRetenciones } from '../services/apiFolder';

const RetentionsComponent = ({ setSelectedRetenciones, year }) => {
    const [retenciones, setRetenciones] = useState([]);
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const fetchRetencionesData = async () => {
    //         try {
    //             const retencionesData = await fetchRetenciones(year);
    //             setRetenciones(retencionesData);
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Error al obtener las retenciones:', error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchRetencionesData();
    // }, [year]);

    const handleRetencionesSelection = (retencion) => {
        setSelectedRetenciones(retencion);
    };

    return (
        <div>
            <h2>Retenciones</h2>
            <h1> PRÓXIMAMENTE</h1>
           {/*  {loading ? (
                <p>Cargando...</p>
            ) : (
                <ul>
                    {retenciones.map((retencion, index) => (
                        <li key={index} onClick={() => handleRetencionesSelection(retencion)}>
                            {retencion}
                        </li>
                    ))}
                </ul>
            )} */}
        </div>
    );
};

export default RetentionsComponent;
