// src/components/ChangePeople.js

import React, { useState, useEffect } from 'react';
import '../../../../../styles/change/formChangeManager.css';
import elipse355 from '../../../../../../images/ellipse355.png';
import { Link } from 'react-router-dom';
import GeneralInfoComponent from './changeComponents/generalInfoComponent';
import ContactInfoWithPopup from './changeComponents/contactInfoWithPopUp';
import { OrganizationalChangeComponent } from './changeComponents/organizationalChangeComponentPeople';
import { RoleChangeComponent } from './changeComponents/roleChangeComponentPeople';
import { SalaryChangeComponent } from './changeComponents/salaryChangeComponentPeople';
import { LocationChangeComponent } from './changeComponents/locationChangeComponentPeople';
import { ContractChangeComponent } from './changeComponents/contractChangeComponentPeople';
import CommentsForm from './changeComponents/commentsForm';
import HandleSubmit from './changeComponents/handleSubmit';

//Sección de formulario para realizar cambios en el área de People. Se maneja de igual forma que el formulario de cambio de manager, pero con campos específicos.

const ChangeRequest = ({ ticketData, userInfoSSFF }) => {
  //General Info
  const [employeeName, setEmployeeName] = useState(
    ticketData?.employeeName || ''
  );
  const [effectiveDate, setEffectiveDate] = useState(
    ticketData?.effectiveDate || ''
  );
  const [managerName, setManagerName] = useState(ticketData?.managerName || '');
  const [managerEmail, setManagerEmail] = useState(
    ticketData?.managerEmail || ''
  );

  const [changeType, setChangeType] = useState(['info']);
  const [focusedButton, setFocusedButton] = useState('info');
  //Job Classification
  const [isNewJobClasification, setIsNewJobClasification] = useState(false);
  const [jcTitle, setJcTitle] = useState('');
  const [jcLevel, setJcLevel] = useState('');
  const [jcPayGrade, setJcPayGrade] = useState('');
  const [jcTelefónicaLevel, setJcTelefónicaLevel] = useState('');
  const [jcTelefónicaGrade, setJcTelefónicaGrade] = useState('');
  const [legalEntity, setLegalEntity] = useState('');
  const [jobFunction, setJobFunction] = useState('');
  const [jcCreationDate, setJcCreationDate] = useState('');
  //New Position
  const [isNewJobPosition, setIsNewJobPosition] = useState(false);
  const [manager, setManager] = useState('');
  const [businessUnitPosition, setBusinessUnitPosition] = useState('');
  const [divisionPosition, setDivisionPosition] = useState('');
  const [departmentPosition, setDepartmentPosition] = useState('');
  const [costCenterPosition, setCostCenterPosition] = useState('');
  const [globalRolePosition, setGlobalRolePosition] = useState('');
  const [benchmark, setBenchmark] = useState('');
  const [locationPosition, setLocationPosition] = useState('');
  const [jobClassificationPosition, setJobClassificationPosition] =
    useState('');
  //Organizational Change
  const [organizationalChange, setOrganizationalChange] = useState(
    ticketData?.organizationalChange?.reorganization || false
  );
  const [newDepartments, setNewDepartments] = useState(
    ticketData?.organizationalChange?.newDepartments || ''
  );
  const [responsiblePerson, setResponsiblePerson] = useState(
    ticketData?.organizationalChange?.responsiblePerson || ''
  );
  const [hrbp, setHrbp] = useState('');
  const [businessUnit, setBusinessUnit] = useState('');
  const [division, setDivision] = useState('');
  const [department, setDepartment] = useState('');
  const [costCenter, setCostCenter] = useState('');
  const [objectivesResult, setObjectivesResult] = useState('');

  //Role Change
  const [roleChange, setRoleChange] = useState('');
  const [roleChangeDetails, setRoleChangeDetails] = useState(
    ticketData?.roleChange?.roleChangeDetails || ''
  );
  const [managerChange, setManagerChange] = useState(
    ticketData?.roleChange?.managerChange || ''
  );
  const [newManager, setNewManager] = useState(
    ticketData?.roleChange?.newManager || ''
  );
  const [globalRole, setGlobalRole] = useState('');
  const [jobClassification, setJobClassification] = useState('');
  const [category, setCategory] = useState('');
  const [benchmarkCode, setBenchmarkCode] = useState('');
  //Salary Change
  const [salaryChange, setSalaryChange] = useState(
    ticketData?.salaryChange?.salary || ''
  );
  const [currency, setCurrency] = useState(
    ticketData?.salaryChange?.currency || ''
  );
  const [bonusOrIncentives, setBonusOrIncentives] = useState('');
  const [bonusPercentage, setBonusPercentage] = useState('');
  const [incentivesAmount, setIncentivesAmount] = useState(
    ticketData?.salaryChange?.incentives || ''
  );
  const [salaryReason, setSalaryReason] = useState(
    ticketData?.salaryChange?.salaryReason || ''
  );
  //location Change
  const [locationChange, setLocationChange] = useState(
    ticketData?.locationChange?.locationChange || false
  );
  const [city, setCity] = useState(ticketData?.locationChange?.city || '');
  const [country, setCountry] = useState(
    ticketData?.locationChange?.country || ''
  );
  const [building, setBuilding] = useState(
    ticketData?.locationChange?.building || ''
  );
  const [floor, setFloor] = useState(ticketData?.locationChange?.floor || '');

  const [contractChange, setContractChange] = useState(
    ticketData?.contractChange || ''
  );

  const [changeDetails, setChangeDetails] = useState('');

  const [comments, setComments] = useState('');

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);

  const [hasStartedFillingOrgChange, setHasStartedFillingOrgChange] =
    useState(false);
  const [hasStartedFillingRoleChange, setHasStartedFillingRoleChange] =
    useState(false);
  const [hasStartedFillingSalaryChange, setHasStartedFillingSalaryChange] =
    useState(false);
  const [hasStartedFillingLocationChange, setHasStartedFillingLocationChange] =
    useState(false);
  const [hasStartedFillingContractChange, setHasStartedFillingContractChange] =
    useState(false);
  const [hasStartedFillingComments, setHasStartedFillingComments] =
    useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setChangeType(['info']);
    setFocusedButton('info');
  }, [setChangeType, setFocusedButton]);

  const handleMenuClick = (type) => {
    setChangeType([type]);
    setFocusedButton(type);
  };

  useEffect(() => {
    const areFieldsValid =
      effectiveDate.trim() !== '' &&
      (!changeType.length ||
        (changeType.includes('Organizational') &&
          organizationalChange &&
          newDepartments &&
          responsiblePerson &&
          hrbp &&
          businessUnit &&
          division &&
          department &&
          costCenter &&
          objectivesResult) ||
        (changeType.includes('Role') &&
          ((roleChange === 'yes' &&
            roleChangeDetails &&
            ((managerChange === 'yes' && newManager) ||
              managerChange === 'no')) ||
            (roleChange === 'no' &&
              changeDetails &&
              globalRole &&
              jobClassification &&
              category &&
              benchmarkCode))) ||
        (changeType.includes('Salary') &&
          salaryChange &&
          currency &&
          ((bonusOrIncentives === 'bonus' && bonusPercentage) ||
            (bonusOrIncentives === 'incentives' && incentivesAmount)) &&
          salaryReason) ||
        (changeType.includes('Location') &&
          city &&
          country &&
          building &&
          floor) ||
        (changeType.includes('Contract') && contractChange) ||
        comments);

    setIsButtonEnabled(areFieldsValid);
  }, []);

  const handleOrganizationalChange = (value) => {
    setOrganizationalChange(value);
    setHasStartedFillingOrgChange(true);
  };

  const handleRoleChange = (value) => {
    setRoleChange(value);
    setHasStartedFillingRoleChange(true);
  };

  const handleSalaryChange = (value) => {
    setSalaryChange(value);
    setHasStartedFillingSalaryChange(true);
  };

  const handleLocationChange = (value) => {
    setLocationChange(value);
    setHasStartedFillingLocationChange(true);
  };

  const handleContractChange = (value) => {
    setContractChange(value);
    setHasStartedFillingContractChange(true);
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    setHasStartedFillingComments(true);
  };

  const handleClearForm = () => {
    // Función para limpiar el formulario
    setEmployeeName(ticketData?.employeeName || '');
    setEffectiveDate(ticketData?.effectiveDate || '');
    setOrganizationalChange(
      ticketData?.organizationalChange?.reorganization || false
    );
    setNewDepartments(ticketData?.organizationalChange?.newDepartments || '');
    setResponsiblePerson(
      ticketData?.organizationalChange?.responsiblePerson || ''
    );
    setHrbp('');
    setBusinessUnit('');
    setDivision('');
    setDepartment('');
    setRoleChangeDetails(ticketData?.roleChange?.roleChangeDetails || '');
    setManagerChange(ticketData?.roleChange?.managerChange || '');
    setNewManager(ticketData?.roleChange?.newManager || '');
    setGlobalRole('');
    setJobClassification('');
    setCategory('');
    setBenchmarkCode('');
    setChangeDetails('');
    setSalaryChange(ticketData?.salaryChange?.salary || '');
    setCurrency(ticketData?.salaryChange?.currency || '');
    setIncentivesAmount(ticketData?.salaryChange?.incentives || '');
    setBonusOrIncentives('');
    setBonusPercentage('');
    setSalaryReason(ticketData?.salaryChange?.salaryReason || '');
    setLocationChange(ticketData?.locationChange?.locationChange || false);
    setCity(ticketData?.locationChange?.city || '');
    setCountry(ticketData?.locationChange?.country || '');
    setBuilding(ticketData?.locationChange?.building || '');
    setFloor(ticketData?.locationChange?.floor || '');
    setContractChange(ticketData?.contractChange || '');
    setComments(ticketData?.comments || '');

    setIsButtonEnabled(false);
    setHasStartedFillingOrgChange(false);
    setHasStartedFillingRoleChange(false);
    setHasStartedFillingSalaryChange(false);
    setHasStartedFillingLocationChange(false);
    setHasStartedFillingContractChange(false);
    setHasStartedFillingComments(false);
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  return (
    <>
      <section className='formJiraChange'>
        <div className='jiraChangeForm__breadcrumb'>
          <img src={elipse355} alt='circle-icon' className='circle-icon' />
          <Link to='/form' className='jiraChangeForm__breadcrumb--title'>
            INICIO
          </Link>
          <div className='jiraChangeForm__breadcrumb--text'>
            &gt; SOLICITUD DE CAMBIO
          </div>
        </div>
        <h1 className='formJiraChange__title'>Solicitud de Cambio</h1>
        <div className='jiraChangeForm_menuFormContainer'>
          <nav className='jiraChangeForm_menu'>
            <button
              onClick={() => handleMenuClick('info')}
              style={{
                backgroundColor:
                  focusedButton === 'info' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'info' ? '#06f' : 'initial',
              }}
            >
              Información General
            </button>
            <button
              onClick={() => handleMenuClick('Organizational')}
              style={{
                backgroundColor:
                  focusedButton === 'Organizational' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Organizational' ? '#06f' : 'initial',
              }}
            >
              Cambios Organizativos
            </button>
            <button
              onClick={() => handleMenuClick('Role')}
              style={{
                backgroundColor:
                  focusedButton === 'Role' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Role' ? '#06f' : 'initial',
              }}
            >
              Cambio de Rol
            </button>
            <button
              onClick={() => handleMenuClick('Salary')}
              style={{
                backgroundColor:
                  focusedButton === 'Salary' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Salary' ? '#06f' : 'initial',
              }}
            >
              Cambios Salariales
            </button>
            <button
              onClick={() => handleMenuClick('Location')}
              style={{
                backgroundColor:
                  focusedButton === 'Location' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Location' ? '#06f' : 'initial',
              }}
            >
              Cambios de Localización
            </button>
            <button
              onClick={() => handleMenuClick('Contract')}
              style={{
                backgroundColor:
                  focusedButton === 'Contract' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'Contract' ? '#06f' : 'initial',
              }}
            >
              Cambios de Contrato
            </button>
            <button
              onClick={() => handleMenuClick('comments')}
              style={{
                backgroundColor:
                  focusedButton === 'comments' ? '#F2F4FF' : 'initial',
                color: focusedButton === 'comments' ? '#06f' : 'initial',
              }}
            >
              Observaciones
            </button>
          </nav>

          <form
            className='formJiraChange_form'
            onSubmit={(e) =>
              HandleSubmit(
                e,
                effectiveDate,
                changeType,
                hasStartedFillingOrgChange,
                organizationalChange,
                newDepartments,
                responsiblePerson,
                hrbp,
                businessUnit,
                department,
                division,
                costCenter,
                objectivesResult,
                hasStartedFillingRoleChange,
                roleChange,
                roleChangeDetails,
                managerChange,
                newManager,
                globalRole,
                jobClassification,
                category,
                benchmarkCode,
                changeDetails,
                hasStartedFillingSalaryChange,
                salaryChange,
                currency,
                bonusOrIncentives,
                bonusPercentage,
                incentivesAmount,
                salaryReason,
                hasStartedFillingLocationChange,
                city,
                country,
                building,
                floor,
                hasStartedFillingContractChange,
                contractChange,
                comments,
                isButtonEnabled,
                setIsSubmitting,
                handleClearForm,
                setChangeType,
                setFeedbackPopup,
                setMessageSendingFailed,
                employeeName
              )
            }
          >
            <div className='formJiraChange_section'>
              {changeType.includes('info') && (
                <GeneralInfoComponent
                  isNewJobPosition={isNewJobPosition}
                  setIsNewJobPosition={setIsNewJobPosition}
                  jcTitle={jcTitle}
                  setJcTitle={setJcTitle}
                  jcLevel={jcLevel}
                  setJcLevel={setJcLevel}
                  jcPayGrade={jcPayGrade}
                  setJcPayGrade={setJcPayGrade}
                  jcTelefónicaLevel={jcTelefónicaLevel}
                  setJcTelefónicaLevel={setJcTelefónicaLevel}
                  jcTelefónicaGrade={jcTelefónicaGrade}
                  setJcTelefónicaGrade={setJcTelefónicaGrade}
                  legalEntity={legalEntity}
                  setLegalEntity={setLegalEntity}
                  jobFunction={jobFunction}
                  setJobFunction={setJobFunction}
                  jcCreationDate={jcCreationDate}
                  setJcCreationDate={setJcCreationDate}
                  isNewJobClasification={isNewJobClasification}
                  setIsNewJobClasification={setIsNewJobClasification}
                  effectiveDate={effectiveDate}
                  setEffectiveDate={setEffectiveDate}
                  businessUnitPosition={businessUnitPosition}
                  setBusinessUnitPosition={setBusinessUnitPosition}
                  divisionPosition={divisionPosition}
                  setDivisionPosition={setDivisionPosition}
                  departmentPosition={departmentPosition}
                  setDepartmentPosition={setDepartmentPosition}
                  costCenterPosition={costCenterPosition}
                  setCostCenterPosition={setCostCenterPosition}
                  globalRolePosition={globalRolePosition}
                  setGlobalRolePosition={setGlobalRolePosition}
                  benchmark={benchmark}
                  setBenchmark={setBenchmark}
                  locationPosition={locationPosition}
                  setLocationPosition={setLocationPosition}
                  jobClassificationPosition={jobClassificationPosition}
                  setJobClassificationPosition={setJobClassificationPosition}
                  manager={manager}
                  setManager={setManager}
                  employeeName={employeeName}
                  managerName={managerName}
                  managerEmail={managerEmail}
                />
              )}
              {changeType.includes('Organizational') && (
                <OrganizationalChangeComponent
                  organizationalChange={organizationalChange}
                  newDepartments={newDepartments}
                  responsiblePerson={responsiblePerson}
                  hrbp={hrbp}
                  businessUnit={businessUnit}
                  division={division}
                  department={department}
                  costCenter={costCenter}
                  objectivesResult={objectivesResult}
                  setOrganizationalChange={handleOrganizationalChange}
                  setNewDepartments={setNewDepartments}
                  setResponsiblePerson={setResponsiblePerson}
                  setHrbp={setHrbp}
                  setBusinessUnit={setBusinessUnit}
                  setDivision={setDivision}
                  setDepartment={setDepartment}
                  setCostCenter={setCostCenter}
                  setObjectivesResult={setObjectivesResult}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {changeType.includes('Role') && (
                <RoleChangeComponent
                  roleChange={roleChange}
                  roleChangeDetails={roleChangeDetails}
                  changeDetails={changeDetails}
                  managerChange={managerChange}
                  newManager={newManager}
                  globalRole={globalRole}
                  jobClassification={jobClassification}
                  category={category}
                  benchmarkCode={benchmarkCode}
                  setRoleChange={handleRoleChange}
                  setRoleChangeDetails={setRoleChangeDetails}
                  setChangeDetails={setChangeDetails}
                  setManagerChange={setManagerChange}
                  setNewManager={setNewManager}
                  setGlobalRole={setGlobalRole}
                  setJobClassification={setJobClassification}
                  setCategory={setCategory}
                  setBenchmarkCode={setBenchmarkCode}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {changeType.includes('Salary') && (
                <SalaryChangeComponent
                  salaryChange={salaryChange}
                  currency={currency}
                  bonusOrIncentives={bonusOrIncentives}
                  bonusPercentage={bonusPercentage}
                  incentivesAmount={incentivesAmount}
                  salaryReason={salaryReason}
                  setSalaryChange={handleSalaryChange}
                  setCurrency={setCurrency}
                  setBonusOrIncentives={setBonusOrIncentives}
                  setBonusPercentage={setBonusPercentage}
                  setIncentivesAmount={setIncentivesAmount}
                  setSalaryReason={setSalaryReason}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {changeType.includes('Location') && (
                <LocationChangeComponent
                  locationChange={locationChange}
                  city={city}
                  country={country}
                  building={building}
                  floor={floor}
                  setLocationChange={handleLocationChange}
                  setCity={setCity}
                  setCountry={setCountry}
                  setBuilding={setBuilding}
                  setFloor={setFloor}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {changeType.includes('Contract') && (
                <ContractChangeComponent
                  contractChange={contractChange}
                  setContractChange={handleContractChange}
                />
              )}
              {changeType.includes('comments') && (
                <CommentsForm
                  comments={comments}
                  handleCommentsChange={handleCommentsChange}
                  setComments={setComments}
                  /* showCommentsRequired={showCommentsRequired}
						setShowCommentsRequired={setShowCommentsRequired} */
                />
              )}
            </div>
            <div className='formJiraChangeButton_section'>
              <button
                className={`formJiraChangeButton ${
                  isButtonEnabled ? 'enabled' : 'disabled'
                }`}
                type='submit'
                //disabled={!isButtonEnabled}
              >
                Enviar
              </button>
              {isSubmitting && <div className='loader'></div>}
              <button onClick={handleClearForm} className='secondaryButton'>
                Limpiar
              </button>
            </div>
          </form>
        </div>
        <ContactInfoWithPopup
          feedbackPopup={feedbackPopup}
          messageSendingFailed={messageSendingFailed}
          handleCloseFeedbackPopup={handleCloseFeedbackPopup}
        />
      </section>
    </>
  );
};

export default ChangeRequest;
